import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { loadWorkspaceAccountsFilterValues } from '../../api';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Filter from '../../components/Filter';
import FilterStub from '../../components/FilterStub';
import queryKeys from '../../config/queryKeys';
import useFilter from '../../hooks/useFilter';
import {
  TRAINER_GAME_TYPE_LABELS,
  TRAINER_LIMITS_LABELS,
  TRAINER_POKER_ROOM_LABELS,
} from '../../utils/trainer';

interface Fields {
  stage_in: string[];
  room_in: string[];
  club_in: string[];
  game_type_in: string[];
  limit_in: string[];
  bot_settings_in: string[];
  member_in: string[];
  identity_in: string[];
}

interface Props {
  workspaceId: WorkspaceSchema['id'];
  total?: number;
}

const WorkspaceDashboardFilter = (props: Props) => {
  const { workspaceId, total } = props;

  const { t } = useTranslation();

  const queryKey = [queryKeys.workspaceAccountsFilterValues({ workspaceId })];
  const queryFn = () => loadWorkspaceAccountsFilterValues({ workspaceId });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    placeholderData: keepPreviousData,
    meta: { ignoredStatuses: [403] },
  });

  const filter = useFilter<Fields>({
    stage_in: 'multi',
    room_in: 'multi',
    club_in: 'multi',
    game_type_in: 'multi',
    limit_in: 'multi',
    bot_settings_in: 'multi',
    member_in: 'multi',
    identity_in: 'multi',
  });

  const { values, setValue, clearValue } = filter;

  const {
    stage_in,
    room_in,
    club_in,
    game_type_in,
    limit_in,
    bot_settings_in,
    member_in,
    identity_in,
  } = values;

  const filterProps = { total, filter };

  if (isPending) {
    return (
      <Filter {...filterProps}>
        <Filter.Placeholder />
      </Filter>
    );
  }

  if (!data) return <FilterStub />;

  const { stage_name, room, clubs, game_types, comparative_limits, bot_settings, user, identity } =
    data;

  return (
    <Filter {...filterProps}>
      <div>
        {stage_name.length !== 0 && (
          <Filter.Collapse
            label={t('common.stage')}
            count={stage_in.length}
            onClear={clearValue('stage_in')}
          >
            <Filter.MultiCheckbox
              config={stage_name.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('stage_in'),
                checked: stage_in.includes(item.id),
              }))}
            />
          </Filter.Collapse>
        )}
        {room.length !== 0 && (
          <Filter.Collapse
            label={t('common.poker_room')}
            count={room_in.length}
            onClear={clearValue('room_in')}
          >
            <Filter.MultiCheckbox
              config={room.map((item) => ({
                value: String(item),
                label: TRAINER_POKER_ROOM_LABELS[item],
                onChange: setValue('room_in'),
                checked: room_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        {game_types.length !== 0 && (
          <Filter.Collapse
            label={t('common.game_type')}
            count={game_type_in.length}
            onClear={clearValue('game_type_in')}
          >
            <Filter.MultiCheckbox
              config={game_types.map((item) => ({
                value: String(item),
                label: TRAINER_GAME_TYPE_LABELS[item],
                onChange: setValue('game_type_in'),
                checked: game_type_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        {comparative_limits.length !== 0 && (
          <Filter.Collapse
            label={t('common.limit')}
            count={limit_in.length}
            onClear={clearValue('limit_in')}
          >
            <Filter.MultiCheckbox
              config={comparative_limits.map((item) => ({
                value: String(item),
                label: TRAINER_LIMITS_LABELS[item],
                onChange: setValue('limit_in'),
                checked: limit_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        {clubs.length !== 0 && (
          <Filter.Collapse
            label={t('common.club')}
            count={club_in.length}
            onClear={clearValue('club_in')}
          >
            <Filter.MultiCheckbox
              config={clubs.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('club_in'),
                checked: club_in.includes(item.id),
                addon: item.code,
              }))}
              searchKeys={['addon']}
            />
          </Filter.Collapse>
        )}
        {bot_settings.length !== 0 && (
          <Filter.Collapse
            label={t('common.bot_settings')}
            count={bot_settings_in.length}
            onClear={clearValue('bot_settings_in')}
          >
            <Filter.MultiCheckbox
              config={bot_settings.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('bot_settings_in'),
                checked: bot_settings_in.includes(item.id),
              }))}
            />
          </Filter.Collapse>
        )}
        {user.length !== 0 && (
          <Filter.Collapse
            label={t('common.member')}
            count={member_in.length}
            onClear={clearValue('member_in')}
          >
            <Filter.MultiCheckbox
              config={user.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('member_in'),
                checked: member_in.includes(item.id),
              }))}
            />
          </Filter.Collapse>
        )}
        {identity.length !== 0 && (
          <Filter.Collapse
            label={t('common.identity')}
            count={identity_in.length}
            onClear={clearValue('identity_in')}
          >
            <Filter.MultiCheckbox
              config={identity.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('identity_in'),
                checked: identity_in.includes(item.id),
              }))}
            />
          </Filter.Collapse>
        )}
      </div>
    </Filter>
  );
};

export default WorkspaceDashboardFilter;
