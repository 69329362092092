import { useTranslation } from 'react-i18next';
import { ChannelSchema } from '../../api/schemas/channelSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Button from '../../components/Button';
import PageAddon from '../../components/PageAddon';
import ShowButton from '../../components/ShowButton';
import Tooltip from '../../components/Tooltip';
import icon from '../../static/icons';
import { copyHost, copyIp, copyPassword, copyUsername } from '../../utils/clipboard';
import { formatAbsoluteDate } from '../../utils/date';
import WorkspaceChannelDetailDropdown from './WorkspaceChannelDetailDropdown';
import WorkspaceChannelStatus from './WorkspaceChannelStatus';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  data: ChannelSchema;
  onClose: () => void;
  onEdit: () => void;
}

const WorkspaceChannelDetail = (props: Props) => {
  const { workspaceId, data, onClose, onEdit } = props;

  const { t } = useTranslation();

  const {
    id,
    username,
    password,
    host,
    port,
    ip,
    country,
    avg_latency,
    packet_loss_percent,
    is_used,
    is_deleted,
    modified_on,
    created_on,
  } = data;

  const titleIcons = is_deleted && icon('trash', 12);

  const hostPort = `${host}:${port}`;

  return (
    <PageAddon onClose={onClose} title={host} icons={titleIcons}>
      <PageAddon.Fields>
        <PageAddon.Id id={id} />
        <PageAddon.Field label={t('common.username')}>
          <PageAddon.Copy onClick={() => copyUsername(username)}>{username}</PageAddon.Copy>
        </PageAddon.Field>
        <PageAddon.Field label={t('common.password')}>
          <ShowButton>
            <PageAddon.Copy onClick={() => copyPassword(password)}>{password}</PageAddon.Copy>
          </ShowButton>
        </PageAddon.Field>
        <PageAddon.Toggler label={t('common.connection')}>
          <WorkspaceChannelStatus data={data} />
        </PageAddon.Toggler>
        <PageAddon.Field label={t('common.host')}>
          <PageAddon.Copy onClick={() => copyHost(hostPort)}>{hostPort}</PageAddon.Copy>
        </PageAddon.Field>
        <PageAddon.Field label={t('common.country')}>{country || '—'}</PageAddon.Field>
        <PageAddon.Field label={t('common.ip')}>
          {ip ? <PageAddon.Copy onClick={() => copyIp(ip)}>{ip}</PageAddon.Copy> : '—'}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.avg_latency_ms')}>{avg_latency || '—'}</PageAddon.Field>
        <PageAddon.Field label={t('common.packet_loss_%')}>{packet_loss_percent}</PageAddon.Field>
        <PageAddon.Field label={t('common.used')}>
          {is_used ? t('common.true') : t('common.false')}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.modified')}>
          {formatAbsoluteDate(modified_on)}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.created')}>
          {formatAbsoluteDate(created_on)}
        </PageAddon.Field>
      </PageAddon.Fields>
      {!is_deleted && (
        <PageAddon.Controls>
          <PageAddon.Controls.Group>
            <Tooltip label={t('common.edit')} hideOnMobile>
              <Button
                variant="transparent"
                icon={icon('edit', 16)}
                onClick={onEdit}
                data-testid="edit"
              />
            </Tooltip>
            <WorkspaceChannelDetailDropdown data={data} workspaceId={workspaceId} />
          </PageAddon.Controls.Group>
        </PageAddon.Controls>
      )}
    </PageAddon>
  );
};

export default WorkspaceChannelDetail;
