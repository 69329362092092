import { useTranslation } from 'react-i18next';
import { AccountTablesSearchSchema, STATUS } from '../../../api/schemas/accountTablesSearchSchema';
import Button from '../../../components/Button';
import Collapse from '../../../components/Collapse';
import PageAddon from '../../../components/PageAddon';
import i18n from '../../../i18n';
import icon from '../../../static/icons';
import { copyId } from '../../../utils/clipboard';
import { capitalize, convertCentsToDollars } from '../../../utils/data';
import { formatAbsoluteDate } from '../../../utils/date';
import AccountTable from '../AccountTable';
import { CommonAccountTableSchema } from '../types';
import styles from './styles.module.scss';

const STATUS_LABELS = {
  [STATUS.NOTHING]: i18n.t('common.nothing'),
  [STATUS.GOOD_TABLE]: i18n.t('common.good_table'),
  [STATUS.BAD_TABLE]: i18n.t('common.bad_table'),
  [STATUS.RESERVED]: i18n.t('common.reserved'),
  [STATUS.BANNED]: i18n.t('common.banned'),
};

interface Props {
  tables: CommonAccountTableSchema['tables'];
  tablesSearch: AccountTablesSearchSchema[];
  tablesSearchLoading: boolean;
  tableClosing: boolean;
  canSearchTables: boolean;
  canLeaveTable: boolean;
  onTablesSearchRefresh: () => void;
  onCloseTable: (tableId: CommonAccountTableSchema['tables'][number]['id']) => void;
}

const AccountDetailTablesTab = (props: Props) => {
  const {
    tables,
    tablesSearch,
    tablesSearchLoading,
    tableClosing,
    canSearchTables,
    canLeaveTable,
    onTablesSearchRefresh,
    onCloseTable,
  } = props;

  const { t } = useTranslation();

  const empty = <div className={styles.empty}>{t('common.no_tables')}</div>;

  const renderTables = () => {
    if (!tables.length) return empty;

    return (
      <Collapse.Group>
        {tables.map((item) => (
          <AccountTable
            key={item.id}
            data={item}
            closing={tableClosing}
            canLeaveTable={canLeaveTable}
            onClose={() => onCloseTable(item.id)}
          />
        ))}
      </Collapse.Group>
    );
  };

  const renderSearchTables = () => {
    if (!tablesSearch.length) return empty;

    return (
      <Collapse.Group>
        {tablesSearch.map((item) => (
          <Collapse
            key={item.id}
            label={
              <Collapse.LabelCopy onClick={() => copyId(item.id)}>{item.id}</Collapse.LabelCopy>
            }
          >
            <Collapse.InfoField label={t('common.sb')}>
              {convertCentsToDollars(item.sb)}
            </Collapse.InfoField>
            <Collapse.InfoField label={t('common.bb')}>
              {convertCentsToDollars(item.bb)}
            </Collapse.InfoField>
            <Collapse.InfoField label={t('common.name')}>{item.name}</Collapse.InfoField>
            <Collapse.InfoField label={t('common.max_no_of_seats')}>
              {item.max_no_of_seats}
            </Collapse.InfoField>
            <Collapse.InfoField label={t('common.players')}>
              {item.player_count ?? '—'}
            </Collapse.InfoField>
            <Collapse.InfoField label={t('common.club_table')}>
              {capitalize(String(item.club_table))}
            </Collapse.InfoField>
            <Collapse.InfoField label={t('common.lifetime')}>
              {capitalize(String(item.lifetime))}
            </Collapse.InfoField>
            <Collapse.InfoField label={t('common.color')}>{item.color || '—'}</Collapse.InfoField>
            <Collapse.InfoField label={t('common.color_updated_on')}>
              {item.color_updated_on ? formatAbsoluteDate(item.color_updated_on) : '—'}
            </Collapse.InfoField>
            <Collapse.InfoField label={t('common.updated_on')}>
              {formatAbsoluteDate(item.updated_on)}
            </Collapse.InfoField>
            <Collapse.InfoField label={t('common.status')}>
              {item.status === null ? '—' : STATUS_LABELS[item.status]}
            </Collapse.InfoField>
            <Collapse.InfoField label={t('common.status_updated_on')}>
              {item.status_updated_on ? formatAbsoluteDate(item.status_updated_on) : '—'}
            </Collapse.InfoField>
          </Collapse>
        ))}
      </Collapse.Group>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.group}>
        <div className={styles.title}>
          <PageAddon.Heading level="second">{t('common.playing')}</PageAddon.Heading>
        </div>
        {renderTables()}
      </div>
      {canSearchTables && (
        <div className={styles.group}>
          <div className={styles.title}>
            <PageAddon.Heading level="second">{t('common.suited')}</PageAddon.Heading>
            <Button
              variant="light"
              size="extra-small"
              icon={icon('rotate', 16)}
              disabled={tablesSearchLoading}
              onClick={onTablesSearchRefresh}
            >
              {t('common.refresh')}
            </Button>
          </div>
          {renderSearchTables()}
        </div>
      )}
    </div>
  );
};

export default AccountDetailTablesTab;
