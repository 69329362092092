import { useTranslation } from 'react-i18next';
import { WorkspaceAccountSchema } from '../../api/schemas/account/workspaceAccountSchema';
import { IdentitySchema } from '../../api/schemas/identitySchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import { WorkspaceUserSchema } from '../../api/schemas/workspaceUserSchema';
import ColorTag from '../../components/ColorTag';
import FadingText from '../../components/FadingText';
import Link from '../../components/Link';
import PageAddon from '../../components/PageAddon';
import ShowButton from '../../components/ShowButton';
import { copyPid } from '../../utils/clipboard';
import { formatAbsoluteDate } from '../../utils/date';
import { TRAINER_POKER_ROOM_LABELS } from '../../utils/trainer';
import { checkWorkspaceHasChannelsAccess } from '../../utils/workspace';
import AccountBotSettings from '../account/AccountBotSettings';
import AccountChannels from '../account/AccountChannels';
import AccountGeolocation from '../account/AccountGeolocation';
import AccountRoomConfig from '../account/AccountRoomConfig';
import AccountStatus from '../account/AccountStatus';
import { getGameTypes, getLimits, getTableSizes } from '../account/helpers';
import ClubPageAddonTags from '../club/ClubPageAddonTags';
import { getTitle } from '../session/helpers';
import { getIdentityFullName } from '../workspace-identity/helpers';
import { getDeals } from './helpers';
import WorkspaceAccountMetrics from './WorkspaceAccountMetrics';
import WorkspaceAccountRoomConfigLoader from './WorkspaceAccountRoomConfig/WorkspaceAccountRoomConfigLoader';
import WorkspaceAccountStrategyProfile from './WorkspaceAccountStrategyProfile';

interface Props {
  data: WorkspaceAccountSchema;
  workspaceId: WorkspaceSchema['id'];
  onStrategyProfileEdit: () => void;
  onRoomConfig?: () => void;
  getUserRoute?: (args: { userId: WorkspaceUserSchema['id'] }) => string;
  getIdentityRoute?: (args: { identityId: IdentitySchema['id'] }) => string;
}

const WorkspaceAccountDetailDetailsTab = (props: Props) => {
  const { data, workspaceId, onStrategyProfileEdit, getUserRoute, getIdentityRoute, onRoomConfig } =
    props;

  const { t } = useTranslation();

  const {
    id,
    stage,
    room,
    tags,
    max_tables,
    display_name,
    email,
    email_password,
    pid,
    user,
    identity,
    clubs,
    stopped_clubs,
    affiliate_reference_number,
    trainer_version,
    next_session,
    last_online,
    last_played,
    created_on,
    modified_on,
  } = data;

  const workspaceHasChannelsAccess = checkWorkspaceHasChannelsAccess(workspaceId);

  const getTags = () => {
    if (!tags.length) return '—';

    return tags.map((item) => <ColorTag key={item.id} label={item.name} color={item.color} />);
  };

  const getUser = () => {
    if (!user) return '—';

    const content = user.username;

    if (getUserRoute) {
      return <Link to={getUserRoute({ userId: user.id })}>{content}</Link>;
    }

    return content;
  };

  const getIdentity = () => {
    if (!identity) return '—';

    const content = getIdentityFullName(identity);

    if (getIdentityRoute) {
      return <Link to={getIdentityRoute({ identityId: identity.id })}>{content}</Link>;
    }

    return content;
  };

  return (
    <PageAddon.Fields>
      <PageAddon.Id id={id} />
      <PageAddon.Field label={t('common.nickname')}>
        <FadingText text={display_name || '—'} />
      </PageAddon.Field>
      <PageAddon.Field label={t('common.status')}>
        <AccountStatus data={data} />
      </PageAddon.Field>
      <PageAddon.Field label={t('common.stage')}>{stage.name}</PageAddon.Field>
      <PageAddon.Field label={t('common.poker_room')}>
        {TRAINER_POKER_ROOM_LABELS[room]}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.clubs')}>
        <ClubPageAddonTags data={clubs} stoppedClubs={stopped_clubs} />
      </PageAddon.Field>
      <PageAddon.Field label={t('common.game_types')}>
        <PageAddon.Tags>{getGameTypes(data)}</PageAddon.Tags>
      </PageAddon.Field>
      <PageAddon.Field label={t('common.limits')}>
        <PageAddon.Tags>{getLimits(data)}</PageAddon.Tags>
      </PageAddon.Field>
      <PageAddon.Field label={t('common.table_size')}>
        <PageAddon.Tags>{getTableSizes(data)}</PageAddon.Tags>
      </PageAddon.Field>
      <PageAddon.Field label={t('common.max_tables')}>{max_tables}</PageAddon.Field>
      <PageAddon.Field label={t('common.pid')}>
        {pid ? <PageAddon.Copy onClick={() => copyPid(pid)}>{pid}</PageAddon.Copy> : '—'}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.geolocation')}>
        <AccountGeolocation data={data} />
      </PageAddon.Field>
      {workspaceHasChannelsAccess && <AccountChannels data={data} />}
      <PageAddon.Field label={t('common.next_session')}>
        {next_session ? getTitle({ start: next_session.start, end: next_session.end }) : '—'}
      </PageAddon.Field>
      <PageAddon.Collapse label={t('common.strategy_profile')}>
        <WorkspaceAccountStrategyProfile
          workspaceId={workspaceId}
          account={data}
          onEdit={onStrategyProfileEdit}
        />
      </PageAddon.Collapse>
      <PageAddon.Collapse label={t('common.email')}>
        <PageAddon.Collapse.Field label={t('common.email')}>
          <FadingText text={email || '—'} />
        </PageAddon.Collapse.Field>
        <PageAddon.Collapse.Field label={t('common.password')}>
          {email_password ? <ShowButton>{email_password}</ShowButton> : '—'}
        </PageAddon.Collapse.Field>
      </PageAddon.Collapse>
      <PageAddon.Collapse label={t('common.poker_room_config')}>
        <WorkspaceAccountRoomConfigLoader addon workspaceId={workspaceId} accountId={id}>
          {(args) => (
            <AccountRoomConfig workspace data={args.data} account={data} onEdit={onRoomConfig} />
          )}
        </WorkspaceAccountRoomConfigLoader>
      </PageAddon.Collapse>
      <PageAddon.Collapse label={t('common.metrics')}>
        <WorkspaceAccountMetrics data={data} />
      </PageAddon.Collapse>
      <PageAddon.Field label={t('common.tags')}>
        <PageAddon.Tags>{getTags()}</PageAddon.Tags>
      </PageAddon.Field>
      <PageAddon.Field label={t('common.bot_settings')}>
        <AccountBotSettings data={data} />
      </PageAddon.Field>
      <PageAddon.Field label={t('common.deals')}>
        <PageAddon.Tags>{getDeals(data)}</PageAddon.Tags>
      </PageAddon.Field>
      <PageAddon.Field label={t('common.member')}>{getUser()}</PageAddon.Field>
      <PageAddon.Field label={t('common.identity')}>{getIdentity()}</PageAddon.Field>
      <PageAddon.Field.AffiliateReference value={affiliate_reference_number} />
      <PageAddon.Field label={t('common.trainer_version')}>
        {trainer_version || '—'}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.last_online')}>
        {formatAbsoluteDate(last_online)}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.last_played')}>
        {formatAbsoluteDate(last_played)}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.modified')}>
        {formatAbsoluteDate(modified_on)}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.created')}>
        {formatAbsoluteDate(created_on)}
      </PageAddon.Field>
    </PageAddon.Fields>
  );
};

export default WorkspaceAccountDetailDetailsTab;
