import i18n from '../../../i18n';

export const getFieldGroups = (variant: 'view' | 'csv') => {
  const commonCoreSettingsFields = [
    { value: 3, label: i18n.t('common.poker_room') },
    { value: 4, label: i18n.t('common.club_name') },
    { value: 24, label: i18n.t('common.club_code') },
    { value: 5, label: i18n.t('common.game_types') },
    { value: 6, label: i18n.t('common.limits') },
    { value: 8, label: i18n.t('common.max_tables') },
    { value: 7, label: i18n.t('common.table_sizes') },
    { value: 11, label: i18n.t('common.geolocation') },
    { value: 12, label: i18n.t('common.channels') },
    { value: 17, label: i18n.t('common.bot_settings') },
  ];

  const commonAdditionalInfoFields = [
    { value: 16, label: i18n.t('common.tags') },
    { value: 19, label: i18n.t('common.affiliate_reference') },
    { value: 23, label: i18n.t('common.member') },
    { value: 37, label: i18n.t('common.notes') },
    { value: 10, label: i18n.t('common.pid') },
  ];

  const commonMetricsFields = [
    { value: 13, label: i18n.t('common.hands') },
    { value: 14, label: i18n.t('common.net_win') },
    { value: 15, label: i18n.t('common.bb/100') },
  ];

  const activityStatus = {
    label: i18n.t('common.activity_status'),
    fields: [
      { value: 9, label: i18n.t('common.status') },
      { value: 21, label: i18n.t('common.pilot') },
      { value: 25, label: i18n.t('common.next_session') },
    ],
  };

  const balance = {
    label: i18n.t('common.balance'),
    fields: [
      { value: 38, label: i18n.t('common.club_balance') },
      { value: 39, label: i18n.t('common.lobby_balance') },
    ],
  };

  const dates = {
    label: i18n.t('common.dates'),
    fields: [
      { value: 1, label: i18n.t('common.created') },
      { value: 2, label: i18n.t('common.modified') },
      { value: 20, label: i18n.t('common.last_online') },
      { value: 22, label: i18n.t('common.last_played') },
    ],
  };

  const identity = {
    label: i18n.t('common.identity'),
    fields: [
      { value: 18, label: i18n.t('common.identity') },
      { value: 26, label: i18n.t('common.bank_accounts') },
      { value: 27, label: i18n.t('common.date_of_birth') },
      { value: 28, label: i18n.t('common.documents') },
      { value: 29, label: i18n.t('common.full_name') },
      { value: 30, label: i18n.t('common.gender') },
      { value: 32, label: i18n.t('common.online_wallets') },
      { value: 33, label: i18n.t('common.phones') },
      { value: 34, label: i18n.t('common.photos') },
      { value: 35, label: i18n.t('common.social_networks') },
      { value: 31, label: i18n.t('common.location') },
    ],
  };

  const techInfo = {
    label: i18n.t('common.tech_info'),
    fields: [{ value: 36, label: i18n.t('common.trainer_version') }],
  };

  let fieldGroups;

  if (variant === 'view') {
    fieldGroups = [
      {
        label: i18n.t('common.core_settings'),
        fields: commonCoreSettingsFields,
      },
      {
        label: i18n.t('common.additional_info'),
        fields: [
          ...commonAdditionalInfoFields,
          { value: 40, label: i18n.t('common.nickname') },
          { value: 41, label: i18n.t('common.email') },
          { value: 43, label: i18n.t('common.deals') },
        ],
      },
      activityStatus,
      balance,
      dates,
      {
        label: i18n.t('common.metrics'),
        fields: [...commonMetricsFields, { value: 42, label: i18n.t('common.vpip') }],
      },
      identity,
      techInfo,
    ];
  } else {
    fieldGroups = [
      {
        label: i18n.t('common.core_settings'),
        fields: [
          ...commonCoreSettingsFields,
          { value: 40, label: i18n.t('common.poker_room_config') },
          { value: 64, label: i18n.t('common.id') },
        ],
      },
      {
        label: i18n.t('common.additional_info'),
        fields: [
          ...commonAdditionalInfoFields,
          { value: 61, label: i18n.t('common.nickname') },
          { value: 62, label: i18n.t('common.email') },
          { value: 65, label: i18n.t('common.email_password') },
          { value: 66, label: i18n.t('common.deals') },
        ],
      },
      activityStatus,
      balance,
      dates,
      {
        label: i18n.t('common.metrics'),
        fields: [...commonMetricsFields, { value: 63, label: i18n.t('common.vpip') }],
      },
      identity,
      {
        label: i18n.t('common.strategy_profile'),
        fields: [
          { value: 41, label: i18n.t('common.buyin_bb') },
          { value: 42, label: i18n.t('common.rebuy_threshold_bb') },
          { value: 43, label: i18n.t('common.stop_loss_bb') },
          { value: 44, label: i18n.t('common.stop_win_bb') },
          { value: 45, label: i18n.t('common.max_swing_bb') },
          { value: 46, label: i18n.t('common.stop_loss_by_table_bb') },
          { value: 47, label: i18n.t('common.stop_win_by_table_bb') },
          { value: 48, label: i18n.t('common.max_stack_size_bb') },
          { value: 49, label: i18n.t('common.effective_stack_limit_bb') },
          { value: 50, label: i18n.t('common.table_min_hands') },
          { value: 51, label: i18n.t('common.buyin_range_min') },
          { value: 52, label: i18n.t('common.buyin_range_max') },
          { value: 53, label: i18n.t('common.straddle_tables_buyin_range_min') },
          { value: 54, label: i18n.t('common.straddle_tables_buyin_range_max') },
          { value: 55, label: i18n.t('common.straddle_tables_rebuy_threshold_bb') },
          { value: 56, label: i18n.t('common.player_type') },
          { value: 57, label: i18n.t('common.buyin_step_bb') },
          { value: 58, label: i18n.t('common.straddle_tables_buyin_range_bb') },
          { value: 59, label: i18n.t('common.wait_for_big_blind') },
          { value: 60, label: i18n.t('common.seating_preference') },
        ],
      },
      techInfo,
    ];
  }

  return fieldGroups.map((item) => ({
    ...item,
    fields: item.fields.sort((a, b) => a.label.localeCompare(b.label)),
  }));
};
