import api from '../../services/api';
import URLParams from '../../types/URLParams';
import dealSchema from '../schemas/deal/dealSchema';
import paginationSchemaNew from '../schemas/paginationSchemaNew';

const loadGlobalDeals = async (params?: URLParams) => {
  const response = await api.get('/api/deal', { params });

  return paginationSchemaNew(dealSchema).parse(response.data);
};

export default loadGlobalDeals;
