import { SeatControlRuleSchema } from '../../api/schemas/seatControlRuleSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Dropdown from '../../components/Dropdown';
import useDropdown from './useDropdown';

interface Props {
  data: SeatControlRuleSchema;
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceSeatControlRuleDropdown = (props: Props) => {
  const { title, disabled, options } = useDropdown(props);

  return (
    <Dropdown
      title={title}
      buttonSize="small"
      disabled={disabled}
      options={[options.edit, options.copy, options.divider, options.delete]}
    />
  );
};

export default WorkspaceSeatControlRuleDropdown;
