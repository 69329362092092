import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  endOfDay,
  format,
  formatDistanceToNowStrict,
  isAfter,
  isBefore,
  isThisYear,
  startOfDay,
} from 'date-fns';
import { enUS } from 'date-fns/locale';
import { toZonedTime } from 'date-fns-tz';
import { TimeZoneSchema } from '../api/schemas/timeZoneSchema';
import Tooltip from '../components/Tooltip';
import { MONTH_DAY_TIME, MONTH_DAY_YEAR } from '../constants/DATE_FORMATS';
import i18n, { LANGUAGES } from '../i18n';
import { useTimeZoneStore } from '../store/timeZone';

const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const LOCALE = {
  [LANGUAGES.EN]: enUS,
};

export const getTimeZoneFormat = (timeZone: TimeZoneSchema) => {
  if (timeZone === 'local') return localTimeZone;

  return timeZone;
};

export const getDate = (value?: string) => {
  const storeTimeZone = useTimeZoneStore.getState().timeZone;

  const timeZone = getTimeZoneFormat(storeTimeZone);

  return toZonedTime(value ? new Date(value) : new Date(), timeZone);
};

const formatToRelativeDate = (value: string) =>
  formatDistanceToNowStrict(getDate(value), {
    addSuffix: true,
    locale: LOCALE[i18n.language],
  });

const getStepInMinutes = (value: string) => {
  const diffInMinutes = differenceInMinutes(getDate(), getDate(value));

  if (diffInMinutes >= 0 && diffInMinutes < 5) return i18n.t('date.minute_ago', { count: 1 });
  if (diffInMinutes >= 5 && diffInMinutes < 10) return i18n.t('date.minute_ago', { count: 5 });
  if (diffInMinutes >= 10 && diffInMinutes < 15) return i18n.t('date.minute_ago', { count: 10 });
  if (diffInMinutes >= 15 && diffInMinutes < 30) return i18n.t('date.minute_ago', { count: 15 });
  if (diffInMinutes >= 30 && diffInMinutes < 45) return i18n.t('date.minute_ago', { count: 30 });
  if (diffInMinutes >= 45 && diffInMinutes < 60) return i18n.t('date.minute_ago', { count: 45 });

  return formatToRelativeDate(value);
};

export const formatAbsoluteDate = (value?: string | null) => {
  if (!value) return '—';

  const date = getDate(value);

  if (isThisYear(date)) return format(date, MONTH_DAY_TIME);

  return format(date, MONTH_DAY_YEAR);
};

export const formatRelativeDate = (value?: string | null) => {
  if (!value) return '—';

  const absoluteDate = formatAbsoluteDate(value);

  const date = getDate(value);
  const currentDate = getDate();

  const lessThanDay = !differenceInDays(currentDate, date);

  if (lessThanDay) {
    const lessThanHour = !differenceInHours(currentDate, date);

    return (
      <Tooltip label={absoluteDate}>
        <div>{lessThanHour ? getStepInMinutes(value) : formatToRelativeDate(value)}</div>
      </Tooltip>
    );
  }

  return absoluteDate;
};

export const formatDateWithoutTime = (value?: string) => {
  if (!value) return '—';

  return format(getDate(value), MONTH_DAY_YEAR);
};

export const isDateAfter = (date: Date, value: string) => isAfter(date, endOfDay(new Date(value)));

export const isDateBefore = (date: Date, value?: string) =>
  isBefore(date, startOfDay(value ? new Date(value) : new Date()));
