import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { WorkspaceAccountSchema } from '../../api/schemas/account/workspaceAccountSchema';
import { IdentitySchema } from '../../api/schemas/identitySchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import { WorkspaceUserSchema } from '../../api/schemas/workspaceUserSchema';
import Button from '../../components/Button';
import Notes from '../../components/Notes';
import PageAddon from '../../components/PageAddon';
import Tooltip from '../../components/Tooltip';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import icon from '../../static/icons';
import AccountPilotButton from '../account/AccountPilotButton';
import AccountPilotView from '../account/AccountPilotView';
import { getScheduleTooltip } from '../account/helpers';
import { OnTransactionsOpen } from '../account/types';
import { useWorkspaceAccountTablesQuery } from './queries';
import WorkspaceAccountDetailBalanceTab from './WorkspaceAccountDetailBalanceTab';
import WorkspaceAccountDetailDetailsTab from './WorkspaceAccountDetailDetailsTab';
import WorkspaceAccountDetailDropdown from './WorkspaceAccountDetailDropdown';
import WorkspaceAccountDetailTablesTab from './WorkspaceAccountDetailTablesTab';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  data: WorkspaceAccountSchema;
  onClose: () => void;
  onEdit: () => void;
  onStrategyProfileEdit: () => void;
  onRoomConfig?: () => void;
  onNotesOpen?: () => void;
  onTransactionsOpen?: OnTransactionsOpen;
  getUserRoute?: (args: { userId: WorkspaceUserSchema['id'] }) => string;
  getIdentityRoute?: (args: { identityId: IdentitySchema['id'] }) => string;
}

const WorkspaceAccountDetail = (props: Props) => {
  const {
    workspaceId,
    data,
    onClose,
    onEdit,
    onNotesOpen,
    onTransactionsOpen,
    onStrategyProfileEdit,
    getUserRoute,
    getIdentityRoute,
    onRoomConfig,
  } = props;

  const { t } = useTranslation();
  const { navigate } = useURL();

  const { id, login, comparative_limits, on_verification, notes_count } = data;

  const { data: accountTables } = useWorkspaceAccountTablesQuery({
    workspaceId,
    accountId: data.id,
    enabled: data.is_online,
  });

  const titleIcons = (
    <>
      {Boolean(notes_count) && <Tooltip label={t('common.notes')}>{icon('notes', 12)}</Tooltip>}
      {on_verification && (
        <Tooltip label={t('common.pending_verification')}>{icon('checkCircle', 12)}</Tooltip>
      )}
    </>
  );

  return (
    <PageAddon.Group>
      <Outlet />
      <PageAddon
        title={login}
        icons={titleIcons}
        onClose={onClose}
        tabs={[
          {
            label: t('common.details'),
            content: (
              <WorkspaceAccountDetailDetailsTab
                data={data}
                workspaceId={workspaceId}
                onStrategyProfileEdit={onStrategyProfileEdit}
                onRoomConfig={onRoomConfig}
                getUserRoute={getUserRoute}
                getIdentityRoute={getIdentityRoute}
              />
            ),
          },
          {
            label: t('common.balance'),
            content: (
              <WorkspaceAccountDetailBalanceTab
                accountData={data}
                workspaceId={workspaceId}
                onTransactionsOpen={onTransactionsOpen}
              />
            ),
          },
          {
            label: t('common.tables'),
            content: <WorkspaceAccountDetailTablesTab workspaceId={workspaceId} data={data} />,
            count: accountTables?.tables.length,
          },
        ]}
      >
        <PageAddon.Controls>
          <PageAddon.Controls.Group>
            <PageAddon.Controls.Group.Aside>
              <AccountPilotButton data={data} workspaceId={workspaceId} isDetail />
              <AccountPilotView data={data} isDetail />
            </PageAddon.Controls.Group.Aside>
            <Notes.Button
              count={notes_count}
              onClick={() => navigate(routes.workspaceAccountNotes({ workspaceId, accountId: id }))}
            />
            <Tooltip
              label={getScheduleTooltip({ account: data, workspaceId }) || t('common.schedule')}
              hideOnMobile={Boolean(comparative_limits.length)}
            >
              <div>
                <Button
                  icon={icon('calendar', 16)}
                  variant="transparent"
                  onClick={() =>
                    navigate(routes.workspaceAccountSchedule({ workspaceId, accountId: id }))
                  }
                  data-testid="schedule"
                  disabled={!comparative_limits.length}
                />
              </div>
            </Tooltip>
            <Tooltip label={t('common.edit')} hideOnMobile>
              <Button
                variant="transparent"
                icon={icon('edit', 16)}
                onClick={onEdit}
                data-testid="edit"
              />
            </Tooltip>
            <WorkspaceAccountDetailDropdown
              data={data}
              workspaceId={workspaceId}
              onNotesOpen={onNotesOpen}
            />
          </PageAddon.Controls.Group>
        </PageAddon.Controls>
      </PageAddon>
    </PageAddon.Group>
  );
};

export default WorkspaceAccountDetail;
