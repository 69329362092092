import { useTranslation } from 'react-i18next';
import { OrderSchema, TYPE } from '../../../api/schemas/orderSchema';
import PageAddon from '../../../components/PageAddon';
import PrettyNumber from '../../../components/PrettyNumber';
import { copyClub, copyHandHistoryId, copyLeagueId } from '../../../utils/clipboard';
import { getExtraField } from '../../../utils/data';

interface Props {
  data: OrderSchema;
}

const OrderDetailExtra = (props: Props) => {
  const { data } = props;

  const { t } = useTranslation();

  const { type, order_item } = data;

  if (!order_item?.extra) return null;

  let extra = Object.entries(order_item.extra);

  if (!extra.length) return null;

  if (type === TYPE.POKER_HAND_BILLING) {
    const keys = [
      'hand_history_id',
      'poker_room',
      'league_id',
      'club_id',
      'game_type',
      'table_currency',
      'table_big_blind',
    ];

    const temp: typeof extra = [];

    extra = extra.filter((item) => {
      const [key] = item;

      const index = keys.indexOf(key);

      if (index !== -1) {
        temp[index] = item;

        return false;
      }

      return true;
    });

    extra = [...temp, ...extra];
  }

  const getValue = (key: string, value: string | number) => {
    if (key === 'hand_history_id' && value) {
      return <PageAddon.Copy onClick={() => copyHandHistoryId(value)}>{value}</PageAddon.Copy>;
    }

    if (key === 'league_id' && value) {
      return <PageAddon.Copy onClick={() => copyLeagueId(value)}>{value}</PageAddon.Copy>;
    }

    if (key === 'club_id' && value) {
      return <PageAddon.Copy onClick={() => copyClub(value)}>{value}</PageAddon.Copy>;
    }

    if (typeof value === 'number') return <PrettyNumber>{value}</PrettyNumber>;

    return value;
  };

  return (
    <PageAddon.Collapse label={t('common.extra_fields')}>
      {extra.map((item) => {
        const { key, value, label } = getExtraField(...item);

        return (
          <PageAddon.Collapse.Field key={key} label={label}>
            {getValue(key, value)}
          </PageAddon.Collapse.Field>
        );
      })}
    </PageAddon.Collapse>
  );
};

export default OrderDetailExtra;
