import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createWorkspaceChannel, updateWorkspaceChannel } from '../../api';
import { ChannelSchema } from '../../api/schemas/channelSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Form from '../../components/Form';
import Input from '../../components/Input';
import PageAddon from '../../components/PageAddon';
import PasswordInput from '../../components/PasswordInput';
import SubmitButton from '../../components/SubmitButton';
import { required, maxLength, minLength, max } from '../../utils/form';
import notify from '../../utils/notify';

interface Fields {
  host: string;
  port: string;
  username: string;
  password: string;
}

interface CommonProps {
  workspaceId: WorkspaceSchema['id'];
  onClose: () => void;
}

interface CreateProps {
  onCreate: (args: { channelId: ChannelSchema['id']; host: ChannelSchema['host'] }) => void;
  data?: never;
  onEdit?: never;
}

interface EditProps {
  data: ChannelSchema;
  onEdit: () => void;
  onCreate?: never;
}

type Props = CommonProps & (CreateProps | EditProps);

const WorkspaceChannelForm = (props: Props) => {
  const { data, workspaceId, onClose, onCreate, onEdit } = props;

  const { t } = useTranslation();

  const form = useForm<Fields>({
    defaultValues: {
      host: data?.host || '',
      port: data ? String(data.port) : '',
      username: data?.username || '',
      password: data?.password || '',
    },
  });

  const {
    control,
    register,
    formState: { errors },
  } = form;

  const onSubmit = async (values: Fields) => {
    const payload = {
      ...values,
      port: Number(values.port),
    };

    if (data) {
      await updateWorkspaceChannel({ workspaceId, channelId: data.id, payload });

      onEdit();
    } else {
      const response = await createWorkspaceChannel({ workspaceId, payload });

      onCreate({ channelId: response.id, host: values.host });
    }

    notify('success', {
      title: data ? t('sentences.changes_have_been_saved') : t('sentences.record_has_been_created'),
    });
  };

  return (
    <PageAddon
      onClose={onClose}
      title={data ? t('common.edit_channel') : t('common.create_channel')}
    >
      <Form form={form} onSubmit={onSubmit}>
        <Input
          label={`${t('common.host')}*`}
          error={errors.host?.message}
          {...register('host', { validate: { required } })}
        />
        <Controller
          control={control}
          rules={{
            validate: {
              required,
              max: max(t('common.port'), 65535),
            },
          }}
          name="port"
          render={({ field }) => (
            <Input
              label={`${t('common.port')}*`}
              error={errors.port?.message}
              format={{
                thousandSeparator: false,
                onValueChange: field.onChange,
              }}
              {...field}
            />
          )}
        />
        <Input
          label={`${t('common.username')}*`}
          error={errors.username?.message}
          {...register('username', {
            validate: {
              required,
              minLength: minLength(4),
              maxLength: maxLength(),
            },
          })}
        />
        <PasswordInput
          label={`${t('common.password')}*`}
          error={errors.password?.message}
          {...register('password', {
            validate: { required, minLength: minLength(4) },
          })}
        />
        <PageAddon.Controls>
          <SubmitButton>{data ? t('common.save') : t('common.create')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default WorkspaceChannelForm;
