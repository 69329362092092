import { LobbySchema } from '../../api/schemas/lobbySchema';
import { LobbyTablePlayerSchema, TYPE } from '../../api/schemas/lobbyTablePlayerSchema';
import { COLOR } from '../../api/schemas/lobbyTableSchema';
import i18n from '../../i18n';
import styles from './styles.module.scss';

type Checker = (data: LobbySchema) => boolean;

export const checkIsCritical: Checker = (data) => data.accounts.length >= 3;
export const checkIsWarning: Checker = (data) => data.accounts.length === 2;
export const checkIsOk: Checker = (data) => data.accounts.length === 1;
export const checkIsEmpty: Checker = (data) => !data.accounts.length; /* && !data.table.banned */
/* export const checkIsBanned: Checker = (data) => data.table.banned; */

export const checkIsHU: Checker = (data) => data.table.max_no_of_seats === 2;
export const checkIsSixMax: Checker = (data) => data.table.max_no_of_seats === 6;
export const checkIsFullRing: Checker = (data) => data.table.max_no_of_seats >= 7;

export const checkIsHasPlayers: Checker = (data) => data.table.player_count !== 0;

export const checkIsFull: Checker = (data) => {
  const isFull = data.table.player_count === data.table.max_no_of_seats;

  /* if (data.table.ff_pool) return false; */
  if (isFull && data.accounts.length) return false;

  return isFull;
};

type TableSize = 'HU' | 'six_max' | 'full_ring';

export const statuses = ['critical', 'warning', 'ok', 'empty', 'banned'] as const;

export const STATUS_DOT_CLASS_NAMES: Record<(typeof statuses)[number], string> = {
  critical: styles.critical,
  warning: styles.warning,
  ok: styles.ok,
  empty: styles.empty,
  banned: styles.banned,
};

export const tableSizes: Record<TableSize, string> = {
  HU: 'HU',
  six_max: 'six_max',
  full_ring: 'full_ring',
};

export const tableSizesLabel: Record<string, string> = {
  HU: i18n.t('common.hu'),
  six_max: i18n.t('common.6-max'),
  full_ring: i18n.t('common.full_ring'),
};

export const COLOR_LABELS: Record<LobbySchema['table']['color'], string> = {
  [COLOR.UNKNOWN]: i18n.t('common.unknown'),
  [COLOR.GREEN]: i18n.t('common.green'),
  [COLOR.YELLOW]: i18n.t('common.yellow'),
  [COLOR.RED]: i18n.t('common.red'),
  [COLOR.PURPLE]: i18n.t('common.purple'),
};

export const COLOR_DOT_CLASS_NAMES: Record<LobbySchema['table']['color'], string> = {
  [COLOR.UNKNOWN]: styles.unknown,
  [COLOR.GREEN]: styles.green,
  [COLOR.YELLOW]: styles.yellow,
  [COLOR.RED]: styles.red,
  [COLOR.PURPLE]: styles.purple,
};

export const PLAYER_TYPE_LABELS: Record<LobbyTablePlayerSchema['type'], string> = {
  [TYPE.UNKNOWN]: i18n.t('common.unknown'),
  [TYPE.NOUS]: 'Nous',
  [TYPE.DEEPLAY]: 'Deeplay',
  [TYPE.ALTAY]: 'Altay',
  [TYPE.WEAK_FISH]: 'Weak fish',
  [TYPE.MIDDLE_FISH]: 'Middle fish',
  [TYPE.STRONG_FISH]: 'Strong fish',
  [TYPE.MIDDLE]: 'Middle',
  [TYPE.REG]: 'Reg',
  [TYPE.NOT_ENOUGH_DATA]: i18n.t('common.not_enough_data'),
  [TYPE.NO_DATA]: i18n.t('common.no_data'),
  [TYPE.NOUS_ECO]: 'Nous ECO',
  [TYPE.COLLUDER]: 'Colluder',
};
