import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { pick } from 'remeda';
import { WorkspaceAccountSchema } from '../../../api/schemas/account/workspaceAccountSchema';
import { AccountRoomConfigSchema } from '../../../api/schemas/accountRoomConfigSchema';
import { TRAINER_POKER_ROOM } from '../../../api/schemas/trainerPokerRoomSchema';
import Form from '../../../components/Form';
import PageAddon from '../../../components/PageAddon';
import SubmitButton from '../../../components/SubmitButton';
import { getFields, getDefaultValues, getPayload, roomsFields } from './helpers';
import { Fields } from './types';

interface Props {
  title: string;
  room: WorkspaceAccountSchema['room'];
  onClose: () => void;
  onSubmit: (args: { payload: ReturnType<typeof getPayload> }) => void;
  data?: AccountRoomConfigSchema;
}

const AccountRoomConfigForm = (props: Props) => {
  const { data, room, title, onClose, onSubmit } = props;

  const { t } = useTranslation();

  const form = useForm<Fields>({ defaultValues: getDefaultValues({ data }) });

  const fields = getFields(form);

  return (
    <PageAddon onClose={onClose} title={title}>
      <Form
        form={form}
        onSubmit={(values) =>
          onSubmit({ payload: pick(getPayload({ values }), roomsFields[room]) })
        }
      >
        {room === TRAINER_POKER_ROOM.PARTYPOKER && (
          <>
            {fields.TableShape}
            {fields.FeltColor}
            {fields.Background}
            {fields.DeckType}
            {fields.CardBack}
            {fields.IsDefaultAvatar}
            {fields.AutoBuyinCashGamesRebuy}
            {fields.MuckLosingUncalledHands}
          </>
        )}
        {room === TRAINER_POKER_ROOM.PPPOKER && (
          <>
            {fields.ev_chop_percent}
            {fields.rim_percent}
            {fields.ppsr}
            {fields.autoplay}
            {fields.show_cards}
          </>
        )}
        {room === TRAINER_POKER_ROOM.POKERRRR2 && (
          <>
            {fields.rit_percent}
            {fields.dead_blind}
            {fields.balance_check}
            {fields.auto_muck}
            {fields.timebank}
            {fields.is_standard_stack_seat}
            {fields.auto_rebuy}
            {fields.tournament_addon}
            {fields.max_tournament_rebuy}
          </>
        )}
        {room === TRAINER_POKER_ROOM.RULER && (
          <>
            {fields.nickname}
            {fields.balance_check}
          </>
        )}
        {room === TRAINER_POKER_ROOM.CLUBGG && (
          <>
            {fields.autoplay}
            {fields.show_cards}
          </>
        )}
        <PageAddon.Controls>
          <SubmitButton>{t('common.save')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default AccountRoomConfigForm;
