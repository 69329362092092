import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  adjustmentWorkspaceAccount,
  depositWorkspaceAccount,
  feeWorkspaceAccount,
  loadWorkspaceAccounts,
  loadWorkspaceClubs,
  loadWorkspaceLeagues,
  rewardWorkspaceAccount,
  transferWorkspaceAccount,
  withdrawWorkspaceAccount,
} from '../../../api';
import { WorkspaceAccountSchema } from '../../../api/schemas/account/workspaceAccountSchema';
import { WorkspaceAccountTransactionBalanceSchema } from '../../../api/schemas/account/workspaceAccountTransactionBalanceSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Dialog, { Props as DialogProps } from '../../../components/Dialog';
import Form from '../../../components/Form';
import Input from '../../../components/Input';
import MediaFilesField from '../../../components/MediaFilesField';
import Select, { Option } from '../../../components/Select';
import SubmitButton from '../../../components/SubmitButton';
import Tabs from '../../../components/Tabs';
import Textarea from '../../../components/Textarea';
import { MB } from '../../../constants';
import CONTENT_TYPES from '../../../constants/CONTENT_TYPES';
import EXTENSIONS from '../../../constants/EXTENSIONS';
import i18n from '../../../i18n';
import Files from '../../../types/Files';
import { convertDollarsToCents } from '../../../utils/data';
import { uploadFiles } from '../../../utils/files';
import { required } from '../../../utils/form';
import notify from '../../../utils/notify';
import styles from './styles.module.scss';

type Tab = 'club' | 'league' | 'lobby';
export type Type = 'deposit' | 'withdraw' | 'adjustment' | 'fee' | 'transfer' | 'reward';
type Sign = 'add' | 'subtract';

const TAB_LABELS: Record<Tab, string> = {
  club: i18n.t('common.club'),
  league: i18n.t('common.league'),
  lobby: i18n.t('common.lobby'),
};

const TITLE_LABELS: Record<Type, string> = {
  deposit: i18n.t('common.deposit_to'),
  withdraw: i18n.t('common.withdraw_from'),
  adjustment: i18n.t('common.adjustment_to'),
  fee: i18n.t('common.penalty_to'),
  transfer: i18n.t('common.transfer_to'),
  reward: i18n.t('common.reward_to'),
};

const BUTTON_LABELS: Record<Type, string> = {
  deposit: i18n.t('common.deposit'),
  withdraw: i18n.t('common.withdraw'),
  adjustment: i18n.t('common.adjustment'),
  fee: i18n.t('common.fee'),
  transfer: i18n.t('common.transfer'),
  reward: i18n.t('common.reward'),
};

const SIGN_LABELS: Record<Sign, string> = {
  add: i18n.t('common.add'),
  subtract: i18n.t('common.subtract'),
};

interface Fields {
  amount: string;
  currency: string;
  league_id: Option;
  club_id: Option;
  user_message: string;
  new_account_id: Option;
  file_ids: Files;
}

interface Props {
  workspaceId: WorkspaceSchema['id'];
  accountData: WorkspaceAccountSchema;
  type: Type;
  data?: WorkspaceAccountTransactionBalanceSchema;
  children?: DialogProps['label'];
  onClose?: () => void;
}

const WorkspaceAccountBalanceDialog = (props: Props) => {
  const { workspaceId, accountData, type, data, onClose } = props;

  const { id: accountId, login } = accountData;

  const { t } = useTranslation();

  const form = useForm<Fields>({
    defaultValues: {
      amount: '',
      currency: data?.currency || 'CHM',
      league_id: data?.league_id
        ? { value: data.league_id, label: `${data.league_name || '—'} (${data.league_id})` }
        : null,
      club_id: data?.club_id
        ? { value: data.club_id, label: `${data.club_name || '—'} (${data.club_id})` }
        : null,
      user_message: '',
      new_account_id: null,
      file_ids: [],
    },
  });

  const {
    control,
    register,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = form;

  const { league_id, club_id } = watch();

  const depositType = type === 'deposit';
  const withdrawType = type === 'withdraw';
  const adjustmentType = type === 'adjustment';
  const feeType = type === 'fee';
  const transferType = type === 'transfer';
  const rewardType = type === 'reward';

  const usualTypes = depositType || withdrawType;
  const onlyClubTypes = adjustmentType || feeType || transferType || rewardType;
  const signTypes = adjustmentType;

  const getDefaultTab = () => {
    const lobbyTab = !club_id && !league_id;
    const leagueTab = !club_id && league_id;

    if (onlyClubTypes) return 'club';
    if (!data) return 'club';
    if (lobbyTab) return 'lobby';
    if (leagueTab) return 'league';

    return 'club';
  };

  const tabs = ['club', 'league', 'lobby'] as const;

  const [tab, setTab] = useState<Tab>(getDefaultTab());

  const leagueTab = tab === 'league';
  const clubTab = tab === 'club';

  const signes = ['add', 'subtract'] as const;

  const [sign, setSign] = useState<Sign>(feeType ? 'subtract' : 'add');

  return (
    <Dialog
      onClose={() => {
        if (onClose) {
          onClose();
        }

        reset();
      }}
    >
      {({ close }) => (
        <>
          <Dialog.Top>
            <Dialog.Title>{`${TITLE_LABELS[type]} ${login}`}</Dialog.Title>
          </Dialog.Top>
          <Form
            form={form}
            onSubmit={async (values) => {
              const payload = {
                ...values,
                club_id: values.club_id?.value.split('/')[0] || null,
                league_id: values.league_id?.value || club_id?.value.split('/')[1] || null,
                amount: convertDollarsToCents(
                  Number(`${sign === 'add' ? '+' : '-'}${values.amount}`)
                ),
                file_ids: await uploadFiles(values.file_ids),
              };

              if (transferType) {
                if (!values.new_account_id) throw new Error();

                await transferWorkspaceAccount({
                  workspaceId,
                  accountId,
                  payload: { ...payload, new_account_id: values.new_account_id.value },
                });
              } else {
                const getFn = () => {
                  if (depositType) return depositWorkspaceAccount;
                  if (withdrawType) return withdrawWorkspaceAccount;
                  if (adjustmentType) return adjustmentWorkspaceAccount;
                  if (rewardType) return rewardWorkspaceAccount;

                  return feeWorkspaceAccount;
                };

                await getFn()({ workspaceId, accountId, payload });
              }

              close();
              notify('success', { title: t('common.action_completed') });
            }}
          >
            {!data && usualTypes && (
              <Tabs
                tabs={tabs.map((item) => ({
                  label: TAB_LABELS[item],
                  active: tab === item,
                  onClick: () => setTab(item),
                }))}
              />
            )}
            {signTypes && (
              <Tabs
                tabs={signes.map((item) => ({
                  label: SIGN_LABELS[item],
                  active: sign === item,
                  onClick: () => setSign(item),
                }))}
              />
            )}
            {leagueTab && (
              <Controller
                name="league_id"
                control={control}
                rules={{ validate: { required } }}
                render={({ field }) => (
                  <Select.Async
                    paginated={false}
                    onLoad={async (args) => {
                      const response = await loadWorkspaceLeagues(
                        { workspaceId },
                        { ...args, size: '100', poker_room_in: `${accountData.room}` }
                      );

                      const options = response.items.map((item) => ({
                        value: item.code,
                        label: `${item.name} (${item.code})`,
                      }));

                      return options;
                    }}
                  >
                    {({ options, ...rest }) => (
                      <Select
                        disabled={Boolean(data)}
                        label={`${t('common.league')}*`}
                        options={options}
                        value={field.value}
                        onChange={field.onChange}
                        error={errors.league_id?.message}
                        {...rest}
                      />
                    )}
                  </Select.Async>
                )}
              />
            )}
            {clubTab && (
              <Controller
                name="club_id"
                control={control}
                rules={{ validate: { required } }}
                render={({ field }) => (
                  <Select.Async
                    onLoad={async (args) => {
                      const response = await loadWorkspaceClubs(
                        { workspaceId },
                        { ...args, poker_room_in: `${accountData.room}` }
                      );

                      const options = response.items.map((item) => ({
                        value: item.league ? `${item.code}/${item.league.code}` : item.code,
                        label: `${item.name} (${item.code})`,
                      }));

                      return options;
                    }}
                  >
                    {({ options, ...rest }) => (
                      <Select
                        disabled={Boolean(data)}
                        label={`${t('common.club')}*`}
                        options={options}
                        value={field.value}
                        onChange={field.onChange}
                        error={errors.club_id?.message}
                        {...rest}
                      />
                    )}
                  </Select.Async>
                )}
              />
            )}
            {transferType && (
              <Controller
                name="new_account_id"
                control={control}
                rules={{ validate: { required } }}
                render={({ field }) => (
                  <Select.Async
                    onLoad={async (args) => {
                      const response = await loadWorkspaceAccounts(
                        { workspaceId },
                        { ...args, room_in: `${accountData.room}` }
                      );

                      const options = response.items.map((item) => ({
                        value: item.id,
                        label: item.login,
                      }));

                      return options;
                    }}
                  >
                    {({ options, ...rest }) => (
                      <Select
                        label={`${t('common.account')}*`}
                        options={options}
                        value={field.value}
                        onChange={field.onChange}
                        error={errors.new_account_id?.message}
                        {...rest}
                      />
                    )}
                  </Select.Async>
                )}
              />
            )}
            <div className={styles.inputsGroup}>
              <Controller
                control={control}
                rules={{
                  validate: {
                    required,
                  },
                }}
                name="amount"
                render={({ field }) => (
                  <Input
                    label={`${t('common.amount')}*`}
                    error={errors.amount?.message}
                    format={{
                      decimalScale: 2,
                      onValueChange: field.onChange,
                      allowNegative: false,
                    }}
                    {...field}
                  />
                )}
              />
              <Input
                disabled={Boolean(data)}
                label={`${t('common.currency')}*`}
                error={errors.currency?.message}
                {...register('currency', { validate: { required } })}
              />
            </div>
            <Textarea
              label={t('common.description')}
              maxLength={0}
              error={errors.user_message?.message}
              {...register('user_message')}
            />
            <Controller
              control={control}
              name="file_ids"
              render={({ field }) => (
                <MediaFilesField
                  value={field.value}
                  maxSize={3 * MB}
                  onChange={field.onChange}
                  accept={{
                    [CONTENT_TYPES['image/*']]: [EXTENSIONS.JPG, EXTENSIONS.JPEG, EXTENSIONS.PNG],
                  }}
                  disabled={isSubmitting}
                  {...(errors.file_ids && { error: errors.file_ids?.message })}
                >
                  {t('sentences.images_restrictions')}
                </MediaFilesField>
              )}
            />
            <SubmitButton>{BUTTON_LABELS[type]}</SubmitButton>
          </Form>
        </>
      )}
    </Dialog>
  );
};

export default WorkspaceAccountBalanceDialog;
