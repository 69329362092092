import { useTranslation } from 'react-i18next';
import { AgentSchema } from '../../api/schemas/agentSchema';
import { DealSchema } from '../../api/schemas/deal/dealSchema';
import { GlobalClubSchema } from '../../api/schemas/globalClubSchema';
import FadingText from '../../components/FadingText';
import Link from '../../components/Link';
import PageAddon from '../../components/PageAddon';
import Tag from '../../components/Tag';
import { renderNumber } from '../../utils/data';
import { formatAbsoluteDate } from '../../utils/date';
import { TRAINER_POKER_ROOM_LABELS } from '../../utils/trainer';
import { can } from '../permission/helpers';
import GlobalDealBalance from './GlobalDealBalance';
import GlobalDealBalanceUSD from './GlobalDealBalanceUSD';
import GlobalDealCredit from './GlobalDealCredit';
import GlobalDealProtection from './GlobalDealProtection';
import GlobalDealRole from './GlobalDealRole';
import GlobalDealStatus from './GlobalDealStatus';
import { renderPaymentSystems, renderWorkspaces } from './helpers';

interface Props {
  data: DealSchema;
  getAgentRoute: (args: { agentId: AgentSchema['id'] }) => string;
  getClubRoute: (args: { clubId: GlobalClubSchema['id'] }) => string;
}

const GlobalDealDetailGeneralTab = (props: Props) => {
  const { data, getAgentRoute, getClubRoute } = props;

  const { t } = useTranslation();

  const {
    id,
    agent,
    club,
    poker_room,
    area_id,
    area2_id,
    holder,
    owner,
    legend,
    currency,
    chip_rate,
    rake_back,
    action,
    rev_share,
    win_share,
    rebate,
    agent_fee,
    withdraw_fee,
    total,
    general_conditions,
    additional_conditions,
    policy,
    description,
    reconciliation,
    modified_on,
    created_on,
  } = data;

  return (
    <PageAddon.Fields>
      <PageAddon.Id id={id} />
      <PageAddon.Field label={t('common.agent')}>
        <Link to={getAgentRoute({ agentId: agent.id })}>
          <FadingText text={agent.name} />
        </Link>
      </PageAddon.Field>
      <PageAddon.Field label={t('common.poker_room')}>
        {TRAINER_POKER_ROOM_LABELS[poker_room]}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.club_name')}>
        {can.club.read ? (
          <Link to={getClubRoute({ clubId: club.id })}>
            <FadingText text={club.name} />
          </Link>
        ) : (
          <FadingText text={club.name} />
        )}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.club_id')}>{club.code}</PageAddon.Field>
      <PageAddon.Field label={t('common.union')}>
        {club.league ? <Tag>{club.league.code}</Tag> : '—'}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.su')}>
        {club.league?.super_league ? <Tag>{club.league.super_league.code}</Tag> : '—'}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.area')}>
        <FadingText text={area_id || '—'} />
      </PageAddon.Field>
      <PageAddon.Field label={t('common.area_2')}>
        <FadingText text={area2_id || '—'} />
      </PageAddon.Field>
      <PageAddon.Field label={t('common.holder')}>
        <FadingText text={holder?.username || '—'} />
      </PageAddon.Field>
      <PageAddon.Field label={t('common.owner')}>
        <FadingText text={owner || '—'} />
      </PageAddon.Field>
      <PageAddon.Field label={t('common.legend')}>
        <FadingText text={legend || '—'} />
      </PageAddon.Field>
      <PageAddon.Field label={t('common.role')}>
        <GlobalDealRole data={data} />
      </PageAddon.Field>
      <PageAddon.Field label={t('common.status')}>
        <GlobalDealStatus data={data} />
      </PageAddon.Field>
      <PageAddon.Field label={t('common.credit')}>
        <GlobalDealCredit data={data} />
      </PageAddon.Field>
      <PageAddon.Field label={t('common.protection')}>
        <GlobalDealProtection data={data} />
      </PageAddon.Field>
      <PageAddon.Field label={t('common.currency')}>
        <FadingText text={currency || '—'} />
      </PageAddon.Field>
      <PageAddon.Field label={t('common.chip_rate')}>
        <FadingText text={String(renderNumber(chip_rate))} />
      </PageAddon.Field>
      <PageAddon.Field label={t('common.rakeback')}>
        <FadingText text={String(renderNumber(rake_back))} />
      </PageAddon.Field>
      <PageAddon.Field label={t('common.action')}>
        <FadingText text={String(renderNumber(action))} />
      </PageAddon.Field>
      <PageAddon.Field label={t('common.revshare')}>
        <FadingText text={String(renderNumber(rev_share))} />
      </PageAddon.Field>
      <PageAddon.Field label={t('common.winshare')}>
        <FadingText text={String(renderNumber(win_share))} />
      </PageAddon.Field>
      <PageAddon.Field label={t('common.rebate')}>
        <FadingText text={String(renderNumber(rebate))} />
      </PageAddon.Field>
      <PageAddon.Field label={t('common.agent_fee')}>
        <FadingText text={String(renderNumber(agent_fee))} />
      </PageAddon.Field>
      <PageAddon.Field label={t('common.withdraw_fee')}>
        <FadingText text={String(renderNumber(withdraw_fee))} />
      </PageAddon.Field>
      <PageAddon.Field label={t('common.payment_system')}>
        <PageAddon.Tags>{renderPaymentSystems(data)}</PageAddon.Tags>
      </PageAddon.Field>
      <PageAddon.Field label={t('common.workspaces')}>
        <PageAddon.Tags>{renderWorkspaces(data)}</PageAddon.Tags>
      </PageAddon.Field>
      <PageAddon.Field label={t('common.balance')}>
        <GlobalDealBalance data={data} />
      </PageAddon.Field>
      <PageAddon.Field label={t('common.balance_$')}>
        <GlobalDealBalanceUSD data={data} />
      </PageAddon.Field>
      <PageAddon.Collapse label={t('common.total')} defaultOpen>
        <PageAddon.Collapse.Field label={false}>{total || '—'}</PageAddon.Collapse.Field>
      </PageAddon.Collapse>
      <PageAddon.Collapse label={t('common.general_conditions')} defaultOpen>
        <PageAddon.Collapse.Field label={false}>
          {general_conditions || '—'}
        </PageAddon.Collapse.Field>
      </PageAddon.Collapse>
      <PageAddon.Collapse label={t('common.additional_conditions')} defaultOpen>
        <PageAddon.Collapse.Field label={false}>
          {additional_conditions || '—'}
        </PageAddon.Collapse.Field>
      </PageAddon.Collapse>
      <PageAddon.Collapse label={t('common.policy')} defaultOpen>
        <PageAddon.Collapse.Field label={false}>{policy || '—'}</PageAddon.Collapse.Field>
      </PageAddon.Collapse>
      <PageAddon.Collapse label={t('common.description')} defaultOpen>
        <PageAddon.Collapse.Field label={false}>{description || '—'}</PageAddon.Collapse.Field>
      </PageAddon.Collapse>
      <PageAddon.Collapse label={t('common.reconciliation')} defaultOpen>
        <PageAddon.Collapse.Field label={false}>{reconciliation || '—'}</PageAddon.Collapse.Field>
      </PageAddon.Collapse>
      <PageAddon.Field label={t('common.modified')}>
        {formatAbsoluteDate(modified_on)}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.created')}>
        {formatAbsoluteDate(created_on)}
      </PageAddon.Field>
    </PageAddon.Fields>
  );
};

export default GlobalDealDetailGeneralTab;
