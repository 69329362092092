import clsx from 'clsx';
import { SeatControlRuleSchema } from '../../../api/schemas/seatControlRuleSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import PageDetailLink from '../../../components/PageDetailLink';
import routes from '../../../config/routes';
import icon from '../../../static/icons';
import { ACTION_TYPE_COLOR, ACTION_TYPE_LABEL } from '../../seat-control-rule/helpers';
import styles from './styles.module.scss';

interface Props {
  data: SeatControlRuleSchema;
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceSeatControlRuleDetailLink = (props: Props) => {
  const { data, workspaceId } = props;

  const { id, name, action_type, is_deleted } = data;

  return (
    <div className={styles.container}>
      <PageDetailLink
        label={name}
        to={routes.workspaceSeatConrolRule({ workspaceId, seatControlRuleId: id })}
        {...(is_deleted && { extra: icon('trash', 12) })}
      />
      <div className={clsx(styles.actionType, ACTION_TYPE_COLOR[action_type])}>
        {ACTION_TYPE_LABEL[action_type]}
      </div>
    </div>
  );
};

export default WorkspaceSeatControlRuleDetailLink;
