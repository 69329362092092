import {
  BUSINESS_MODEL,
  WORKSPACE_TYPES,
  WorkspaceSchema,
  WorkspaceType,
} from '../../api/schemas/workspaceSchema';
import { Options } from '../../components/Select';
import i18n from '../../i18n';
import icon from '../../static/icons';

export const WORKSPACE_TYPES_ICONS = {
  [WORKSPACE_TYPES.POKER_FARM]: icon('pokerCard', 16),
  [WORKSPACE_TYPES.POKER_AFFILIATE]: icon('user', 16),
  [WORKSPACE_TYPES.CHANNEL_PROVIDER]: icon('device', 16),
  [WORKSPACE_TYPES.IDENTITY_PROVIDER]: icon('fingerprint', 16),
  [WORKSPACE_TYPES.CLUB_MANAGER]: icon('pokerChip', 16),
} as const;

export const WORKSPACE_TYPES_CROSSED_ICONS = {
  [WORKSPACE_TYPES.POKER_FARM]: icon('pokerCardCrossed', 16),
  [WORKSPACE_TYPES.POKER_AFFILIATE]: icon('userCrossed', 16),
  [WORKSPACE_TYPES.CHANNEL_PROVIDER]: icon('deviceCrossed', 16),
  [WORKSPACE_TYPES.IDENTITY_PROVIDER]: icon('fingerprintCrossed', 16),
  [WORKSPACE_TYPES.CLUB_MANAGER]: icon('pokerChipCrossed', 16),
} as const;

export const WORKSPACE_TYPES_LABELS: Record<WorkspaceType, string> = {
  1: i18n.t('common.poker_farm'),
  2: i18n.t('common.affiliate'),
  3: i18n.t('common.channel_provider'),
  4: i18n.t('common.identity_provider'),
  5: i18n.t('common.club_manager'),
};

export const workspaceTypesOptions = Object.values(WORKSPACE_TYPES).map((item) => ({
  value: item,
  label: WORKSPACE_TYPES_LABELS[item],
}));

export const BUSINESS_MODEL_LABELS: Record<WorkspaceSchema['business_model'], string> = {
  0: i18n.t('common.poker_farm'),
  1: i18n.t('common.BOT'),
  2: i18n.t('common.ECO'),
};

export const businessModelOptions: Options<WorkspaceSchema['business_model']> = [
  { value: BUSINESS_MODEL.BOT, label: i18n.t('common.BOT') },
  { value: BUSINESS_MODEL.ECO, label: i18n.t('common.ECO') },
];
