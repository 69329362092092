import api from '../../services/api';
import { DealSchema } from '../schemas/deal/dealSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { NoteSchema } from '../schemas/noteSchema';

interface Args {
  dealId: DealSchema['id'];
  noteId: NoteSchema['id'];
}

const deleteGlobalDealNote = async (args: Args) => {
  const { dealId, noteId } = args;

  const response = await api.delete(`/api/deal/${dealId}/notes/${noteId}`);

  return emptyResponseSchema.parse(response.data);
};

export default deleteGlobalDealNote;
