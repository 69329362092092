import { ReactNode } from 'react';
import {
  ACTION_TYPE,
  INSTANCE_TYPE,
  NotificationSchema,
} from '../../../api/schemas/notificationSchema';
import { NotificationsSettingSchema } from '../../../api/schemas/notificationsSettingSchema';
import routes from '../../../config/routes';
import { can } from '../../../features/permission/helpers';
import i18n from '../../../i18n';
import { addWhen } from '../../../utils';
import {
  checkIsAccountsVisible,
  checkIsClubManagersVisible,
  checkWorkspaceHasChannelsAccess,
} from '../../../utils/workspace';
import Link from '../../Link';

export const ACTION_TYPE_FILTER_LABELS: Record<NotificationSchema['action_type'], string> = {
  [ACTION_TYPE.UNKNOWN]: '—',
  [ACTION_TYPE.NOTE_CREATED]: i18n.t('common.account_note_created'),
  [ACTION_TYPE.NOTE_UPDATED]: i18n.t('common.account_note_updated'),
  [ACTION_TYPE.ACCOUNT_MOVED]: i18n.t('common.account_moved'),
  [ACTION_TYPE.CM_MANAGER_STOPPED]: i18n.t('common.manager_stopped'),
  [ACTION_TYPE.CHANNEL_ACTIVATED]: i18n.t('common.channel_activated'),
  [ACTION_TYPE.CHANNEL_DEACTIVATED]: i18n.t('common.channel_deactivated'),
  [ACTION_TYPE.CM_TRANSACTION_FAILED]: i18n.t('common.transaction_failed'),
  [ACTION_TYPE.ANNOUNCEMENT_CHANGED]: i18n.t('common.announcements'),
  [ACTION_TYPE.ACCOUNT_START_FAILED]: i18n.t('common.account_failed_start'),
  [ACTION_TYPE.ACCOUNT_CRITICAL_ERROR]: i18n.t('common.account_critical_error'),
};

export const ACTION_TYPE_SETTINGS_LABELS: Record<NotificationSchema['action_type'], string> = {
  [ACTION_TYPE.UNKNOWN]: '—',
  [ACTION_TYPE.NOTE_CREATED]: i18n.t('common.note_created'),
  [ACTION_TYPE.NOTE_UPDATED]: i18n.t('common.note_updated'),
  [ACTION_TYPE.ACCOUNT_MOVED]: i18n.t('common.moved'),
  [ACTION_TYPE.CM_MANAGER_STOPPED]: i18n.t('common.manager_stopped'),
  [ACTION_TYPE.CHANNEL_ACTIVATED]: i18n.t('common.activated'),
  [ACTION_TYPE.CHANNEL_DEACTIVATED]: i18n.t('common.deactivated'),
  [ACTION_TYPE.CM_TRANSACTION_FAILED]: i18n.t('common.transaction_failed'),
  [ACTION_TYPE.ANNOUNCEMENT_CHANGED]: i18n.t('common.announcements'),
  [ACTION_TYPE.ACCOUNT_START_FAILED]: i18n.t('common.failed_start'),
  [ACTION_TYPE.ACCOUNT_CRITICAL_ERROR]: i18n.t('common.critical_error'),
};

type ActionTypesGroups = {
  label: string;
  action_types: NotificationsSettingSchema['action_types'];
};

export const getActionTypesGroups = (
  workspaceId: NotificationsSettingSchema['workspace']['id']
): ActionTypesGroups[] => [
  ...addWhen(
    {
      label: i18n.t('common.accounts'),
      action_types: [
        ACTION_TYPE.ACCOUNT_CRITICAL_ERROR,
        ACTION_TYPE.ACCOUNT_MOVED,
        ACTION_TYPE.ACCOUNT_START_FAILED,
        ACTION_TYPE.NOTE_CREATED,
        ACTION_TYPE.NOTE_UPDATED,
      ],
    },
    checkIsAccountsVisible(workspaceId)
  ),
  ...addWhen(
    {
      label: i18n.t('common.channels'),
      action_types: [ACTION_TYPE.CHANNEL_ACTIVATED, ACTION_TYPE.CHANNEL_DEACTIVATED],
    },
    checkWorkspaceHasChannelsAccess(workspaceId)
  ),
  ...addWhen(
    {
      label: i18n.t('common.club_manager'),
      action_types: [ACTION_TYPE.CM_MANAGER_STOPPED, ACTION_TYPE.CM_TRANSACTION_FAILED],
    },
    checkIsClubManagersVisible(workspaceId)
  ),
  ...addWhen(
    {
      label: i18n.t('common.clubs'),
      action_types: [ACTION_TYPE.ANNOUNCEMENT_CHANGED],
    },
    can.club.read
  ),
];

export const getActionTypeContent = (values: NotificationSchema) => {
  const { action_type, workspace, instance } = values;

  const INSTANCE_TYPE_ROUTES: Record<NotificationSchema['instance']['type'], string> = {
    [INSTANCE_TYPE.UNKNOWN]: routes.error404,
    [INSTANCE_TYPE.ACCOUNT]: routes.workspaceAccount({
      workspaceId: workspace.id,
      accountId: instance.id,
    }),
    [INSTANCE_TYPE.IDENTITY]: routes.workspaceIdentity({
      workspaceId: workspace.id,
      identityId: instance.id,
    }),
    [INSTANCE_TYPE.CHANNEL]: routes.workspaceChannel({
      workspaceId: workspace.id,
      channelId: instance.id,
    }),
    [INSTANCE_TYPE.USER]: routes.workspaceUser({
      workspaceId: workspace.id,
      userId: instance.id,
    }),
    [INSTANCE_TYPE.CM_MANAGER]: routes.workspaceClubManager({
      workspaceId: workspace.id,
      managerId: instance.id,
    }),
    [INSTANCE_TYPE.CM_TRANSACTION]: routes.workspaceClubTransaction({
      workspaceId: workspace.id,
      transactionId: instance.id,
    }),
    [INSTANCE_TYPE.ANNOUNCEMENT]: routes.club({
      clubId: instance.id,
    }),
  };

  const getLink = () => {
    if (instance.type === INSTANCE_TYPE.CM_TRANSACTION) {
      return (
        <Link target="_blank" to={INSTANCE_TYPE_ROUTES[instance.type]}>
          {String(i18n.t('common.transaction'))}
        </Link>
      );
    }

    return (
      <Link
        target="_blank"
        params={false}
        to={`${INSTANCE_TYPE_ROUTES[instance.type]}?search=${instance.id}`}
      >
        {instance.displayed_name}
      </Link>
    );
  };

  const link = getLink();

  const ACTION_TYPE_CONTENT: Record<NotificationSchema['action_type'], ReactNode> = {
    [ACTION_TYPE.UNKNOWN]: '—',
    [ACTION_TYPE.NOTE_CREATED]: (
      <>
        {i18n.t('sentences.note_has_been_added')} {link}
      </>
    ),
    [ACTION_TYPE.NOTE_UPDATED]: (
      <>
        {i18n.t('sentences.note_has_been_edited')} {link}
      </>
    ),
    [ACTION_TYPE.ACCOUNT_MOVED]: (
      <>
        {link} {i18n.t('sentences.has_been_moved')}
      </>
    ),
    [ACTION_TYPE.CM_MANAGER_STOPPED]: (
      <>
        {link} {i18n.t('sentences.has_been_stopped')}
      </>
    ),
    [ACTION_TYPE.CHANNEL_ACTIVATED]: (
      <>
        {link} {i18n.t('sentences.has_been_activated')}
      </>
    ),
    [ACTION_TYPE.CHANNEL_DEACTIVATED]: (
      <>
        {link} {i18n.t('sentences.has_been_deactivated')}
      </>
    ),
    [ACTION_TYPE.CM_TRANSACTION_FAILED]: (
      <>
        {link} {i18n.t('sentences.has_been_failed')}
      </>
    ),
    [ACTION_TYPE.ANNOUNCEMENT_CHANGED]: (
      <>
        {i18n.t('sentences.the_announcements_at_the_club')} {link}{' '}
        {i18n.t('sentences.have_been_changed')}
      </>
    ),
    [ACTION_TYPE.ACCOUNT_START_FAILED]: (
      <>
        {link} {i18n.t('sentences.failed_to_start')}
      </>
    ),
    [ACTION_TYPE.ACCOUNT_CRITICAL_ERROR]: (
      <>
        {link} {i18n.t('sentences.critical_error')}
      </>
    ),
  };

  return ACTION_TYPE_CONTENT[action_type];
};
