import {
  WorkspaceClubManagerSchema,
  STATUS,
  TYPE,
} from '../../api/schemas/workspaceClubManagerSchema';
import i18n from '../../i18n';

export const CLUB_MANAGER_TYPES_LABELS: Record<WorkspaceClubManagerSchema['type'], string> = {
  [TYPE.UNKNOWN]: '—',
  [TYPE.AGENT]: i18n.t('common.agent'),
  [TYPE.MANAGER]: i18n.t('common.manager'),
};

export const CLUB_MANAGER_STATUSES_LABELS: Record<WorkspaceClubManagerSchema['status'], string> = {
  [STATUS.UNKNOWN]: '—',
  [STATUS.STOPPED]: i18n.t('common.stopped'),
  [STATUS.STARTED]: i18n.t('common.started'),
  [STATUS.OFFLINE]: i18n.t('common.offline'),
  [STATUS.ONLINE]: i18n.t('common.online'),
};
