import { GlobalAccountSchema } from '../../api/schemas/account/globalAccountSchema';
import Dropdown from '../../components/Dropdown';
import useDropdown from './useDropdown';

interface Props {
  data: GlobalAccountSchema;
}

const GlobalAccountDropdown = (props: Props) => {
  const { title, options } = useDropdown(props);

  return (
    <Dropdown
      title={title}
      buttonSize="small"
      options={[
        options.edit,
        options.schedule,
        options.resetCache,
        options.copy,
        options.divider,
        options.closePokerRoom,
      ]}
    />
  );
};

export default GlobalAccountDropdown;
