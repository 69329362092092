import { WorkspaceDashboardAccountSchema } from '../../../api/schemas/workspace-dashboard/workspaceDashboardAccountSchema';
import i18n from '../../../i18n';
import { TRAINER_GAME_TYPE_LABELS, TRAINER_LIMITS_LABELS } from '../../../utils/trainer';

export type GroupKey = 'stage' | 'game_type' | 'limit' | 'bot_settings' | 'member' | 'identity';

export const GROUPS = [
  'stage',
  'game_type',
  'limit',
  'bot_settings',
  'member',
  'identity',
] as const;

export const GROUPS_LABELS: Record<GroupKey, string> = {
  stage: i18n.t('common.stage'),
  game_type: i18n.t('common.game_type'),
  limit: i18n.t('common.limit'),
  bot_settings: i18n.t('common.bot_settings'),
  member: i18n.t('common.member'),
  identity: i18n.t('common.identity'),
};

export const getContent = (data: WorkspaceDashboardAccountSchema) => {
  const { stage, game_type, limit, bot_settings, member, identity } = data;

  return {
    stage: stage || '—',
    game_type: game_type ? TRAINER_GAME_TYPE_LABELS[game_type] : '—',
    limit: limit ? TRAINER_LIMITS_LABELS[limit] : '—',
    bot_settings: bot_settings || '—',
    member: member || '—',
    identity: identity || '—',
  };
};

export const COLUMNS_WIDTHS: Record<GroupKey, number> = {
  stage: 80,
  game_type: 80,
  limit: 80,
  bot_settings: 200,
  member: 160,
  identity: 100,
};
