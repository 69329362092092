import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { deleteWorkspaceChannel } from '../../api';
import { ChannelSchema } from '../../api/schemas/channelSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import { presets } from '../../components/Dropdown';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import { RouteParams } from '../../utils/generics';
import notify from '../../utils/notify';

type Params = RouteParams<typeof routes.workspaceChannel>;

interface Args {
  data: ChannelSchema;
  workspaceId: WorkspaceSchema['id'];
}

const useDropdown = (args: Args) => {
  const { data, workspaceId } = args;

  const { id, username, is_deleted } = data;

  const { t } = useTranslation();
  const { navigate } = useURL();
  const { channelId } = useParams<Params>();

  return {
    title: username,
    disabled: is_deleted,
    options: {
      edit: {
        ...presets.edit,
        onClick: () => navigate(routes.workspaceChannelEdit({ workspaceId, channelId: id })),
      },
      copy: presets.copyId(id),
      divider: { divider: true },
      delete: {
        ...presets.delete,
        onClick: async () => {
          await deleteWorkspaceChannel({ workspaceId, channelId: id });

          if (id === channelId) {
            navigate(routes.workspaceChannels({ workspaceId }));
          }

          notify('success', { title: t('sentences.record_has_been_deleted') });
        },
      },
    },
  };
};

export default useDropdown;
