import { ReactElement } from 'react';
import { OBJECT, TYPE, STATUS } from '../../../api/schemas/account/accountTransactionSchema';
import i18n from '../../../i18n';
import icon from '../../../static/icons';
import { CommonAccountTransactionSchema } from '../types';
import styles from './styles.module.scss';

export const OBJECT_LABELS: Record<CommonAccountTransactionSchema['trx_object'], string> = {
  [OBJECT.UNKNOWN]: i18n.t('common.unknown'),
  [OBJECT.LOBBY]: i18n.t('common.lobby'),
  [OBJECT.TABLE]: i18n.t('common.table'),
  [OBJECT.CLUB]: i18n.t('common.club'),
  [OBJECT.LEAGUE]: i18n.t('common.league'),
};

export const TYPE_LABELS: Record<CommonAccountTransactionSchema['trx_type'], string> = {
  [TYPE.UNKNOWN]: i18n.t('common.unknown'),
  [TYPE.DEPOSIT]: i18n.t('common.deposit'),
  [TYPE.WITHDRAW]: i18n.t('common.withdraw'),
  [TYPE.GAME_RESULT]: i18n.t('common.game_result'),
  [TYPE.ADJUSTMENT]: i18n.t('common.adjustment'),
  [TYPE.FEE]: i18n.t('common.penalty'),
  [TYPE.TRANSFER]: i18n.t('common.transfer'),
  [TYPE.REWARD]: i18n.t('common.reward'),
};

export const STATUS_LABELS: Record<CommonAccountTransactionSchema['trx_status'], string> = {
  [STATUS.UNKNOWN]: i18n.t('common.unknown'),
  [STATUS.PENDING]: i18n.t('common.pending'),
  [STATUS.COMPLETED]: i18n.t('common.completed'),
  [STATUS.CANCELED]: i18n.t('common.canceled'),
};

export const STATUS_ICONS: Record<
  CommonAccountTransactionSchema['trx_status'],
  ReactElement | null
> = {
  [STATUS.UNKNOWN]: null,
  [STATUS.PENDING]: icon('clock', 12, { className: styles.blue }),
  [STATUS.COMPLETED]: icon('checkCircle', 12, { className: styles.green }),
  [STATUS.CANCELED]: icon('crossCircle', 12, { className: styles.red }),
};
