import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  changeWorkspaceAccountRegistrationStatus,
  finishWorkspaceAccountVerification,
  submitWorkspaceAccountForVerification,
  updateWorkspaceAccount,
} from '../../api';
import { REGISTRATION_STATUS } from '../../api/schemas/account';
import { WorkspaceAccountSchema } from '../../api/schemas/account/workspaceAccountSchema';
import { TRAINER_POKER_ROOM } from '../../api/schemas/trainerPokerRoomSchema';
import { TRAINER_TECH } from '../../api/schemas/trainerTechSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import { presets } from '../../components/Dropdown';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import icon from '../../static/icons';
import { useUserStore } from '../../store/user';
import notify from '../../utils/notify';
import { getScheduleTooltip } from '../account/helpers';
import { useCloseWorkspaceAccountTablesPokerRoomMutation } from './mutations';
import useDropdownStages from './useDropdownStages';

interface Args {
  data: WorkspaceAccountSchema;
  workspaceId: WorkspaceSchema['id'];
  changePassword: () => void;
  history: () => void;
  deposit: () => void;
  withdraw: () => void;
  adjustment: () => void;
  fee: () => void;
  reward: () => void;
  verificationCode: () => void;
  onNotesOpen?: () => void;
}

const useDropdown = (args: Args) => {
  const {
    data,
    workspaceId,
    changePassword,
    history,
    deposit,
    withdraw,
    adjustment,
    fee,
    reward,
    verificationCode,
    onNotesOpen,
  } = args;

  const { id, login, room, is_online, comparative_limits, on_verification, on_registration } = data;

  const { t } = useTranslation();
  const { navigate } = useURL();
  const { workspace } = useUserStore();

  const closeRoom = useCloseWorkspaceAccountTablesPokerRoomMutation();

  const [view, setView] = useState<
    'default' | 'stages' | 'balance' | 'verification' | 'registration'
  >('default');

  const setDefaultView = () => setView('default');

  const { stages } = useDropdownStages({
    data,
    workspaceId,
    onClick: async (stage) => {
      await updateWorkspaceAccount({
        workspaceId,
        accountId: id,
        payload: { stage_id: stage.id },
      });

      notify('success', { title: t('sentences.changes_have_been_saved') });
    },
  });

  return {
    title: view === 'stages' ? t('common.move_to') : login,
    view,
    setDefaultView,
    options: {
      edit: {
        ...presets.edit,
        onClick: () => navigate(routes.workspaceAccountEdit({ workspaceId, accountId: id })),
      },
      changePassword: {
        icon: icon('unlock', 20),
        label: t('common.change_password'),
        onClick: changePassword,
      },
      ...(onNotesOpen && {
        notes: {
          icon: icon('notes', 20),
          label: t('common.notes'),
          onClick: onNotesOpen,
        },
      }),
      history: {
        icon: icon('clockBack', 20),
        label: t('common.history'),
        onClick: history,
      },
      schedule: {
        icon: icon('calendar', 20),
        label: t('common.schedule'),
        onClick: () => navigate(routes.workspaceAccountSchedule({ workspaceId, accountId: id })),
        disabled: !comparative_limits.length,
        tooltip: getScheduleTooltip({ account: data, workspaceId }),
      },
      deposit: {
        icon: icon('deposit', 20),
        label: t('common.deposit'),
        onClick: deposit,
      },
      withdraw: {
        icon: icon('withdraw', 20),
        label: t('common.withdraw'),
        onClick: withdraw,
      },
      adjustment: {
        icon: icon('crosshair', 20),
        label: t('common.adjustment'),
        onClick: adjustment,
      },
      fee: {
        icon: icon('percent', 20),
        label: t('common.penalty'),
        onClick: fee,
      },
      reward: {
        icon: icon('gift', 20),
        label: t('common.reward'),
        onClick: reward,
      },
      ...(!on_verification && {
        sumbitVerification: {
          icon: icon('playCircle', 20),
          label: t('common.sumbit_to_verification'),
          onClick: async () => {
            await submitWorkspaceAccountForVerification({ workspaceId, accountId: id });

            notify('success', { title: t('common.action_completed') });
          },
          disabled: data.is_online,
        },
      }),
      ...(on_verification && {
        finishVerification: {
          icon: icon('pauseCircle', 20),
          label: t('common.finish_verification'),
          onClick: async () => {
            await finishWorkspaceAccountVerification({ workspaceId, accountId: id });

            notify('success', { title: t('common.action_completed') });
          },
        },
      }),
      ...(on_verification && {
        showVerificationCode: {
          icon: icon('key', 20),
          label: t('common.show_verification_code'),
          onClick: verificationCode,
        },
      }),
      revertToUnregistered: {
        label: t('common.revert_to_unregistered'),
        onClick: async () => {
          await changeWorkspaceAccountRegistrationStatus({
            workspaceId,
            accountId: id,
            payload: { on_registration: REGISTRATION_STATUS.NOT_REGISTERED },
          });

          notify('success', { title: t('common.action_completed') });
        },
      },
      finishRegistration: {
        label: t('common.finish_registration'),
        onClick: async () => {
          await changeWorkspaceAccountRegistrationStatus({
            workspaceId,
            accountId: id,
            payload: { on_registration: REGISTRATION_STATUS.REGISTERED },
          });

          notify('success', { title: t('common.action_completed') });
        },
      },
      closePokerRoom: {
        danger: true,
        confirm: true,
        icon: icon('logOut', 20),
        label: t('common.close_poker_room'),
        confirmText: t('common.close'),
        disabled: !is_online,
        onClick: () => closeRoom.mutate({ workspaceId, accountId: id }),
        ...(!is_online && { tooltip: t('common.account_is_offline') }),
      },
      copy: presets.copyId(id),
      divider: { divider: true },
      move_to: {
        ...presets.more,
        icon: icon('cornerDownRight', 20),
        label: t('common.move_to'),
        danger: true,
        onClick: () => setView('stages'),
      },
      balance: {
        ...presets.more,
        icon: icon('dollar', 20),
        label: t('common.balance'),
        onClick: () => setView('balance'),
      },
      ...(workspace?.trainer_tech.includes(TRAINER_TECH.POKERRRR2_DROP_MANUAL) &&
        room === TRAINER_POKER_ROOM.POKERRRR2 && {
          verification: {
            ...presets.more,
            icon: icon('checkCircle', 20),
            label: t('common.verification'),
            onClick: () => setView('verification'),
          },
        }),
      ...((on_registration === REGISTRATION_STATUS.IN_PROGRESS ||
        on_registration === REGISTRATION_STATUS.ERROR_RECEIVED) && {
        registration: {
          ...presets.more,
          icon: icon('rCircle', 20),
          label: t('common.registration'),
          onClick: () => setView('registration'),
        },
      }),
      back: {
        ...presets.back(),
        onClick: setDefaultView,
      },
      stages,
    },
  };
};

export default useDropdown;
