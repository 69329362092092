import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import GlobalAccountDetail from '../../features/global-account/GlobalAccountDetail';
import GlobalAccountForm from '../../features/global-account/GlobalAccountForm';
import GlobalAccountLoader from '../../features/global-account/GlobalAccountLoader';
import GlobalAccountRoomConfigForm from '../../features/global-account/GlobalAccountRoomConfig/GlobalAccountRoomConfigForm';
import GlobalAccountRoomConfigLoader from '../../features/global-account/GlobalAccountRoomConfig/GlobalAccountRoomConfigLoader';
import GlobalClubDeleteError from '../../features/global-club/GlobalClubDeleteError';
import GlobalClubDetail from '../../features/global-club/GlobalClubDetail';
import GlobalClubForm from '../../features/global-club/GlobalClubForm';
import GlobalClubLoader from '../../features/global-club/GlobalClubLoader';
import GlobalClubNotes from '../../features/global-club/GlobalClubNotes';
import GlobalLeagueDetail from '../../features/global-league/GlobalLeagueDetail';
import GlobalLeagueForm from '../../features/global-league/GlobalLeagueForm';
import GlobalLeagueLoader from '../../features/global-league/GlobalLeagueLoader';
import GlobalSuperLeagueDetail from '../../features/global-super-league/GlobalSuperLeagueDetail';
import GlobalSuperLeagueForm from '../../features/global-super-league/GlobalSuperLeagueForm';
import GlobalSuperLeagueLoader from '../../features/global-super-league/GlobalSuperLeagueLoader';
import GlobalWorkspaceDetail from '../../features/global-workspace/GlobalWorkspaceDetail';
import GlobalWorkspaceForm from '../../features/global-workspace/GlobalWorkspaceForm';
import GlobalWorkspaceLoader from '../../features/global-workspace/GlobalWorkspaceLoader';
import GlobalClubsPage from '../../pages/GlobalClubsPage';
import Notes from '../Notes';
import Route from './Route';

const page = <GlobalClubsPage />;

const createForm = (
  <Route>
    {({ navigate }) => (
      <GlobalClubForm
        onClose={() => navigate(routes.clubs)}
        onCreate={({ clubId }) => navigate(routes.club({ clubId }))}
      />
    )}
  </Route>
);

const detail = (
  <Route params={['clubId']}>
    {({ clubId, navigate }) => (
      <GlobalClubLoader clubId={clubId}>
        {({ data }) => (
          <GlobalClubDetail
            data={data}
            onClose={() => navigate(routes.clubs)}
            onEdit={() => navigate(routes.clubEdit({ clubId }))}
            getNotesRoute={() => navigate(routes.clubNotes({ clubId }))}
            getLeagueRoute={({ leagueId }) => routes.clubLeague({ clubId, leagueId })}
            getWorkspaceRoute={({ workspaceId }) => routes.clubWorkspace({ clubId, workspaceId })}
          />
        )}
      </GlobalClubLoader>
    )}
  </Route>
);

const detailNotes = (
  <Route params={['clubId']}>
    {({ clubId, navigate }) => (
      <GlobalClubNotes clubId={clubId}>
        {(loader) => <Notes onClose={() => navigate(routes.club({ clubId }))} {...loader} />}
      </GlobalClubNotes>
    )}
  </Route>
);

const detailLeague = (
  <Route params={['clubId', 'leagueId']}>
    {({ clubId, leagueId, navigate }) => (
      <GlobalLeagueLoader leagueId={leagueId}>
        {({ data }) => (
          <GlobalLeagueDetail
            data={data}
            onClose={() => navigate(routes.club({ clubId }))}
            onEdit={() => navigate(routes.clubLeagueEdit({ clubId, leagueId }))}
            getSuperLeagueRoute={({ superLeagueId }) =>
              routes.clubLeagueSuperLeague({
                clubId,
                leagueId,
                superLeagueId,
              })
            }
          />
        )}
      </GlobalLeagueLoader>
    )}
  </Route>
);

const detailLeagueSuperLeague = (
  <Route params={['clubId', 'leagueId', 'superLeagueId']}>
    {({ clubId, leagueId, superLeagueId, navigate }) => (
      <GlobalSuperLeagueLoader superLeagueId={superLeagueId}>
        {({ data }) => (
          <GlobalSuperLeagueDetail
            data={data}
            onClose={() => navigate(routes.clubLeague({ clubId, leagueId }))}
            onEdit={() =>
              navigate(
                routes.clubLeagueSuperLeagueEdit({
                  clubId,
                  leagueId,
                  superLeagueId,
                })
              )
            }
          />
        )}
      </GlobalSuperLeagueLoader>
    )}
  </Route>
);

const detailLeagueSuperLeagueEditForm = (
  <Route params={['clubId', 'leagueId', 'superLeagueId']}>
    {({ clubId, leagueId, superLeagueId, navigate }) => (
      <GlobalSuperLeagueLoader superLeagueId={superLeagueId}>
        {({ data }) => (
          <GlobalSuperLeagueForm
            data={data}
            onClose={() =>
              navigate(
                routes.clubLeagueSuperLeague({
                  clubId,
                  leagueId,
                  superLeagueId,
                })
              )
            }
            onEdit={() =>
              navigate(
                routes.clubLeagueSuperLeague({
                  clubId,
                  leagueId,
                  superLeagueId,
                })
              )
            }
          />
        )}
      </GlobalSuperLeagueLoader>
    )}
  </Route>
);

const detailLeagueEditForm = (
  <Route params={['clubId', 'leagueId']}>
    {({ clubId, leagueId, navigate }) => (
      <GlobalLeagueLoader leagueId={leagueId}>
        {({ data }) => (
          <GlobalLeagueForm
            data={data}
            onClose={() => navigate(routes.clubLeague({ clubId, leagueId }))}
            onEdit={() => navigate(routes.clubLeague({ clubId, leagueId }))}
          />
        )}
      </GlobalLeagueLoader>
    )}
  </Route>
);

const detailWorkspace = (
  <Route params={['clubId', 'workspaceId']}>
    {({ clubId, workspaceId, navigate }) => (
      <GlobalWorkspaceLoader workspaceId={workspaceId}>
        {({ data }) => (
          <GlobalWorkspaceDetail
            data={data}
            onClose={() => navigate(routes.club({ clubId }))}
            onEdit={() => navigate(routes.clubWorkspaceEdit({ clubId, workspaceId }))}
          />
        )}
      </GlobalWorkspaceLoader>
    )}
  </Route>
);

const detailWorkspaceEdit = (
  <Route params={['clubId', 'workspaceId']}>
    {({ clubId, workspaceId, navigate }) => (
      <GlobalWorkspaceLoader workspaceId={workspaceId}>
        {({ data, clubsData }) => (
          <GlobalWorkspaceForm
            data={data}
            clubsData={clubsData}
            onClose={() => navigate(routes.clubWorkspace({ clubId, workspaceId }))}
            onEdit={() => navigate(routes.clubWorkspace({ clubId, workspaceId }))}
          />
        )}
      </GlobalWorkspaceLoader>
    )}
  </Route>
);

const deleteError = (
  <Route params={['clubId']}>
    {({ clubId, navigate }) => (
      <GlobalClubLoader clubId={clubId}>
        {({ data }) => (
          <GlobalClubDeleteError data={data} onClose={() => navigate(routes.club({ clubId }))} />
        )}
      </GlobalClubLoader>
    )}
  </Route>
);

const deleteErrorAccount = (
  <Route params={['clubId', 'accountId']}>
    {({ clubId, accountId, navigate }) => (
      <GlobalAccountLoader accountId={accountId}>
        {(args) => (
          <GlobalAccountDetail
            data={args.data}
            onClose={() => navigate(routes.clubError({ clubId }))}
            onEdit={() => navigate(routes.clubErrorAccountEdit({ clubId, accountId }))}
            onRoomConfig={() => navigate(routes.clubErrorAccountRoomConfig({ clubId, accountId }))}
          />
        )}
      </GlobalAccountLoader>
    )}
  </Route>
);

const deleteErrorAccountRoomConfig = (
  <Route params={['clubId', 'accountId']}>
    {({ clubId, accountId, navigate }) => (
      <GlobalAccountLoader accountId={accountId}>
        {({ data: account }) => (
          <GlobalAccountRoomConfigLoader accountId={accountId}>
            {({ data }) => (
              <GlobalAccountRoomConfigForm
                data={data}
                account={account}
                onClose={() => navigate(routes.clubErrorAccount({ clubId, accountId }))}
              />
            )}
          </GlobalAccountRoomConfigLoader>
        )}
      </GlobalAccountLoader>
    )}
  </Route>
);

const deleteErrorAccountEditForm = (
  <Route params={['clubId', 'accountId']}>
    {({ clubId, accountId, navigate }) => (
      <GlobalAccountLoader accountId={accountId}>
        {(args) => (
          <GlobalAccountForm
            data={args.data}
            onClose={() => navigate(routes.clubErrorAccount({ clubId, accountId }))}
          />
        )}
      </GlobalAccountLoader>
    )}
  </Route>
);

const editForm = (
  <Route params={['clubId']}>
    {({ clubId, navigate }) => (
      <GlobalClubLoader clubId={clubId}>
        {({ data, workspacesData }) => (
          <GlobalClubForm
            data={data}
            workspacesData={workspacesData}
            onClose={() => navigate(routes.club({ clubId }))}
            onEdit={() => navigate(routes.club({ clubId }))}
          />
        )}
      </GlobalClubLoader>
    )}
  </Route>
);

const globalClubsPage: RouteObject = {
  path: routes.clubs,
  element: page,
  children: [
    {
      path: routes.clubsCreate,
      element: createForm,
    },
    {
      path: routes.club({ clubId: ':clubId' }),
      element: detail,
      children: [
        {
          path: routes.clubNotes({ clubId: ':clubId' }),
          element: detailNotes,
        },
        {
          path: routes.clubLeague({
            clubId: ':clubId',
            leagueId: ':leagueId',
          }),
          element: detailLeague,
          children: [
            {
              path: routes.clubLeagueSuperLeague({
                clubId: ':clubId',
                leagueId: ':leagueId',
                superLeagueId: ':superLeagueId',
              }),
              element: detailLeagueSuperLeague,
            },
            {
              path: routes.clubLeagueSuperLeagueEdit({
                clubId: ':clubId',
                leagueId: ':leagueId',
                superLeagueId: ':superLeagueId',
              }),
              element: detailLeagueSuperLeagueEditForm,
            },
          ],
        },
        {
          path: routes.clubLeagueEdit({
            clubId: ':clubId',
            leagueId: ':leagueId',
          }),
          element: detailLeagueEditForm,
        },
        {
          path: routes.clubWorkspace({ clubId: ':clubId', workspaceId: ':workspaceId' }),
          element: detailWorkspace,
        },
        {
          path: routes.clubWorkspaceEdit({ clubId: ':clubId', workspaceId: ':workspaceId' }),
          element: detailWorkspaceEdit,
        },
      ],
    },
    {
      path: routes.clubError({ clubId: ':clubId' }),
      element: deleteError,
      children: [
        {
          path: routes.clubErrorAccount({
            clubId: ':clubId',
            accountId: ':accountId',
          }),
          element: deleteErrorAccount,
        },
        {
          path: routes.clubErrorAccountRoomConfig({
            clubId: ':clubId',
            accountId: ':accountId',
          }),
          element: deleteErrorAccountRoomConfig,
        },
        {
          path: routes.clubErrorAccountEdit({
            clubId: ':clubId',
            accountId: ':accountId',
          }),
          element: deleteErrorAccountEditForm,
        },
      ],
    },
    {
      path: routes.clubEdit({ clubId: ':clubId' }),
      element: editForm,
    },
  ],
};

export default globalClubsPage;
