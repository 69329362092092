import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import GlobalAccountDetail from '../../features/global-account/GlobalAccountDetail';
import GlobalAccountForm from '../../features/global-account/GlobalAccountForm';
import GlobalAccountLoader from '../../features/global-account/GlobalAccountLoader';
import GlobalAccountRoomConfigForm from '../../features/global-account/GlobalAccountRoomConfig/GlobalAccountRoomConfigForm';
import GlobalAccountRoomConfigLoader from '../../features/global-account/GlobalAccountRoomConfig/GlobalAccountRoomConfigLoader';
import GlobalLobbyDetail from '../../features/global-lobby/GlobalLobbyDetail';
import GlobalLobbyLoader from '../../features/global-lobby/GlobalLobbyLoader';
import GlobalLobbyPage from '../../pages/GlobalLobbyPage';
import Route from './Route';

const page = <GlobalLobbyPage />;

const detail = (
  <Route params={['tableId']}>
    {({ tableId, navigate }) => (
      <GlobalLobbyLoader tableId={tableId}>
        {({ data }) => <GlobalLobbyDetail data={data} onClose={() => navigate(routes.lobby)} />}
      </GlobalLobbyLoader>
    )}
  </Route>
);

const detailAccount = (
  <Route params={['accountId', 'tableId']}>
    {({ accountId, tableId, navigate }) => (
      <GlobalAccountLoader accountId={accountId}>
        {({ data }) => (
          <GlobalAccountDetail
            data={data}
            onClose={() => navigate(routes.lobbyTable({ tableId }))}
            onEdit={() => navigate(routes.lobbyTableAccountEdit({ tableId, accountId }))}
            onRoomConfig={() =>
              navigate(routes.lobbyTableAccountRoomConfig({ tableId, accountId }))
            }
          />
        )}
      </GlobalAccountLoader>
    )}
  </Route>
);

const detailAccountRoomConfig = (
  <Route params={['tableId', 'accountId']}>
    {({ tableId, accountId, navigate }) => (
      <GlobalAccountLoader accountId={accountId}>
        {({ data: account }) => (
          <GlobalAccountRoomConfigLoader accountId={accountId}>
            {({ data }) => (
              <GlobalAccountRoomConfigForm
                data={data}
                account={account}
                onClose={() => navigate(routes.lobbyTableAccount({ tableId, accountId }))}
              />
            )}
          </GlobalAccountRoomConfigLoader>
        )}
      </GlobalAccountLoader>
    )}
  </Route>
);

const detailAccountEditForm = (
  <Route params={['accountId', 'tableId']}>
    {({ accountId, tableId, navigate }) => (
      <GlobalAccountLoader accountId={accountId}>
        {({ data }) => (
          <GlobalAccountForm
            data={data}
            onClose={() => navigate(routes.lobbyTableAccount({ tableId, accountId }))}
          />
        )}
      </GlobalAccountLoader>
    )}
  </Route>
);

const globalLobbyPage: RouteObject = {
  path: routes.lobby,
  element: page,
  children: [
    {
      path: routes.lobbyTable({ tableId: ':tableId' }),
      element: detail,
      children: [
        {
          path: routes.lobbyTableAccount({
            tableId: ':tableId',
            accountId: ':accountId',
          }),
          element: detailAccount,
        },
        {
          path: routes.lobbyTableAccountRoomConfig({
            tableId: ':tableId',
            accountId: ':accountId',
          }),
          element: detailAccountRoomConfig,
        },
        {
          path: routes.lobbyTableAccountEdit({
            tableId: ':tableId',
            accountId: ':accountId',
          }),
          element: detailAccountEditForm,
        },
      ],
    },
  ],
};

export default globalLobbyPage;
