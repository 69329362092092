import { PROTECTION } from '../../api/schemas/deal';
import { DealSchema } from '../../api/schemas/deal/dealSchema';
import Badge, { Color } from '../../components/Badge';
import { PROTECTION_LABELS } from './helpers';

const COLORS: Record<DealSchema['protection'], Color> = {
  [PROTECTION.UNKNOWN]: 'grey',
  [PROTECTION.PROTECTION]: 'green',
  [PROTECTION.PENALTY]: 'red',
  [PROTECTION.NO_PENALTY]: 'blue',
  [PROTECTION.FIFTY_FIFTY]: 'orange',
};

interface Props {
  data: DealSchema;
}

const GlobalDealProtection = (props: Props) => {
  const { data } = props;

  const { protection } = data;

  return <Badge color={COLORS[protection]}>{PROTECTION_LABELS[protection]}</Badge>;
};

export default GlobalDealProtection;
