import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { updateWorkspaceStrategyProfile } from '../../../api';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import { WorkspaceStrategyProfileSchema } from '../../../api/schemas/workspaceStrategyProfileSchema';
import Checkbox from '../../../components/Checkbox';
import Input from '../../../components/Input';
import Select, { Option } from '../../../components/Select';
import { useUserStore } from '../../../store/user';
import { convertStringToNumber } from '../../../utils/data';
import { required, max, handleError } from '../../../utils/form';
import { numbers } from '../../../utils/validations';
import {
  PLAYER_TYPE_LABELS,
  SEATING_PREFERENCE_LABELS,
  playerTypesOptions,
  seatingPreferencesOptions,
} from '../../account/helpers';
import styles from './styles.module.scss';

const INPUT_PADDINGS = 9;
const MAX_WIDTH = 80;

interface Fields {
  player_type: Option<WorkspaceStrategyProfileSchema['player_type']>;
  buyin_bb_min: string;
  buyin_bb_max: string;
  buyin_step_bb: string;
  buyin_bb_straddle_table_min: string;
  buyin_bb_straddle_table_max: string;
  buyin_step_bb_straddle_table: string;
  rebuy_below_bb_min: string;
  rebuy_below_bb_max: string;
  rebuy_below_bb_straddle_table_min: string;
  rebuy_below_bb_straddle_table_max: string;
  stop_lose_bb_min: string;
  stop_lose_bb_max: string;
  stop_win_bb_min: string;
  stop_win_bb_max: string;
  stop_lose_table_bb_min: string;
  stop_lose_table_bb_max: string;
  stop_win_table_bb_min: string;
  stop_win_table_bb_max: string;
  max_swing_bb_min: string;
  max_swing_bb_max: string;
  effective_stack_limit_bb_min: string;
  effective_stack_limit_bb_max: string;
  max_stack_size_bb_min: string;
  max_stack_size_bb_max: string;
  table_min_hands: string;
  wait_for_big_blind: boolean;
  seating_preference: Option<WorkspaceStrategyProfileSchema['seating_preference']>;
}

interface Props {
  data: WorkspaceStrategyProfileSchema;
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceSettingsStrategyProfile = (props: Props) => {
  const { data, workspaceId } = props;

  const { t } = useTranslation();

  const user = useUserStore();

  const owner = user.checkOwner(workspaceId);

  const form = useForm<Fields>({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      player_type: { value: data.player_type, label: PLAYER_TYPE_LABELS[data.player_type] },
      buyin_bb_min: String(data.buyin_bb_min),
      buyin_bb_max: String(data.buyin_bb_max),
      buyin_step_bb: String(data.buyin_step_bb),
      buyin_bb_straddle_table_min: String(data.buyin_bb_straddle_table_min),
      buyin_bb_straddle_table_max: String(data.buyin_bb_straddle_table_max),
      buyin_step_bb_straddle_table: String(data.buyin_step_bb_straddle_table),
      rebuy_below_bb_min: String(data.rebuy_below_bb_min),
      rebuy_below_bb_max: String(data.rebuy_below_bb_max),
      rebuy_below_bb_straddle_table_min: String(data.rebuy_below_bb_straddle_table_min),
      rebuy_below_bb_straddle_table_max: String(data.rebuy_below_bb_straddle_table_max),
      stop_lose_bb_min: String(data.stop_lose_bb_min),
      stop_lose_bb_max: String(data.stop_lose_bb_max),
      stop_win_bb_min: String(data.stop_win_bb_min),
      stop_win_bb_max: String(data.stop_win_bb_max),
      stop_lose_table_bb_min: String(data.stop_lose_table_bb_min),
      stop_lose_table_bb_max: String(data.stop_lose_table_bb_max),
      stop_win_table_bb_min: String(data.stop_win_table_bb_min),
      stop_win_table_bb_max: String(data.stop_win_table_bb_max),
      max_swing_bb_min: String(data.max_swing_bb_min),
      max_swing_bb_max: String(data.max_swing_bb_max),
      effective_stack_limit_bb_min: String(data.effective_stack_limit_bb_min),
      effective_stack_limit_bb_max: String(data.effective_stack_limit_bb_max),
      max_stack_size_bb_min: String(data.max_stack_size_bb_min),
      max_stack_size_bb_max: String(data.max_stack_size_bb_max),
      table_min_hands: String(data.table_min_hands),
      wait_for_big_blind: data.wait_for_big_blind,
      seating_preference: {
        value: data.seating_preference,
        label: SEATING_PREFERENCE_LABELS[data.seating_preference],
      },
    },
  });

  const { control, handleSubmit, setError } = form;

  const onSubmit = async (values: Fields) => {
    try {
      const payload = {
        player_type: values.player_type?.value,
        buyin_bb_min: convertStringToNumber(values.buyin_bb_min),
        buyin_bb_max: convertStringToNumber(values.buyin_bb_max),
        buyin_step_bb: convertStringToNumber(values.buyin_step_bb),
        buyin_bb_straddle_table_min: convertStringToNumber(values.buyin_bb_straddle_table_min),
        buyin_bb_straddle_table_max: convertStringToNumber(values.buyin_bb_straddle_table_max),
        buyin_step_bb_straddle_table: convertStringToNumber(values.buyin_step_bb_straddle_table),
        rebuy_below_bb_min: convertStringToNumber(values.rebuy_below_bb_min),
        rebuy_below_bb_max: convertStringToNumber(values.rebuy_below_bb_max),
        rebuy_below_bb_straddle_table_min: convertStringToNumber(
          values.rebuy_below_bb_straddle_table_min
        ),
        rebuy_below_bb_straddle_table_max: convertStringToNumber(
          values.rebuy_below_bb_straddle_table_max
        ),
        stop_lose_bb_min: convertStringToNumber(values.stop_lose_bb_min),
        stop_lose_bb_max: convertStringToNumber(values.stop_lose_bb_max),
        stop_win_bb_min: convertStringToNumber(values.stop_win_bb_min),
        stop_win_bb_max: convertStringToNumber(values.stop_win_bb_max),
        stop_lose_table_bb_min: convertStringToNumber(values.stop_lose_table_bb_min),
        stop_lose_table_bb_max: convertStringToNumber(values.stop_lose_table_bb_max),
        stop_win_table_bb_min: convertStringToNumber(values.stop_win_table_bb_min),
        stop_win_table_bb_max: convertStringToNumber(values.stop_win_table_bb_max),
        max_swing_bb_min: convertStringToNumber(values.max_swing_bb_min),
        max_swing_bb_max: convertStringToNumber(values.max_swing_bb_max),
        effective_stack_limit_bb_min: convertStringToNumber(values.effective_stack_limit_bb_min),
        effective_stack_limit_bb_max: convertStringToNumber(values.effective_stack_limit_bb_max),
        max_stack_size_bb_min: convertStringToNumber(values.max_stack_size_bb_min),
        max_stack_size_bb_max: convertStringToNumber(values.max_stack_size_bb_max),
        table_min_hands: convertStringToNumber(values.table_min_hands),
        wait_for_big_blind: values.wait_for_big_blind,
        seating_preference: values.seating_preference?.value,
      };

      await updateWorkspaceStrategyProfile({ workspaceId, payload });
    } catch (error) {
      handleError({ error, setError });
    }
  };

  const commonProps = { control, onSubmit: handleSubmit(onSubmit) };

  const inputProps = {
    ...commonProps,
    disabled: !owner,
    allow: numbers,
    className: styles.input,
    setInputWidth: (width: number) =>
      width + INPUT_PADDINGS < MAX_WIDTH ? width + INPUT_PADDINGS : MAX_WIDTH,
  };

  const selectProps = { ...commonProps, disabled: !owner, popupSize: 'auto' as const };

  const divider = <span className={styles.divider}>—</span>;

  return (
    <div>
      <div className={styles.container}>
        <div>
          <span>{t('common.player_type')}</span>
          <div>
            <Select.Quick name="player_type" options={playerTypesOptions} {...selectProps} />
          </div>
        </div>
        <div>
          <span>{t('common.buyin_range_bb')}</span>
          <div>
            <Input.Quick
              name="buyin_bb_min"
              rules={{ validate: { required, max: max(t('common.buyin_range_bb'), 100000) } }}
              {...inputProps}
            />
            {divider}
            <Input.Quick
              name="buyin_bb_max"
              rules={{ validate: { required, max: max(t('common.buyin_range_bb'), 100000) } }}
              {...inputProps}
            />
          </div>
        </div>
        <div>
          <span>{t('common.buyin_step_bb')}</span>
          <div>
            <Input.Quick name="buyin_step_bb" rules={{ validate: { required } }} {...inputProps} />
          </div>
        </div>
        <div>
          <span>{t('common.straddle_tables_buyin_range_bb')}</span>
          <div>
            <Input.Quick
              name="buyin_bb_straddle_table_min"
              rules={{
                validate: {
                  required,
                  max: max(t('common.straddle_tables_buyin_range_bb'), 100000),
                },
              }}
              {...inputProps}
            />
            {divider}
            <Input.Quick
              name="buyin_bb_straddle_table_max"
              rules={{
                validate: {
                  required,
                  max: max(t('common.straddle_tables_buyin_range_bb'), 100000),
                },
              }}
              {...inputProps}
            />
          </div>
        </div>
        <div>
          <span>{t('common.straddle_tables_buyin_step_bb')}</span>
          <div>
            <Input.Quick
              name="buyin_step_bb_straddle_table"
              rules={{ validate: { required } }}
              {...inputProps}
            />
          </div>
        </div>
        <div>
          <span>{t('common.rebuy_threshold_bb')}</span>
          <div>
            <Input.Quick
              name="rebuy_below_bb_min"
              rules={{ validate: { required, max: max(t('common.rebuy_threshold_bb'), 100000) } }}
              {...inputProps}
            />
            {divider}
            <Input.Quick
              name="rebuy_below_bb_max"
              rules={{ validate: { required, max: max(t('common.rebuy_threshold_bb'), 100000) } }}
              {...inputProps}
            />
          </div>
        </div>
        <div>
          <span>{t('common.straddle_tables_rebuy_threshold_bb')}</span>
          <div>
            <Input.Quick
              name="rebuy_below_bb_straddle_table_min"
              rules={{
                validate: {
                  required,
                  max: max(t('common.straddle_tables_rebuy_threshold_bb'), 100000),
                },
              }}
              {...inputProps}
            />
            {divider}
            <Input.Quick
              name="rebuy_below_bb_straddle_table_max"
              rules={{
                validate: {
                  required,
                  max: max(t('common.straddle_tables_rebuy_threshold_bb'), 100000),
                },
              }}
              {...inputProps}
            />
          </div>
        </div>
        <div>
          <span>{t('common.stop_loss_bb')}</span>
          <div>
            <Input.Quick
              name="stop_lose_bb_min"
              rules={{ validate: { required, max: max(t('common.stop_loss_bb'), 100000) } }}
              {...inputProps}
            />
            {divider}
            <Input.Quick
              name="stop_lose_bb_max"
              rules={{ validate: { required, max: max(t('common.stop_loss_bb'), 100000) } }}
              {...inputProps}
            />
          </div>
        </div>
        <div>
          <span>{t('common.stop_win_bb')}</span>
          <div>
            <Input.Quick
              name="stop_win_bb_min"
              rules={{ validate: { required, max: max(t('common.stop_win_bb'), 100000) } }}
              {...inputProps}
            />
            {divider}
            <Input.Quick
              name="stop_win_bb_max"
              rules={{ validate: { required, max: max(t('common.stop_win_bb'), 100000) } }}
              {...inputProps}
            />
          </div>
        </div>
        <div>
          <span>{t('common.stop_loss_by_table_bb')}</span>
          <div>
            <Input.Quick
              name="stop_lose_table_bb_min"
              rules={{
                validate: { required, max: max(t('common.stop_loss_by_table_bb'), 100000) },
              }}
              {...inputProps}
            />
            {divider}
            <Input.Quick
              name="stop_lose_table_bb_max"
              rules={{
                validate: { required, max: max(t('common.stop_loss_by_table_bb'), 100000) },
              }}
              {...inputProps}
            />
          </div>
        </div>
        <div>
          <span>{t('common.stop_win_by_table_bb')}</span>
          <div>
            <Input.Quick
              name="stop_win_table_bb_min"
              rules={{ validate: { required, max: max(t('common.stop_win_by_table_bb'), 100000) } }}
              {...inputProps}
            />
            {divider}
            <Input.Quick
              name="stop_win_table_bb_max"
              rules={{ validate: { required, max: max(t('common.stop_win_by_table_bb'), 100000) } }}
              {...inputProps}
            />
          </div>
        </div>
        <div>
          <span>{t('common.max_swing_bb')}</span>
          <div>
            <Input.Quick
              name="max_swing_bb_min"
              rules={{ validate: { required, max: max(t('common.max_swing_bb'), 100000) } }}
              {...inputProps}
            />
            {divider}
            <Input.Quick
              name="max_swing_bb_max"
              rules={{ validate: { required, max: max(t('common.max_swing_bb'), 100000) } }}
              {...inputProps}
            />
          </div>
        </div>
        <div>
          <span>{t('common.effective_stack_limit_bb')}</span>
          <div>
            <Input.Quick
              name="effective_stack_limit_bb_min"
              rules={{
                validate: { required, max: max(t('common.effective_stack_limit_bb'), 100000) },
              }}
              {...inputProps}
            />
            {divider}
            <Input.Quick
              name="effective_stack_limit_bb_max"
              rules={{
                validate: { required, max: max(t('common.effective_stack_limit_bb'), 100000) },
              }}
              {...inputProps}
            />
          </div>
        </div>
        <div>
          <span>{t('common.max_stack_size_bb')}</span>
          <div>
            <Input.Quick
              name="max_stack_size_bb_min"
              rules={{ validate: { required, max: max(t('common.max_stack_size_bb'), 100000) } }}
              {...inputProps}
            />
            {divider}
            <Input.Quick
              name="max_stack_size_bb_max"
              rules={{ validate: { required, max: max(t('common.max_stack_size_bb'), 100000) } }}
              {...inputProps}
            />
          </div>
        </div>
        <div>
          <span>{t('common.table_min_hands')}</span>
          <div>
            <Input.Quick
              name="table_min_hands"
              rules={{ validate: { required, max: max(t('common.table_min_hands'), 100000) } }}
              {...inputProps}
            />
          </div>
        </div>
        <div>
          <span>{t('common.wait_for_big_blind')}</span>
          <div>
            <Checkbox.Quick name="wait_for_big_blind" {...inputProps} />
          </div>
        </div>
        <div>
          <span>{t('common.seating_preference')}</span>
          <div>
            <Select.Quick
              name="seating_preference"
              options={seatingPreferencesOptions}
              {...selectProps}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceSettingsStrategyProfile;
