import { useTranslation } from 'react-i18next';
import { DealFilterValuesSchema } from '../../api/schemas/deal/dealSchema';
import Filter from '../../components/Filter';
import useFilter from '../../hooks/useFilter';
import { CREDIT_LABELS, PROTECTION_LABELS, ROLE_LABELS, STATUS_LABELS } from './helpers';

interface Fields {
  agent_id_in: string[];
  club_id_in: string[];
  area_id_in: string[];
  area2_id_in: string[];
  holder_id_in: string[];
  owner_in: string[];
  legend_in: string[];
  role_in: string[];
  status_in: string[];
  credit_in: string[];
  protection_in: string[];
  currency_in: string[];
  policy_in: string[];
  chip_rate_ge: string;
  chip_rate_le: string;
  rake_back_ge: string;
  rake_back_le: string;
  action_ge: string;
  action_le: string;
  rev_share_ge: string;
  rev_share_le: string;
  win_share_ge: string;
  win_share_le: string;
  rebate_ge: string;
  rebate_le: string;
  agent_fee_ge: string;
  agent_fee_le: string;
  withdraw_fee_ge: string;
  withdraw_fee_le: string;
  created_ge: string;
  created_le: string;
  updated_ge: string;
  updated_le: string;
  show_deleted: boolean;
}

interface Props {
  isPending: boolean;
  data?: DealFilterValuesSchema;
}

const GlobalDealFilter = (props: Props) => {
  const { isPending, data } = props;

  const { t } = useTranslation();

  const filter = useFilter<Fields>({
    agent_id_in: 'multi',
    club_id_in: 'multi',
    area_id_in: 'multi',
    area2_id_in: 'multi',
    holder_id_in: 'multi',
    owner_in: 'multi',
    legend_in: 'multi',
    role_in: 'multi',
    status_in: 'multi',
    credit_in: 'multi',
    protection_in: 'multi',
    currency_in: 'multi',
    policy_in: 'multi',
    chip_rate_ge: 'single',
    chip_rate_le: 'single',
    rake_back_ge: 'single',
    rake_back_le: 'single',
    action_ge: 'single',
    action_le: 'single',
    rev_share_ge: 'single',
    rev_share_le: 'single',
    win_share_ge: 'single',
    win_share_le: 'single',
    rebate_ge: 'single',
    rebate_le: 'single',
    agent_fee_ge: 'single',
    agent_fee_le: 'single',
    withdraw_fee_ge: 'single',
    withdraw_fee_le: 'single',
    created_ge: 'single',
    created_le: 'single',
    updated_ge: 'single',
    updated_le: 'single',
    show_deleted: 'toggle',
  });

  const { values, setValue, clearValue } = filter;

  const {
    agent_id_in,
    club_id_in,
    area_id_in,
    area2_id_in,
    holder_id_in,
    owner_in,
    legend_in,
    role_in,
    status_in,
    credit_in,
    protection_in,
    currency_in,
    policy_in,
    chip_rate_ge,
    chip_rate_le,
    rake_back_ge,
    rake_back_le,
    action_ge,
    action_le,
    rev_share_ge,
    rev_share_le,
    win_share_ge,
    win_share_le,
    rebate_ge,
    rebate_le,
    agent_fee_ge,
    agent_fee_le,
    withdraw_fee_ge,
    withdraw_fee_le,
    created_ge,
    created_le,
    updated_ge,
    updated_le,
    show_deleted,
  } = values;

  const filterProps = { filter };

  if (isPending) {
    return (
      <Filter {...filterProps}>
        <Filter.Placeholder />
      </Filter>
    );
  }

  if (!data) return null;

  const {
    agent,
    club,
    area_id,
    area2_id,
    holder,
    owner,
    legend,
    role,
    status,
    credit,
    protection,
    currency,
    policy,
    chip_rate,
    rake_back,
    action,
    rev_share,
    win_share,
    rebate,
    agent_fee,
    withdraw_fee,
  } = data;

  return (
    <Filter {...filterProps}>
      <div>
        {agent.length !== 0 && (
          <Filter.Collapse
            label={t('common.agent')}
            count={agent_id_in.length}
            onClear={clearValue('agent_id_in')}
          >
            <Filter.MultiCheckbox
              config={agent.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('agent_id_in'),
                checked: agent_id_in.includes(item.id),
              }))}
            />
          </Filter.Collapse>
        )}
        {club.length !== 0 && (
          <Filter.Collapse
            label={t('common.club')}
            count={club_id_in.length}
            onClear={clearValue('club_id_in')}
          >
            <Filter.MultiCheckbox
              config={club.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('club_id_in'),
                checked: club_id_in.includes(item.id),
                addon: item.code,
              }))}
            />
          </Filter.Collapse>
        )}
        {area_id.length !== 0 && (
          <Filter.Collapse
            label={t('common.area')}
            count={area_id_in.length}
            onClear={clearValue('area_id_in')}
          >
            <Filter.MultiCheckbox
              config={area_id.map((item) => ({
                value: item,
                label: item,
                onChange: setValue('area_id_in'),
                checked: area_id_in.includes(item),
              }))}
            />
          </Filter.Collapse>
        )}
        {area2_id.length !== 0 && (
          <Filter.Collapse
            label={t('common.area_2')}
            count={area2_id_in.length}
            onClear={clearValue('area2_id_in')}
          >
            <Filter.MultiCheckbox
              config={area2_id.map((item) => ({
                value: item,
                label: item,
                onChange: setValue('area2_id_in'),
                checked: area2_id_in.includes(item),
              }))}
            />
          </Filter.Collapse>
        )}
        {holder.length !== 0 && (
          <Filter.Collapse
            label={t('common.holder')}
            count={holder_id_in.length}
            onClear={clearValue('holder_id_in')}
          >
            <Filter.MultiCheckbox
              config={holder.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('holder_id_in'),
                checked: holder_id_in.includes(item.id),
              }))}
            />
          </Filter.Collapse>
        )}
        {owner.length !== 0 && (
          <Filter.Collapse
            label={t('common.owner')}
            count={owner_in.length}
            onClear={clearValue('owner_in')}
          >
            <Filter.MultiCheckbox
              config={owner.map((item) => ({
                value: item,
                label: item,
                onChange: setValue('owner_in'),
                checked: owner_in.includes(item),
              }))}
            />
          </Filter.Collapse>
        )}
        {legend.length !== 0 && (
          <Filter.Collapse
            label={t('common.legend')}
            count={legend_in.length}
            onClear={clearValue('legend_in')}
          >
            <Filter.MultiCheckbox
              config={legend.map((item) => ({
                value: item,
                label: item,
                onChange: setValue('legend_in'),
                checked: legend_in.includes(item),
              }))}
            />
          </Filter.Collapse>
        )}
        {role.length !== 0 && (
          <Filter.Collapse
            label={t('common.role')}
            count={role_in.length}
            onClear={clearValue('role_in')}
          >
            <Filter.MultiCheckbox
              config={role.map((item) => ({
                value: String(item),
                label: ROLE_LABELS[item],
                onChange: setValue('role_in'),
                checked: role_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        {status.length !== 0 && (
          <Filter.Collapse
            label={t('common.status')}
            count={status_in.length}
            onClear={clearValue('status_in')}
          >
            <Filter.MultiCheckbox
              config={status.map((item) => ({
                value: String(item),
                label: STATUS_LABELS[item],
                onChange: setValue('status_in'),
                checked: status_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        {credit.length !== 0 && (
          <Filter.Collapse
            label={t('common.credit')}
            count={credit_in.length}
            onClear={clearValue('credit_in')}
          >
            <Filter.MultiCheckbox
              config={credit.map((item) => ({
                value: String(item),
                label: CREDIT_LABELS[item],
                onChange: setValue('credit_in'),
                checked: credit_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        {protection.length !== 0 && (
          <Filter.Collapse
            label={t('common.protection')}
            count={protection_in.length}
            onClear={clearValue('protection_in')}
          >
            <Filter.MultiCheckbox
              config={protection.map((item) => ({
                value: String(item),
                label: PROTECTION_LABELS[item],
                onChange: setValue('protection_in'),
                checked: protection_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        {currency.length !== 0 && (
          <Filter.Collapse
            label={t('common.currency')}
            count={currency_in.length}
            onClear={clearValue('currency_in')}
          >
            <Filter.MultiCheckbox
              config={currency.map((item) => ({
                value: item,
                label: item,
                onChange: setValue('currency_in'),
                checked: currency_in.includes(item),
              }))}
            />
          </Filter.Collapse>
        )}
        {policy.length !== 0 && (
          <Filter.Collapse
            label={t('common.policy')}
            count={policy_in.length}
            onClear={clearValue('policy_in')}
          >
            <Filter.MultiCheckbox
              config={policy.map((item) => ({
                value: item,
                label: item,
                onChange: setValue('policy_in'),
                checked: policy_in.includes(item),
              }))}
            />
          </Filter.Collapse>
        )}
        <Filter.RangeInput
          label={t('common.chip_rate')}
          step={1}
          min={chip_rate.min}
          max={chip_rate.max}
          valueMin={chip_rate_ge}
          valueMax={chip_rate_le}
          fieldMin="chip_rate_ge"
          fieldMax="chip_rate_le"
        />
        <Filter.RangeInput
          label={t('common.rakeback')}
          step={1}
          min={rake_back.min}
          max={rake_back.max}
          valueMin={rake_back_ge}
          valueMax={rake_back_le}
          fieldMin="rake_back_ge"
          fieldMax="rake_back_le"
        />
        <Filter.RangeInput
          label={t('common.action')}
          step={1}
          min={action.min}
          max={action.max}
          valueMin={action_ge}
          valueMax={action_le}
          fieldMin="action_ge"
          fieldMax="action_le"
        />
        <Filter.RangeInput
          label={t('common.revshare')}
          step={1}
          min={rev_share.min}
          max={rev_share.max}
          valueMin={rev_share_ge}
          valueMax={rev_share_le}
          fieldMin="rev_share_ge"
          fieldMax="rev_share_le"
        />
        <Filter.RangeInput
          label={t('common.winshare')}
          step={1}
          min={win_share.min}
          max={win_share.max}
          valueMin={win_share_ge}
          valueMax={win_share_le}
          fieldMin="win_share_ge"
          fieldMax="win_share_le"
        />
        <Filter.RangeInput
          label={t('common.rebate')}
          step={1}
          min={rebate.min}
          max={rebate.max}
          valueMin={rebate_ge}
          valueMax={rebate_le}
          fieldMin="rebate_ge"
          fieldMax="rebate_le"
        />
        <Filter.RangeInput
          label={t('common.agent_fee')}
          step={1}
          min={agent_fee.min}
          max={agent_fee.max}
          valueMin={agent_fee_ge}
          valueMax={agent_fee_le}
          fieldMin="agent_fee_ge"
          fieldMax="agent_fee_le"
        />
        <Filter.RangeInput
          label={t('common.withdraw_fee')}
          step={1}
          min={withdraw_fee.min}
          max={withdraw_fee.max}
          valueMin={withdraw_fee_ge}
          valueMax={withdraw_fee_le}
          fieldMin="withdraw_fee_ge"
          fieldMax="withdraw_fee_le"
        />
        <Filter.Collapse
          label={t('common.modified')}
          {...((updated_ge || updated_le) && {
            onClear: clearValue(['updated_ge', 'updated_le']),
          })}
        >
          <Filter.Date
            from={{ value: updated_ge, onChange: setValue('updated_ge') }}
            to={{ value: updated_le, onChange: setValue('updated_le') }}
          />
        </Filter.Collapse>
        <Filter.Collapse
          label={t('common.created')}
          {...((created_ge || created_le) && {
            onClear: clearValue(['created_ge', 'created_le']),
          })}
        >
          <Filter.Date
            from={{ value: created_ge, onChange: setValue('created_ge') }}
            to={{ value: created_le, onChange: setValue('created_le') }}
          />
        </Filter.Collapse>
      </div>
      <Filter.Switcher checked={show_deleted} onChange={setValue('show_deleted')}>
        {t('common.show_deleted')}
      </Filter.Switcher>
    </Filter>
  );
};

export default GlobalDealFilter;
