import { useQuery } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { loadWorkspaceStrategyProfile } from '../../../api';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import { WorkspaceStrategyProfileSchema } from '../../../api/schemas/workspaceStrategyProfileSchema';
import queryKeys from '../../../config/queryKeys';
import WorkspaceSettingsSpinner from '../WorkspaceSettingsSpinner';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  children: (args: { data: WorkspaceStrategyProfileSchema }) => ReactElement;
}

const WorkspaceSettingsStrategyProfileLoader = (props: Props) => {
  const { workspaceId, children } = props;

  const queryKey = [queryKeys.workspaceStrategyProfile({ workspaceId })];
  const queryFn = () => loadWorkspaceStrategyProfile({ workspaceId });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403] },
  });

  if (isPending) return <WorkspaceSettingsSpinner />;
  if (!data) return null;

  return children({ data });
};

export default WorkspaceSettingsStrategyProfileLoader;
