import { CREDIT } from '../../api/schemas/deal';
import { DealSchema } from '../../api/schemas/deal/dealSchema';
import Badge, { Color } from '../../components/Badge';
import { CREDIT_LABELS } from './helpers';

const COLORS: Record<DealSchema['credit'], Color> = {
  [CREDIT.UNKNOWN]: 'grey',
  [CREDIT.FULL]: 'orange',
  [CREDIT.LIMITED]: 'orange',
  [CREDIT.FIFTY_FIFTY]: 'blue',
  [CREDIT.NO_CREDIT]: 'red',
};

interface Props {
  data: DealSchema;
}

const GlobalDealCredit = (props: Props) => {
  const { data } = props;

  const { credit } = data;

  return <Badge color={COLORS[credit]}>{CREDIT_LABELS[credit]}</Badge>;
};

export default GlobalDealCredit;
