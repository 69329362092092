import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import GlobalAccountDetail from '../../features/global-account/GlobalAccountDetail';
import GlobalAccountForm from '../../features/global-account/GlobalAccountForm';
import GlobalAccountLoader from '../../features/global-account/GlobalAccountLoader';
import GlobalAccountRoomConfigForm from '../../features/global-account/GlobalAccountRoomConfig/GlobalAccountRoomConfigForm';
import GlobalAccountRoomConfigLoader from '../../features/global-account/GlobalAccountRoomConfig/GlobalAccountRoomConfigLoader';
import GlobalOrderLoader from '../../features/global-order/GlobalOrderLoader';
import GlobalOrderTransactions from '../../features/global-order/GlobalOrderTransactions';
import OrderDetail from '../../features/order/OrderDetail';
import GlobalOrdersPage from '../../pages/GlobalOrdersPage';
import Route from './Route';

const page = <GlobalOrdersPage />;

const detail = (
  <Route params={['orderId']}>
    {({ orderId, navigate }) => (
      <GlobalOrderLoader orderId={orderId}>
        {({ data }) => (
          <OrderDetail
            data={data}
            transactions={<GlobalOrderTransactions orderId={orderId} />}
            onClose={() => navigate(routes.orders)}
          />
        )}
      </GlobalOrderLoader>
    )}
  </Route>
);

const detailAccount = (
  <Route params={['orderId', 'accountId']}>
    {({ orderId, accountId, navigate }) => (
      <GlobalAccountLoader accountId={accountId}>
        {({ data }) => (
          <GlobalAccountDetail
            data={data}
            onClose={() => navigate(routes.order({ orderId }))}
            onEdit={() => navigate(routes.orderAccountEdit({ orderId, accountId }))}
            onRoomConfig={() => navigate(routes.orderAccountRoomConfig({ orderId, accountId }))}
          />
        )}
      </GlobalAccountLoader>
    )}
  </Route>
);

const detailAccountRoomConfig = (
  <Route params={['orderId', 'accountId']}>
    {({ orderId, accountId, navigate }) => (
      <GlobalAccountLoader accountId={accountId}>
        {({ data: account }) => (
          <GlobalAccountRoomConfigLoader accountId={accountId}>
            {({ data }) => (
              <GlobalAccountRoomConfigForm
                data={data}
                account={account}
                onClose={() => navigate(routes.orderAccount({ orderId, accountId }))}
              />
            )}
          </GlobalAccountRoomConfigLoader>
        )}
      </GlobalAccountLoader>
    )}
  </Route>
);

const detailAccountEditForm = (
  <Route params={['orderId', 'accountId']}>
    {({ orderId, accountId, navigate }) => (
      <GlobalAccountLoader accountId={accountId}>
        {({ data }) => (
          <GlobalAccountForm
            data={data}
            onClose={() => navigate(routes.orderAccount({ orderId, accountId }))}
          />
        )}
      </GlobalAccountLoader>
    )}
  </Route>
);

const globalOrdersPage: RouteObject = {
  path: routes.orders,
  element: page,
  children: [
    {
      path: routes.order({ orderId: ':orderId' }),
      element: detail,
      children: [
        {
          path: routes.orderAccount({ orderId: ':orderId', accountId: ':accountId' }),
          element: detailAccount,
        },
        {
          path: routes.orderAccountRoomConfig({
            orderId: ':orderId',
            accountId: ':accountId',
          }),
          element: detailAccountRoomConfig,
        },
        {
          path: routes.orderAccountEdit({ orderId: ':orderId', accountId: ':accountId' }),
          element: detailAccountEditForm,
        },
      ],
    },
  ],
};

export default globalOrdersPage;
