import { ReactNode } from 'react';
import icon from '../../../static/icons';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
  onClick: () => void;
}

const PageAddonCopy = (props: Props) => {
  const { children, onClick } = props;

  return (
    <button type="button" className={styles.container} onClick={onClick}>
      {children}
      {icon('copy', 16)}
    </button>
  );
};

export default PageAddonCopy;
