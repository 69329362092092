import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { loadGlobalWorkspaceAddress } from '../../../api';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Button from '../../../components/Button';
import PageAddon from '../../../components/PageAddon';
import Placeholder from '../../../components/Placeholder';
import queryKeys from '../../../config/queryKeys';
import icon from '../../../static/icons';
import { copyAddress } from '../../../utils/clipboard';
import styles from './styles.module.scss';

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const GlobalWorkspaceDetailAddress = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();

  const queryKey = [queryKeys.globalWorkspaceAddress({ workspaceId })];
  const queryFn = () => loadGlobalWorkspaceAddress({ workspaceId });

  const { data, isPending, isError, refetch } = useQuery({
    queryKey,
    queryFn,
  });

  if (isPending) return <Placeholder className={styles.placeholder} />;

  if (isError) {
    return (
      <div className={styles.error}>
        <span>{t('common.error')}</span>
        <Button variant="light" size="small" icon={icon('refresh', 16)} onClick={() => refetch()}>
          {t('common.retry')}
        </Button>
      </div>
    );
  }

  if (!data) return null;

  const { tron_address } = data;

  const copy = () => copyAddress(tron_address);

  const start = tron_address.slice(0, 5);
  const end = [...tron_address].reverse().join('').slice(0, 6);

  return <PageAddon.Copy onClick={copy}>{`${start}...${end}`}</PageAddon.Copy>;
};

export default GlobalWorkspaceDetailAddress;
