import clsx from 'clsx';
import { ElementRef, forwardRef, ReactNode } from 'react';
import styles from './styles.module.scss';

export type Color = 'green' | 'orange' | 'red' | 'blue' | 'purple' | 'grey';

const COLORS: Record<Color, string> = {
  green: styles.green,
  orange: styles.orange,
  red: styles.red,
  blue: styles.blue,
  purple: styles.purple,
  grey: styles.grey,
};

interface Props {
  color: Color;
  children: ReactNode;
}

const Badge = forwardRef<ElementRef<'div'>, Props>((props, ref) => {
  const { color, children } = props;

  return (
    <div ref={ref} className={clsx(styles.container, COLORS[color])}>
      {children}
    </div>
  );
});

export default Badge;
