import { WorkspaceAccountSchema } from '../../api/schemas/account/workspaceAccountSchema';
import { WorkspaceStageSchema } from '../../api/schemas/stage/workspaceStageSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import { Options } from '../../components/Dropdown';
import useURL from '../../hooks/useURL';
import i18n from '../../i18n';
import { useWorkspaceAccountsStagesQuery } from './queries';
import useCurrentStage from './useCurrentStage';
import WorkspaceAccountStageLabel from './WorkspaceAccountStageLabel';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  onClick: (stage: WorkspaceStageSchema) => void;
  data?: WorkspaceAccountSchema;
  displayAll?: boolean;
}

const useDropdownStages = (args: Args) => {
  const { workspaceId, onClick, data, displayAll } = args;

  const { urlParams } = useURL();

  const { search } = urlParams;

  const { data: stagesData } = useWorkspaceAccountsStagesQuery({ workspaceId });

  const { currentStage } = useCurrentStage();

  const allStagesView = search || displayAll;

  const activeStage = stagesData?.items.find((item) =>
    allStagesView ? data?.stage.order === item.order : item.order === currentStage
  );

  const stages: Options =
    [
      activeStage || [],
      stagesData ? stagesData.items.filter((item) => item.id !== activeStage?.id) : [],
    ]
      .flat()
      .map((item, index) => {
        const active = allStagesView
          ? data?.stage.order === item.order
          : currentStage === item.order;
        const confirm =
          !item.is_playable ||
          item.stop_current_session ||
          item.clear_channel ||
          item.clear_schedule;

        return {
          label: (
            <WorkspaceAccountStageLabel data={item} active={active} index={index}>
              {item.name}
            </WorkspaceAccountStageLabel>
          ),
          active,
          onClick: () => onClick(item),
          disabled: active,
          confirm,
          confirmText: i18n.t('common.move'),
        };
      }) || [];

  return { stages, activeStage };
};

export default useDropdownStages;
