import { Controller, UseFormReturn } from 'react-hook-form';
import { AccountStrategyProfileSchema } from '../../../../api/schemas/accountStrategyProfileSchema';
import Input from '../../../../components/Input';
import Label from '../../../../components/Label';
import Select from '../../../../components/Select';
import i18n from '../../../../i18n';
import { convertStringToNumber } from '../../../../utils/data';
import { max, required } from '../../../../utils/form';
import {
  PLAYER_TYPE_LABELS,
  playerTypesOptions,
  booleanOptions,
  getBooleanOption,
  SEATING_PREFERENCE_LABELS,
  seatingPreferencesOptions,
} from '../../../account/helpers';
import styles from './styles.module.scss';
import { Fields } from './types';

export const getDefaultValues = (data?: AccountStrategyProfileSchema) => ({
  player_type: data
    ? { value: data.player_type, label: PLAYER_TYPE_LABELS[data.player_type] }
    : null,
  buyin_bb_min: data ? String(data.buyin_bb_min) : '',
  buyin_bb_max: data ? String(data.buyin_bb_max) : '',
  buyin_step_bb: data ? String(data.buyin_step_bb) : '',
  buyin_bb_straddle_table_min: data ? String(data.buyin_bb_straddle_table_min) : '',
  buyin_bb_straddle_table_max: data ? String(data.buyin_bb_straddle_table_max) : '',
  buyin_step_bb_straddle_table: data ? String(data.buyin_step_bb_straddle_table) : '',
  rebuy_below_bb: data ? String(data.rebuy_below_bb) : '',
  rebuy_below_bb_straddle_table: data ? String(data.rebuy_below_bb_straddle_table) : '',
  stop_lose_bb: data ? String(data.stop_lose_bb) : '',
  stop_win_bb: data ? String(data.stop_win_bb) : '',
  stop_lose_table_bb: data ? String(data.stop_lose_table_bb) : '',
  stop_win_table_bb: data ? String(data.stop_win_table_bb) : '',
  max_swing_bb: data ? String(data.max_swing_bb) : '',
  effective_stack_limit_bb: data ? String(data.effective_stack_limit_bb) : '',
  max_stack_size_bb: data ? String(data.max_stack_size_bb) : '',
  table_min_hands: data ? String(data.table_min_hands) : '',
  wait_for_big_blind: data ? getBooleanOption(data.wait_for_big_blind) : null,
  seating_preference: data
    ? { value: data.seating_preference, label: SEATING_PREFERENCE_LABELS[data.seating_preference] }
    : null,
});

// eslint-disable-next-line no-use-before-define
export const labels: Record<keyof ReturnType<typeof getFields>, string> = {
  player_type: i18n.t('common.player_type'),
  buyin_bb_min: i18n.t('common.buyin_range_bb'),
  buyin_step_bb: i18n.t('common.buyin_step_bb'),
  buyin_bb_straddle_table_min: i18n.t('common.straddle_tables_buyin_range_bb'),
  buyin_step_bb_straddle_table: i18n.t('common.straddle_tables_buyin_step_bb'),
  rebuy_below_bb: i18n.t('common.rebuy_threshold_bb'),
  rebuy_below_bb_straddle_table: i18n.t('common.straddle_tables_rebuy_threshold_bb'),
  stop_lose_bb: i18n.t('common.stop_loss_bb'),
  stop_win_bb: i18n.t('common.stop_win_bb'),
  stop_lose_table_bb: i18n.t('common.stop_loss_by_table_bb'),
  stop_win_table_bb: i18n.t('common.stop_win_by_table_bb'),
  max_swing_bb: i18n.t('common.max_swing_bb'),
  effective_stack_limit_bb: i18n.t('common.effective_stack_limit_bb'),
  max_stack_size_bb: i18n.t('common.max_stack_size_bb'),
  table_min_hands: i18n.t('common.table_min_hands'),
  wait_for_big_blind: i18n.t('common.wait_for_big_blind'),
  seating_preference: i18n.t('common.seating_preference'),
};

export const getFields = (form: UseFormReturn<Fields>) => {
  const {
    control,
    formState: { errors },
  } = form;

  const MAX = 100_000;

  return {
    player_type: (
      <Controller
        control={control}
        rules={{ validate: { required } }}
        name="player_type"
        render={({ field }) => (
          <Select
            clearable={false}
            label={{
              text: `${labels.player_type}*`,
              hint: String(i18n.t('sentences.strategy_profile_player_type')),
            }}
            options={playerTypesOptions}
            value={field.value}
            onChange={field.onChange}
            error={errors.player_type?.message}
          />
        )}
      />
    ),
    buyin_bb_min: (
      <div>
        <Label hint={String(i18n.t('sentences.strategy_profile_buyin_range_bb'))}>
          {`${labels.buyin_bb_min}*`}
        </Label>
        <div className={styles.controllersGroup}>
          <Controller
            control={control}
            rules={{
              validate: {
                required,
                max: max(labels.buyin_bb_min, MAX),
              },
            }}
            name="buyin_bb_min"
            render={({ field }) => (
              <Input
                placeholder={i18n.t('common.min')}
                error={errors.buyin_bb_min?.message}
                format={{ onValueChange: field.onChange }}
                {...field}
              />
            )}
          />
          <span>—</span>
          <Controller
            control={control}
            rules={{
              validate: {
                required,
                max: max(labels.buyin_bb_min, MAX),
              },
            }}
            name="buyin_bb_max"
            render={({ field }) => (
              <Input
                placeholder={i18n.t('common.max')}
                error={errors.buyin_bb_max?.message}
                format={{ onValueChange: field.onChange }}
                {...field}
              />
            )}
          />
        </div>
      </div>
    ),
    buyin_step_bb: (
      <Controller
        control={control}
        rules={{ validate: { required, max: max(labels.buyin_step_bb, 100) } }}
        name="buyin_step_bb"
        render={({ field }) => (
          <Input
            label={{
              text: `${labels.buyin_step_bb}*`,
              hint: String(i18n.t('sentences.strategy_profile_buyin_step_bb')),
            }}
            error={errors.buyin_step_bb?.message}
            format={{ onValueChange: field.onChange }}
            {...field}
          />
        )}
      />
    ),
    buyin_bb_straddle_table_min: (
      <div>
        <Label hint={String(i18n.t('sentences.strategy_profile_straddle_tables_buyin_range_bb'))}>
          {`${labels.buyin_bb_straddle_table_min}*`}
        </Label>
        <div className={styles.controllersGroup}>
          <Controller
            control={control}
            rules={{
              validate: {
                required,
                max: max(labels.buyin_bb_straddle_table_min, MAX),
              },
            }}
            name="buyin_bb_straddle_table_min"
            render={({ field }) => (
              <Input
                placeholder={i18n.t('common.min')}
                error={errors.buyin_bb_straddle_table_min?.message}
                format={{ onValueChange: field.onChange }}
                {...field}
              />
            )}
          />
          <span>—</span>
          <Controller
            control={control}
            rules={{
              validate: {
                required,
                max: max(labels.buyin_bb_straddle_table_min, MAX),
              },
            }}
            name="buyin_bb_straddle_table_max"
            render={({ field }) => (
              <Input
                placeholder={i18n.t('common.max')}
                error={errors.buyin_bb_straddle_table_max?.message}
                format={{ onValueChange: field.onChange }}
                {...field}
              />
            )}
          />
        </div>
      </div>
    ),
    buyin_step_bb_straddle_table: (
      <Controller
        control={control}
        rules={{ validate: { required, max: max(labels.buyin_step_bb_straddle_table, 100) } }}
        name="buyin_step_bb_straddle_table"
        render={({ field }) => (
          <Input
            label={{
              text: `${labels.buyin_step_bb_straddle_table}*`,
              hint: String(i18n.t('sentences.strategy_profile_straddle_tables_buyin_step_bb')),
            }}
            error={errors.buyin_step_bb_straddle_table?.message}
            format={{ onValueChange: field.onChange }}
            {...field}
          />
        )}
      />
    ),
    rebuy_below_bb: (
      <Controller
        control={control}
        rules={{
          validate: {
            required,
            max: max(labels.rebuy_below_bb, MAX),
          },
        }}
        name="rebuy_below_bb"
        render={({ field }) => (
          <Input
            label={{
              text: `${labels.rebuy_below_bb}*`,
              hint: String(i18n.t('sentences.strategy_profile_rebuy_threshold_bb')),
            }}
            error={errors.rebuy_below_bb?.message}
            format={{ onValueChange: field.onChange }}
            {...field}
          />
        )}
      />
    ),
    rebuy_below_bb_straddle_table: (
      <Controller
        control={control}
        rules={{
          validate: {
            required,
            max: max(labels.rebuy_below_bb_straddle_table, MAX),
          },
        }}
        name="rebuy_below_bb_straddle_table"
        render={({ field }) => (
          <Input
            label={{
              text: `${labels.rebuy_below_bb_straddle_table}*`,
              hint: String(i18n.t('sentences.strategy_profile_straddle_tables_rebuy_threshold_bb')),
            }}
            error={errors.rebuy_below_bb_straddle_table?.message}
            format={{ onValueChange: field.onChange }}
            {...field}
          />
        )}
      />
    ),
    stop_lose_bb: (
      <Controller
        control={control}
        rules={{
          validate: {
            required,
            max: max(labels.stop_lose_bb, MAX),
          },
        }}
        name="stop_lose_bb"
        render={({ field }) => (
          <Input
            label={{
              text: `${labels.stop_lose_bb}*`,
              hint: String(i18n.t('sentences.strategy_profile_stop_lose_bb')),
            }}
            error={errors.stop_lose_bb?.message}
            format={{ onValueChange: field.onChange }}
            {...field}
          />
        )}
      />
    ),
    stop_win_bb: (
      <Controller
        control={control}
        rules={{
          validate: {
            required,
            max: max(labels.stop_lose_table_bb, MAX),
          },
        }}
        name="stop_win_bb"
        render={({ field }) => (
          <Input
            label={{
              text: `${labels.stop_win_bb}*`,
              hint: String(i18n.t('sentences.strategy_profile_stop_win_bb')),
            }}
            error={errors.stop_win_bb?.message}
            format={{ onValueChange: field.onChange }}
            {...field}
          />
        )}
      />
    ),
    stop_lose_table_bb: (
      <Controller
        control={control}
        rules={{
          validate: {
            required,
            max: max(labels.stop_lose_table_bb, MAX),
          },
        }}
        name="stop_lose_table_bb"
        render={({ field }) => (
          <Input
            label={{
              text: `${labels.stop_lose_table_bb}*`,
              hint: String(i18n.t('sentences.strategy_profile_stop_lose_table_bb')),
            }}
            error={errors.stop_lose_table_bb?.message}
            format={{ onValueChange: field.onChange }}
            {...field}
          />
        )}
      />
    ),
    stop_win_table_bb: (
      <Controller
        control={control}
        rules={{
          validate: {
            required,
            max: max(labels.stop_win_table_bb, MAX),
          },
        }}
        name="stop_win_table_bb"
        render={({ field }) => (
          <Input
            label={{
              text: `${labels.stop_win_table_bb}*`,
              hint: String(i18n.t('sentences.strategy_profile_stop_win_table_bb')),
            }}
            error={errors.stop_win_table_bb?.message}
            format={{ onValueChange: field.onChange }}
            {...field}
          />
        )}
      />
    ),
    max_swing_bb: (
      <Controller
        control={control}
        rules={{
          validate: {
            required,
            max: max(labels.max_swing_bb, MAX),
          },
        }}
        name="max_swing_bb"
        render={({ field }) => (
          <Input
            label={{
              text: `${labels.max_swing_bb}*`,
              hint: String(i18n.t('sentences.strategy_profile_max_swing_bb')),
            }}
            error={errors.max_swing_bb?.message}
            format={{ onValueChange: field.onChange }}
            {...field}
          />
        )}
      />
    ),
    effective_stack_limit_bb: (
      <Controller
        control={control}
        rules={{
          validate: {
            required,
            max: max(labels.effective_stack_limit_bb, MAX),
          },
        }}
        name="effective_stack_limit_bb"
        render={({ field }) => (
          <Input
            label={{
              text: `${labels.effective_stack_limit_bb}*`,
              hint: String(i18n.t('sentences.strategy_profile_effective_stack_limit_bb')),
            }}
            error={errors.effective_stack_limit_bb?.message}
            format={{ onValueChange: field.onChange }}
            {...field}
          />
        )}
      />
    ),
    max_stack_size_bb: (
      <Controller
        control={control}
        rules={{
          validate: {
            required,
            max: max(labels.max_stack_size_bb, MAX),
          },
        }}
        name="max_stack_size_bb"
        render={({ field }) => (
          <Input
            label={{
              text: `${labels.max_stack_size_bb}*`,
              hint: String(i18n.t('sentences.strategy_profile_max_stack_size_bb')),
            }}
            error={errors.max_stack_size_bb?.message}
            format={{ onValueChange: field.onChange }}
            {...field}
          />
        )}
      />
    ),
    table_min_hands: (
      <Controller
        control={control}
        rules={{
          validate: {
            required,
            max: max(labels.table_min_hands, MAX),
          },
        }}
        name="table_min_hands"
        render={({ field }) => (
          <Input
            label={{
              text: `${labels.table_min_hands}*`,
              hint: String(i18n.t('sentences.strategy_profile_table_min_hands')),
            }}
            error={errors.table_min_hands?.message}
            format={{ onValueChange: field.onChange }}
            {...field}
          />
        )}
      />
    ),
    wait_for_big_blind: (
      <Controller
        name="wait_for_big_blind"
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Select
            clearable={false}
            label={{
              text: `${labels.wait_for_big_blind}*`,
              hint: String(i18n.t('sentences.strategy_profile_wait_for_big_blind')),
            }}
            options={booleanOptions}
            value={field.value}
            onChange={field.onChange}
            error={errors.wait_for_big_blind?.message}
          />
        )}
      />
    ),
    seating_preference: (
      <Controller
        control={control}
        rules={{ validate: { required } }}
        name="seating_preference"
        render={({ field }) => (
          <Select
            clearable={false}
            label={{
              text: `${labels.seating_preference}*`,
              hint: String(i18n.t('sentences.strategy_profile_seating_preference')),
            }}
            options={seatingPreferencesOptions}
            value={field.value}
            onChange={field.onChange}
            error={errors.seating_preference?.message}
          />
        )}
      />
    ),
  };
};

export const getPayload = (args: { values: Fields }) => {
  const { values } = args;

  return {
    player_type: values.player_type?.value,
    buyin_bb_min: convertStringToNumber(values.buyin_bb_min),
    buyin_bb_max: convertStringToNumber(values.buyin_bb_max),
    buyin_step_bb: convertStringToNumber(values.buyin_step_bb),
    buyin_bb_straddle_table_min: convertStringToNumber(values.buyin_bb_straddle_table_min),
    buyin_bb_straddle_table_max: convertStringToNumber(values.buyin_bb_straddle_table_max),
    buyin_step_bb_straddle_table: convertStringToNumber(values.buyin_step_bb_straddle_table),
    rebuy_below_bb: convertStringToNumber(values.rebuy_below_bb),
    rebuy_below_bb_straddle_table: convertStringToNumber(values.rebuy_below_bb_straddle_table),
    stop_lose_bb: convertStringToNumber(values.stop_lose_bb),
    stop_win_bb: convertStringToNumber(values.stop_win_bb),
    stop_lose_table_bb: convertStringToNumber(values.stop_lose_table_bb),
    stop_win_table_bb: convertStringToNumber(values.stop_win_table_bb),
    max_swing_bb: convertStringToNumber(values.max_swing_bb),
    effective_stack_limit_bb: convertStringToNumber(values.effective_stack_limit_bb),
    max_stack_size_bb: convertStringToNumber(values.max_stack_size_bb),
    table_min_hands: convertStringToNumber(values.table_min_hands),
    wait_for_big_blind: Boolean(values.wait_for_big_blind?.value),
    seating_preference: values.seating_preference?.value,
  };
};
