import { ReactNode } from 'react';
import PageAddonField from '../PageAddonField';
import styles from './styles.module.scss';

interface Props {
  label: ReactNode;
  children: ReactNode;
}

const PageAddonToggler = (props: Props) => {
  const { label, children } = props;

  return (
    <PageAddonField label={label}>
      <div className={styles.container}>{children}</div>
    </PageAddonField>
  );
};

export default PageAddonToggler;
