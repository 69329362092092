import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { dealFilterValuesSchema } from '../schemas/deal/dealSchema';

const loadGlobalDealsFilterValues = async (params?: URLParams) => {
  const response = await api.get('/api/deal/filter-values', { params });

  return dealFilterValuesSchema.parse(response.data);
};

export default loadGlobalDealsFilterValues;
