import { z } from 'zod';
import dateTimeSchema from './dateTimeSchema';
import { stageCommonSchema } from './stage';
import trainerPokerRoomSchema from './trainerPokerRoomSchema';
import trainerTechSchema from './trainerTechSchema';

export const WORKSPACE_TYPES = {
  POKER_FARM: 1,
  POKER_AFFILIATE: 2,
  CHANNEL_PROVIDER: 3,
  IDENTITY_PROVIDER: 4,
  CLUB_MANAGER: 5,
} as const;

export const BUSINESS_MODEL = {
  UNKNOWN: 0,
  BOT: 1,
  ECO: 2,
} as const;

const ownerUsernameSchema = z.string();

const workspaceSchema = z.object({
  id: z.string(),
  index: z.string(),
  name: z.string(),
  owner_id: z.string(),
  owner_username: ownerUsernameSchema.or(z.null()),
  start_new_table: z.boolean(),
  allow_negative_balance_pilot: z.boolean(),
  maintenance_types: z.array(z.nativeEnum(WORKSPACE_TYPES)),
  business_model: z.nativeEnum(BUSINESS_MODEL),
  bar_seq: z.string().or(z.null()),
  poker_rooms: z.array(trainerPokerRoomSchema),
  trainer_tech: z.array(trainerTechSchema),
  auto_create_mining_session: z.boolean(),
  mining_stages: z.array(stageCommonSchema),
  mining_session_duration: z.number(),
  mining_session_interval: z.number(),
  is_deleted: z.boolean(),
  created_on: dateTimeSchema,
  modified_on: dateTimeSchema,
});

export const workspaceFilterValuesSchema = z.object({
  business_model: z.array(workspaceSchema.shape.business_model),
  maintenance_types: workspaceSchema.shape.maintenance_types,
  trainer_tech: workspaceSchema.shape.trainer_tech,
  poker_rooms: workspaceSchema.shape.poker_rooms,
  owner: z.array(
    z.object({
      id: workspaceSchema.shape.owner_id,
      name: ownerUsernameSchema,
    })
  ),
});

export type WorkspaceSchema = z.infer<typeof workspaceSchema>;
export type WorkspaceTypes = z.infer<typeof workspaceSchema.shape.maintenance_types>;
export type WorkspaceType = WorkspaceTypes[number];

export default workspaceSchema;
