import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Global from '../Global';
import Layout from '../Layout';
import error404Page from './error404Page';
import globalAccountsPage from './globalAccountsPage';
import globalAgentsPage from './globalAgentsPage';
import globalBotSettingsPage from './globalBotSettingsPage';
import globalBotSettingsRulesPage from './globalBotSettingsRulesPage';
import globalClubsPage from './globalClubsPage';
import globalDealsPage from './globalDealsPage';
import globalLeaguesPage from './globalLeaguesPage';
import globalLobbyPage from './globalLobbyPage';
import globalOrdersPage from './globalOrdersPage';
import globalRolesPage from './globalRolesPage';
import globalSeatConrolRulesPage from './globalSeatConrolRulesPage';
import globalSessionsPage from './globalSessionsPage';
import globalSuperLeaguesPage from './globalSuperLeaguesPage';
import globalUsersPage from './globalUsersPage';
import globalWorkspacesPage from './globalWorkspacesPage';
import homePage from './homePage';
import invitePage from './invitePage';
import loginPage from './loginPage';
import recoveryPage from './recoveryPage';
import resetPage from './resetPage';
import storybookPage from './storybookPage';
import workspaceAccountsPage from './workspaceAccountsPage';
import workspaceChannelsPage from './workspaceChannelsPage';
import workspaceClubManagersPage from './workspaceClubManagersPage';
import workspaceClubMembersPage from './workspaceClubMembersPage';
import workspaceClubTransactionsPage from './workspaceClubTransactionsPage';
import workspaceClubWhitelistPage from './workspaceClubWhitelistPage';
import workspaceIdentitiesPage from './workspaceIdentitiesPage';
import workspaceMarketIdentityPage from './workspaceMarketIdentityPage';
import workspaceOrdersPage from './workspaceOrdersPage';
import workspaceSeatConrolRulesPage from './workspaceSeatConrolRulesPage';
import workspaceSessionsPage from './workspaceSessionsPage';
import workspaceSettingsPage from './workspaceSettingsPage';
import workspaceUsersPage from './workspaceUsersPage';

const router = createBrowserRouter([
  {
    element: <Global />,
    children: [
      /* registrationPage, */
      invitePage,
      loginPage,
      recoveryPage,
      resetPage,
      storybookPage,
      {
        element: <Layout />,
        children: [
          homePage,
          workspaceAccountsPage,
          workspaceSessionsPage,
          workspaceChannelsPage,
          workspaceClubManagersPage,
          workspaceClubMembersPage,
          workspaceClubWhitelistPage,
          workspaceClubTransactionsPage,
          workspaceUsersPage,
          workspaceIdentitiesPage,
          workspaceMarketIdentityPage,
          workspaceOrdersPage,
          workspaceSeatConrolRulesPage,
          workspaceSettingsPage,
          globalLobbyPage,
          globalAccountsPage,
          globalSessionsPage,
          globalWorkspacesPage,
          globalOrdersPage,
          globalDealsPage,
          globalAgentsPage,
          globalUsersPage,
          globalRolesPage,
          globalSeatConrolRulesPage,
          globalBotSettingsPage,
          globalBotSettingsRulesPage,
          globalClubsPage,
          globalLeaguesPage,
          globalSuperLeaguesPage,
          error404Page,
        ],
      },
    ],
  },
]);

const Router = () => <RouterProvider router={router} />;

export default Router;
