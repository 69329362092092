import { useTranslation } from 'react-i18next';
import PageAddon from '../../components/PageAddon';
import ShowButton from '../../components/ShowButton';
import { copy } from '../../utils/clipboard';
import { getGeolocationAsString } from './helpers';
import { CommonAccountSchema } from './types';

interface Props {
  data: CommonAccountSchema;
}

const AccountGeolocation = (props: Props) => {
  const { data } = props;

  const { t } = useTranslation();

  const { geo } = data;

  if (!geo) return <>—</>;

  const handleClick = () => copy(getGeolocationAsString(data.geo), t('common.geolocation'));

  return (
    <ShowButton>
      <PageAddon.Copy onClick={handleClick}>
        {geo.lat},
        <br />
        {geo.lng}
      </PageAddon.Copy>
    </ShowButton>
  );
};

export default AccountGeolocation;
