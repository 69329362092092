import { DealSchema } from '../../api/schemas/deal/dealSchema';
import PrettyNumber from '../../components/PrettyNumber';
import { convertCentsToDollars } from '../../utils/data';

interface Props {
  data: DealSchema;
}

const GlobalDealBalance = (props: Props) => {
  const { data } = props;

  const { balance } = data;

  return <PrettyNumber>{convertCentsToDollars(balance)}</PrettyNumber>;
};

export default GlobalDealBalance;
