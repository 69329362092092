import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import icon from '../../static/icons';
import { useUserStore } from '../../store/user';
import { logoutUser } from '../../utils/auth';
import Dropdown from '../Dropdown';
import styles from './styles.module.scss';

const User = () => {
  const { t } = useTranslation();

  const user = useUserStore();

  return (
    <Dropdown
      addon={
        <div className={styles.addon}>
          <span>{user.data?.username}</span>
        </div>
      }
      options={[
        {
          icon: icon('logOut', 20),
          label: t('common.log_out'),
          danger: true,
          onClick: logoutUser,
        },
      ]}
    >
      {({ open }) => (
        <button type="button" className={clsx(styles.avatar, open && styles.open)}>
          {icon('user', 16)}
        </button>
      )}
    </Dropdown>
  );
};

export default User;
