import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { loadWorkspaceCounters } from '../../../api';
import { CountersSchema } from '../../../api/schemas/countersSchema';
import queryKeys from '../../../config/queryKeys';
import routes from '../../../config/routes';
import useWebSocketMessage from '../../../hooks/useWebSocketMessage';
import icon from '../../../static/icons';
import { useUserStore } from '../../../store/user';
import { updateDetailQueryData } from '../../../utils/queryClient';
import {
  checkIsAccountsVisible,
  checkIsClubManagersVisible,
  checkIsIdentitiesVisible,
  checkIsMarketVisible,
  checkIsOrdersVisible,
  checkWorkspaceHasChannelsAccess,
} from '../../../utils/workspace';
import SidebarItem from '../SidebarItem';
import SidebarSeparator from '../SidebarSeparator';
import SidebarWorkspacesSelector from '../SidebarWorkspacesSelector';

const SidebarWorkspaces = () => {
  const { t } = useTranslation();

  const { workspace } = useUserStore();

  const workspaceId = workspace?.id;

  const countersQueryKey = [queryKeys.workspaceCounters({ workspaceId: String(workspaceId) })];
  const countersQueryFn = () => loadWorkspaceCounters({ workspaceId: String(workspaceId) });

  const { data } = useQuery({
    queryKey: countersQueryKey,
    queryFn: countersQueryFn,
    enabled: Boolean(workspaceId),
    meta: { ignoredStatuses: [403] },
  });

  useWebSocketMessage<CountersSchema>({
    domain: ['Account', 'Channel', 'Identity', 'MaintenanceUserCount', 'Manager'],
    onMessage: (message) => {
      if (
        message.action === 'AccountsCounterChanged' ||
        message.action === 'ChannelsCounterChanged' ||
        message.action === 'IdentitiesCounterChanged' ||
        message.action === 'UsersCounterChanged' ||
        message.action === 'ManagersCounterChanged'
      ) {
        if (message.meta_info?.maintenance_id === workspaceId) {
          updateDetailQueryData(countersQueryKey, message.payload);
        }
      }
    },
  });

  return (
    <>
      {workspaceId && <SidebarSeparator isWorkspaces />}
      <SidebarWorkspacesSelector />
      {workspaceId && (
        <ul>
          <SidebarItem to={routes.home} icon={icon('pieChart', 24)}>
            {t('common.dashboard')}
          </SidebarItem>
          {checkIsAccountsVisible(workspaceId) && (
            <SidebarItem
              to={routes.workspaceAccounts({ workspaceId })}
              icon={icon('spadeMap', 24)}
              count={data?.accounts_count}
            >
              {t('common.accounts')}
            </SidebarItem>
          )}
          {checkWorkspaceHasChannelsAccess(workspaceId) && (
            <SidebarItem
              to={routes.workspaceChannels({ workspaceId })}
              icon={icon('device', 24)}
              count={data?.channels_count}
            >
              {t('common.channels')}
            </SidebarItem>
          )}
          {checkIsClubManagersVisible(workspaceId) && (
            <SidebarItem
              to={routes.workspaceClubManagers({ workspaceId })}
              icon={icon('pokerChip', 24)}
              count={data?.managers_count}
            >
              {t('common.club_manager')}
            </SidebarItem>
          )}
          <SidebarItem
            to={routes.workspaceUsers({ workspaceId })}
            icon={icon('user', 24)}
            count={data?.users_count}
          >
            {t('common.members')}
          </SidebarItem>
          {checkIsIdentitiesVisible(workspaceId) && (
            <SidebarItem
              to={routes.workspaceIdentities({ workspaceId })}
              icon={icon('fingerprint', 24)}
              count={data?.identities_count}
            >
              {t('common.identities')}
            </SidebarItem>
          )}
          {checkIsMarketVisible(workspaceId) && (
            <SidebarItem
              to={routes.workspaceMarketIdentities({ workspaceId })}
              icon={icon('shoppingCart', 24)}
            >
              {t('common.market')}
            </SidebarItem>
          )}
          {checkIsOrdersVisible(workspaceId) && (
            <SidebarItem to={routes.workspaceOrders({ workspaceId })} icon={icon('document', 24)}>
              {t('common.orders')}
            </SidebarItem>
          )}
          {/* {checkIsSeatControlRulesVisible(workspaceId) && (
            <SidebarItem
              to={routes.workspaceSeatConrolRules({ workspaceId })}
              icon={icon('compass', 24)}
            >
              {t('common.seat_control_rules')}
            </SidebarItem>
          )} */}
          <SidebarItem to={routes.workspaceSettings({ workspaceId })} icon={icon('settings', 24)}>
            {t('common.settings')}
          </SidebarItem>
        </ul>
      )}
    </>
  );
};

export default SidebarWorkspaces;
