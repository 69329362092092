import { useTranslation } from 'react-i18next';
import { SeatControlRuleSchema } from '../../../api/schemas/seatControlRuleSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Button from '../../../components/Button';
import PageAddon from '../../../components/PageAddon';
import QueryBuilderPreview from '../../../components/QueryBuilder/QueryBuilderPreview';
import Tooltip from '../../../components/Tooltip';
import icon from '../../../static/icons';
import { formatAbsoluteDate } from '../../../utils/date';
import { ACTION_TYPE_COLOR, ACTION_TYPE_LABEL } from '../../seat-control-rule/helpers';
import WorkspaceSeatControlRuleDetailDropdown from '../WorkspaceSeatControlRuleDetailDropdown';
import styles from './styles.module.scss';

interface Props {
  data: SeatControlRuleSchema;
  workspaceId: WorkspaceSchema['id'];
  onClose: () => void;
  onEdit: () => void;
}

const WorkspaceSeatControlRuleDetail = (props: Props) => {
  const { data, workspaceId, onClose, onEdit } = props;

  const { t } = useTranslation();

  const {
    id,
    name,
    maintenance,
    action_type,
    query,
    is_active,
    is_deleted,
    created_on,
    modified_on,
  } = data;

  const titleIcons = is_deleted && icon('trash', 12);

  return (
    <PageAddon title={name} icons={titleIcons} onClose={onClose}>
      <PageAddon.Fields>
        <PageAddon.Id id={id} />
        <PageAddon.Field label={t('common.action')}>
          <span className={ACTION_TYPE_COLOR[action_type]}>{ACTION_TYPE_LABEL[action_type]}</span>
        </PageAddon.Field>
        <PageAddon.Field label={t('common.status')}>
          {is_active ? t('common.active') : t('common.inactive')}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.workspace')}>{maintenance.name}</PageAddon.Field>
        <PageAddon.Field label={t('common.modified')}>
          {formatAbsoluteDate(modified_on)}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.created')}>
          {formatAbsoluteDate(created_on)}
        </PageAddon.Field>
      </PageAddon.Fields>
      <PageAddon.Heading level="second" className={styles.heading}>
        {t('common.rule')}
      </PageAddon.Heading>
      <QueryBuilderPreview query={query} />
      {!is_deleted && (
        <PageAddon.Controls>
          <PageAddon.Controls.Group>
            <Tooltip label={t('common.edit')} hideOnMobile>
              <div>
                <Button
                  variant="transparent"
                  icon={icon('edit', 16)}
                  onClick={onEdit}
                  data-testid="edit"
                />
              </div>
            </Tooltip>
            <WorkspaceSeatControlRuleDetailDropdown data={data} workspaceId={workspaceId} />
          </PageAddon.Controls.Group>
        </PageAddon.Controls>
      )}
    </PageAddon>
  );
};

export default WorkspaceSeatControlRuleDetail;
