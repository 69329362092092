import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import WorkspaceAccountAutoCreateForm from '../../features/workspace-account/WorkspaceAccountAutoCreateForm';
import WorkspaceAccountBulkActionsDetail from '../../features/workspace-account/WorkspaceAccountBulkActionsDetail';
import WorkspaceAccountDetail from '../../features/workspace-account/WorkspaceAccountDetail';
import WorkspaceAccountBulkForm from '../../features/workspace-account/WorkspaceAccountForm/WorkspaceAccountBulkForm';
import WorkspaceAccountDetailForm from '../../features/workspace-account/WorkspaceAccountForm/WorkspaceAccountDetailForm';
import WorkspaceAccountImport from '../../features/workspace-account/WorkspaceAccountImport';
import WorkspaceAccountLoader from '../../features/workspace-account/WorkspaceAccountLoader';
import WorkspaceAccountNotes from '../../features/workspace-account/WorkspaceAccountNotes';
import WorkspaceAccountRoomConfigBulkForm from '../../features/workspace-account/WorkspaceAccountRoomConfig/WorkspaceAccountRoomConfigBulkForm';
import WorkspaceAccountRoomConfigForm from '../../features/workspace-account/WorkspaceAccountRoomConfig/WorkspaceAccountRoomConfigForm';
import WorkspaceAccountRoomConfigLoader from '../../features/workspace-account/WorkspaceAccountRoomConfig/WorkspaceAccountRoomConfigLoader';
import WorkspaceAccountSchedule from '../../features/workspace-account/WorkspaceAccountSchedule';
import WorkspaceAccountStrategyProfileForm from '../../features/workspace-account/WorkspaceAccountStrategyProfile/WorkspaceAccountStrategyProfileForm';
import WorkspaceAccountStrategyProfileBulkForm from '../../features/workspace-account/WorkspaceAccountStrategyProfile/WorkspaceAccountStrategyProfileForm/WorkspaceAccountStrategyProfileBulkForm';
import WorkspaceAccountStrategyProfileLoader from '../../features/workspace-account/WorkspaceAccountStrategyProfile/WorkspaceAccountStrategyProfileLoader';
import WorkspaceAccountTransactions from '../../features/workspace-account/WorkspaceAccountTransactions';
import WorkspaceIdentityDetail from '../../features/workspace-identity/WorkspaceIdentityDetail';
import WorkspaceIdentityForm from '../../features/workspace-identity/WorkspaceIdentityForm';
import WorkspaceIdentityLoader from '../../features/workspace-identity/WorkspaceIdentityLoader';
import WorkspaceUserDetail from '../../features/workspace-user/WorkspaceUserDetail';
import WorkspaceUserLoader from '../../features/workspace-user/WorkspaceUserLoader';
import WorkspaceAccountsPage from '../../pages/WorkspaceAccountsPage';
import Notes from '../Notes';
import Route from './Route';

const page = (
  <Route params={['workspaceId']}>
    {({ workspaceId }) => <WorkspaceAccountsPage workspaceId={workspaceId} />}
  </Route>
);

const createForm = (
  <Route params={['workspaceId']}>
    {({ workspaceId, navigate }) => (
      <WorkspaceAccountDetailForm
        workspaceId={workspaceId}
        onClose={() => navigate(routes.workspaceAccounts({ workspaceId }))}
        onCreate={({ accountId }) => navigate(routes.workspaceAccount({ workspaceId, accountId }))}
      />
    )}
  </Route>
);

const autoCreateForm = (
  <Route params={['workspaceId']}>
    {({ workspaceId, navigate }) => (
      <WorkspaceAccountAutoCreateForm
        workspaceId={workspaceId}
        onClose={() => navigate(routes.workspaceAccounts({ workspaceId }))}
      />
    )}
  </Route>
);

const importForm = (
  <Route params={['workspaceId']}>
    {({ workspaceId, navigate }) => (
      <WorkspaceAccountImport
        workspaceId={workspaceId}
        onClose={() => navigate(routes.workspaceAccounts({ workspaceId }))}
      />
    )}
  </Route>
);

const detail = (
  <Route params={['workspaceId', 'accountId']}>
    {({ workspaceId, accountId, navigate }) => (
      <WorkspaceAccountLoader workspaceId={workspaceId} accountId={accountId}>
        {({ data }) => (
          <WorkspaceAccountDetail
            data={data}
            workspaceId={workspaceId}
            onClose={() => navigate(routes.workspaceAccounts({ workspaceId }))}
            onEdit={() => navigate(routes.workspaceAccountEdit({ workspaceId, accountId }))}
            onNotesOpen={() => navigate(routes.workspaceAccountNotes({ workspaceId, accountId }))}
            onTransactionsOpen={({ type, id }) =>
              navigate(routes.workspaceAccountTransactions({ workspaceId, accountId, type, id }))
            }
            onStrategyProfileEdit={() =>
              navigate(routes.workspaceAccountStrategyProfileEdit({ workspaceId, accountId }))
            }
            onRoomConfig={() =>
              navigate(routes.workspaceAccountRoomConfig({ workspaceId, accountId }))
            }
            getUserRoute={({ userId }) =>
              routes.workspaceAccountUser({ workspaceId, accountId, userId })
            }
            getIdentityRoute={({ identityId }) =>
              routes.workspaceAccountIdentity({ workspaceId, accountId, identityId })
            }
          />
        )}
      </WorkspaceAccountLoader>
    )}
  </Route>
);

const detailNotes = (
  <Route params={['workspaceId', 'accountId']}>
    {({ workspaceId, accountId, navigate }) => (
      <WorkspaceAccountNotes workspaceId={workspaceId} accountId={accountId}>
        {(loader) => (
          <Notes
            onClose={() => navigate(routes.workspaceAccount({ workspaceId, accountId }))}
            {...loader}
          />
        )}
      </WorkspaceAccountNotes>
    )}
  </Route>
);

const detailTransactions = (
  <Route params={['workspaceId', 'accountId']}>
    {({ workspaceId, accountId, navigate }) => (
      <WorkspaceAccountTransactions
        workspaceId={workspaceId}
        accountId={accountId}
        onClose={() => navigate(routes.workspaceAccount({ workspaceId, accountId }))}
      />
    )}
  </Route>
);

const detailStrategyProfileEditForm = (
  <Route params={['workspaceId', 'accountId']}>
    {({ workspaceId, accountId, navigate }) => (
      <WorkspaceAccountLoader workspaceId={workspaceId} accountId={accountId}>
        {({ data: account }) => (
          <WorkspaceAccountStrategyProfileLoader workspaceId={workspaceId} accountId={accountId}>
            {({ data }) => (
              <WorkspaceAccountStrategyProfileForm
                data={data}
                account={account}
                workspaceId={workspaceId}
                onClose={() => navigate(routes.workspaceAccount({ workspaceId, accountId }))}
              />
            )}
          </WorkspaceAccountStrategyProfileLoader>
        )}
      </WorkspaceAccountLoader>
    )}
  </Route>
);

const detailRoomConfig = (
  <Route params={['workspaceId', 'accountId']}>
    {({ workspaceId, accountId, navigate }) => (
      <WorkspaceAccountLoader workspaceId={workspaceId} accountId={accountId}>
        {({ data: account }) => (
          <WorkspaceAccountRoomConfigLoader workspaceId={workspaceId} accountId={accountId}>
            {({ data }) => (
              <WorkspaceAccountRoomConfigForm
                workspaceId={workspaceId}
                data={data}
                account={account}
                onClose={() => navigate(routes.workspaceAccount({ workspaceId, accountId }))}
              />
            )}
          </WorkspaceAccountRoomConfigLoader>
        )}
      </WorkspaceAccountLoader>
    )}
  </Route>
);

const detailIdentity = (
  <Route params={['workspaceId', 'accountId', 'identityId']}>
    {({ workspaceId, accountId, identityId, navigate }) => (
      <WorkspaceIdentityLoader workspaceId={workspaceId} identityId={identityId}>
        {({ data }) => (
          <WorkspaceIdentityDetail
            data={data}
            workspaceId={workspaceId}
            onClose={() => navigate(routes.workspaceAccount({ workspaceId, accountId }))}
            onEdit={() =>
              navigate(routes.workspaceAccountIdentityEdit({ workspaceId, accountId, identityId }))
            }
          />
        )}
      </WorkspaceIdentityLoader>
    )}
  </Route>
);

const detailIdentityEditForm = (
  <Route params={['workspaceId', 'accountId', 'identityId']}>
    {({ workspaceId, accountId, identityId, navigate }) => (
      <WorkspaceIdentityLoader workspaceId={workspaceId} identityId={identityId}>
        {({ data }) => (
          <WorkspaceIdentityForm
            data={data}
            workspaceId={workspaceId}
            onClose={() =>
              navigate(routes.workspaceAccountIdentity({ workspaceId, accountId, identityId }))
            }
            onEdit={() =>
              navigate(routes.workspaceAccountIdentity({ workspaceId, accountId, identityId }))
            }
          />
        )}
      </WorkspaceIdentityLoader>
    )}
  </Route>
);

const schedule = (
  <Route params={['workspaceId', 'accountId']}>
    {({ workspaceId, accountId }) => (
      <WorkspaceAccountSchedule workspaceId={workspaceId} accountId={accountId} />
    )}
  </Route>
);

const detailUser = (
  <Route params={['workspaceId', 'accountId', 'userId']}>
    {({ workspaceId, accountId, userId, navigate }) => (
      <WorkspaceUserLoader workspaceId={workspaceId} userId={userId}>
        {({ data }) => (
          <WorkspaceUserDetail
            data={data}
            workspaceId={workspaceId}
            onClose={() => navigate(routes.workspaceAccount({ workspaceId, accountId }))}
          />
        )}
      </WorkspaceUserLoader>
    )}
  </Route>
);

const editForm = (
  <Route params={['workspaceId', 'accountId']}>
    {({ workspaceId, accountId, navigate }) => (
      <WorkspaceAccountLoader workspaceId={workspaceId} accountId={accountId}>
        {({ data }) => (
          <WorkspaceAccountDetailForm
            data={data}
            workspaceId={workspaceId}
            onClose={() => navigate(routes.workspaceAccount({ workspaceId, accountId }))}
            onEdit={() => navigate(routes.workspaceAccount({ workspaceId, accountId }))}
          />
        )}
      </WorkspaceAccountLoader>
    )}
  </Route>
);

const bulkEditForm = (
  <Route params={['workspaceId']}>
    {({ workspaceId }) => <WorkspaceAccountBulkForm workspaceId={workspaceId} />}
  </Route>
);

const bulkStrategyProfileEditForm = (
  <Route params={['workspaceId']}>
    {({ workspaceId }) => <WorkspaceAccountStrategyProfileBulkForm workspaceId={workspaceId} />}
  </Route>
);

const bulkRoomConfig = (
  <Route params={['workspaceId']}>
    {({ workspaceId }) => <WorkspaceAccountRoomConfigBulkForm workspaceId={workspaceId} />}
  </Route>
);

const bulkSuccess = (
  <Route params={['workspaceId']}>
    {({ workspaceId }) => <WorkspaceAccountBulkActionsDetail workspaceId={workspaceId} />}
  </Route>
);

const bulkError = (
  <Route params={['workspaceId']}>
    {({ workspaceId }) => <WorkspaceAccountBulkActionsDetail workspaceId={workspaceId} />}
  </Route>
);

const bulkErrorAccount = (
  <Route params={['workspaceId', 'accountId']}>
    {({ workspaceId, accountId, navigate }) => (
      <WorkspaceAccountLoader workspaceId={workspaceId} accountId={accountId}>
        {({ data }) => (
          <WorkspaceAccountDetail
            data={data}
            workspaceId={workspaceId}
            onClose={() => navigate(routes.workspaceAccountsBulkError({ workspaceId }))}
            onEdit={() =>
              navigate(routes.workspaceAccountsBulkErrorAccountEdit({ workspaceId, accountId }))
            }
            onStrategyProfileEdit={() =>
              navigate(
                routes.workspaceAccountsBulkErrorAccountStrategyProfileEdit({
                  workspaceId,
                  accountId,
                })
              )
            }
            onRoomConfig={() =>
              navigate(
                routes.workspaceAccountsBulkErrorAccountRoomConfig({
                  workspaceId,
                  accountId,
                })
              )
            }
          />
        )}
      </WorkspaceAccountLoader>
    )}
  </Route>
);

const bulkErrorAccountEditForm = (
  <Route params={['workspaceId', 'accountId']}>
    {({ workspaceId, accountId, navigate }) => (
      <WorkspaceAccountLoader workspaceId={workspaceId} accountId={accountId}>
        {({ data }) => (
          <WorkspaceAccountDetailForm
            data={data}
            workspaceId={workspaceId}
            onClose={() =>
              navigate(routes.workspaceAccountsBulkErrorAccount({ workspaceId, accountId }))
            }
            onEdit={() =>
              navigate(routes.workspaceAccountsBulkErrorAccount({ workspaceId, accountId }))
            }
          />
        )}
      </WorkspaceAccountLoader>
    )}
  </Route>
);

const bulkErrorAccountStrategyProfileEditForm = (
  <Route params={['workspaceId', 'accountId']}>
    {({ workspaceId, accountId, navigate }) => (
      <WorkspaceAccountLoader workspaceId={workspaceId} accountId={accountId}>
        {({ data: account }) => (
          <WorkspaceAccountStrategyProfileLoader workspaceId={workspaceId} accountId={accountId}>
            {({ data }) => (
              <WorkspaceAccountStrategyProfileForm
                data={data}
                account={account}
                workspaceId={workspaceId}
                onClose={() =>
                  navigate(routes.workspaceAccountsBulkErrorAccount({ workspaceId, accountId }))
                }
              />
            )}
          </WorkspaceAccountStrategyProfileLoader>
        )}
      </WorkspaceAccountLoader>
    )}
  </Route>
);

const bulkErrorAccountRoomConfig = (
  <Route params={['workspaceId', 'accountId']}>
    {({ workspaceId, accountId, navigate }) => (
      <WorkspaceAccountLoader workspaceId={workspaceId} accountId={accountId}>
        {({ data: account }) => (
          <WorkspaceAccountRoomConfigLoader workspaceId={workspaceId} accountId={accountId}>
            {({ data }) => (
              <WorkspaceAccountRoomConfigForm
                workspaceId={workspaceId}
                data={data}
                account={account}
                onClose={() =>
                  navigate(routes.workspaceAccountsBulkErrorAccount({ workspaceId, accountId }))
                }
              />
            )}
          </WorkspaceAccountRoomConfigLoader>
        )}
      </WorkspaceAccountLoader>
    )}
  </Route>
);

const workspaceAccountsPage: RouteObject = {
  path: routes.workspaceAccounts({ workspaceId: ':workspaceId' }),
  element: page,
  children: [
    {
      path: routes.workspaceAccountsCreate({ workspaceId: ':workspaceId' }),
      element: createForm,
    },
    {
      path: routes.workspaceAccountsAutoCreate({ workspaceId: ':workspaceId' }),
      element: autoCreateForm,
    },
    {
      path: routes.workspaceAccountsImport({ workspaceId: ':workspaceId' }),
      element: importForm,
    },
    {
      path: routes.workspaceAccount({
        workspaceId: ':workspaceId',
        accountId: ':accountId',
      }),
      element: detail,
      children: [
        {
          path: routes.workspaceAccountNotes({
            workspaceId: ':workspaceId',
            accountId: ':accountId',
          }),
          element: detailNotes,
        },
        {
          path: routes.workspaceAccountTransactions({
            workspaceId: ':workspaceId',
            accountId: ':accountId',
            type: ':type',
            id: ':id?',
          }),
          element: detailTransactions,
        },
        {
          path: routes.workspaceAccountUser({
            workspaceId: ':workspaceId',
            accountId: ':accountId',
            userId: ':userId',
          }),
          element: detailUser,
        },
        {
          path: routes.workspaceAccountIdentity({
            workspaceId: ':workspaceId',
            accountId: ':accountId',
            identityId: ':identityId',
          }),
          element: detailIdentity,
        },
        {
          path: routes.workspaceAccountIdentityEdit({
            workspaceId: ':workspaceId',
            accountId: ':accountId',
            identityId: ':identityId',
          }),
          element: detailIdentityEditForm,
        },
        {
          path: routes.workspaceAccountSchedule({
            workspaceId: ':workspaceId',
            accountId: ':accountId',
          }),
          element: schedule,
        },
      ],
    },
    {
      path: routes.workspaceAccountEdit({ workspaceId: ':workspaceId', accountId: ':accountId' }),
      element: editForm,
    },
    {
      path: routes.workspaceAccountStrategyProfileEdit({
        workspaceId: ':workspaceId',
        accountId: ':accountId',
      }),
      element: detailStrategyProfileEditForm,
    },
    {
      path: routes.workspaceAccountRoomConfig({
        workspaceId: ':workspaceId',
        accountId: ':accountId',
      }),
      element: detailRoomConfig,
    },
    {
      path: routes.workspaceAccountsBulkEdit({ workspaceId: ':workspaceId' }),
      element: bulkEditForm,
    },
    {
      path: routes.workspaceAccountsBulkStrategyProfileEdit({ workspaceId: ':workspaceId' }),
      element: bulkStrategyProfileEditForm,
    },
    {
      path: routes.workspaceAccountsBulkRoomConfig({ workspaceId: ':workspaceId' }),
      element: bulkRoomConfig,
    },
    {
      path: routes.workspaceAccountsBulkSuccess({ workspaceId: ':workspaceId' }),
      element: bulkSuccess,
    },
    {
      path: routes.workspaceAccountsBulkError({ workspaceId: ':workspaceId' }),
      element: bulkError,
      children: [
        {
          path: routes.workspaceAccountsBulkErrorAccount({
            workspaceId: ':workspaceId',
            accountId: ':accountId',
          }),
          element: bulkErrorAccount,
        },
        {
          path: routes.workspaceAccountsBulkErrorAccountEdit({
            workspaceId: ':workspaceId',
            accountId: ':accountId',
          }),
          element: bulkErrorAccountEditForm,
        },
        {
          path: routes.workspaceAccountsBulkErrorAccountStrategyProfileEdit({
            workspaceId: ':workspaceId',
            accountId: ':accountId',
          }),
          element: bulkErrorAccountStrategyProfileEditForm,
        },
        {
          path: routes.workspaceAccountsBulkErrorAccountRoomConfig({
            workspaceId: ':workspaceId',
            accountId: ':accountId',
          }),
          element: bulkErrorAccountRoomConfig,
        },
      ],
    },
  ],
};

export default workspaceAccountsPage;
