import { useInfiniteQuery } from '@tanstack/react-query';
import { ReactElement, useId, useState } from 'react';
import { Options, Props as SelectProps } from './types';

interface Props {
  onLoad: (args: { page?: string; search?: string }) => Promise<Options>;
  children: (args: {
    options: Options;
    filterOptions: Exclude<SelectProps['filterOptions'], undefined>;
    loading: Exclude<SelectProps['loading'], undefined>;
    onSearch: Exclude<SelectProps['onSearch'], undefined>;
    onOpen: Exclude<SelectProps['onOpen'], undefined>;
    onLastOptionInView?: Exclude<SelectProps['onLastOptionInView'], undefined>;
  }) => ReactElement;
  paginated?: boolean;
}

const SelectAsync = (props: Props) => {
  const { onLoad, children, paginated = true } = props;

  const id = useId();
  const [search, setSearch] = useState('');
  const [enabled, setEnabled] = useState(false);

  const queryKey = [id, search, enabled];

  const { data, isPending, fetchNextPage } = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam }) =>
      onLoad({ ...(pageParam && { page: String(pageParam) }), ...(search && { search }) }),
    enabled,
    initialPageParam: 0,
    getNextPageParam: (_, pages) => pages.length + 1,
  });

  const options = data?.pages.flat() || [];

  return children({
    options,
    filterOptions: false,
    loading: isPending,
    onOpen: setEnabled,
    onSearch: setSearch,
    ...(paginated && { onLastOptionInView: fetchNextPage }),
  });
};

export default SelectAsync;
