import { WorkspaceSchema, WORKSPACE_TYPES } from '../api/schemas/workspaceSchema';
import { useUserStore } from '../store/user';

export type Checker = (id: WorkspaceSchema['id']) => boolean;

export const checkWorkspaceType = (
  args: { workspaceId: WorkspaceSchema['id'] },
  workspaceTypes: Array<keyof typeof WORKSPACE_TYPES>
) => {
  const { workspaceId } = args;

  const { workspaces } = useUserStore.getState();

  if (workspaces && workspaces[workspaceId]) {
    const { types } = workspaces[workspaceId];

    const workspaceTypesEnums = workspaceTypes.map((item) => WORKSPACE_TYPES[item]);

    if (types.some((item) => workspaceTypesEnums.includes(item))) return true;
  }

  return false;
};

export const checkWorkspaceHasChannelsAccess: Checker = (id) =>
  checkWorkspaceType({ workspaceId: id }, ['CHANNEL_PROVIDER', 'POKER_FARM', 'CLUB_MANAGER']);

export const checkWorkspaceIdentityPokerFarm: Checker = (id) =>
  checkWorkspaceType({ workspaceId: id }, ['POKER_FARM']) &&
  !checkWorkspaceType({ workspaceId: id }, ['IDENTITY_PROVIDER']);

export const checkIsAccountsVisible: Checker = (id) =>
  checkWorkspaceType({ workspaceId: id }, ['POKER_FARM']);

export const checkIsClubManagersVisible: Checker = (id) =>
  checkWorkspaceType({ workspaceId: id }, ['CLUB_MANAGER']);

export const checkIsIdentitiesVisible: Checker = (id) =>
  checkWorkspaceType({ workspaceId: id }, ['IDENTITY_PROVIDER', 'POKER_FARM']);

export const checkIsMarketVisible: Checker = (id) =>
  checkWorkspaceType({ workspaceId: id }, ['IDENTITY_PROVIDER']);

export const checkIsOrdersVisible: Checker = (id) =>
  checkWorkspaceType({ workspaceId: id }, ['IDENTITY_PROVIDER', 'POKER_FARM']);

export const checkIsSeatControlRulesVisible: Checker = (id) =>
  checkWorkspaceType({ workspaceId: id }, ['POKER_FARM']);
