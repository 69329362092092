import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import GlobalAccountDetail from '../../features/global-account/GlobalAccountDetail';
import GlobalAccountForm from '../../features/global-account/GlobalAccountForm';
import GlobalAccountLoader from '../../features/global-account/GlobalAccountLoader';
import GlobalAccountRoomConfigForm from '../../features/global-account/GlobalAccountRoomConfig/GlobalAccountRoomConfigForm';
import GlobalAccountRoomConfigLoader from '../../features/global-account/GlobalAccountRoomConfig/GlobalAccountRoomConfigLoader';
import GlobalSessionDetail from '../../features/global-session/GlobalSessionDetail';
import GlobalSessionForm from '../../features/global-session/GlobalSessionForm';
import GlobalSessionLoader from '../../features/global-session/GlobalSessionLoader';
import GlobalSessionsPage from '../../pages/GlobalSessionsPage';
import Route from './Route';

const page = <GlobalSessionsPage />;

const detail = (
  <Route params={['accountId', 'sessionId']}>
    {({ accountId, sessionId, navigate }) => (
      <GlobalSessionLoader accountId={accountId} sessionId={sessionId}>
        {({ data }) => (
          <GlobalSessionDetail
            data={data}
            onClose={() => navigate(routes.sessions)}
            onEdit={() => navigate(routes.sessionEdit({ accountId, sessionId }))}
          />
        )}
      </GlobalSessionLoader>
    )}
  </Route>
);

const detailAccount = (
  <Route params={['accountId', 'sessionId']}>
    {({ accountId, sessionId, navigate }) => (
      <GlobalAccountLoader accountId={accountId}>
        {({ data }) => (
          <GlobalAccountDetail
            data={data}
            onClose={() => navigate(routes.session({ accountId, sessionId }))}
            onEdit={() => navigate(routes.sessionAccountEdit({ accountId, sessionId }))}
            onRoomConfig={() => navigate(routes.sessionAccountRoomConfig({ accountId, sessionId }))}
          />
        )}
      </GlobalAccountLoader>
    )}
  </Route>
);

const detailAccountRoomConfig = (
  <Route params={['sessionId', 'accountId']}>
    {({ sessionId, accountId, navigate }) => (
      <GlobalAccountLoader accountId={accountId}>
        {({ data: account }) => (
          <GlobalAccountRoomConfigLoader accountId={accountId}>
            {({ data }) => (
              <GlobalAccountRoomConfigForm
                data={data}
                account={account}
                onClose={() => navigate(routes.sessionAccount({ sessionId, accountId }))}
              />
            )}
          </GlobalAccountRoomConfigLoader>
        )}
      </GlobalAccountLoader>
    )}
  </Route>
);

const detailAccountEdit = (
  <Route params={['accountId', 'sessionId']}>
    {({ accountId, sessionId, navigate }) => (
      <GlobalAccountLoader accountId={accountId}>
        {({ data }) => (
          <GlobalAccountForm
            data={data}
            onClose={() => navigate(routes.sessionAccount({ accountId, sessionId }))}
          />
        )}
      </GlobalAccountLoader>
    )}
  </Route>
);

const editForm = (
  <Route params={['accountId', 'sessionId']}>
    {({ accountId, sessionId, navigate }) => (
      <GlobalSessionLoader accountId={accountId} sessionId={sessionId}>
        {({ data }) => (
          <GlobalSessionForm
            data={data}
            onClose={() => navigate(routes.session({ accountId, sessionId }))}
          />
        )}
      </GlobalSessionLoader>
    )}
  </Route>
);

const globalSessionsPage: RouteObject = {
  path: routes.sessions,
  element: page,
  children: [
    {
      path: routes.session({ accountId: ':accountId', sessionId: ':sessionId' }),
      element: detail,
      children: [
        {
          path: routes.sessionAccount({
            accountId: ':accountId',
            sessionId: ':sessionId',
          }),
          element: detailAccount,
        },
        {
          path: routes.sessionAccountRoomConfig({
            accountId: ':accountId',
            sessionId: ':sessionId',
          }),
          element: detailAccountRoomConfig,
        },
        {
          path: routes.sessionAccountEdit({
            accountId: ':accountId',
            sessionId: ':sessionId',
          }),
          element: detailAccountEdit,
        },
      ],
    },
    {
      path: routes.sessionEdit({ accountId: ':accountId', sessionId: ':sessionId' }),
      element: editForm,
    },
  ],
};

export default globalSessionsPage;
