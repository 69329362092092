import { CREDIT, PAYMENT_SYSTEM, PROTECTION, ROLE, STATUS } from '../../api/schemas/deal';
import { DealSchema } from '../../api/schemas/deal/dealSchema';
import Tag from '../../components/Tag';
import i18n from '../../i18n';

export const ROLE_LABELS: Record<DealSchema['role'], string> = {
  [ROLE.UNKNOWN]: i18n.t('common.unknown'),
  [ROLE.PLAYER]: i18n.t('common.player'),
  [ROLE.AGENT]: i18n.t('common.agent'),
  [ROLE.CLUBOWNER]: i18n.t('common.club_owner'),
};

export const STATUS_LABELS: Record<DealSchema['status'], string> = {
  [STATUS.UNKNOWN]: i18n.t('common.unknown'),
  [STATUS.REVIEW]: i18n.t('common.review'),
  [STATUS.AVAILABLE]: i18n.t('common.available'),
  [STATUS.ACTIVE]: i18n.t('common.active'),
  [STATUS.PAUSED]: i18n.t('common.paused'),
  [STATUS.DISCUSSION]: i18n.t('common.discussion'),
  [STATUS.STOP]: i18n.t('common.stop'),
  [STATUS.NOT_RELEVANT]: i18n.t('common.not_relevant'),
};

export const CREDIT_LABELS: Record<DealSchema['credit'], string> = {
  [CREDIT.UNKNOWN]: i18n.t('common.unknown'),
  [CREDIT.FULL]: i18n.t('common.full'),
  [CREDIT.LIMITED]: i18n.t('common.limited'),
  [CREDIT.FIFTY_FIFTY]: i18n.t('common.fifty_fifty'),
  [CREDIT.NO_CREDIT]: i18n.t('common.no_credit'),
};

export const PROTECTION_LABELS: Record<DealSchema['protection'], string> = {
  [PROTECTION.UNKNOWN]: i18n.t('common.unknown'),
  [PROTECTION.PROTECTION]: i18n.t('common.protection'),
  [PROTECTION.PENALTY]: i18n.t('common.penalty'),
  [PROTECTION.NO_PENALTY]: i18n.t('common.no_penalty'),
  [PROTECTION.FIFTY_FIFTY]: i18n.t('common.fifty_fifty'),
};

export const PAYMENT_SYSTEM_LABELS: Record<DealSchema['payment_system'][number], string> = {
  [PAYMENT_SYSTEM.UNKNOWN]: i18n.t('common.unknown'),
  [PAYMENT_SYSTEM.USDT]: i18n.t('common.usdt'),
  [PAYMENT_SYSTEM.BTC]: i18n.t('common.btc'),
  [PAYMENT_SYSTEM.BANK_CARD_RU]: i18n.t('common.bank_card_ru'),
  [PAYMENT_SYSTEM.VENMO]: i18n.t('common.venmo'),
  [PAYMENT_SYSTEM.ZELLE]: i18n.t('common.zelle'),
  [PAYMENT_SYSTEM.CASH_APP]: i18n.t('common.cash_app'),
  [PAYMENT_SYSTEM.PAYPAL]: i18n.t('common.paypal'),
  [PAYMENT_SYSTEM.APPLE_PAY]: i18n.t('common.apple_pay'),
};

export const roleOptions = Object.values(ROLE)
  .filter((item) => item !== STATUS.UNKNOWN)
  .map((item) => ({
    value: item,
    label: ROLE_LABELS[item],
  }));

export const statusOptions = Object.values(STATUS)
  .filter((item) => item !== STATUS.UNKNOWN)
  .map((item) => ({
    value: item,
    label: STATUS_LABELS[item],
  }));

export const creditOptions = Object.values(CREDIT)
  .filter((item) => item !== STATUS.UNKNOWN)
  .map((item) => ({
    value: item,
    label: CREDIT_LABELS[item],
  }));

export const protectionOptions = Object.values(PROTECTION)
  .filter((item) => item !== STATUS.UNKNOWN)
  .map((item) => ({
    value: item,
    label: PROTECTION_LABELS[item],
  }));

export const paymentSystemOptions = Object.values(PAYMENT_SYSTEM)
  .filter((item) => item !== STATUS.UNKNOWN)
  .map((item) => ({
    value: item,
    label: PAYMENT_SYSTEM_LABELS[item],
  }));

export const renderPaymentSystems = (data: DealSchema) => {
  const { payment_system } = data;

  if (!payment_system.length) return '—';

  return payment_system.map((item) => <Tag key={item}>{PAYMENT_SYSTEM_LABELS[item]}</Tag>);
};

export const renderWorkspaces = (data: DealSchema) => {
  const { workspaces } = data;

  if (!workspaces.length) return '—';

  return workspaces.map((item) => <Tag key={item.id}>{item.name}</Tag>);
};
