import { useTranslation } from 'react-i18next';
import { WorkspaceAccountSchema } from '../../../api/schemas/account/workspaceAccountSchema';
import { FileSchema } from '../../../api/schemas/fileSchema';
import { IdentitySchema } from '../../../api/schemas/identitySchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import FilesList from '../../../components/FilesList';
import Link from '../../../components/Link';
import PageAddon from '../../../components/PageAddon';
import ShowButton from '../../../components/ShowButton';
import Tag from '../../../components/Tag';
import icon from '../../../static/icons';
import { copyLogin, copyPassword, copyPhone } from '../../../utils/clipboard';
import { formatAbsoluteDate, formatDateWithoutTime } from '../../../utils/date';
import { getFileURL } from '../../../utils/files';
import { TRAINER_POKER_ROOM_LABELS } from '../../../utils/trainer';
import { checkWorkspaceIdentityPokerFarm } from '../../../utils/workspace';
import {
  WALLETS_LABELS,
  BANK_ACCOUNTS_LABELS,
  DOCUMENTS_LABELS,
  styleIcon,
  PHOTOS_LABELS,
  DOCUMENTS_ICONS_16,
  WALLETS_ICONS_16,
  SOCIAL_NETWORKS_ICONS_16,
  SOCIAL_NETWORKS_LABELS,
  getGender,
} from '../helpers';
import WorkspaceIdentityPageAddonFieldLabelChannel from '../WorkspaceIdentityPageAddonFieldChannel';
import styles from './styles.module.scss';

interface Props {
  data: IdentitySchema;
  workspaceId: WorkspaceSchema['id'];
  getAccountRoute?: (args: { accountId: WorkspaceAccountSchema['id'] }) => string;
}

const WorkspaceIdentityDetailTabGeneral = (props: Props) => {
  const { data, workspaceId, getAccountRoute } = props;

  const { t } = useTranslation();

  const {
    id,
    date_of_birth,
    gender,
    location,
    channel,
    rooms,
    phones,
    online_wallets,
    bank_accounts,
    documents,
    photos,
    social_networks,
    accounts,
    modified_on,
    created_on,
  } = data;

  const isPokerFarm = checkWorkspaceIdentityPokerFarm(workspaceId);

  const getPokerRooms = () => {
    if (!rooms.length) return '—';

    return rooms.map((item) => <Tag key={item}>{TRAINER_POKER_ROOM_LABELS[item]}</Tag>);
  };

  const getFiles = (files: FileSchema[]) =>
    files.map((item) => ({
      name: item.original_name,
      src: getFileURL(item, 'identity'),
    }));

  const isCollapsesExist = Boolean(
    online_wallets.length ||
      bank_accounts.length ||
      documents.length ||
      photos.length ||
      social_networks.length
  );

  const getAccounts = () => {
    if (!accounts.length) return '—';

    if (getAccountRoute) {
      return accounts.map((item) => (
        <Link key={item.id} to={getAccountRoute({ accountId: item.id })}>
          <Tag>{item.login}</Tag>
        </Link>
      ));
    }

    return accounts.map((item) => <Tag key={item.id}>{item.login}</Tag>);
  };

  return (
    <PageAddon.Fields>
      <PageAddon.Id id={id} />
      <PageAddon.Field label={t('common.date_of_birth')}>
        {formatAbsoluteDate(date_of_birth)}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.gender')}>{getGender(gender)}</PageAddon.Field>
      <PageAddon.Field label={t('common.country')}>{location.country_iso}</PageAddon.Field>
      <PageAddon.Field label={t('common.city')}>{location.city}</PageAddon.Field>
      <PageAddon.Field label={t('common.accounts')}>
        <PageAddon.Tags>{getAccounts()}</PageAddon.Tags>
      </PageAddon.Field>
      {!isPokerFarm && <WorkspaceIdentityPageAddonFieldLabelChannel channel={channel} />}
      {!isPokerFarm && (
        <PageAddon.Field label={t('sentences.present_in_poker_rooms')}>
          <PageAddon.Tags>{getPokerRooms()}</PageAddon.Tags>
        </PageAddon.Field>
      )}
      {phones.map((item, index) => (
        <PageAddon.Field key={item} label={`${t('common.phone')} ${index + 1}`}>
          <PageAddon.Copy onClick={() => copyPhone(item)}>{item}</PageAddon.Copy>
        </PageAddon.Field>
      ))}
      {isCollapsesExist && (
        <div className={styles.collapseGroups}>
          {Boolean(online_wallets.length) && (
            <PageAddon.CollapseGroup label={t('common.online_wallets')}>
              {online_wallets.map((item) => {
                const { password } = item;

                return (
                  <PageAddon.Collapse
                    key={`${item.type}-${item.login}-${item.password}`}
                    icon={WALLETS_ICONS_16[item.type]}
                    label={WALLETS_LABELS[item.type]}
                  >
                    <PageAddon.Collapse.Field label={t('common.login')}>
                      <PageAddon.Collapse.Field.Copy onClick={() => copyLogin(item.login)}>
                        {item.login}
                      </PageAddon.Collapse.Field.Copy>
                    </PageAddon.Collapse.Field>
                    {password && (
                      <PageAddon.Collapse.Field label={t('common.password')}>
                        <ShowButton>
                          <PageAddon.Collapse.Field.Copy onClick={() => copyPassword(password)}>
                            {password}
                          </PageAddon.Collapse.Field.Copy>
                        </ShowButton>
                      </PageAddon.Collapse.Field>
                    )}
                  </PageAddon.Collapse>
                );
              })}
            </PageAddon.CollapseGroup>
          )}
          {Boolean(bank_accounts.length) && (
            <PageAddon.CollapseGroup label={t('common.bank_accounts')}>
              {bank_accounts.map((item) => (
                <PageAddon.Collapse
                  key={`${item.type}-${item.name}`}
                  icon={icon('creditCard', 16, { className: styles.icon })}
                  label={item.name}
                >
                  <PageAddon.Collapse.Field label={t('common.type')}>
                    {BANK_ACCOUNTS_LABELS[item.type]}
                  </PageAddon.Collapse.Field>
                  <PageAddon.Collapse.Field label={t('common.banking_digits')}>
                    {item.banking_digits}
                  </PageAddon.Collapse.Field>
                  <PageAddon.Collapse.Field label={t('common.full_name')}>
                    {item.fullname}
                  </PageAddon.Collapse.Field>
                </PageAddon.Collapse>
              ))}
            </PageAddon.CollapseGroup>
          )}
          {Boolean(documents.length) && (
            <PageAddon.CollapseGroup label={t('common.documents')}>
              {documents.map((item) => (
                <PageAddon.Collapse
                  key={item.files[0].id}
                  icon={item.type ? styleIcon(DOCUMENTS_ICONS_16[item.type]) : null}
                  label={DOCUMENTS_LABELS[item.type]}
                >
                  <PageAddon.Collapse.Field label={t('common.date_of_issue')}>
                    {formatDateWithoutTime(item.issued)}
                  </PageAddon.Collapse.Field>
                  {item.expire && (
                    <PageAddon.Collapse.Field label={t('common.date_of_expiry')}>
                      {formatDateWithoutTime(item.expire)}
                    </PageAddon.Collapse.Field>
                  )}
                  {item.files && (
                    <>
                      <PageAddon.Media files={getFiles(item.files)} />
                      <FilesList files={getFiles(item.files)} />
                    </>
                  )}
                </PageAddon.Collapse>
              ))}
            </PageAddon.CollapseGroup>
          )}
          {Boolean(photos.length) && (
            <PageAddon.CollapseGroup label={t('common.photos')}>
              {photos.map((item) => (
                <PageAddon.Collapse
                  key={item.files[0].id}
                  icon={styleIcon(icon('imageWithMan', 16))}
                  label={PHOTOS_LABELS[item.type]}
                >
                  {item.files && <PageAddon.Media files={getFiles(item.files)} />}
                </PageAddon.Collapse>
              ))}
            </PageAddon.CollapseGroup>
          )}
          {Boolean(social_networks.length) && (
            <PageAddon.CollapseGroup label={t('common.social_networks')}>
              {social_networks.map((item) => {
                const href = item.link.includes('https') ? item.link : `https://${item.link}`;

                return (
                  <PageAddon.Collapse
                    key={`${item.type}-${item.link}`}
                    icon={SOCIAL_NETWORKS_ICONS_16[item.type]}
                    label={SOCIAL_NETWORKS_LABELS[item.type]}
                  >
                    <PageAddon.Collapse.Field label={t('common.url')}>
                      <a href={href} target="_blank" rel="noreferrer">
                        {item.link}
                      </a>
                    </PageAddon.Collapse.Field>
                    {item.login && (
                      <PageAddon.Collapse.Field label={t('common.login')}>
                        <PageAddon.Collapse.Field.Copy onClick={() => copyLogin(item.login)}>
                          {item.login}
                        </PageAddon.Collapse.Field.Copy>
                      </PageAddon.Collapse.Field>
                    )}
                    {item.password && (
                      <PageAddon.Collapse.Field label={t('common.password')}>
                        <ShowButton>
                          <PageAddon.Collapse.Field.Copy
                            onClick={() => copyPassword(item.password)}
                          >
                            {item.password}
                          </PageAddon.Collapse.Field.Copy>
                        </ShowButton>
                      </PageAddon.Collapse.Field>
                    )}
                  </PageAddon.Collapse>
                );
              })}
            </PageAddon.CollapseGroup>
          )}
        </div>
      )}
      <PageAddon.Field label={t('common.modified')}>
        {formatAbsoluteDate(modified_on)}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.created')}>
        {formatAbsoluteDate(created_on)}
      </PageAddon.Field>
    </PageAddon.Fields>
  );
};

export default WorkspaceIdentityDetailTabGeneral;
