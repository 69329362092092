import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  createGlobalWorkspace,
  loadGlobalClubs,
  loadGlobalUsers,
  loadGlobalWorkspaceStages,
  updateGlobalWorkspace,
} from '../../../api';
import { GlobalClubSchema } from '../../../api/schemas/globalClubSchema';
import {
  BUSINESS_MODEL,
  WorkspaceSchema,
  WorkspaceType,
} from '../../../api/schemas/workspaceSchema';
import Checkbox from '../../../components/Checkbox';
import Form from '../../../components/Form';
import Input from '../../../components/Input';
import PageAddon from '../../../components/PageAddon';
import Select, { Option, Options } from '../../../components/Select';
import SubmitButton from '../../../components/SubmitButton';
import { secondsToHours, hoursToSeconds } from '../../../utils/data';
import { maxLength, minLength, required, uuid } from '../../../utils/form';
import notify from '../../../utils/notify';
import {
  TRAINER_POKER_ROOM_LABELS,
  TRAINER_TECH_LABELS,
  trainerPokerRoomOptions,
  trainerTechOptions,
} from '../../../utils/trainer';
import { time } from '../../../utils/validations';
import { can } from '../../permission/helpers';
import { WORKSPACE_TYPES_LABELS, businessModelOptions, workspaceTypesOptions } from '../helpers';
import styles from './styles.module.scss';

interface Fields {
  name: string;
  user_id: Option;
  business_model: Option<WorkspaceSchema['business_model']>;
  maintenance_types: Options<WorkspaceType>;
  poker_rooms: Options<WorkspaceSchema['poker_rooms'][number]>;
  club_ids: Options;
  trainer_tech: Options<WorkspaceSchema['trainer_tech'][number]>;
  bar_seq: string;
  start_new_table: boolean;
  allow_negative_balance_pilot: boolean;
  auto_create_mining_session: boolean;
  mining_stage_ids: Options<WorkspaceSchema['mining_stages'][number]['id']>;
  mining_session_duration: string;
  mining_session_interval: string;
}

interface CommonProps {
  onClose: () => void;
}

interface CreateProps {
  onCreate: (args: { workspaceId: WorkspaceSchema['id'] }) => void;
  data?: never;
  clubsData?: never;
  onEdit?: never;
}

interface EditProps {
  data: WorkspaceSchema;
  clubsData: GlobalClubSchema[];
  onEdit: () => void;
  onCreate?: never;
}

type Props = CommonProps & (CreateProps | EditProps);

const GlobalWorkspaceForm = (props: Props) => {
  const { data, clubsData, onClose, onCreate, onEdit } = props;

  const { t } = useTranslation();

  const form = useForm<Fields>({
    defaultValues: {
      name: data?.name || '',
      user_id: null,
      business_model: businessModelOptions.find(
        (item) => item.value === (data ? data.business_model : BUSINESS_MODEL.BOT)
      ),
      maintenance_types: data
        ? data.maintenance_types.map((item) => ({
            value: item,
            label: WORKSPACE_TYPES_LABELS[item],
          }))
        : [],
      poker_rooms: data
        ? data.poker_rooms.map((item) => ({
            value: item,
            label: TRAINER_POKER_ROOM_LABELS[item],
          }))
        : [],
      ...(can.club.read && {
        club_ids: clubsData
          ? clubsData.map((item) => ({
              value: item.id,
              label: item.name,
            }))
          : [],
      }),
      trainer_tech: data
        ? data.trainer_tech.map((item) => ({
            value: item,
            label: TRAINER_TECH_LABELS[item],
          }))
        : [],
      bar_seq: data?.bar_seq || '',
      start_new_table: data?.start_new_table || false,
      allow_negative_balance_pilot: data?.allow_negative_balance_pilot || true,
      auto_create_mining_session: data?.auto_create_mining_session || false,
      mining_stage_ids: data
        ? data.mining_stages.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        : [],
      mining_session_duration: data ? secondsToHours(data.mining_session_duration) : '',
      mining_session_interval: data ? secondsToHours(data.mining_session_interval) : '',
    },
  });

  const {
    control,
    watch,
    register,
    formState: { errors },
  } = form;

  const autoMining = watch('auto_create_mining_session');

  const onSubmit = async (values: Fields) => {
    if (!values.business_model) throw new Error();

    const payload = {
      business_model: values.business_model.value,
      maintenance_types: values.maintenance_types.map((item) => item.value),
      poker_rooms: values.poker_rooms.map((item) => item.value),
      ...(can.club.read && {
        club_ids: values.club_ids.map((item) => item.value),
      }),
      trainer_tech: values.trainer_tech.map((item) => item.value),
      bar_seq: values.bar_seq || null,
      start_new_table: values.start_new_table,
      allow_negative_balance_pilot: values.allow_negative_balance_pilot,
    };

    const autoMiningPayload = {
      mining_stage_ids: values.mining_stage_ids.map((item) => item.value),
      mining_session_duration: hoursToSeconds(values.mining_session_duration),
      mining_session_interval: hoursToSeconds(values.mining_session_interval),
    };

    if (data) {
      await updateGlobalWorkspace({
        workspaceId: data.id,
        payload: {
          auto_create_mining_session: values.auto_create_mining_session,
          ...(autoMining && autoMiningPayload),
          ...payload,
        },
      });

      onEdit();
    } else {
      if (!values.user_id) throw new Error();

      const response = await createGlobalWorkspace({
        payload: {
          name: values.name,
          user_id: values.user_id.value,
          ...payload,
        },
      });

      onCreate({ workspaceId: response.id });
    }

    notify('success', {
      title: data ? t('sentences.changes_have_been_saved') : t('sentences.record_has_been_created'),
    });
  };

  return (
    <PageAddon
      onClose={onClose}
      title={data ? `${t('common.edit')} ${data.name}` : t('common.create_workspace')}
    >
      <Form form={form} onSubmit={onSubmit}>
        {!data && (
          <Controller
            name="user_id"
            control={control}
            rules={{ validate: { required } }}
            render={({ field }) => (
              <Select.Async
                onLoad={async (args) => {
                  const response = await loadGlobalUsers(args);

                  const options = response.items.map((item) => ({
                    value: item.id,
                    label: item.username,
                  }));

                  return options;
                }}
              >
                {({ options, ...rest }) => (
                  <Select
                    clearable={false}
                    label={`${t('common.owner')}*`}
                    options={options}
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.user_id?.message}
                    {...rest}
                  />
                )}
              </Select.Async>
            )}
          />
        )}
        {!data && (
          <Input
            label={`${t('common.name')}*`}
            error={errors.name?.message}
            {...register('name', {
              validate: {
                required,
                minLength: minLength(),
                maxLength: maxLength(),
              },
            })}
          />
        )}
        <Input
          label={t('common.bar_sequence')}
          error={errors.bar_seq?.message}
          {...register('bar_seq', { validate: { uuid } })}
        />
        <Controller
          name="business_model"
          control={control}
          rules={{ validate: { required } }}
          render={({ field }) => (
            <Select
              clearable={false}
              options={businessModelOptions}
              label={t('common.business_model')}
              value={field.value}
              onChange={field.onChange}
              error={errors.business_model?.message}
            />
          )}
        />
        <Controller
          name="maintenance_types"
          control={control}
          rules={{ validate: { required } }}
          render={({ field }) => (
            <Select.Multi
              renderValueAsTag
              label={`${t('common.types')}*`}
              options={workspaceTypesOptions}
              value={field.value}
              onChange={field.onChange}
              error={errors.maintenance_types?.message}
            />
          )}
        />
        <Controller
          name="trainer_tech"
          control={control}
          render={({ field }) => (
            <Select.Multi
              renderValueAsTag
              label={t('common.trainer_technologies')}
              options={trainerTechOptions}
              value={field.value}
              onChange={field.onChange}
              error={errors.trainer_tech?.message}
            />
          )}
        />
        <Controller
          name="poker_rooms"
          control={control}
          render={({ field }) => (
            <Select.Multi
              renderValueAsTag
              label={t('common.poker_rooms')}
              options={trainerPokerRoomOptions}
              value={field.value}
              onChange={field.onChange}
              error={errors.poker_rooms?.message}
            />
          )}
        />
        {can.club.read && (
          <Controller
            name="club_ids"
            control={control}
            render={({ field }) => (
              <Select.Async
                onLoad={async (args) => {
                  const response = await loadGlobalClubs(args);

                  return response.items.map((item) => ({
                    value: item.id,
                    label: item.name,
                    caption: item.code,
                  }));
                }}
              >
                {(selectProps) => (
                  <Select.Multi
                    label={t('common.clubs')}
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.club_ids?.message}
                    {...selectProps}
                  />
                )}
              </Select.Async>
            )}
          />
        )}
        <Checkbox rtl variant="switch" {...register('start_new_table')}>
          {t('common.start_new_table')}
        </Checkbox>
        <Checkbox rtl variant="switch" {...register('allow_negative_balance_pilot')}>
          {t('common.disable_balance_check')}
        </Checkbox>
        {data && (
          <div className={styles.collapse}>
            <Checkbox rtl variant="switch" {...register('auto_create_mining_session')}>
              {t('common.automining')}
            </Checkbox>
            {autoMining && (
              <div className={styles.fields}>
                <Controller
                  name="mining_stage_ids"
                  control={control}
                  rules={{ validate: { required } }}
                  render={({ field }) => (
                    <Select.AsyncV2
                      onLoad={(args) => loadGlobalWorkspaceStages({ workspaceId: data.id }, args)}
                      select={(item) => ({
                        value: item.id,
                        label: item.name,
                      })}
                    >
                      {(selectProps) => (
                        <Select.Multi
                          renderValueAsTag
                          label={{
                            text: `${t('common.stages')}*`,
                            hint: t('sentences.automining_stages'),
                          }}
                          value={field.value}
                          onChange={field.onChange}
                          error={errors.mining_stage_ids?.message}
                          {...selectProps}
                        />
                      )}
                    </Select.AsyncV2>
                  )}
                />
                <Controller
                  name="mining_session_duration"
                  control={control}
                  rules={{ validate: { required, minLength: minLength(4) } }}
                  render={({ field }) => (
                    <Input
                      label={{
                        text: `${t('common.session_duration')}*`,
                        hint: t('sentences.automining_session_duration'),
                      }}
                      error={errors.mining_session_duration?.message}
                      patternFormat={{
                        pattern: '##:##',
                        mask: ['h', 'h', 'm', 'm'],
                        allowEmptyFormatting: true,
                        onValueChange: field.onChange,
                        isAllowed: ({ value }) => time(value),
                      }}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="mining_session_interval"
                  control={control}
                  rules={{ validate: { required, minLength: minLength(4) } }}
                  render={({ field }) => (
                    <Input
                      label={{
                        text: `${t('common.session_interval')}*`,
                        hint: t('sentences.automining_session_interval'),
                      }}
                      error={errors.mining_session_interval?.message}
                      patternFormat={{
                        pattern: '##:##',
                        mask: ['h', 'h', 'm', 'm'],
                        allowEmptyFormatting: true,
                        onValueChange: field.onChange,
                        isAllowed: ({ value }) => time(value),
                      }}
                      {...field}
                    />
                  )}
                />
              </div>
            )}
          </div>
        )}
        <PageAddon.Controls>
          <SubmitButton>{data ? t('common.save') : t('common.create')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default GlobalWorkspaceForm;
