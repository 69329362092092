import { useState } from 'react';
import { WorkspaceAccountSchema } from '../../../api/schemas/account/workspaceAccountSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Dropdown from '../../../components/Dropdown';
import routes from '../../../config/routes';
import useURL from '../../../hooks/useURL';
import { compact } from '../../../utils';
import useDropdown from '../useDropdown';
import WorkspaceAccountBalanceDialog, { Type } from '../WorkspaceAccountBalanceDialog';
import WorkspaceAccountChangePasswordDialog from '../WorkspaceAccountChangePasswordDialog';
import WorkspaceAccountHistoryDialog from '../WorkspaceAccountHistoryDialog';
import WorkspaceAccountVerificationCodeDialog from '../WorkspaceAccountVerificationCodeDialog';
import styles from './styles.module.scss';

interface Props {
  data: WorkspaceAccountSchema;
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceAccountDropdown = (props: Props) => {
  const { data, workspaceId } = props;

  const { navigate } = useURL();

  const [type, setType] = useState<
    Omit<Type, 'transfer'> | 'change-password' | 'history' | 'verification' | null
  >(null);

  const { title, view, options, setDefaultView } = useDropdown({
    ...props,
    changePassword: () => setType('change-password'),
    history: () => setType('history'),
    deposit: () => setType('deposit'),
    withdraw: () => setType('withdraw'),
    adjustment: () => setType('adjustment'),
    fee: () => setType('fee'),
    reward: () => setType('reward'),
    verificationCode: () => setType('verification'),
    onNotesOpen: () => navigate(routes.workspaceAccountNotes({ workspaceId, accountId: data.id })),
  });

  const getOptions = () => {
    if (view === 'stages') return [options.back, options.divider, ...options.stages];

    if (view === 'balance') {
      return [
        options.back,
        options.divider,
        options.deposit,
        options.withdraw,
        options.adjustment,
        options.fee,
        options.reward,
      ];
    }

    if (view === 'registration') {
      return compact([
        options.back,
        options.divider,
        options.revertToUnregistered,
        options.finishRegistration,
      ]);
    }

    if (view === 'verification') {
      return compact([
        options.back,
        options.divider,
        options.sumbitVerification,
        options.finishVerification,
        options.showVerificationCode,
      ]);
    }

    return compact([
      options.edit,
      options.history,
      options.notes,
      options.schedule,
      options.changePassword,
      options.copy,
      options.balance,
      options.registration,
      options.verification,
      options.divider,
      options.closePokerRoom,
      options.move_to,
    ]);
  };

  const onClose = () => setType(null);

  return (
    <>
      {type === 'change-password' && (
        <WorkspaceAccountChangePasswordDialog id={workspaceId} data={data} onClose={onClose} />
      )}
      {type === 'history' && (
        <WorkspaceAccountHistoryDialog workspaceId={workspaceId} account={data} onClose={onClose} />
      )}
      {type === 'deposit' && (
        <WorkspaceAccountBalanceDialog
          type="deposit"
          workspaceId={workspaceId}
          accountData={data}
          onClose={onClose}
        />
      )}
      {type === 'withdraw' && (
        <WorkspaceAccountBalanceDialog
          type="withdraw"
          workspaceId={workspaceId}
          accountData={data}
          onClose={onClose}
        />
      )}
      {type === 'adjustment' && (
        <WorkspaceAccountBalanceDialog
          type="adjustment"
          workspaceId={workspaceId}
          accountData={data}
          onClose={onClose}
        />
      )}
      {type === 'fee' && (
        <WorkspaceAccountBalanceDialog
          type="fee"
          workspaceId={workspaceId}
          accountData={data}
          onClose={onClose}
        />
      )}
      {type === 'reward' && (
        <WorkspaceAccountBalanceDialog
          type="reward"
          workspaceId={workspaceId}
          accountData={data}
          onClose={onClose}
        />
      )}
      {type === 'verification' && (
        <WorkspaceAccountVerificationCodeDialog
          workspaceId={workspaceId}
          accountId={data.id}
          onClose={onClose}
        />
      )}
      <Dropdown
        title={title}
        buttonSize="small"
        onClose={setDefaultView}
        className={styles.dropdown}
        options={getOptions()}
      />
    </>
  );
};

export default WorkspaceAccountDropdown;
