import { useTranslation } from 'react-i18next';
import { updateWorkspaceAccountRoomConfig } from '../../../api';
import { WorkspaceAccountSchema } from '../../../api/schemas/account/workspaceAccountSchema';
import { AccountRoomConfigSchema } from '../../../api/schemas/accountRoomConfigSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import notify from '../../../utils/notify';
import AccountRoomConfigForm from '../../account/AccountRoomConfig/AccountRoomConfigForm';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  data: AccountRoomConfigSchema;
  account: WorkspaceAccountSchema;
  onClose: () => void;
}

const WorkspaceAccountRoomConfigForm = (props: Props) => {
  const { workspaceId, data, account, onClose } = props;

  const { t } = useTranslation();

  return (
    <AccountRoomConfigForm
      data={data}
      room={account.room}
      title={`${t('common.poker_room_config_for')} ${account.login}`}
      onClose={onClose}
      onSubmit={async ({ payload }) => {
        await updateWorkspaceAccountRoomConfig({
          workspaceId,
          accountId: account.id,
          payload,
        });

        onClose();
        notify('success', { title: t('sentences.changes_have_been_saved') });
      }}
    />
  );
};

export default WorkspaceAccountRoomConfigForm;
