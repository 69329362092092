import { useQuery } from '@tanstack/react-query';
import { loadGlobalAccountStrategyProfile } from '../../api';
import { GlobalAccountSchema } from '../../api/schemas/account/globalAccountSchema';
import PageAddon from '../../components/PageAddon';
import queryKeys from '../../config/queryKeys';
import AccountStrategyProfile from '../account/AccountStrategyProfile';

interface Props {
  account: GlobalAccountSchema;
}

const GlobalAccountStrategyProfile = (props: Props) => {
  const { account } = props;

  const { id } = account;

  const queryKey = [queryKeys.accountStrategyProfile({ accountId: id })];
  const queryFn = () => loadGlobalAccountStrategyProfile({ accountId: id });

  const { data, isPending, isError, refetch } = useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403] },
  });

  if (isError) return <PageAddon.Collapse.Error onClick={() => refetch()} />;

  return <AccountStrategyProfile data={data} loading={isPending} />;
};

export default GlobalAccountStrategyProfile;
