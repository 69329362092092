import { DealSchema } from '../../../api/schemas/deal/dealSchema';
import Collapse from '../../../components/Collapse';
import Empty from '../../../components/Empty';
import Link from '../../../components/Link';
import routes from '../../../config/routes';
import styles from './styles.module.scss';

interface Props {
  data: DealSchema;
}

const GlobalDealDetailAccountsTab = (props: Props) => {
  const { data } = props;

  const { accounts } = data;

  const getContent = () => {
    if (!accounts.length) return <Empty />;

    return (
      <Collapse.Group>
        {accounts.map((item) => (
          <Collapse
            key={item.id}
            collapse={false}
            label={
              <Link
                target="_blank"
                to={`${routes.workspaceAccount({
                  workspaceId: item.workspace.id,
                  accountId: item.id,
                })}?search=${item.id}`}
              >
                {item.login}
              </Link>
            }
            caption={
              <Collapse.Caption>{`${item.workspace.name} • ${item.mga_id}`}</Collapse.Caption>
            }
          />
        ))}
      </Collapse.Group>
    );
  };

  return <div className={styles.container}>{getContent()}</div>;
};

export default GlobalDealDetailAccountsTab;
