import { Payload } from '../../components/Notes';
import api from '../../services/api';
import { DealSchema } from '../schemas/deal/dealSchema';
import noteSchema from '../schemas/noteSchema';

interface Args {
  dealId: DealSchema['id'];
  payload: Payload;
}

const createGlobalDealNote = async (args: Args) => {
  const { dealId, payload } = args;

  const response = await api.post(`/api/deal/${dealId}/notes`, payload);

  return noteSchema.pick({ id: true }).parse(response.data);
};

export default createGlobalDealNote;
