import { Controller, UseFormReturn } from 'react-hook-form';
import {
  loadWorkspaceBotSettings,
  loadWorkspaceDeals,
  loadWorkspaceIdentities,
  loadWorkspaceUsers,
} from '../../../api';
import { WorkspaceAccountSchema } from '../../../api/schemas/account/workspaceAccountSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Input from '../../../components/Input';
import PasswordInput from '../../../components/PasswordInput';
import Select from '../../../components/Select';
import Textarea from '../../../components/Textarea';
import i18n from '../../../i18n';
import { required, maxLength, minLength, email } from '../../../utils/form';
import {
  TRAINER_GAME_TYPE_LABELS,
  TRAINER_LIMITS_LABELS,
  TRAINER_POKER_ROOM_LABELS,
  getTrainerPokerRoomOptions,
  trainerGameTypeOptions,
} from '../../../utils/trainer';
import AccountFormLimitIds from '../../account/AccountFormLimitIds';
import AccountGeolocationDialog from '../../account/AccountGeolocationDialog';
import {
  TABLE_SIZE_LABELS,
  getGeolocationAsString,
  maxTableOptions,
  tableSizeOptions,
} from '../../account/helpers';
import { WorkspaceAccountFields } from '../../account/types';
import { getBotSettingCaption } from '../../global-bot-setting-rule/helpers';
import { getIdentityFullName } from '../../workspace-identity/helpers';
import { getChannelsValue } from '../helpers';
import WorkspaceAccountFormTagIds from '../WorkspaceAccountFormTagIds';
import styles from './styles.module.scss';
import WorkspaceAccountFormChannelIds from './WorkspaceAccountFormChannelIds';
import WorkspaceAccountFormClubIds from './WorkspaceAccountFormClubIds';

export const getDefaultValues = (args: { data?: WorkspaceAccountSchema } = {}) => {
  const { data } = args;

  return {
    login: data?.login || '',
    password: '',
    room: data ? { value: data.room, label: TRAINER_POKER_ROOM_LABELS[data.room] } : null,
    game_types: data
      ? data.game_types.map((item) => ({
          value: item,
          label: TRAINER_GAME_TYPE_LABELS[item],
        }))
      : [],
    comparative_limits: data
      ? data.comparative_limits.map((item) => ({ value: item, label: TRAINER_LIMITS_LABELS[item] }))
      : [],
    club_ids: data
      ? data.clubs.map((item) => ({ value: item.id, label: item.name, caption: item.code }))
      : [],
    stopped_club_ids: data ? data.stopped_clubs.map((item) => item.id) : [],
    table_sizes: data
      ? data.table_sizes.map((item) => ({
          value: item,
          label: TABLE_SIZE_LABELS[item],
        }))
      : [],
    max_tables: data ? { value: data.max_tables, label: String(data.max_tables) } : null,
    geo: data ? getGeolocationAsString(data.geo) : '',
    channel_ids: data ? getChannelsValue(data.channels) : [],
    email: data?.email || '',
    email_password: data?.email_password || '',
    tag_ids: data ? data.tags.map((item) => ({ value: item.id, label: item.name })) : [],
    affiliate_reference_number: data?.affiliate_reference_number || '',
    user_id: data?.user ? { value: data.user.id, label: data.user.username } : null,
    bot_settings_ids: data
      ? data.bot_settings.map((item) => ({ value: item.id, label: item.name }))
      : [],
    deal_ids: data ? data.deals.map((item) => ({ value: item.id, label: item.code })) : [],
    identity_id: data?.identity
      ? { value: data.identity.id, label: getIdentityFullName(data.identity) }
      : null,
  };
};

export const getFields = (args: {
  form: UseFormReturn<WorkspaceAccountFields & { nickname_pool?: string }>;
  workspace: WorkspaceSchema;
  required?: boolean;
}) => {
  const { form, workspace, required: req } = args;

  const { id: workspaceId } = workspace;

  const workspacePokerRoomOptions = getTrainerPokerRoomOptions(workspace.poker_rooms);

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = form;

  const validate = req ? { required } : {};

  const room = watch('room')?.value;

  return {
    login: (
      <Controller
        name="login"
        control={control}
        rules={{
          validate: { required, minLength: minLength(), maxLength: maxLength(), ...validate },
        }}
        render={({ field }) => (
          <Input label={`${i18n.t('common.login')}*`} error={errors.login?.message} {...field} />
        )}
      />
    ),
    password: (
      <Controller
        name="password"
        control={control}
        rules={{ validate: { required, minLength: minLength(), ...validate } }}
        render={({ field }) => (
          <PasswordInput
            label={`${i18n.t('common.password')}*`}
            error={errors.password?.message}
            {...field}
          />
        )}
      />
    ),
    room: (
      <Controller
        name="room"
        control={control}
        rules={{ validate: { required, ...validate } }}
        render={({ field }) => (
          <Select
            clearable={false}
            label={`${i18n.t('common.poker_room')}*`}
            value={field.value}
            error={errors.room?.message}
            onChange={(value) => {
              setValue('game_types', []);
              setValue('comparative_limits', []);
              setValue('deal_ids', []);

              field.onChange(value);
            }}
            options={workspacePokerRoomOptions}
          />
        )}
      />
    ),
    club_ids: <WorkspaceAccountFormClubIds workspaceId={workspaceId} required={req} />,
    game_types: (
      <Controller
        name="game_types"
        control={control}
        {...(req && { rules: { validate: required } })}
        render={({ field }) => (
          <Select.Multi
            renderValueAsTag
            label={i18n.t('common.game_types')}
            value={field.value}
            onChange={field.onChange}
            error={errors.game_types?.message}
            options={trainerGameTypeOptions}
          />
        )}
      />
    ),
    comparative_limits: <AccountFormLimitIds required={req} />,
    table_sizes: (
      <Controller
        name="table_sizes"
        control={control}
        {...(req && { rules: { validate: required } })}
        render={({ field }) => (
          <Select.Multi
            label={i18n.t('common.table_size')}
            options={tableSizeOptions}
            value={field.value}
            onChange={field.onChange}
            error={errors.table_sizes?.message}
          />
        )}
      />
    ),
    max_tables: (
      <Controller
        name="max_tables"
        control={control}
        {...(req && { rules: { validate: required } })}
        render={({ field }) => (
          <Select
            label={i18n.t('common.max_tables')}
            options={maxTableOptions}
            value={field.value}
            onChange={field.onChange}
            error={errors.max_tables?.message}
          />
        )}
      />
    ),
    geo: (
      <Controller
        name="geo"
        control={control}
        {...(req && { rules: { validate: required } })}
        render={({ field }) => (
          <Input
            label={i18n.t('common.geolocation')}
            extra={<AccountGeolocationDialog />}
            error={errors.geo?.message}
            className={styles.geoInput}
            {...field}
          />
        )}
      />
    ),
    channel_ids: <WorkspaceAccountFormChannelIds workspaceId={workspaceId} required={req} />,
    email: (
      <Controller
        name="email"
        control={control}
        rules={{ validate: email }}
        render={({ field }) => (
          <Input label={i18n.t('common.email')} error={errors.email?.message} {...field} />
        )}
      />
    ),
    email_password: (
      <Controller
        name="email_password"
        control={control}
        render={({ field }) => (
          <PasswordInput
            label={i18n.t('common.email_password')}
            error={errors.email_password?.message}
            {...field}
          />
        )}
      />
    ),
    tag_ids: <WorkspaceAccountFormTagIds workspaceId={workspaceId} required={req} />,
    affiliate_reference_number: (
      <Controller
        name="affiliate_reference_number"
        control={control}
        {...(req && { rules: { validate: required } })}
        render={({ field }) => (
          <Input
            label={i18n.t('common.affiliate_reference')}
            error={errors.affiliate_reference_number?.message}
            {...field}
          />
        )}
      />
    ),
    user_id: (
      <Controller
        name="user_id"
        control={control}
        {...(req && { rules: { validate: required } })}
        render={({ field }) => (
          <Select.AsyncV2
            onLoad={(params) => loadWorkspaceUsers({ workspaceId }, params)}
            select={(item) => ({
              value: item.id,
              label: item.username,
            })}
          >
            {({ options, ...rest }) => (
              <Select
                label={i18n.t('common.member')}
                options={options}
                value={field.value}
                onChange={field.onChange}
                error={errors.user_id?.message}
                {...rest}
              />
            )}
          </Select.AsyncV2>
        )}
      />
    ),
    identity_id: (
      <Controller
        name="identity_id"
        control={control}
        {...(req && { rules: { validate: required } })}
        render={({ field }) => (
          <Select.AsyncV2
            onLoad={(params) => loadWorkspaceIdentities({ workspaceId }, params)}
            select={(item) => ({
              value: item.id,
              label: getIdentityFullName(item),
            })}
          >
            {({ options, ...rest }) => (
              <Select
                label={i18n.t('common.identity')}
                options={options}
                value={field.value}
                onChange={field.onChange}
                error={errors.identity_id?.message}
                {...rest}
              />
            )}
          </Select.AsyncV2>
        )}
      />
    ),
    bot_settings_ids: (
      <Controller
        name="bot_settings_ids"
        control={control}
        {...(req && { rules: { validate: required } })}
        render={({ field }) => (
          <Select.Async
            onLoad={async (params) => {
              const response = await loadWorkspaceBotSettings({ workspaceId }, params);

              const options = response.items.map((item) => ({
                value: item.id,
                label: item.name,
                caption: getBotSettingCaption(item),
              }));

              return options;
            }}
          >
            {({ options, ...rest }) => (
              <Select.Multi
                label={i18n.t('common.bot_settings')}
                options={options}
                value={field.value}
                onChange={field.onChange}
                error={errors.bot_settings_ids?.message}
                {...rest}
              />
            )}
          </Select.Async>
        )}
      />
    ),
    deal_ids: (
      <Controller
        name="deal_ids"
        control={control}
        {...(req && { rules: { validate: required } })}
        render={({ field }) => (
          <Select.AsyncV2
            onLoad={(params) =>
              loadWorkspaceDeals(
                { workspaceId },
                { ...params, ...(room && { poker_room_in: String(room) }) }
              )
            }
            select={(item) => ({
              value: item.id,
              label: item.code,
              caption: `${item.agent.name} • ${item.club.name} • ${item.club.code}`,
            })}
          >
            {({ options, ...rest }) => (
              <Select.Multi
                label={i18n.t('common.deals')}
                options={options}
                value={field.value}
                onChange={field.onChange}
                error={errors.deal_ids?.message}
                {...rest}
              />
            )}
          </Select.AsyncV2>
        )}
      />
    ),
    nickname_pool: (
      <Controller
        name="nickname_pool"
        control={control}
        {...(req && { rules: { validate: required } })}
        render={({ field }) => (
          <Textarea
            label={{
              text: i18n.t('common.nickname_pool'),
              hint: String(i18n.t('sentences.enter_nicknames_separated_by_commas')),
            }}
            maxLength={0}
            className={styles.textarea}
            error={errors.nickname_pool?.message}
            {...field}
          />
        )}
      />
    ),
  };
};

export const getPayload = (args: {
  values: WorkspaceAccountFields & { nickname_pool?: string };
}) => {
  const { values } = args;

  const [lat, lng] = values.geo.split(',');

  return {
    login: values.login,
    password: values.password,
    room: values.room,
    club_ids: values.club_ids.map((item) => item.value),
    stopped_club_ids: values.stopped_club_ids,
    game_types: values.game_types.map((item) => item.value),
    comparative_limits: values.comparative_limits.map((item) => item.value),
    table_sizes: values.table_sizes.map((item) => item.value),
    ...(values.max_tables && { max_tables: values.max_tables.value }),
    geo: values.geo ? { lat: Number(lat), lng: Number(lng) } : null,
    channel_ids: values.channel_ids.map((item) => item.value),
    email: values.email || null,
    email_password: values.email_password || null,
    tag_ids: values.tag_ids.map((item) => item.value),
    affiliate_reference_number: values.affiliate_reference_number || null,
    user_id: values.user_id?.value || null,
    identity_id: values.identity_id?.value || null,
    bot_settings_ids: values.bot_settings_ids.map((item) => item.value),
    deal_ids: values.deal_ids.map((item) => item.value),
    nickname_pool: values.nickname_pool,
  };
};
