import { ACTION_TYPE, HistorySchema } from '../../api/schemas/historySchema';
import COLORS from '../../constants/COLORS';
import i18n from '../../i18n';

export type ActionType = HistorySchema['action_type'];

export type Enums = Record<string, Record<number, string>>;

export const ACTION_TYPE_LABELS: Record<ActionType, string> = {
  [ACTION_TYPE.UNKNOWN]: '—',
  [ACTION_TYPE.CREATED]: i18n.t('common.created'),
  [ACTION_TYPE.EDITED]: i18n.t('common.edited'),
  [ACTION_TYPE.DELETED]: i18n.t('common.removed'),
  [ACTION_TYPE.RESTORED]: i18n.t('common.restored'),
  [ACTION_TYPE.STRATEGY_PROFILE_CREATED]: i18n.t('common.strategy_profile_created'),
  [ACTION_TYPE.STRATEGY_PROFILE_EDITED]: i18n.t('common.strategy_profile_edited'),
  [ACTION_TYPE.NOTE_CREATED]: i18n.t('common.note_created'),
  [ACTION_TYPE.NOTE_EDITED]: i18n.t('common.note_edited'),
  [ACTION_TYPE.SESSION_CREATED]: i18n.t('common.session_created'),
  [ACTION_TYPE.SESSION_EDITED]: i18n.t('common.session_edited'),
  [ACTION_TYPE.NOTE_DELETED]: i18n.t('common.note_removed'),
  [ACTION_TYPE.SESSION_DELETED]: i18n.t('common.session_removed'),
};

export const ACTION_TYPE_COLORS: Record<ActionType, string> = {
  [ACTION_TYPE.UNKNOWN]: COLORS.GREY_300,
  [ACTION_TYPE.CREATED]: COLORS.GREEN_100,
  [ACTION_TYPE.EDITED]: COLORS.ORANGE_100,
  [ACTION_TYPE.DELETED]: COLORS.RED_100,
  [ACTION_TYPE.RESTORED]: COLORS.BLUE_200,
  [ACTION_TYPE.STRATEGY_PROFILE_CREATED]: COLORS.GREEN_100,
  [ACTION_TYPE.STRATEGY_PROFILE_EDITED]: COLORS.ORANGE_100,
  [ACTION_TYPE.NOTE_CREATED]: COLORS.GREEN_100,
  [ACTION_TYPE.NOTE_EDITED]: COLORS.ORANGE_100,
  [ACTION_TYPE.SESSION_CREATED]: COLORS.GREEN_100,
  [ACTION_TYPE.SESSION_EDITED]: COLORS.ORANGE_100,
  [ACTION_TYPE.NOTE_DELETED]: COLORS.RED_100,
  [ACTION_TYPE.SESSION_DELETED]: COLORS.RED_100,
};
