import { useQuery } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { loadGlobalDeal } from '../../api';
import { DealSchema } from '../../api/schemas/deal/dealSchema';
import PageAddon from '../../components/PageAddon';
import queryKeys from '../../config/queryKeys';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import { updateDetailQueryData } from '../../utils/queryClient';

interface Props {
  dealId: DealSchema['id'];
  children: (args: { data: DealSchema }) => ReactElement;
}

const GlobalDealLoader = (props: Props) => {
  const { dealId, children } = props;

  const queryKey = [queryKeys.deal({ dealId })];
  const queryFn = () => loadGlobalDeal({ dealId });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403] },
  });

  useWebSocketMessage<DealSchema>({
    domain: 'GlobalDeal',
    onMessage: (message) => {
      if (
        (message.action === 'Edited' ||
          message.action === 'NoteAdded' ||
          message.action === 'NoteRemoved') &&
        message.payload.id === dealId
      ) {
        updateDetailQueryData(queryKey, message.payload);
      }
    },
  });

  if (isPending) return <PageAddon.DetailPlaceholder />;
  if (!data) return null;

  return children({ data });
};

export default GlobalDealLoader;
