import { format, isSameDay, isThisYear } from 'date-fns';
import { STATUS, TYPE } from '../../api/schemas/session';
import Tag from '../../components/Tag';
import { MONTH_DAY_TIME, MONTH_DAY_YEAR_TIME, TIME } from '../../constants/DATE_FORMATS';
import i18n from '../../i18n';
import { getDate } from '../../utils/date';
import { CommonSessionSchema } from './types';

export const STATUS_LABELS: Record<CommonSessionSchema['status'], string> = {
  [STATUS.UNKNOWN]: '—',
  [STATUS.PLANNED]: i18n.t('common.planned'),
  [STATUS.SENT_START]: i18n.t('common.sent_start'),
  [STATUS.RUNNING]: i18n.t('common.running'),
  [STATUS.SENT_FINISH]: i18n.t('common.sent_finish'),
  [STATUS.FINISHED]: i18n.t('common.finished'),
  [STATUS.EXPIRED]: i18n.t('common.expired'),
  [STATUS.CANCELED]: i18n.t('common.canceled'),
};

export const TYPE_LABELS: Record<CommonSessionSchema['session_type'], string> = {
  [TYPE.UNKNOWN]: '—',
  [TYPE.GAME]: i18n.t('common.game'),
  [TYPE.MINING]: i18n.t('common.mining'),
};

export const TYPE_ENUM: Record<string, CommonSessionSchema['session_type']> = {
  [String(TYPE.UNKNOWN)]: TYPE.UNKNOWN,
  [String(TYPE.GAME)]: TYPE.GAME,
  [String(TYPE.MINING)]: TYPE.MINING,
};

export const getTitle = (args: {
  start: CommonSessionSchema['start'];
  end: CommonSessionSchema['end'];
}) => {
  const { start: startDate, end: endDate } = args;

  const start = getDate(startDate);
  const end = getDate(endDate);

  if (isThisYear(start) && isThisYear(end)) {
    const formattedStart = format(start, MONTH_DAY_TIME);

    if (isSameDay(start, end)) return `${formattedStart} — ${format(end, TIME)}`;

    return `${formattedStart} — ${format(end, MONTH_DAY_TIME)}`;
  }

  const formattedStart = format(start, MONTH_DAY_YEAR_TIME);

  if (isSameDay(start, end)) return `${formattedStart} — ${format(end, TIME)}`;

  return `${formattedStart} — ${format(end, MONTH_DAY_YEAR_TIME)}`;
};

export const renderClubs = (clubs: CommonSessionSchema['clubs']) => {
  if (clubs.length) {
    return (
      <Tag count={clubs.length}>
        <Tag.FadingText text={clubs[0].name} />
      </Tag>
    );
  }

  return '—';
};
