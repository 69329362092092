import { Controller, UseFormReturn } from 'react-hook-form';
import { AccountRoomConfigSchema } from '../../../api/schemas/accountRoomConfigSchema';
import { TRAINER_POKER_ROOM } from '../../../api/schemas/trainerPokerRoomSchema';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import i18n from '../../../i18n';
import { required } from '../../../utils/form';
import { booleanOptions, getBooleanOption } from '../helpers';
import { Fields } from './types';

const TABLE_SHAPES = {
  ZERO: 0,
  ONE: 1,
} as const;

const FELT_COLORS = {
  GREEN: '3,135,44',
  BLUE: '2,80,151',
  RED: '158,3,3',
  GRAY: '52,52,52',
} as const;

const BACKGROUNDS = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
} as const;

const DECK_TYPES = {
  DEFAULT: 'Default',
  FOUR_COLOR_CLASSIC: '4ColorClassic',
  MODERN: 'Modern',
  MODERN_COLOR: 'ModernColor',
  ALTERNATIVE: 'Alternative',
  ALTERNATIVE_MINIMAL: 'AlternativeMinimal',
} as const;

const CARDS_BACK = {
  EMPTY: '',
  MODERN: 'Modern',
  ALTERNATIVE: 'Alternative',
  ALTERNATIVE_MINIMAL: 'AlternativeMinimal',
  WPT: 'WPT',
  PPLIVE: 'PPLive',
} as const;

const tableShapes = Object.values(TABLE_SHAPES);
const feltColors = Object.values(FELT_COLORS);
const backgrounds = Object.values(BACKGROUNDS);
const deckTypes = Object.values(DECK_TYPES);
const cardsBack = Object.values(CARDS_BACK);

const tableShapesOptions = tableShapes.map((item) => ({ value: item, label: String(item) }));
const feltColorsOptions = feltColors.map((item) => ({ value: item, label: item }));
const backgroundsOptions = backgrounds.map((item) => ({ value: item, label: String(item) }));
const deckTypesOptions = deckTypes.map((item) => ({ value: item, label: item }));
const cardsBackOptions = cardsBack.map((item) => ({ value: item, label: item }));

export const getDefaultValues = (args: { data?: AccountRoomConfigSchema } = {}) => {
  const { data } = args;

  return {
    // PartyPoker
    TableShape: data ? { value: Number(data.TableShape), label: String(data.TableShape) } : null,
    FeltColor: data ? { value: String(data.FeltColor), label: String(data.FeltColor) } : null,
    Background: data ? { value: Number(data.Background), label: String(data.Background) } : null,
    DeckType: data ? { value: String(data.DeckType), label: String(data.DeckType) } : null,
    CardBack: data ? { value: String(data.CardBack), label: String(data.CardBack) } : null,
    IsDefaultAvatar: data ? getBooleanOption(data.IsDefaultAvatar) : null,
    AutoBuyinCashGamesRebuy: data ? getBooleanOption(data.AutoBuyinCashGamesRebuy) : null,
    MuckLosingUncalledHands: data ? getBooleanOption(data.MuckLosingUncalledHands) : null,

    // PPPoker
    ev_chop_percent: data ? String(data.ev_chop_percent) : '',
    rim_percent: data ? String(data.rim_percent) : '',
    ppsr: data ? getBooleanOption(data.ppsr) : null,
    autoplay: data ? getBooleanOption(data.autoplay) : null,
    show_cards: data ? getBooleanOption(data.show_cards) : null,

    // Pokerrrr2
    rit_percent: data ? String(data.rit_percent) : '',
    dead_blind: data ? getBooleanOption(data.dead_blind) : null,
    auto_muck: data ? getBooleanOption(data.auto_muck) : null,
    timebank: data ? getBooleanOption(data.timebank) : null,
    is_standard_stack_seat: data ? getBooleanOption(data.is_standard_stack_seat) : null,
    auto_rebuy: data ? getBooleanOption(data.auto_rebuy) : null,
    tournament_addon: data ? getBooleanOption(data.tournament_addon) : null,
    max_tournament_rebuy: data ? String(data.max_tournament_rebuy) : '',

    // Ruler
    nickname: data ? String(data.nickname) : '',

    // common
    balance_check: data ? getBooleanOption(data.balance_check) : null, // Pokerrrr2, Ruler
  };
};

// eslint-disable-next-line no-use-before-define
export const labels: Record<keyof ReturnType<typeof getFields>, string> = {
  TableShape: i18n.t('common.table_shape'),
  FeltColor: i18n.t('common.felt_color'),
  Background: i18n.t('common.background'),
  DeckType: i18n.t('common.deck_type'),
  CardBack: i18n.t('common.card_back'),
  IsDefaultAvatar: i18n.t('common.default_avatar'),
  AutoBuyinCashGamesRebuy: i18n.t('sentences.auto_buy_in_rebuy_cash_games'),
  MuckLosingUncalledHands: i18n.t('sentences.muck_losing_uncalled_hands'),
  ev_chop_percent: i18n.t('common.ev_chop_%'),
  rim_percent: i18n.t('common.rim_%'),
  ppsr: i18n.t('common.ppsr'),
  autoplay: i18n.t('common.autoplay'),
  rit_percent: i18n.t('common.rit_%'),
  dead_blind: i18n.t('common.dead_blind'),
  auto_muck: i18n.t('common.auto_muck'),
  timebank: i18n.t('common.timebank'),
  balance_check: i18n.t('common.balance_check'),
  nickname: i18n.t('common.nickname'),
  show_cards: i18n.t('common.show_cards'),
  is_standard_stack_seat: i18n.t('common.standart_stack_seat'),
  auto_rebuy: i18n.t('common.auto_rebuy'),
  tournament_addon: i18n.t('common.tournament_addon'),
  max_tournament_rebuy: i18n.t('common.max_tournament_rebuy'),
};

export const getFields = (form: UseFormReturn<Fields>) => {
  const {
    control,
    formState: { errors },
  } = form;

  return {
    // PartyPoker
    TableShape: (
      <Controller
        name="TableShape"
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.TableShape}
            options={tableShapesOptions}
            value={field.value}
            onChange={field.onChange}
            error={errors.TableShape?.message}
          />
        )}
      />
    ),
    FeltColor: (
      <Controller
        name="FeltColor"
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.FeltColor}
            options={feltColorsOptions}
            value={field.value}
            onChange={field.onChange}
            error={errors.FeltColor?.message}
          />
        )}
      />
    ),
    Background: (
      <Controller
        name="Background"
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.Background}
            options={backgroundsOptions}
            value={field.value}
            onChange={field.onChange}
            error={errors.Background?.message}
          />
        )}
      />
    ),
    DeckType: (
      <Controller
        name="DeckType"
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.DeckType}
            options={deckTypesOptions}
            value={field.value}
            onChange={field.onChange}
            error={errors.DeckType?.message}
          />
        )}
      />
    ),
    CardBack: (
      <Controller
        name="CardBack"
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.CardBack}
            options={cardsBackOptions}
            value={field.value}
            onChange={field.onChange}
            error={errors.CardBack?.message}
          />
        )}
      />
    ),
    IsDefaultAvatar: (
      <Controller
        name="IsDefaultAvatar"
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.IsDefaultAvatar}
            options={booleanOptions}
            value={field.value}
            onChange={field.onChange}
            error={errors.IsDefaultAvatar?.message}
          />
        )}
      />
    ),
    AutoBuyinCashGamesRebuy: (
      <Controller
        name="AutoBuyinCashGamesRebuy"
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.AutoBuyinCashGamesRebuy}
            options={booleanOptions}
            value={field.value}
            onChange={field.onChange}
            error={errors.AutoBuyinCashGamesRebuy?.message}
          />
        )}
      />
    ),
    MuckLosingUncalledHands: (
      <Controller
        name="MuckLosingUncalledHands"
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.MuckLosingUncalledHands}
            options={booleanOptions}
            value={field.value}
            onChange={field.onChange}
            error={errors.MuckLosingUncalledHands?.message}
          />
        )}
      />
    ),

    // PPPoker
    ev_chop_percent: (
      <Controller
        name="ev_chop_percent"
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Input
            label={labels.ev_chop_percent}
            error={errors.ev_chop_percent?.message}
            {...field}
          />
        )}
      />
    ),
    rim_percent: (
      <Controller
        name="rim_percent"
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Input label={labels.rim_percent} error={errors.rim_percent?.message} {...field} />
        )}
      />
    ),
    ppsr: (
      <Controller
        name="ppsr"
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.ppsr}
            options={booleanOptions}
            value={field.value}
            onChange={field.onChange}
            error={errors.ppsr?.message}
          />
        )}
      />
    ),

    /// Pokerrrr2
    rit_percent: (
      <Controller
        name="rit_percent"
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Input label={labels.rit_percent} error={errors.rit_percent?.message} {...field} />
        )}
      />
    ),
    dead_blind: (
      <Controller
        name="dead_blind"
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.dead_blind}
            options={booleanOptions}
            value={field.value}
            onChange={field.onChange}
            error={errors.dead_blind?.message}
          />
        )}
      />
    ),
    auto_muck: (
      <Controller
        name="auto_muck"
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.auto_muck}
            options={booleanOptions}
            value={field.value}
            onChange={field.onChange}
            error={errors.auto_muck?.message}
          />
        )}
      />
    ),
    timebank: (
      <Controller
        name="timebank"
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.timebank}
            options={booleanOptions}
            value={field.value}
            onChange={field.onChange}
            error={errors.timebank?.message}
          />
        )}
      />
    ),
    is_standard_stack_seat: (
      <Controller
        name="is_standard_stack_seat"
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.is_standard_stack_seat}
            options={booleanOptions}
            value={field.value}
            onChange={field.onChange}
            error={errors.is_standard_stack_seat?.message}
          />
        )}
      />
    ),
    auto_rebuy: (
      <Controller
        name="auto_rebuy"
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.auto_rebuy}
            options={booleanOptions}
            value={field.value}
            onChange={field.onChange}
            error={errors.auto_rebuy?.message}
          />
        )}
      />
    ),
    tournament_addon: (
      <Controller
        name="tournament_addon"
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.tournament_addon}
            options={booleanOptions}
            value={field.value}
            onChange={field.onChange}
            error={errors.tournament_addon?.message}
          />
        )}
      />
    ),
    max_tournament_rebuy: (
      <Controller
        name="max_tournament_rebuy"
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Input
            label={labels.max_tournament_rebuy}
            error={errors.max_tournament_rebuy?.message}
            {...field}
          />
        )}
      />
    ),

    /// Ruler
    nickname: (
      <Controller
        name="nickname"
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Input label={labels.nickname} error={errors.nickname?.message} {...field} />
        )}
      />
    ),

    // common
    balance_check: (
      <Controller
        name="balance_check" // Pokerrrr2, Ruler
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.balance_check}
            options={booleanOptions}
            value={field.value}
            onChange={field.onChange}
            error={errors.balance_check?.message}
          />
        )}
      />
    ),
    autoplay: (
      <Controller
        name="autoplay" // PPPoker, ClubGG
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.autoplay}
            options={booleanOptions}
            value={field.value}
            onChange={field.onChange}
            error={errors.autoplay?.message}
          />
        )}
      />
    ),
    show_cards: (
      <Controller
        name="show_cards" // PPPoker, ClubGG
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.show_cards}
            options={booleanOptions}
            value={field.value}
            onChange={field.onChange}
            error={errors.show_cards?.message}
          />
        )}
      />
    ),
  };
};

export const getPayload = (args: { values: Fields }) => {
  const { values } = args;

  return {
    // PartyPoker
    TableShape: values.TableShape?.value,
    FeltColor: values.FeltColor?.value,
    Background: values.Background?.value,
    DeckType: values.DeckType?.value,
    CardBack: values.CardBack?.value,
    IsDefaultAvatar: Boolean(values.IsDefaultAvatar?.value),
    AutoBuyinCashGamesRebuy: Boolean(values.AutoBuyinCashGamesRebuy?.value),
    MuckLosingUncalledHands: Boolean(values.MuckLosingUncalledHands?.value),

    // PPPoker
    ev_chop_percent: Number(values.ev_chop_percent),
    rim_percent: Number(values.rim_percent),
    ppsr: Boolean(values.ppsr?.value),

    // Pokerrrr2
    rit_percent: Number(values.rit_percent),
    dead_blind: Boolean(values.dead_blind?.value),
    auto_muck: Boolean(values.auto_muck?.value),
    timebank: Boolean(values.timebank?.value),
    is_standard_stack_seat: Boolean(values.is_standard_stack_seat?.value),
    auto_rebuy: Boolean(values.auto_rebuy?.value),
    tournament_addon: Boolean(values.tournament_addon?.value),
    max_tournament_rebuy: Number(values.max_tournament_rebuy),

    // Ruler
    nickname: values.nickname,

    // common
    balance_check: Boolean(values.balance_check?.value), // Pokerrrr2, Ruler
    autoplay: Boolean(values.autoplay?.value), // PPPoker, ClubGG
    show_cards: Boolean(values.show_cards?.value), // PPPoker, ClubGG
  };
};

export const roomsFields: Record<string, (keyof ReturnType<typeof getFields>)[]> = {
  [TRAINER_POKER_ROOM.PARTYPOKER]: [
    'TableShape',
    'FeltColor',
    'Background',
    'DeckType',
    'CardBack',
    'IsDefaultAvatar',
    'AutoBuyinCashGamesRebuy',
    'MuckLosingUncalledHands',
  ],
  [TRAINER_POKER_ROOM.PPPOKER]: [
    'ev_chop_percent',
    'rim_percent',
    'ppsr',
    'autoplay',
    'show_cards',
  ],
  [TRAINER_POKER_ROOM.POKERRRR2]: [
    'rit_percent',
    'dead_blind',
    'balance_check',
    'auto_muck',
    'timebank',
    'is_standard_stack_seat',
    'auto_rebuy',
    'tournament_addon',
    'max_tournament_rebuy',
  ],
  [TRAINER_POKER_ROOM.RULER]: ['nickname', 'balance_check'],
  [TRAINER_POKER_ROOM.CLUBGG]: ['autoplay', 'show_cards'],
};
