import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import { GlobalUserSchema } from '../schemas/globalUserSchema';
import workspaceSchema, { WorkspaceSchema, WorkspaceTypes } from '../schemas/workspaceSchema';

type Schema = WorkspaceSchema;

interface Payload {
  name: Schema['name'];
  business_model: Schema['business_model'];
  user_id: GlobalUserSchema['id'];
  maintenance_types: WorkspaceTypes;
  poker_rooms: Schema['poker_rooms'];
  trainer_tech: Schema['trainer_tech'];
  bar_seq: Schema['bar_seq'];
  start_new_table: Schema['start_new_table'];
  allow_negative_balance_pilot: Schema['allow_negative_balance_pilot'];
}

interface Args {
  payload: Payload;
}

const createGlobalWorkspace = async (args: Args) => {
  const { payload } = args;

  const response = await api.post(`/api/global/${ALIASES.WORKSPACE.L}`, payload);

  return workspaceSchema.pick({ id: true }).parse(response.data);
};

export default createGlobalWorkspace;
