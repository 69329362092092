import { ROLE } from '../../api/schemas/deal';
import { DealSchema } from '../../api/schemas/deal/dealSchema';
import Badge, { Color } from '../../components/Badge';
import { ROLE_LABELS } from './helpers';

const COLORS: Record<DealSchema['role'], Color> = {
  [ROLE.UNKNOWN]: 'grey',
  [ROLE.PLAYER]: 'red',
  [ROLE.AGENT]: 'orange',
  [ROLE.CLUBOWNER]: 'green',
};

interface Props {
  data: DealSchema;
}

const GlobalDealRole = (props: Props) => {
  const { data } = props;

  const { role } = data;

  return <Badge color={COLORS[role]}>{ROLE_LABELS[role]}</Badge>;
};

export default GlobalDealRole;
