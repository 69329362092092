import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { loadWorkspaceSeatControlRules } from '../../api';
import { SeatControlRuleSchema } from '../../api/schemas/seatControlRuleSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Card from '../../components/Card';
import Page from '../../components/Page';
import PageControls from '../../components/PageControls';
import Table, { getTableProps } from '../../components/Table';
import queryKeys from '../../config/queryKeys';
import routes from '../../config/routes';
import WorkspaceSeatControlRuleCreateButton from '../../features/workspace-seat-control-rule/WorkspaceSeatControlRuleCreateButton';
import WorkspaceSeatControlRuleDetailLink from '../../features/workspace-seat-control-rule/WorkspaceSeatControlRuleDetailLink';
import WorkspaceSeatControlRuleDropdown from '../../features/workspace-seat-control-rule/WorkspaceSeatControlRuleDropdown';
import WorkspaceSeatControlRuleFilter from '../../features/workspace-seat-control-rule/WorkspaceSeatControlRuleFilter';
import WorkspaceSeatControlRuleIsActive from '../../features/workspace-seat-control-rule/WorkspaceSeatControlRuleIsActive';
import useBreakpoints from '../../hooks/useBreakpoints';
import usePaginatedDataNew from '../../hooks/usePaginatedDataNew';
import useURL from '../../hooks/useURL';
import useWebSocketPaginatedActions from '../../hooks/useWebSocketPaginatedActions';
import { RouteParams } from '../../utils/generics';
import Error403Page from '../Error403Page';
import styles from './styles.module.scss';

type Params = RouteParams<typeof routes.workspaceSeatConrolRule>;

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceSeatControlRulesPage = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();
  const { isDesktop, isTablet, isPhone } = useBreakpoints();
  const { seatControlRuleId } = useParams<Params>();
  const { urlParams } = useURL();
  const { search } = urlParams;

  const queryKey = [queryKeys.workspaceSeatControlRules({ workspaceId }), urlParams];

  const query = usePaginatedDataNew({
    queryKey,
    queryFn: (params) =>
      loadWorkspaceSeatControlRules({ workspaceId }, { ...urlParams, ...params }),
  });

  const { data, error, checkIsInView } = query;

  const { checkIsCreated } = useWebSocketPaginatedActions<
    SeatControlRuleSchema,
    SeatControlRuleSchema
  >({
    queryKey,
    domain: 'Rule',
    updateAction: ['Edited', 'Activated', 'Deactivated'],
    select: (item) => item,
    enabled: (message) => workspaceId === message.meta_info?.maintenance_id,
  });

  if (error?.response?.status === 403) return <Error403Page />;

  return (
    <Page
      heading={t('common.seat_control_rules')}
      extra={
        <PageControls>
          <WorkspaceSeatControlRuleCreateButton workspaceId={workspaceId} />
        </PageControls>
      }
      filter={<WorkspaceSeatControlRuleFilter />}
      addon={<Outlet />}
    >
      {(isDesktop || isTablet) && (
        <Table
          data={data}
          search={search}
          {...getTableProps(query)}
          className={styles.table}
          columns={[
            {
              title: t('common.name'),
              render: ({ values }) => (
                <WorkspaceSeatControlRuleDetailLink data={values} workspaceId={workspaceId} />
              ),
            },
            {
              title: t('common.status'),
              render: ({ values }) => (
                <WorkspaceSeatControlRuleIsActive data={values} workspaceId={workspaceId} />
              ),
            },
            {
              render: ({ values }) => (
                <WorkspaceSeatControlRuleDropdown data={values} workspaceId={workspaceId} />
              ),
              align: 'right',
              controls: true,
            },
          ]}
          renderRow={(row, index) => (
            <Table.Row
              highlighted={checkIsCreated(row.data.id)}
              active={row.data.id === seatControlRuleId}
              danger={row.data.is_deleted}
              {...checkIsInView(index)}
              {...row}
            />
          )}
        />
      )}
      {isPhone && (
        <Card.Group>
          {data.map((item, index) => (
            <Card
              key={item.id}
              highlighted={checkIsCreated(item.id)}
              danger={item.is_deleted}
              {...checkIsInView(index)}
            >
              <Card.Top>
                <WorkspaceSeatControlRuleDetailLink data={item} workspaceId={workspaceId} />
                <Card.Top.Controls>
                  <WorkspaceSeatControlRuleDropdown data={item} workspaceId={workspaceId} />
                </Card.Top.Controls>
              </Card.Top>
              <Card.Field.Group>
                <Card.Field label={t('common.status')}>
                  <WorkspaceSeatControlRuleIsActive data={item} workspaceId={workspaceId} />
                </Card.Field>
              </Card.Field.Group>
            </Card>
          ))}
        </Card.Group>
      )}
    </Page>
  );
};

export default WorkspaceSeatControlRulesPage;
