import { useTranslation } from 'react-i18next';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import PageCreateButton from '../../components/PageCreateButton';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceSeatControlRuleCreateButton = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();
  const { navigate } = useURL();

  const handleClick = () => navigate(routes.workspaceSeatConrolRulesCreate({ workspaceId }));

  return <PageCreateButton onClick={handleClick}>{t('common.rule')}</PageCreateButton>;
};

export default WorkspaceSeatControlRuleCreateButton;
