import { DealSchema } from '../../api/schemas/deal/dealSchema';
import Tag from '../../components/Tag';
import { PAYMENT_SYSTEM_LABELS } from '../../features/global-deal/helpers';

export const renderTag = (value?: string) => {
  if (!value) return '—';

  return (
    <Tag.Group>
      <Tag>{value}</Tag>
    </Tag.Group>
  );
};

export const renderClubName = (data: DealSchema) => renderTag(data.club.name);
export const renderClubCode = (data: DealSchema) => renderTag(data.club.code);
export const renderClubLeague = (data: DealSchema) => renderTag(data.club.league?.code);

export const renderClubSuperLeague = (data: DealSchema) =>
  renderTag(data.club.league?.super_league?.code);

export const renderPaymentSystems = (data: DealSchema) => {
  const { payment_system } = data;

  if (payment_system.length) {
    return (
      <Tag.Group
        tooltip={(index) =>
          payment_system
            .slice(index)
            .map((item) => PAYMENT_SYSTEM_LABELS[item])
            .join(', ')
        }
      >
        {payment_system.map((item) => (
          <Tag key={item}>{PAYMENT_SYSTEM_LABELS[item]}</Tag>
        ))}
      </Tag.Group>
    );
  }

  return '—';
};

export const renderWorkspaces = (data: DealSchema) => {
  const { workspaces } = data;

  if (workspaces.length) {
    return (
      <Tag.Group
        tooltip={(index) =>
          workspaces
            .slice(index)
            .map((item) => item.name)
            .join(', ')
        }
      >
        {workspaces.map((item) => (
          <Tag key={item.id}>{item.name}</Tag>
        ))}
      </Tag.Group>
    );
  }

  return '—';
};
