import { ReactNode } from 'react';
import { HistorySchema } from '../../api/schemas/historySchema';
import usePaginatedDataNew from '../../hooks/usePaginatedDataNew';
import Dialog from '../Dialog';
import Empty from '../Empty';
import SearchInput from '../SearchInput';
import Spinner from '../Spinner';
import { Enums } from './helpers';
import HistoryDialogCollapse from './HistoryDialogCollapse';
import HistoryDialogFilter from './HistoryDialogFilter';
import styles from './styles.module.scss';

interface Props {
  query: ReturnType<typeof usePaginatedDataNew<HistorySchema>>;
  title: string;
  search: string;
  onSearch: (value: string) => void;
  onClose: () => void;
  filter?: ReactNode;
  enums?: Enums;
}

const HistoryDialog = (props: Props) => {
  const { query, title, search, onSearch, onClose, filter, enums } = props;

  const { data, loading, loadingMore, checkIsInView } = query;

  const getContent = () => {
    if (loading) {
      return (
        <div className={styles.state}>
          <Spinner />
        </div>
      );
    }

    if (!data.length) {
      return (
        <div className={styles.state}>
          <Empty />
        </div>
      );
    }

    return (
      <div className={styles.list}>
        {data.map((item, index) => (
          <HistoryDialogCollapse
            key={item.id}
            data={item}
            enums={enums}
            {...checkIsInView(index)}
          />
        ))}
      </div>
    );
  };

  return (
    <Dialog className={styles.dialog} onClose={onClose}>
      {({ scrolled, onScroll }) => (
        <>
          <Dialog.Top className={styles.top} scrolled={scrolled}>
            <Dialog.Title>{title}</Dialog.Title>
            <div className={styles.filters}>
              <SearchInput size="small" value={search} onChange={onSearch} />
              {filter}
            </div>
          </Dialog.Top>
          <div className={styles.content} onScroll={onScroll}>
            {getContent()}
            {loadingMore && (
              <div className={styles.spinner}>
                <Spinner />
              </div>
            )}
          </div>
        </>
      )}
    </Dialog>
  );
};

HistoryDialog.Filter = HistoryDialogFilter;

export default HistoryDialog;
