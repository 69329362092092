import { ReactNode } from 'react';
import PageAddonControlsGroupAside from './PageAddonControlsGroupAside';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
}

const PageAddonControlsGroup = (props: Props) => {
  const { children } = props;

  return <div className={styles.container}>{children}</div>;
};

PageAddonControlsGroup.Aside = PageAddonControlsGroupAside;

export default PageAddonControlsGroup;
