import { DealSchema } from '../../api/schemas/deal/dealSchema';
import PrettyNumber from '../../components/PrettyNumber';
import { convertCentsToDollars } from '../../utils/data';

interface Props {
  data: DealSchema;
}

const GlobalDealBalanceUSD = (props: Props) => {
  const { data } = props;

  const { balance_usd } = data;

  if (!balance_usd) return '—';

  return <PrettyNumber>{convertCentsToDollars(balance_usd)}</PrettyNumber>;
};

export default GlobalDealBalanceUSD;
