import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { WorkspaceAccountSchema } from '../../../api/schemas/account/workspaceAccountSchema';
import Form from '../../../components/Form';
import PageAddon from '../../../components/PageAddon';
import SubmitButton from '../../../components/SubmitButton';
import useWorkspace from '../../../hooks/useWorkspace';
import { WorkspaceAccountFields as Fields } from '../../account/types';
import { getDefaultValues, getFields, getPayload } from './helpers';

interface Props {
  data?: WorkspaceAccountSchema;
  onClose: () => void;
  onSubmit: (args: { payload: ReturnType<typeof getPayload> }) => void;
}

const WorkspaceAccountForm = (props: Props) => {
  const { data, onClose, onSubmit } = props;

  const { t } = useTranslation();
  const workspace = useWorkspace();

  const form = useForm<Fields>({ defaultValues: getDefaultValues({ data }) });

  const fields = getFields({ form, workspace });

  return (
    <PageAddon
      title={data ? `${t('common.edit')} ${data.login}` : t('common.create_account')}
      onClose={onClose}
    >
      <Form form={form} onSubmit={(values) => onSubmit({ payload: getPayload({ values }) })}>
        {fields.login}
        {!data && fields.password}
        {!data && fields.room}
        {fields.club_ids}
        {fields.game_types}
        {fields.comparative_limits}
        {fields.table_sizes}
        {fields.max_tables}
        {fields.geo}
        {fields.channel_ids}
        {fields.email}
        {fields.email_password}
        {fields.tag_ids}
        {fields.affiliate_reference_number}
        {fields.user_id}
        {fields.identity_id}
        {fields.bot_settings_ids}
        {fields.deal_ids}
        <PageAddon.Controls>
          <SubmitButton>{data ? t('common.save') : t('common.create')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default WorkspaceAccountForm;
