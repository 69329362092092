import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createWorkspaceToken, deleteWorkspaceToken, updateWorkspaceToken } from '../../../api';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import { WorkspaceTokenSchema } from '../../../api/schemas/workspaceTokenSchema';
import Button from '../../../components/Button';
import Confirm from '../../../components/Confirm';
import Input from '../../../components/Input';
import Tooltip from '../../../components/Tooltip';
import icon from '../../../static/icons';
import { copyHeader } from '../../../utils/clipboard';
import { required, handleError } from '../../../utils/form';
import WorkspaceSettingsField from '../WorkspaceSettingsField';

interface Fields {
  name: string;
}

interface Props {
  workspaceId: WorkspaceSchema['id'];
  data?: WorkspaceTokenSchema;
  onRemove?: () => void;
}

const WorkspaceSettingsTokensItem = (props: Props) => {
  const { workspaceId, data, onRemove } = props;

  const { t } = useTranslation();

  const { control, handleSubmit, setError } = useForm<Fields>({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: { name: data?.name || '' },
  });

  const onSubmit = async (values: Fields) => {
    try {
      const payload = { name: values.name };

      if (data) {
        updateWorkspaceToken({ workspaceId, tokenId: data.id, payload });
      } else {
        await createWorkspaceToken({ workspaceId, payload });

        if (onRemove) onRemove();
      }
    } catch (error) {
      handleError({ error, setError });
    }
  };

  return (
    <WorkspaceSettingsField>
      <Input.Quick
        hint
        name="name"
        control={control}
        rules={{ validate: { required } }}
        autoFocus={!data}
        onSubmit={handleSubmit(onSubmit)}
      />
      {data && (
        <Tooltip label={t('common.copy')}>
          <Button
            size="small"
            variant="transparent"
            icon={icon('copy', 16)}
            onClick={() => copyHeader(data.id)}
          />
        </Tooltip>
      )}
      <Tooltip label={t('common.delete')}>
        <Confirm
          danger
          confirmText={t('common.delete')}
          onConfirm={async () => {
            if (data) await deleteWorkspaceToken({ workspaceId, tokenId: data.id });

            if (onRemove) onRemove();
          }}
        >
          {({ confirming }) => (
            <Button
              danger
              size="small"
              variant="transparent"
              focused={confirming}
              icon={icon('trash', 16)}
            />
          )}
        </Confirm>
      </Tooltip>
    </WorkspaceSettingsField>
  );
};

export default WorkspaceSettingsTokensItem;
