/* eslint-disable no-shadow */

import { QueryKey, InfiniteData, QueryClient, QueryCache } from '@tanstack/react-query';
import { isAxiosError, AxiosError } from 'axios';
import { ZodError } from 'zod';
import { LobbySchema } from '../api/schemas/lobbySchema';
import { PaginationSchema } from '../api/schemas/paginationSchema';
import i18n from '../i18n';
import Id from '../types/Id';
import { handleError } from './form';
import notify from './notify';

declare module '@tanstack/react-query' {
  interface Register {
    defaultError: AxiosError;
    queryMeta: { ignoredStatuses?: number[] };
  }
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      gcTime: 0,
      refetchOnWindowFocus: false,
    },
    mutations: {
      onSuccess: () => notify('success', { title: String(i18n.t('common.action_completed')) }),
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (error instanceof ZodError) {
        // eslint-disable-next-line no-console
        console.error(error.message);
      } else if (isAxiosError(error)) {
        // eslint-disable-next-line no-console
        console.error(error.message);
      } else {
        // eslint-disable-next-line no-console
        console.error(error);
      }

      handleError({ error, ignoredStatuses: query?.meta?.ignoredStatuses });
    },
  }),
});

export const createPaginatedQueryData = <T>(queryKey: QueryKey, data: T) =>
  queryClient.setQueriesData<PaginationSchema<T>>({ queryKey }, (prevData) => {
    if (prevData) {
      return {
        ...prevData,
        items: [...prevData.items, data],
      };
    }

    return prevData;
  });

export const updatePaginatedQueryData = <T>(
  queryKey: QueryKey,
  data: T,
  callback: (data: T) => boolean
) =>
  queryClient.setQueriesData<PaginationSchema<T>>({ queryKey }, (prevData) => {
    if (prevData) {
      return {
        ...prevData,
        items: prevData.items.map((item) => (callback(item) ? { ...item, ...data } : item)),
      };
    }

    return prevData;
  });

export const deletePaginatedQueryData = <T>(queryKey: QueryKey, callback: (data: T) => boolean) =>
  queryClient.setQueriesData<PaginationSchema<T>>({ queryKey }, (prevData) => {
    if (prevData) {
      return {
        ...prevData,
        items: prevData.items.filter((item) => !callback(item)),
      };
    }

    return prevData;
  });

export const updateDetailQueryData = <T>(queryKey: QueryKey, data: T) =>
  queryClient.setQueriesData<T>({ queryKey }, (prevData) => {
    if (prevData) return { ...prevData, ...data };

    return prevData;
  });

export const mutateInfinitePaginatedQueryData = <T>(
  queryKey: QueryKey,
  callback: (prevData: T) => T
) => {
  queryClient.setQueriesData<InfiniteData<PaginationSchema<T>>>({ queryKey }, (prevData) => {
    if (prevData) {
      const pages = prevData.pages.map((item) => ({
        ...item,
        items: item.items.map(callback),
      }));

      return {
        ...prevData,
        pages,
      };
    }

    return prevData;
  });
};

export const mutateDetailQueryData = <T>(queryKey: QueryKey, callback: (prevData: T) => T) =>
  queryClient.setQueriesData<T>({ queryKey }, (prevData) => {
    if (prevData) return callback(prevData);

    return prevData;
  });

export const createQueryData = <T>(queryKey: QueryKey, data: T) =>
  queryClient.setQueriesData<T[]>({ queryKey }, (prevData) => {
    if (prevData) return [...prevData, data];

    return prevData;
  });

export const updateQueryData = <T>(queryKey: QueryKey, data: T, callback: (data: T) => boolean) =>
  queryClient.setQueriesData<T[]>({ queryKey }, (prevData) => {
    if (prevData) return prevData.map((item) => (callback(item) ? { ...item, ...data } : item));

    return prevData;
  });

export const mutateQueryData = <T>(queryKey: QueryKey, callback: (prevData: T) => T) =>
  queryClient.setQueriesData<T[]>({ queryKey }, (prevData) => {
    if (prevData) return prevData.map(callback);

    return prevData;
  });

export const deleteQueryData = <T>(queryKey: QueryKey, callback: (data: T) => boolean) =>
  queryClient.setQueriesData<T[]>({ queryKey }, (prevData) => {
    if (prevData) return prevData.filter((item) => !callback(item));

    return prevData;
  });

export const createInfinitePaginatedQueryData = <T>(
  queryKey: QueryKey,
  data: T,
  config: { total?: boolean; append?: boolean } = {}
) => {
  const { total, append } = config;

  queryClient.setQueriesData<InfiniteData<PaginationSchema<T>>>({ queryKey }, (prevData) => {
    if (prevData) {
      const pages = prevData.pages.map((item, index) => {
        let { items } = item;

        if (append) items = [...items, data];
        if (!append) items = [data, ...items];

        return {
          ...item,
          items: index === 0 ? items : item.items,
          ...(total && { total: item.total + 1 }),
        };
      });

      return { ...prevData, pages };
    }

    return prevData;
  });
};

export const updateInfinitePaginatedQueryData = <T>(
  queryKey: QueryKey,
  data: T,
  callback: (data: T) => boolean
) =>
  queryClient.setQueriesData<InfiniteData<PaginationSchema<T>>>({ queryKey }, (prevData) => {
    if (prevData) {
      const pages = prevData.pages.map((item) => ({
        ...item,
        items: item.items.map((el) => (callback(el) ? { ...el, ...data } : el)),
      }));

      return { ...prevData, pages };
    }

    return prevData;
  });

export const deleteInfinitePaginatedQueryData = <T extends Id>(
  queryKey: QueryKey,
  callback: (data: T) => boolean,
  total?: boolean
) =>
  queryClient.setQueriesData<InfiniteData<PaginationSchema<T>>>({ queryKey }, (prevData) => {
    if (prevData) {
      const pages = prevData.pages.map((item) => ({
        ...item,
        items: item.items.filter((el) => !callback(el)),
        ...(total && { total: item.total - 1 }),
      }));

      return { ...prevData, pages };
    }

    return prevData;
  });

export const updateLobbyData = (queryKey: QueryKey, data: LobbySchema) => {
  queryClient.setQueriesData<LobbySchema[]>({ queryKey }, (prevData) => {
    if (prevData) return prevData.map((item) => (item.table.id === data.table.id ? data : item));

    return prevData;
  });
};

export const resetQuery = (queryKey: QueryKey) => queryClient.setQueryData(queryKey, undefined);
