import clsx from 'clsx';
import { useRef, useState } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Input from '../Input';
import { Props as InputProps } from '../types';
import styles from './styles.module.scss';

type Props<T extends FieldValues> = UseControllerProps<T> &
  InputProps & {
    onSubmit: () => void;
    hint?: boolean;
  };

const InputQuick = <T extends FieldValues>(props: Props<T>) => {
  const { name, control, rules, disabled, onSubmit, className, hint, ...rest } = props;

  const {
    field,
    fieldState: { isDirty, error },
  } = useController({ name, control, rules });

  const { t } = useTranslation();
  const [focused, setFocused] = useState(false);

  const esc = useRef(false);
  const initialValue = useRef<string>(field.value);

  const size = 'small';

  if (disabled) return <div className={styles.value}>{field.value}</div>;

  return (
    <Input
      data-quick
      size={size}
      placeholder={t('common.enter')}
      className={clsx(styles.input, error && styles.error, className)}
      onFocus={() => setFocused(true)}
      onClick={(event) => {
        if (!focused) {
          const { length } = event.currentTarget.value;

          event.currentTarget.setSelectionRange(length, length);

          setFocused(true);
        }
      }}
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          field.onChange(initialValue.current);

          esc.current = true;
          event.currentTarget.blur();
        }

        if (event.key === 'Enter') {
          event.preventDefault();
          event.currentTarget.blur();
        }
      }}
      {...field}
      onBlur={(event) => {
        if (esc.current) {
          esc.current = false;
        } else if (isDirty) {
          initialValue.current = event.currentTarget.value;

          onSubmit();
        } else {
          field.onChange(initialValue.current);
        }

        field.onBlur();
        setFocused(false);
      }}
      {...(hint &&
        focused &&
        field.value !== '' && { extra: <div className={styles.enter}>{t('common.enter')}</div> })}
      {...(error && { error: String(error.message) })}
      errorVariant="popover"
      {...rest}
    />
  );
};

export default InputQuick;
