import styles from './styles.module.scss';

interface Props {
  label: string;
  color: string;
}

const ColorTag = (props: Props) => {
  const { label, color } = props;

  return (
    <div className={styles.container}>
      <div style={{ backgroundColor: color }} />
      {label}
    </div>
  );
};

export default ColorTag;
