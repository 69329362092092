import { QueryKeys } from './types';

const queryKeys: QueryKeys = {
  currentUser: 'currentUser',
  permissions: 'permissions',

  notifications: 'notifications',
  notificationsFilterValues: 'notifications-filter-values',
  notificationsCount: 'notifications-count',
  notificationsSettings: 'notifications-settings',
  notificationsSetting: ({ workspaceId }) => `notifications-setting-${workspaceId}`,

  workspaces: 'workspaces',
  workspace: ({ workspaceId }) => `workspace-${workspaceId}`,
  workspaceCounters: ({ workspaceId }) => `workspace-${workspaceId}-counters`,
  workspaceBalance: ({ workspaceId }) => `workspace-${workspaceId}-balance`,
  workspaceAddress: ({ workspaceId }) => `workspace-${workspaceId}-address`,
  workspaceStrategyProfile: ({ workspaceId }) => `workspace-${workspaceId}-strategy-profile`,

  workspaceDashboardAccounts: ({ workspaceId }) => `workspace-${workspaceId}-dashboard-accounts`,
  workspaceDashboardAccountsTotal: ({ workspaceId }) =>
    `workspace-${workspaceId}-dashboard-accounts/total`,
  workspaceDashboardAccountsCount: ({ workspaceId }) =>
    `workspace-${workspaceId}-dashboard-accounts/count`,
  workspaceDashboardChannelsCount: ({ workspaceId }) =>
    `workspace-${workspaceId}-dashboard-channels/count`,
  workspaceDashboardIdentitiesCount: ({ workspaceId }) =>
    `workspace-${workspaceId}-dashboard-identities/count`,

  workspaceUsers: ({ workspaceId }) => `workspace-${workspaceId}-users`,
  workspaceUser: ({ workspaceId, userId }) => `workspace-${workspaceId}-user-${userId}`,

  workspaceAccounts: ({ workspaceId }) => `workspace-${workspaceId}-accounts`,
  workspaceAccountsFilterValues: ({ workspaceId }) =>
    `workspace-${workspaceId}-accounts-filter-values`,
  workspaceAccountsBuyCountryList: ({ workspaceId }) =>
    `workspace-${workspaceId}-accounts-buy-country-list`,
  workspaceAccount: ({ workspaceId, accountId }) => `workspace-${workspaceId}-account-${accountId}`,
  workspaceAccountHistory: ({ workspaceId, accountId }) =>
    `workspace-${workspaceId}-account-${accountId}-history`,
  workspaceAccountHistoryFilterValues: ({ workspaceId, accountId }) =>
    `workspace-${workspaceId}-account-${accountId}-history-filter-values`,
  workspaceAccountNotes: ({ workspaceId, accountId }) =>
    `workspace-${workspaceId}-account-${accountId}-notes`,
  workspaceAccountStrategyProfile: ({ workspaceId, accountId }) =>
    `workspace-${workspaceId}-account-${accountId}-strategy-profile`,
  workspaceAccountRoomConfig: ({ workspaceId, accountId }) =>
    `workspace-${workspaceId}-account-${accountId}-room-config`,
  workspaceAccountBalance: ({ workspaceId, accountId }) =>
    `workspace-${workspaceId}-account-${accountId}-balance`,
  workspaceAccountTransactions: ({ workspaceId, accountId }) =>
    `workspace-${workspaceId}-account-${accountId}-transactions`,
  workspaceAccountVerificationCode: ({ workspaceId, accountId }) =>
    `workspace-${workspaceId}-account-${accountId}-verification-code`,

  workspaceAccountSchedule: ({ workspaceId, accountId }) =>
    `workspace-${workspaceId}-account-${accountId}-schedule`,
  workspaceAccountScheduleSession: ({ workspaceId, accountId, sessionId }) =>
    `workspace-${workspaceId}-account-${accountId}-schedule-session-${sessionId}`,

  workspaceAccountTables: ({ workspaceId, accountId }) =>
    `workspace-${workspaceId}-account-tables-${accountId}`,
  workspaceAccountTablesSearch: ({ workspaceId, accountId }) =>
    `workspace-${workspaceId}-account-tables-search-${accountId}`,

  workspaceAccountsStages: ({ workspaceId }) => `workspace-${workspaceId}-accounts-stages`,

  workspaceSessions: ({ workspaceId }) => `workspace-${workspaceId}-sessions`,
  workspaceSessionsFilterValues: ({ workspaceId }) =>
    `workspace-${workspaceId}-sessions-filter-values`,
  workspaceSession: ({ workspaceId, sessionId }) =>
    `/workspace/${workspaceId}/session/${sessionId}`,

  workspaceChannels: ({ workspaceId }) => `workspace-${workspaceId}-channels`,
  workspaceChannelsFilterValues: ({ workspaceId }) =>
    `workspace-${workspaceId}-channels-filter-values`,
  workspaceChannel: ({ workspaceId, channelId }) => `workspace-${workspaceId}-channel-${channelId}`,

  workspaceClubManagers: ({ workspaceId }) => `workspace-${workspaceId}-club-managers`,
  workspaceClubManagersFilterValues: ({ workspaceId }) =>
    `workspace-${workspaceId}-club-managers-filter-values`,
  workspaceClubManager: ({ workspaceId, managerId }) =>
    `workspace-${workspaceId}-club-manager-${managerId}`,

  workspaceClubMembers: ({ workspaceId }) => `workspace-${workspaceId}-club-members`,
  workspaceClubMembersFilterValues: ({ workspaceId }) =>
    `workspace-${workspaceId}-club-members-filter-values`,
  workspaceClubMember: ({ workspaceId, memberId }) =>
    `workspace-${workspaceId}-club-member-${memberId}`,

  workspaceClubWhitelist: ({ workspaceId }) => `workspace-${workspaceId}-club-whitelist`,
  workspaceClubWhitelistMember: ({ workspaceId, whitelistMemberId }) =>
    `workspace-${workspaceId}-club-whitelist-${whitelistMemberId}`,

  workspaceClubTransactions: ({ workspaceId }) => `workspace-${workspaceId}-club-transactions`,
  workspaceClubTransactionsFilterValues: ({ workspaceId }) =>
    `workspace-${workspaceId}-club-transactions-filter-values`,
  workspaceClubTransaction: ({ workspaceId, transactionId }) =>
    `workspace-${workspaceId}-club-transaction-${transactionId}`,

  workspaceIdentities: ({ workspaceId }) => `workspace-${workspaceId}-identities`,
  workspaceIdentitiesFilterValues: ({ workspaceId }) =>
    `workspace-${workspaceId}-identities-filter-values`,
  workspaceIdentity: ({ workspaceId, identityId }) =>
    `workspace-${workspaceId}-identity-${identityId}`,

  workspaceMarketIdentities: ({ workspaceId }) => `workspace-${workspaceId}-market-identities`,
  workspaceMarketIdentitiesFilterValues: ({ workspaceId }) =>
    `workspace-${workspaceId}-market-identities-filter-values`,
  workspaceMarketIdentity: ({ workspaceId, identityId }) =>
    `workspace-${workspaceId}-market-identity-${identityId}`,

  workspaceOrders: ({ workspaceId }) => `workspace-${workspaceId}-orders`,
  workspaceOrdersFilterValues: ({ workspaceId }) => `workspace-${workspaceId}-orders-filter-values`,
  workspaceOrder: ({ workspaceId, orderId }) => `workspace-${workspaceId}-order-${orderId}`,
  workspaceOrderTransactions: ({ workspaceId, orderId }) =>
    `workspace-${workspaceId}-order-${orderId}-transactions`,

  workspaceSeatControlRules: ({ workspaceId }) => `workspace-${workspaceId}-seat-control-rules`,
  workspaceSeatControlRule: ({ workspaceId, seatControlRuleId }) =>
    `workspace-${workspaceId}-seat-control-rule-${seatControlRuleId}`,

  workspaceWallets: ({ workspaceId }) => `workspace-${workspaceId}-wallets`,
  workspaceWallet: ({ workspaceId, walletId }) => `workspace-${workspaceId}-wallet-${walletId}`,

  workspaceTokens: ({ workspaceId }) => `workspace-${workspaceId}-tokens`,
  workspaceToken: ({ workspaceId, tokenId }) => `workspace-${workspaceId}-token-${tokenId}`,

  workspaceTags: ({ workspaceId }) => `workspace-${workspaceId}-tags`,
  workspaceTag: ({ workspaceId, tagId }) => `workspace-${workspaceId}-tag-${tagId}`,

  globalWorkspaces: 'global-workspaces',
  globalWorkspacesFilterValues: 'global-workspaces-filter-values',
  globalWorkspace: ({ workspaceId }) => `global-workspace-${workspaceId}`,
  globalWorkspaceBalance: ({ workspaceId }) => `global-workspace-${workspaceId}-balance`,
  globalWorkspaceAddress: ({ workspaceId }) => `global-workspace-${workspaceId}-address`,
  globalWorkspaceUsers: ({ workspaceId }) => `global-workspace-${workspaceId}-users`,
  globalWorkspaceClubs: ({ workspaceId }) => `global-workspace-${workspaceId}-clubs`,

  orders: 'orders',
  ordersFilterValues: 'orders-filter-values',
  order: ({ orderId }) => `order-${orderId}`,
  orderTransactions: ({ orderId }) => `order-${orderId}-transactions`,

  deals: 'deals',
  dealsFilterValues: 'deals-filter-values',
  deal: ({ dealId }) => `deal-${dealId}`,
  dealNotes: ({ dealId }) => `deal-${dealId}-notes`,

  agents: 'agents',
  agent: ({ agentId }) => `agent-${agentId}`,
  agentNotes: ({ agentId }) => `agent-${agentId}-notes`,

  seatControlRules: 'seat-control-rules',
  seatControlRulesFilterValues: 'seat-control-rules-filter-values',
  seatControlRule: ({ seatControlRuleId }) => `seat-control-rule-${seatControlRuleId}`,

  users: 'users',
  usersFilterValues: 'users-filter-values',
  user: ({ userId }) => `user-${userId}`,

  roles: 'roles',
  role: ({ roleId }) => `role-${roleId}`,

  accounts: 'accounts',
  accountsFilterValues: 'accounts-filter-values',
  account: ({ accountId }) => `account-${accountId}`,
  accountStrategyProfile: ({ accountId }) => `account-${accountId}-strategy-profile`,
  accountFingerprint: ({ accountId }) => `account-${accountId}-fingerprint`,
  accountRoomConfig: ({ accountId }) => `account-${accountId}-room-config`,
  accountBalance: ({ accountId }) => `account-${accountId}-balance`,
  accountTransactions: ({ accountId }) => `account-${accountId}-transactions`,

  accountSchedule: ({ accountId }) => `account-${accountId}-schedule`,
  accountScheduleSession: ({ accountId, sessionId }) =>
    `account-${accountId}-schedule-session-${sessionId}`,

  sessions: 'sessions',
  sessionsFilterValues: 'sessions-filter-values',
  session: ({ sessionId }) => `session-${sessionId}`,

  accountTables: ({ accountId }) => `account-tables-${accountId}`,
  accountTablesSearch: ({ accountId }) => `account-tables-search-${accountId}`,

  lobby: 'lobby',
  lobbyTable: ({ tableId }) => `lobby-${tableId}`,
  lobbyTablePlayers: ({ tableId }) => `lobby-${tableId}-players`,

  botSettings: `bot-settings`,
  botSettingsFilterValues: `bot-settings-filter-values`,
  botSetting: ({ botSettingId }) => `bot-setting-${botSettingId}`,

  botSettingsRules: `bot-settings-rules`,
  botSettingsRulesFilterValues: `bot-settings-rules-filter-values`,
  botSettingRule: ({ botSettingRuleId }) => `bot-setting-rule-${botSettingRuleId}`,

  clubs: 'clubs',
  clubsFilterValues: 'clubs-filter-values',
  club: ({ clubId }) => `club-${clubId}`,
  clubNotes: ({ clubId }) => `club-${clubId}-notes`,
  clubWorkspaces: ({ clubId }) => `club-${clubId}-workspaces`,
  clubAnnouncements: ({ clubId }) => `club-${clubId}-announcements`,

  leagues: 'leagues',
  league: ({ leagueId }) => `league-${leagueId}`,

  superLeagues: 'super-leagues',
  superLeague: ({ superLeagueId }) => `super-league-${superLeagueId}`,
};

export default queryKeys;
