import { useTranslation } from 'react-i18next';
import PageAddon from '../../../components/PageAddon';
import Tag from '../../../components/Tag';
import Tooltip from '../../../components/Tooltip';
import icon from '../../../static/icons';
import { TRAINER_GAME_TYPE_LABELS } from '../../../utils/trainer';
import { CommonAccountSchema } from '../types';
import styles from './styles.module.scss';

const checkIcon = icon('check', 16, { className: styles.icon });
const crossIcon = icon('cross', 16, { className: styles.icon });

interface Props {
  data: CommonAccountSchema;
}

const AccountBotSettings = (props: Props) => {
  const { data } = props;

  const { t } = useTranslation();

  const { bot_settings } = data;

  if (!bot_settings.length) return <>—</>;

  return (
    <PageAddon.Tags>
      {bot_settings.map((item) => {
        const {
          game_type,
          bot_code,
          bot_build,
          bot_build_straddle,
          bot_code_tight,
          bot_build_tight,
          params_size,
          params_size_hu,
        } = item;

        return (
          <Tooltip
            key={item.id}
            label={
              <Tooltip.FieldGroup>
                <Tooltip.Field label={t('common.game_type')}>
                  {TRAINER_GAME_TYPE_LABELS[game_type]}
                </Tooltip.Field>
                <Tooltip.Field label={t('common.code')}>{bot_code}</Tooltip.Field>
                <Tooltip.Field label={t('common.build')}>{bot_build}</Tooltip.Field>
                {bot_build_straddle && (
                  <Tooltip.Field label={t('common.straddle')}>{bot_build_straddle}</Tooltip.Field>
                )}
                {bot_code_tight && (
                  <Tooltip.Field label={t('common.code_tight')}>{bot_code_tight}</Tooltip.Field>
                )}
                {bot_build_tight && (
                  <Tooltip.Field label={t('common.build_tight')}>{bot_build_tight}</Tooltip.Field>
                )}
                <Tooltip.Field label={t('common.params')}>
                  {params_size ? checkIcon : crossIcon}
                </Tooltip.Field>
                <Tooltip.Field label={t('common.params_hu')}>
                  {params_size_hu ? checkIcon : crossIcon}
                </Tooltip.Field>
              </Tooltip.FieldGroup>
            }
          >
            <Tag>{item.name}</Tag>
          </Tooltip>
        );
      })}
    </PageAddon.Tags>
  );
};

export default AccountBotSettings;
