import { useTranslation } from 'react-i18next';
import { DealSchema } from '../../api/schemas/deal/dealSchema';
import PageDetailLink from '../../components/PageDetailLink';
import Tooltip from '../../components/Tooltip';
import routes from '../../config/routes';
import icon from '../../static/icons';

interface Props {
  data: DealSchema;
}

const GlobalDealDetailLink = (props: Props) => {
  const { data } = props;

  const { id, code, notes_count } = data;

  const { t } = useTranslation();

  return (
    <PageDetailLink
      label={code}
      to={routes.deal({ dealId: id })}
      extra={
        <>
          {Boolean(notes_count) && <Tooltip label={t('common.notes')}>{icon('notes', 12)}</Tooltip>}
        </>
      }
    />
  );
};

export default GlobalDealDetailLink;
