import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import seatControlRuleSchema, { SeatControlRuleSchema } from '../schemas/seatControlRuleSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  seatControlRuleId: SeatControlRuleSchema['id'];
}

const loadWorkspaceSeatControlRule = async (args: Args) => {
  const { workspaceId, seatControlRuleId } = args;

  const response = await api.get(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/seat-control/${seatControlRuleId}`
  );

  return seatControlRuleSchema.parse(response.data);
};

export default loadWorkspaceSeatControlRule;
