import { useTranslation } from 'react-i18next';
import { AccountStrategyProfileSchema } from '../../api/schemas/accountStrategyProfileSchema';
import PageAddon from '../../components/PageAddon';
import Tooltip from '../../components/Tooltip';
import { PLAYER_TYPE_LABELS, SEATING_PREFERENCE_LABELS } from './helpers';

const Label = (props: { tooltip: string; children: string }) => {
  const { tooltip, children } = props;

  return (
    <Tooltip label={tooltip}>
      <div>{children}</div>
    </Tooltip>
  );
};

interface Props {
  loading: boolean;
  data?: AccountStrategyProfileSchema;
}

const AccountStrategyProfile = (props: Props) => {
  const { loading, data } = props;

  const { t } = useTranslation();

  return (
    <>
      <PageAddon.Collapse.Field
        loading={loading}
        label={
          <Label tooltip={t('sentences.strategy_profile_player_type')}>
            {t('common.player_type')}
          </Label>
        }
      >
        {data && PLAYER_TYPE_LABELS[data.player_type]}
      </PageAddon.Collapse.Field>
      <PageAddon.Collapse.Field
        loading={loading}
        label={
          <Label tooltip={t('sentences.strategy_profile_buyin_range_bb')}>
            {t('common.buyin_range_bb')}
          </Label>
        }
      >
        {`${data?.buyin_bb_min} — ${data?.buyin_bb_max}`}
      </PageAddon.Collapse.Field>
      <PageAddon.Collapse.Field
        loading={loading}
        label={
          <Label tooltip={t('sentences.strategy_profile_buyin_step_bb')}>
            {t('common.buyin_step_bb')}
          </Label>
        }
      >
        {data?.buyin_step_bb}
      </PageAddon.Collapse.Field>
      <PageAddon.Collapse.Field
        loading={loading}
        label={
          <Label tooltip={t('sentences.strategy_profile_straddle_tables_buyin_range_bb')}>
            {t('common.straddle_tables_buyin_range_bb')}
          </Label>
        }
      >
        {`${data?.buyin_bb_straddle_table_min} — ${data?.buyin_bb_straddle_table_max}`}
      </PageAddon.Collapse.Field>
      <PageAddon.Collapse.Field
        loading={loading}
        label={
          <Label tooltip={t('sentences.strategy_profile_straddle_tables_buyin_step_bb')}>
            {t('common.straddle_tables_buyin_step_bb')}
          </Label>
        }
      >
        {data?.buyin_step_bb_straddle_table}
      </PageAddon.Collapse.Field>
      <PageAddon.Collapse.Field
        loading={loading}
        label={
          <Label tooltip={t('sentences.strategy_profile_rebuy_threshold_bb')}>
            {t('common.rebuy_threshold_bb')}
          </Label>
        }
      >
        {data?.rebuy_below_bb}
      </PageAddon.Collapse.Field>
      <PageAddon.Collapse.Field
        loading={loading}
        label={
          <Label tooltip={t('sentences.strategy_profile_straddle_tables_rebuy_threshold_bb')}>
            {t('common.straddle_tables_rebuy_threshold_bb')}
          </Label>
        }
      >
        {data?.rebuy_below_bb_straddle_table}
      </PageAddon.Collapse.Field>
      <PageAddon.Collapse.Field
        loading={loading}
        label={
          <Label tooltip={t('sentences.strategy_profile_stop_lose_bb')}>
            {t('common.stop_loss_bb')}
          </Label>
        }
      >
        {data?.stop_lose_bb}
      </PageAddon.Collapse.Field>
      <PageAddon.Collapse.Field
        loading={loading}
        label={
          <Label tooltip={t('sentences.strategy_profile_stop_win_bb')}>
            {t('common.stop_win_bb')}
          </Label>
        }
      >
        {data?.stop_win_bb}
      </PageAddon.Collapse.Field>
      <PageAddon.Collapse.Field
        loading={loading}
        label={
          <Label tooltip={t('sentences.strategy_profile_stop_lose_table_bb')}>
            {t('common.stop_loss_by_table_bb')}
          </Label>
        }
      >
        {data?.stop_lose_table_bb}
      </PageAddon.Collapse.Field>
      <PageAddon.Collapse.Field
        loading={loading}
        label={
          <Label tooltip={t('sentences.strategy_profile_stop_win_table_bb')}>
            {t('common.stop_win_by_table_bb')}
          </Label>
        }
      >
        {data?.stop_win_table_bb}
      </PageAddon.Collapse.Field>
      <PageAddon.Collapse.Field
        loading={loading}
        label={
          <Label tooltip={t('sentences.strategy_profile_max_swing_bb')}>
            {t('common.max_swing_bb')}
          </Label>
        }
      >
        {data?.max_swing_bb}
      </PageAddon.Collapse.Field>
      <PageAddon.Collapse.Field
        loading={loading}
        label={
          <Label tooltip={t('sentences.strategy_profile_effective_stack_limit_bb')}>
            {t('common.effective_stack_limit_bb')}
          </Label>
        }
      >
        {data?.effective_stack_limit_bb}
      </PageAddon.Collapse.Field>
      <PageAddon.Collapse.Field
        loading={loading}
        label={
          <Label tooltip={t('sentences.strategy_profile_max_stack_size_bb')}>
            {t('common.max_stack_size_bb')}
          </Label>
        }
      >
        {data?.max_stack_size_bb}
      </PageAddon.Collapse.Field>
      <PageAddon.Collapse.Field
        loading={loading}
        label={
          <Label tooltip={t('sentences.strategy_profile_table_min_hands')}>
            {t('common.table_min_hands')}
          </Label>
        }
      >
        {data?.table_min_hands}
      </PageAddon.Collapse.Field>
      <PageAddon.Collapse.Field
        loading={loading}
        label={
          <Label tooltip={t('sentences.strategy_profile_wait_for_big_blind')}>
            {t('common.wait_for_big_blind')}
          </Label>
        }
      >
        {data?.wait_for_big_blind ? t('common.true') : t('common.false')}
      </PageAddon.Collapse.Field>
      <PageAddon.Collapse.Field
        loading={loading}
        label={
          <Label tooltip={t('sentences.strategy_profile_seating_preference')}>
            {t('common.seating_preference')}
          </Label>
        }
      >
        {data && SEATING_PREFERENCE_LABELS[data.seating_preference]}
      </PageAddon.Collapse.Field>
    </>
  );
};

export default AccountStrategyProfile;
