import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en } from './static/locales';

const LANGUAGES = {
  EN: 'en-US',
};

const resources = {
  [LANGUAGES.EN]: {
    translation: en,
  },
} as const;

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: LANGUAGES.EN,
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
  },
});

/* declare module 'i18next' {
  interface CustomTypeOptions {
    resources: (typeof resources)['en'];
  }
} */

declare module 'react-i18next' {
  interface CustomTypeOptions {
    resources: (typeof resources)['en'];
  }
}

export { LANGUAGES };

export default i18n;
