import { useTranslation } from 'react-i18next';
import { AccountRoomConfigSchema } from '../../../api/schemas/accountRoomConfigSchema';
import { TRAINER_POKER_ROOM } from '../../../api/schemas/trainerPokerRoomSchema';
import Button from '../../../components/Button';
import PageAddon from '../../../components/PageAddon';
import icon from '../../../static/icons';
import { can } from '../../permission/helpers';
import { CommonAccountSchema } from '../types';

interface Props {
  data: AccountRoomConfigSchema;
  account: CommonAccountSchema;
  onEdit?: () => void;
  workspace?: boolean;
}

const AccountRoomConfig = (props: Props) => {
  const { data, account, workspace, onEdit } = props;

  const { t } = useTranslation();

  const { room } = account;

  const getEdit = () => {
    if (!onEdit) return false;
    if (workspace) return true;

    return can.account.roomConfigCreateUpdate;
  };

  const renderField = (value: unknown) => {
    if (value === '') return '—';

    return `${value}`;
  };

  return (
    <>
      {room === TRAINER_POKER_ROOM.PARTYPOKER && (
        <>
          <PageAddon.Collapse.Field label={t('common.table_shape')}>
            {renderField(data?.TableShape)}
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field label={t('common.felt_color')}>
            {renderField(data?.FeltColor)}
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field label={t('common.background')}>
            {renderField(data?.Background)}
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field label={t('common.deck_type')}>
            {renderField(data?.DeckType)}
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field label={t('common.card_back')}>
            {renderField(data?.CardBack)}
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field label={t('common.default_avatar')}>
            {data?.IsDefaultAvatar ? t('common.true') : t('common.false')}
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field label={t('sentences.auto_buy_in_rebuy_cash_games')}>
            {data?.AutoBuyinCashGamesRebuy ? t('common.true') : t('common.false')}
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field label={t('sentences.muck_losing_uncalled_hands')}>
            {data?.MuckLosingUncalledHands ? t('common.true') : t('common.false')}
          </PageAddon.Collapse.Field>
        </>
      )}
      {room === TRAINER_POKER_ROOM.PPPOKER && (
        <>
          <PageAddon.Collapse.Field label={t('common.ev_chop_%')}>
            {renderField(data?.ev_chop_percent)}
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field label={t('common.rim_%')}>
            {renderField(data?.rim_percent)}
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field label={t('common.ppsr')}>
            {data?.ppsr ? t('common.true') : t('common.false')}
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field label={t('common.autoplay')}>
            {data?.autoplay ? t('common.true') : t('common.false')}
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field label={t('common.show_cards')}>
            {data?.show_cards ? t('common.true') : t('common.false')}
          </PageAddon.Collapse.Field>
        </>
      )}
      {room === TRAINER_POKER_ROOM.POKERRRR2 && (
        <>
          <PageAddon.Collapse.Field label={t('common.rit_%')}>
            {renderField(data?.rit_percent)}
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field label={t('common.dead_blind')}>
            {data?.dead_blind ? t('common.true') : t('common.false')}
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field label={t('common.balance_check')}>
            {data?.balance_check ? t('common.true') : t('common.false')}
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field label={t('common.auto_muck')}>
            {data?.auto_muck ? t('common.true') : t('common.false')}
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field label={t('common.timebank')}>
            {data?.timebank ? t('common.true') : t('common.false')}
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field label={t('common.standart_stack_seat')}>
            {data?.is_standard_stack_seat ? t('common.true') : t('common.false')}
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field label={t('common.auto_rebuy')}>
            {data?.auto_rebuy ? t('common.true') : t('common.false')}
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field label={t('common.tournament_addon')}>
            {data?.tournament_addon ? t('common.true') : t('common.false')}
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field label={t('common.max_tournament_rebuy')}>
            {renderField(data?.max_tournament_rebuy)}
          </PageAddon.Collapse.Field>
        </>
      )}
      {room === TRAINER_POKER_ROOM.RULER && (
        <>
          <PageAddon.Collapse.Field label={t('common.nickname')}>
            {renderField(data?.nickname)}
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field label={t('common.balance_check')}>
            {data?.balance_check ? t('common.true') : t('common.false')}
          </PageAddon.Collapse.Field>
        </>
      )}
      {room === TRAINER_POKER_ROOM.CLUBGG && (
        <>
          <PageAddon.Collapse.Field label={t('common.autoplay')}>
            {data?.autoplay ? t('common.true') : t('common.false')}
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field label={t('common.show_cards')}>
            {data?.show_cards ? t('common.true') : t('common.false')}
          </PageAddon.Collapse.Field>
        </>
      )}
      {getEdit() && (
        <Button size="small" variant="secondary" icon={icon('edit', 16)} onClick={onEdit}>
          {t('common.edit')}
        </Button>
      )}
    </>
  );
};

export default AccountRoomConfig;
