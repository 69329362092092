import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { OBJECT } from '../../../api/schemas/account/accountTransactionSchema';
import routes from '../../../config/routes';
import { RouteParams } from '../../../utils/generics';

type Params =
  | RouteParams<typeof routes.accountTransactions>
  | RouteParams<typeof routes.workspaceAccountTransactions>;

const useAccountTransactionsFilter = () => {
  const [search, setSearch] = useState('');

  const { type, id } = useParams<Params>();

  return {
    search,
    urlParams: {
      ...(type === 'table' && { trx_object_eq: String(OBJECT.TABLE), table_id: id }),
      ...(type === 'club' && { trx_object_eq: String(OBJECT.CLUB), club_id: id }),
      ...(type === 'league' && { trx_object_eq: String(OBJECT.LEAGUE), league_id: id }),
      ...(type === 'lobby' && { trx_object_eq: String(OBJECT.LOBBY) }),
      search,
    },
    setSearch,
  };
};

export default useAccountTransactionsFilter;
