import i18n from '../../../i18n';
import { CommonAccountBalanceSchema, CommonAccountTransactionBalanceSchema } from '../types';
import styles from './styles.module.scss';
import { RenderButton } from './types';

const escapeId = (id: string) => new URLSearchParams({ id }).toString().replace('id=', '');

export const getBalanceParams = (data: CommonAccountTransactionBalanceSchema) => {
  const { league_id, club_id, table_id } = data;

  if (table_id) return { type: 'table' as const, id: escapeId(table_id) };
  if (club_id) return { type: 'club' as const, id: escapeId(club_id) };
  if (league_id) return { type: 'league' as const, id: escapeId(league_id) };

  return { type: 'lobby' as const };
};

export const getBalanceRecords = (
  data: CommonAccountBalanceSchema,
  renderButton?: RenderButton
) => {
  const { lobby_balance, league_balance, club_balance, in_game_balance } = data;

  const divider = <span className={styles.divider}>•</span>;

  const lobby = lobby_balance
    ? [
        {
          title: <span className={styles.mainWord}>{String(i18n.t('common.lobby'))}</span>,
          amount: lobby_balance.amount,
          currency: lobby_balance.currency,
          ...(renderButton && {
            renderButton: renderButton({
              amount: lobby_balance.amount,
              currency: lobby_balance.currency,
              league_id: null,
              league_name: null,
              club_id: null,
              club_name: null,
              table_id: null,
            }),
          }),
        },
      ]
    : [];

  const leagues = league_balance.map((item) => ({
    title: (
      <>
        {item.league_name && (
          <>
            <span className={styles.mainWord}>{item.league_name}</span>
            {divider}
          </>
        )}
        {item.league_id}
        {divider}
        {i18n.t('common.league')}
      </>
    ),
    amount: item.amount,
    currency: item.currency,
    ...(renderButton && {
      renderButton: renderButton({
        amount: item.amount,
        currency: item.currency,
        league_id: item.league_id,
        league_name: item.league_name,
        club_id: null,
        club_name: null,
        table_id: null,
      }),
    }),
  }));

  const clubs = club_balance.map((item) => ({
    title: (
      <>
        {item.club_name && (
          <>
            <span className={styles.mainWord}>{item.club_name}</span>
            {divider}
          </>
        )}
        {item.club_id}
        {divider}
        {i18n.t('common.club')}
      </>
    ),
    amount: item.amount,
    currency: item.currency,
    ...(renderButton && {
      renderButton: renderButton({
        amount: item.amount,
        currency: item.currency,
        league_id: item.league_id,
        league_name: item.league_name,
        club_id: item.club_id,
        club_name: item.club_name,
        table_id: null,
      }),
    }),
  }));

  const tables = in_game_balance.map((item) => ({
    title: (
      <>
        <span className={styles.mainWord}>{item.table_id}</span>
        {item.club_name && (
          <>
            {divider}
            {item.club_name}
          </>
        )}
        {divider}
        {i18n.t('common.table')}
      </>
    ),
    amount: item.amount,
    currency: item.currency,
    ...(renderButton && {
      renderButton: renderButton({
        amount: item.amount,
        currency: item.currency,
        league_id: item.league_id,
        league_name: item.league_name,
        club_id: item.club_id,
        club_name: item.club_name,
        table_id: item.table_id,
      }),
    }),
  }));

  return [...lobby, ...leagues, ...clubs, ...tables];
};
