import api from '../../services/api';
import globalClubSchema, { GlobalClubSchema } from '../schemas/globalClubSchema';

type Schema = GlobalClubSchema;

interface Payload {
  name: Schema['name'];
  code: Schema['code'];
  poker_room: Schema['poker_room'];
  league_id: Exclude<Schema['league'], null>['id'] | null;
  extra: Schema['extra'];
}

interface Args {
  payload: Payload;
}

const createGlobalClub = async (args: Args) => {
  const { payload } = args;

  const response = await api.post('/api/reference/club', payload);

  return globalClubSchema.pick({ id: true }).parse(response.data);
};

export default createGlobalClub;
