import {
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  autoUpdate,
  offset,
  flip,
  arrow,
  Placement,
  FloatingOverlay,
  FloatingTree,
  useFloatingParentNodeId,
  useFloatingNodeId,
  FloatingNode,
  Strategy,
  FloatingPortal,
  FloatingArrow,
  FloatingArrowProps,
} from '@floating-ui/react';
import clsx from 'clsx';
import { useState, cloneElement, useRef, ReactNode, ReactElement, useEffect } from 'react';
import useBreakpoints from '../../hooks/useBreakpoints';
import usePrevious from '../../hooks/usePrevious';
import i18n from '../../i18n';
import icon from '../../static/icons';
import { copyId } from '../../utils/clipboard';
import { cutUUID } from '../../utils/data';
import Button, { Props as ButtonProps } from '../Button';
import SheetTop from '../SheetTop';
import { DropdownProvider } from './DropdownContext';
import DropdownOption from './DropdownOption';
import styles from './styles.module.scss';
import { Presets, Options, Children, ConfirmIndex, CloseDropdown } from './types';

export const presets: Presets = {
  edit: { icon: icon('edit', 20), label: String(i18n.t('common.edit')) },
  delete: {
    icon: icon('trash', 20),
    label: String(i18n.t('common.delete')),
    danger: true,
    confirm: true,
    confirmText: i18n.t('common.delete'),
  },
  restore: {
    icon: icon('rotate', 20),
    label: String(i18n.t('common.restore')),
    danger: true,
    confirm: true,
    confirmText: i18n.t('common.restore'),
  },
  copyId: (id) => ({
    icon: icon('hash', 20),
    label: String(i18n.t('common.copy_id')),
    caption: cutUUID(id),
    onClick: () => copyId(id),
  }),
  more: {
    close: false,
    extra: icon('chevronRight', 16, { className: styles.nav }),
  },
  back: (label?: string) => ({
    icon: icon('chevronLeft', 16, { className: styles.nav }),
    label: <span className={styles.boldLabel}>{label || String(i18n.t('common.back'))}</span>,
    close: false,
  }),
};

type RenderOptions = (args: { closeDropdown: CloseDropdown }) => ReactElement;

interface Props {
  options: Options | RenderOptions;
  title?: string;
  disabled?: boolean;
  addon?: ReactNode;
  buttonSize?: ButtonProps['size'];
  placement?: Placement;
  className?: string;
  arrowOffset?: FloatingArrowProps['staticOffset'];
  strategy?: Strategy;
  closeOnScroll?: boolean;
  onClose?: () => void;
  children?: Children;
}

// возможно можно использовать getItemProps чтобы закрывать дропдаун при клике на опцию

const DropdownComponent = (props: Props) => {
  const {
    title,
    options = [],
    disabled,
    addon,
    buttonSize = 'small',
    closeOnScroll,
    placement: customPlacement = 'bottom-end',
    strategy: customStrategy = 'fixed',
    className: customClassName,
    arrowOffset,
    onClose,
    children,
  } = props;

  const { isMobile } = useBreakpoints();

  const nodeId = useFloatingNodeId();
  const [open, setOpen] = useState(false);
  const [confirmIndex, setConfirmIndex] = useState<ConfirmIndex>(null);

  const closeDropdown = () => setOpen(false);

  const arrowRef = useRef(null);

  const { refs, strategy, context, floatingStyles } = useFloating({
    open,
    nodeId,
    strategy: customStrategy,
    onOpenChange: setOpen,
    ...(!isMobile && {
      placement: customPlacement,
      whileElementsMounted: autoUpdate,
      middleware: [offset(8), flip(), arrow({ element: arrowRef })],
    }),
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context, { outsidePressEvent: 'click', ancestorScroll: closeOnScroll }),
  ]);

  const prevOpen = usePrevious(open);

  useEffect(() => {
    if (open !== prevOpen && !open) {
      setConfirmIndex(null);

      if (onClose) {
        onClose();
      }
    }
  }, [open, prevOpen, onClose]);

  const referenceProps = { ref: refs.setReference, ...getReferenceProps() };

  const content = (
    <>
      {isMobile && (
        <SheetTop title={title} onCancel={closeDropdown}>
          {addon}
        </SheetTop>
      )}
      {!isMobile && addon}
      <DropdownProvider
        confirmIndex={confirmIndex}
        setConfirmIndex={setConfirmIndex}
        closeDropdown={closeDropdown}
      >
        <ul>
          {typeof options === 'function'
            ? options({ closeDropdown })
            : options.map((option, index) => (
                <DropdownOption key={index} index={index} {...option} />
              ))}
        </ul>
      </DropdownProvider>
    </>
  );

  const className = clsx(styles.container, customClassName, strategy);

  const renderContent = () => {
    if (open) {
      if (isMobile) {
        return (
          <FloatingOverlay lockScroll className={className}>
            <div
              data-options="true"
              className={styles.options}
              {...getFloatingProps({ ref: refs.setFloating })}
            >
              {content}
            </div>
          </FloatingOverlay>
        );
      }

      return (
        <div
          ref={refs.setFloating}
          className={className}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <FloatingArrow
            ref={arrowRef}
            context={context}
            tipRadius={1}
            staticOffset={arrowOffset}
            className={styles.arrow}
          />
          <div data-options="true" className={styles.options}>
            {content}
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      {children ? (
        cloneElement(children({ open, closeDropdown, referenceProps }), referenceProps)
      ) : (
        <Button
          size={buttonSize}
          variant="transparent"
          icon={icon('moreVertical', 16)}
          disabled={disabled}
          data-testid="dropdown"
          {...referenceProps}
        />
      )}
      <FloatingNode id={nodeId}>
        {strategy === 'fixed' ? (
          <FloatingPortal id="root">{renderContent()}</FloatingPortal>
        ) : (
          renderContent()
        )}
      </FloatingNode>
    </>
  );
};

const Dropdown = (props: Props) => {
  const parentId = useFloatingParentNodeId();

  if (parentId === null) {
    return (
      <FloatingTree>
        <DropdownComponent {...props} />
      </FloatingTree>
    );
  }

  return <DropdownComponent {...props} />;
};

Dropdown.Option = DropdownOption;

export default Dropdown;
