import { useTranslation } from 'react-i18next';
import { createWorkspaceAccount, updateWorkspaceAccount } from '../../../api';
import { WorkspaceAccountSchema } from '../../../api/schemas/account/workspaceAccountSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import notify from '../../../utils/notify';
import WorkspaceAccountForm from './WorkspaceAccountForm';

interface CommonProps {
  workspaceId: WorkspaceSchema['id'];
  onClose: () => void;
}

interface CreateProps {
  onCreate: (args: { accountId: WorkspaceAccountSchema['id'] }) => void;
  data?: never;
  onEdit?: never;
}

interface EditProps {
  data: WorkspaceAccountSchema;
  onEdit: () => void;
  onCreate?: never;
}

type Props = CommonProps & (CreateProps | EditProps);

const WorkspaceAccountDetailForm = (props: Props) => {
  const { workspaceId, data, onClose, onCreate, onEdit } = props;

  const { t } = useTranslation();

  return (
    <WorkspaceAccountForm
      data={data}
      onClose={onClose}
      onSubmit={async ({ payload }) => {
        const { room, password, ...rest } = payload;

        if (!room) throw new Error();

        if (data) {
          await updateWorkspaceAccount({ workspaceId, accountId: data.id, payload: rest });

          onEdit();
        } else {
          const response = await createWorkspaceAccount({
            workspaceId,
            payload: {
              ...rest,
              password,
              room: room.value,
            },
          });

          onCreate({ accountId: response.id });
        }

        notify('success', {
          title: data
            ? t('sentences.changes_have_been_saved')
            : t('sentences.record_has_been_created'),
        });
      }}
    />
  );
};

export default WorkspaceAccountDetailForm;
