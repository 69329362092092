import { z } from 'zod';

export const accountsPermissions = [
  'nous_platform_global_account_read',
  'nous_platform_global_account_create_update',
  'nous_platform_global_account_balance_read',
  'nous_platform_global_account_email_read',
  'nous_platform_global_account_fingerprint_read',
  'nous_platform_global_account_fingerprint_create_update',
  'nous_platform_global_account_fingerprint_delete',
  'nous_platform_global_account_room_customize_read',
  'nous_platform_global_account_room_customize_create_update',
  'nous_platform_global_account_switch_pilot_off',
] as const;

export const botSettingPermissions = [
  'nous_platform_global_bot_setting_read',
  'nous_platform_global_bot_setting_create_update',
  'nous_platform_global_bot_setting_delete',
  'nous_platform_global_bot_setting_usage_rule_read',
  'nous_platform_global_bot_setting_usage_rule_create_update',
  'nous_platform_global_bot_setting_usage_rule_delete',
] as const;

export const clubPermissions = [
  'nous_platform_club_read',
  'nous_platform_club_create_update',
  'nous_platform_club_delete',
] as const;

export const dealPermissions = [
  'nous_platform_global_deal_read',
  'nous_platform_global_deal_create_update',
  'nous_platform_global_deal_delete',
  'nous_platform_global_agent_read',
  'nous_platform_global_agent_create_update',
  'nous_platform_global_agent_delete',
] as const;

export const lobbyPermissions = [
  'nous_platform_lobby_read',
  'nous_platform_lobby_close_poker_room',
  'nous_platform_lobby_leave_table',
  'nous_platform_lobby_players_read',
  'nous_platform_lobby_reset_cache',
  'nous_platform_lobby_search_tables',
] as const;

export const ordersPermissions = ['nous_platform_global_order_read'] as const;

export const rolesPermissions = [
  'nous_platform_role_read',
  'nous_platform_role_create_update',
  'nous_platform_role_delete',
] as const;

export const seatControlRulesPermissions = [
  'nous_platform_seat_control_read',
  'nous_platform_seat_control_create_update',
  'nous_platform_seat_control_delete',
  'nous_platform_seat_control_restore_deleted',
] as const;

export const sessionsPermissions = [
  'nous_platform_session_read',
  'nous_platform_session_create_update',
  'nous_platform_session_delete',
] as const;

export const usersPermissions = [
  'nous_platform_user_read',
  'nous_platform_user_create_update',
] as const;

export const workspacesPermissions = [
  'nous_platform_global_maintenance_read',
  'nous_platform_global_maintenance_create_update',
  'nous_platform_global_maintenance_delete',
  'nous_platform_global_maintenance_address',
  'nous_platform_global_maintenance_balance',
] as const;

const permissionSchema = z.enum([
  ...accountsPermissions,
  ...botSettingPermissions,
  ...clubPermissions,
  ...dealPermissions,
  ...lobbyPermissions,
  ...ordersPermissions,
  ...rolesPermissions,
  ...seatControlRulesPermissions,
  ...sessionsPermissions,
  ...usersPermissions,
  ...workspacesPermissions,
]);

export type PermissionSchema = z.infer<typeof permissionSchema>;

export default permissionSchema;
