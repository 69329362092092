import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { updateWorkspaceAccountStrategyProfile } from '../../../../api';
import { WorkspaceAccountSchema } from '../../../../api/schemas/account/workspaceAccountSchema';
import { AccountStrategyProfileSchema } from '../../../../api/schemas/accountStrategyProfileSchema';
import { WorkspaceSchema } from '../../../../api/schemas/workspaceSchema';
import Form from '../../../../components/Form';
import PageAddon from '../../../../components/PageAddon';
import SubmitButton from '../../../../components/SubmitButton';
import notify from '../../../../utils/notify';
import { getDefaultValues, getFields, getPayload } from './helpers';
import { Fields } from './types';

interface Props {
  data: AccountStrategyProfileSchema;
  account: WorkspaceAccountSchema;
  workspaceId: WorkspaceSchema['id'];
  onClose: () => void;
}

const WorkspaceAccountStrategyProfileDetailForm = (props: Props) => {
  const { data, account, workspaceId, onClose } = props;

  const { t } = useTranslation();

  const form = useForm<Fields>({ defaultValues: getDefaultValues(data) });

  const fields = getFields(form);

  return (
    <PageAddon onClose={onClose} title={`${t('common.strategy_profile_for')} ${account.login}`}>
      <Form
        form={form}
        onSubmit={async (values) => {
          await updateWorkspaceAccountStrategyProfile({
            workspaceId,
            accountId: account.id,
            payload: getPayload({ values }),
          });

          onClose();
          notify('success', { title: t('sentences.changes_have_been_saved') });
        }}
      >
        {fields.player_type}
        {fields.buyin_bb_min}
        {fields.buyin_step_bb}
        {fields.buyin_bb_straddle_table_min}
        {fields.buyin_step_bb_straddle_table}
        {fields.rebuy_below_bb}
        {fields.rebuy_below_bb_straddle_table}
        {fields.stop_lose_bb}
        {fields.stop_win_bb}
        {fields.stop_lose_table_bb}
        {fields.stop_win_table_bb}
        {fields.max_swing_bb}
        {fields.effective_stack_limit_bb}
        {fields.max_stack_size_bb}
        {fields.table_min_hands}
        {fields.wait_for_big_blind}
        {fields.seating_preference}
        <PageAddon.Controls>
          <SubmitButton>{t('common.save')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default WorkspaceAccountStrategyProfileDetailForm;
