import { QuerySchema, RuleSchema } from '../../../api/schemas/querySchema';
import i18n from '../../../i18n';
import { convertCentsToDollars } from '../../../utils/data';
import {
  fields,
  operators,
  checkIsInOperator,
  gameTypeIdOptions,
  checkIsFloatField,
  statusOptions,
  blindTypeOptions,
  straddleTypeOptions,
  runItTwiceTypeOptions,
} from '../helpers';
import styles from './styles.module.scss';

const getValue = (rule: RuleSchema) => {
  const isFloatField = checkIsFloatField(rule.field);
  const isInOperator = checkIsInOperator(rule.operator);

  if (isFloatField) {
    if (isInOperator && Array.isArray(rule.value)) {
      if (rule.value.every((item) => typeof item === 'number')) {
        return rule.value.map((item) => convertCentsToDollars(Number(item))).join(', ');
      }

      return rule.value.join(', ');
    }

    return convertCentsToDollars(Number(rule.value));
  }

  if (rule.field === 'ante') return String(convertCentsToDollars(Number(rule.value)));

  if (
    rule.field === 'game_type_id' ||
    rule.field === 'Status' ||
    rule.field === 'BlindType' ||
    rule.field === 'StraddleType' ||
    rule.field === 'RunItTwiceType'
  ) {
    const getOptions = () => {
      if (rule.field === 'game_type_id') return gameTypeIdOptions;
      if (rule.field === 'Status') return statusOptions;
      if (rule.field === 'BlindType') return blindTypeOptions;
      if (rule.field === 'StraddleType') return straddleTypeOptions;
      if (rule.field === 'RunItTwiceType') return runItTwiceTypeOptions;

      return [];
    };

    const options = getOptions();

    if (isInOperator && Array.isArray(rule.value)) {
      return rule.value
        .map((item) => options.find((el) => el.value === String(item))?.label)
        .join(', ');
    }

    return options.find((el) => el.value === String(rule.value))?.label;
  }

  if (isInOperator && Array.isArray(rule.value)) return rule.value.join(', ');

  if (rule.value === true) return i18n.t('common.true');
  if (rule.value === false) return i18n.t('common.false');

  return rule.value;
};

const getQuery = (query: QuerySchema | RuleSchema, index = 0) => {
  if ('rules' in query) {
    return (
      <div key={`${query.combinator}-${index}`} className={styles.group}>
        <div className={styles.groupLabel}>{query.combinator}</div>
        {query.rules.map(getQuery)}
      </div>
    );
  }

  return (
    <div key={`${query.field}-${index}`} className={styles.rule}>
      <div className={styles.field}>{fields.find((item) => item.name === query.field)?.label}</div>
      <div className={styles.operator}>
        {operators.find((item) => item.name === query.operator)?.icon}
      </div>
      <div className={styles.value}>{getValue(query)}</div>
    </div>
  );
};

interface Props {
  query: QuerySchema;
}

const QueryBuilderPreview = (props: Props) => {
  const { query } = props;

  return <div className={styles.container}>{getQuery(query)}</div>;
};

export default QueryBuilderPreview;
