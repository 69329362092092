import { ReactNode } from 'react';
import { toast } from 'react-toastify';
import i18n from '../i18n';
import icon from '../static/icons';

type Type = 'success' | 'warning' | 'info' | 'error';

interface CommonConfig {
  title: ReactNode;
  description?: string;
}

interface DefaultConfig {
  large?: never;
  subTitle?: never;
}

interface LargeConfig {
  large: true;
  subTitle?: ReactNode;
}

type Config = CommonConfig & (DefaultConfig | LargeConfig);

export const getMessage = (type: Type, config: Config) => {
  const { large = false, title, subTitle, description } = config;

  return (
    <>
      {!large && <span data-toastify-subtitle>{String(i18n.t(`common.${type}`))}</span>}
      {subTitle && <span data-toastify-large-subtitle>{subTitle}</span>}
      <div>
        <span data-toastify-title>{title}</span>
        {description && <span data-toastify-description>{description}</span>}
      </div>
    </>
  );
};

const notify = (type: Type, config: Config) => {
  const { large = false } = config;

  const message = getMessage(type, config);

  if (type === 'success') {
    toast.success(message, {
      icon: icon('checkCircle', 20),
      ...(large && { className: 'Toastify__toast--large' }),
    });
  }

  if (type === 'warning') {
    toast.warning(message, {
      icon: icon('alertTriangle', 20),
      ...(large && { className: 'Toastify__toast--large' }),
    });
  }

  if (type === 'info') {
    toast.info(message, {
      icon: icon('alertCircle', 20),
      ...(large && { className: 'Toastify__toast--large' }),
    });
  }

  if (type === 'error') {
    toast.error(message, {
      icon: icon('xCircle', 20),
      ...(large && { className: 'Toastify__toast--large' }),
    });
  }
};

export default notify;
