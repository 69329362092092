import api from '../../services/api';
import dealSchema, { DealSchema } from '../schemas/deal/dealSchema';

interface Args {
  dealId: DealSchema['id'];
}

const loadGlobalDeal = async (args: Args) => {
  const { dealId } = args;

  const response = await api.get(`/api/deal/${dealId}`);

  return dealSchema.parse(response.data);
};

export default loadGlobalDeal;
