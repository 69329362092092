import icon from '../../../../static/icons';
import { copy } from '../../../../utils/clipboard';
import Button from '../../../Button';
import styles from './styles.module.scss';

interface Props {
  label: string;
  value: string | number | boolean;
  nestingLevel: number;
}

const PageAddonCodeRow = (props: Props) => {
  const { label, value, nestingLevel } = props;

  return (
    <div style={{ columnGap: `${nestingLevel * 8}px` }} className={styles.container}>
      <div className={styles.index}>
        <Button
          variant="light"
          icon={icon('copy', 12)}
          className={styles.copyButton}
          onClick={() => copy(`${label}: ${JSON.stringify(value)}`, label)}
        />
      </div>
      <div className={styles.info}>
        {nestingLevel > 1 && <div className={styles.dot} />}
        <div>
          <span>{`${label}: `}</span>
          <span className={styles.value}>{typeof value === 'boolean' ? String(value) : value}</span>
        </div>
      </div>
    </div>
  );
};

export default PageAddonCodeRow;
