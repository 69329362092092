import { useQuery } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { loadWorkspaceSeatControlRule } from '../../api';
import { SeatControlRuleSchema } from '../../api/schemas/seatControlRuleSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import PageAddon from '../../components/PageAddon';
import queryKeys from '../../config/queryKeys';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import { updateDetailQueryData } from '../../utils/queryClient';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  seatControlRuleId: SeatControlRuleSchema['id'];
  children: (args: {
    data: SeatControlRuleSchema;
    seatControlRuleId: SeatControlRuleSchema['id'];
  }) => ReactElement;
}

const WorkspaceSeatControlRuleLoader = (props: Props) => {
  const { workspaceId, seatControlRuleId, children } = props;

  const queryKey = [queryKeys.workspaceSeatControlRule({ workspaceId, seatControlRuleId })];
  const queryFn = () => loadWorkspaceSeatControlRule({ workspaceId, seatControlRuleId });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403] },
  });

  useWebSocketMessage<SeatControlRuleSchema>({
    domain: 'Rule',
    onMessage: (message) => {
      if (
        (message.action === 'Edited' ||
          message.action === 'Activated' ||
          message.action === 'Deactivated' ||
          message.action === 'Deleted') &&
        message.payload.id === seatControlRuleId
      ) {
        updateDetailQueryData(queryKey, message.payload);
      }
    },
  });

  if (isPending) return <PageAddon.DetailPlaceholder />;
  if (!data) return null;

  return children({ data, seatControlRuleId });
};

export default WorkspaceSeatControlRuleLoader;
