import { useTranslation } from 'react-i18next';
import PrettyNumber from '../../../components/PrettyNumber';
import Stub from '../../../components/Stub';
import { convertCentsToDollars } from '../../../utils/data';
import { CommonAccountBalanceSchema } from '../types';
import { getBalanceRecords } from './helpers';
import styles from './styles.module.scss';
import { RenderButton } from './types';

interface Props {
  data: CommonAccountBalanceSchema;
  renderButton?: RenderButton;
}

const AccountBalance = (props: Props) => {
  const { data, renderButton } = props;

  const { t } = useTranslation();

  const balanceRecords = getBalanceRecords(data, renderButton);

  if (!balanceRecords.length) return <Stub title={t('common.balance_is_unknown')} />;

  return (
    <div className={styles.container}>
      {balanceRecords.map((item, index) => (
        <div key={index} className={styles.widget}>
          <div className={styles.content}>
            <div className={styles.title}>{item.title}</div>
            <div className={styles.info}>
              <span className={styles.amount}>
                <PrettyNumber>{convertCentsToDollars(item.amount)}</PrettyNumber>
              </span>
              <span className={styles.currency}>{item.currency}</span>
            </div>
          </div>
          {item.renderButton}
        </div>
      ))}
    </div>
  );
};

export default AccountBalance;
