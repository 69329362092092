import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import useBreakpoints from '../../../hooks/useBreakpoints';
import useTimeZone from '../../../hooks/useTimeZone';
import icon from '../../../static/icons';
import { useSidebarStore } from '../../../store/sidebar';
import Button from '../../Button';
import Dropdown from '../../Dropdown';
import Link from '../../Link';
import User from '../../User';
import { Props as PageProps } from '../types';
import PageHeaderNotifications from './PageHeaderNotifications';
import styles from './styles.module.scss';

const PageHeaderTime = () => {
  const { t } = useTranslation();
  const { time, options } = useTimeZone();

  return (
    <Dropdown
      title={t('common.time_zone')}
      addon={
        <div className={styles.addon}>
          <span>{t('sentences.time_zone_settings_affect_all_dates')}</span>
        </div>
      }
      options={options}
      className={styles.dropdown}
    >
      {({ open }) => (
        <Button focused={open} size="small" variant="outline">
          {icon('clock', 16)}
          {time}
        </Button>
      )}
    </Dropdown>
  );
};

interface Props {
  heading: PageProps['heading'];
  tabs: PageProps['tabs'];
  widget: PageProps['widget'];
  scrolled: boolean;
  hasAddon: boolean;
}

const PageHeader = (props: Props) => {
  const { heading, tabs, widget, scrolled, hasAddon } = props;

  const { open, toggle } = useSidebarStore((state) => ({ open: state.open, toggle: state.toggle }));
  const { isPhone, isTablet, isDesktop } = useBreakpoints();

  const className = clsx(styles.container, {
    [styles.withTabs]: tabs,
    [styles.withShadow]: scrolled && hasAddon,
  });

  return (
    <div className={className}>
      <div className={clsx(styles.wrapper, scrolled && !hasAddon && styles.withShadow)}>
        <h1 className={styles.heading}>{heading}</h1>
        {tabs && (
          <div className={clsx(styles.tabs, hasAddon && styles.withMargin)}>
            {tabs.map((item) => (
              <Link
                key={item.to}
                to={item.to}
                params={false}
                className={clsx(styles.tab, item.active && styles.active, item.dot && styles.dot)}
              >
                {item.label}
                {item.active && (
                  <motion.div layoutId="tabIndicator" className={styles.tabIndicator} />
                )}
              </Link>
            ))}
          </div>
        )}
      </div>
      <div className={styles.user}>
        <PageHeaderTime />
        {widget}
        {isDesktop && <PageHeaderNotifications />}
        <User />
      </div>
      <div className={styles.controls}>
        {widget}
        {(isTablet || isPhone) && <PageHeaderNotifications />}
        <Button
          size="small"
          variant="transparent"
          icon={open ? icon('burgerClose', 24) : icon('burgerMenu', 24)}
          onClick={toggle}
        />
      </div>
    </div>
  );
};

export default PageHeader;
