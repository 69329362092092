export const isObject = (value: unknown) =>
  typeof value === 'object' && !Array.isArray(value) && value !== null;

export const addWhen = <T>(value: T, conditional: boolean) => (conditional ? [value] : []);

export const compact = <T extends T[number][]>(array: T) =>
  array.flatMap((item) => (item ? [item] : []));

export const omit = <T extends Record<string, unknown>, K extends keyof T>(
  object: T,
  keys: K[]
): Omit<T, K> => {
  keys.forEach((key) => delete object[key]);

  return object;
};
