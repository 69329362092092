import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { AgentSchema } from '../../api/schemas/agentSchema';
import { DealSchema } from '../../api/schemas/deal/dealSchema';
import { GlobalClubSchema } from '../../api/schemas/globalClubSchema';
import Button from '../../components/Button';
import Notes from '../../components/Notes';
import PageAddon from '../../components/PageAddon';
import Tooltip from '../../components/Tooltip';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import icon from '../../static/icons';
import { can } from '../permission/helpers';
import GlobalDealDetailAccountsTab from './GlobalDealDetailAccountsTab';
import GlobalDealDetailDropdown from './GlobalDealDetailDropdown';
import GlobalDealDetailGeneralTab from './GlobalDealDetailGeneralTab';

interface Props {
  data: DealSchema;
  onClose: () => void;
  onEdit: () => void;
  getAgentRoute: (args: { agentId: AgentSchema['id'] }) => string;
  getClubRoute: (args: { clubId: GlobalClubSchema['id'] }) => string;
}

const GlobalDealDetail = (props: Props) => {
  const { data, onClose, onEdit, getAgentRoute, getClubRoute } = props;

  const { t } = useTranslation();
  const { navigate } = useURL();

  const { id, code, notes_count, is_deleted } = data;

  const titleIcons = (
    <>
      {is_deleted && icon('trash', 12)}
      {Boolean(notes_count) && <Tooltip label={t('common.notes')}>{icon('notes', 12)}</Tooltip>}
    </>
  );

  return (
    <PageAddon.Group>
      <Outlet />
      <PageAddon
        title={code}
        icons={titleIcons}
        onClose={onClose}
        tabs={[
          {
            label: t('common.general'),
            content: (
              <GlobalDealDetailGeneralTab
                data={data}
                getAgentRoute={getAgentRoute}
                getClubRoute={getClubRoute}
              />
            ),
          },
          {
            label: t('common.accounts'),
            content: <GlobalDealDetailAccountsTab data={data} />,
            count: data.accounts.length,
          },
        ]}
      >
        {!is_deleted && (
          <PageAddon.Controls>
            <PageAddon.Controls.Group>
              <Notes.Button
                count={notes_count}
                onClick={() => navigate(routes.dealNotes({ dealId: id }))}
              />
              <Tooltip label={can.deal.createUpdate ? t('common.edit') : t('common.no_permission')}>
                <div>
                  <Button
                    variant="transparent"
                    icon={icon('edit', 16)}
                    onClick={onEdit}
                    data-testid="edit"
                    disabled={!can.deal.createUpdate}
                  />
                </div>
              </Tooltip>
              <GlobalDealDetailDropdown data={data} />
            </PageAddon.Controls.Group>
          </PageAddon.Controls>
        )}
      </PageAddon>
    </PageAddon.Group>
  );
};

export default GlobalDealDetail;
