import { z } from 'zod';
import dateTimeSchema from '../dateTimeSchema';
import workspaceSchema from '../workspaceSchema';
import { stageCommonSchema } from '.';

export const COLUMNS = {
  UNKNOWN: 0,
  CREATED_ON: 1,
  MODIFIED_ON: 2,
  ROOM: 3,
  CLUB_NAME: 4,
  GAME_TYPES: 5,
  LIMITS: 6,
  TABLE_SIZES: 7,
  MAX_TABLES: 8,
  IS_ONLINE: 9,
  PID: 10,
  GEO: 11,
  CHANNELS: 12,
  HANDS_PLAYED: 13,
  NET_WIN_SUM: 14,
  WIN_RATE_BB: 15,
  TAGS: 16,
  BOT_SETTINGS: 17,
  IDENTITY: 18,
  AFFILIATE_REFERENCE_NUMBER: 19,
  LAST_ONLINE: 20,
  PILOT_STATUS: 21,
  LAST_PLAYED: 22,
  USER: 23,
  CLUB_CODE: 24,
  NEXT_SESSION: 25,
  IDENTITY_BANK_ACCOUNTS: 26,
  IDENTITY_DATE_OF_BIRTH: 27,
  IDENTITY_DOCUMENTS: 28,
  IDENTITY_FULL_NAME: 29,
  IDENTITY_GENDER: 30,
  IDENTITY_LOCATION: 31,
  IDENTITY_ONLINE_WALLETS: 32,
  IDENTITY_PHONES: 33,
  IDENTITY_PHOTOS: 34,
  IDENTITY_SOCIAL_NETWORKS: 35,
  TRAINER_VERSION: 36,
  NOTES: 37,
  BALANCE_CLUB: 38,
  BALANCE_LOBBY: 39,
  DISPLAY_NAME: 40,
  EMAIL: 41,
  VPIP: 42,
  DEALS: 43,
} as const;

const workspaceStageSchema = stageCommonSchema.extend({
  maintenance: workspaceSchema.pick({
    id: true,
    name: true,
    owner_id: true,
    maintenance_types: true,
    is_deleted: true,
  }),
  columns: z.array(z.nativeEnum(COLUMNS)),
  is_playable: z.boolean(),
  stop_current_session: z.boolean(),
  clear_channel: z.boolean(),
  clear_schedule: z.boolean(),
  default_sorting: z.object({ direction: z.enum(['asc', 'desc']), name: z.string() }),
  is_system: z.boolean(),
  is_deleted: z.boolean(),
  created_on: dateTimeSchema,
  modified_on: dateTimeSchema,
});

export type WorkspaceStageSchema = z.infer<typeof workspaceStageSchema>;

export default workspaceStageSchema;
