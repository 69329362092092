import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { deleteWorkspaceSeatControlRule } from '../../api';
import { SeatControlRuleSchema } from '../../api/schemas/seatControlRuleSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import { presets } from '../../components/Dropdown';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import { RouteParams } from '../../utils/generics';
import notify from '../../utils/notify';

type Params = RouteParams<typeof routes.workspaceSeatConrolRule>;

interface Args {
  data: SeatControlRuleSchema;
  workspaceId: WorkspaceSchema['id'];
}

const useDropdown = (args: Args) => {
  const { data, workspaceId } = args;

  const { id, name, is_deleted } = data;

  const { t } = useTranslation();
  const { navigate } = useURL();
  const { seatControlRuleId } = useParams<Params>();

  return {
    title: name,
    disabled: is_deleted,
    options: {
      edit: {
        ...presets.edit,
        onClick: () =>
          navigate(routes.workspaceSeatConrolRuleEdit({ workspaceId, seatControlRuleId: id })),
      },
      copy: presets.copyId(id),
      divider: { divider: true },
      delete: {
        ...presets.delete,
        onClick: async () => {
          await deleteWorkspaceSeatControlRule({ workspaceId, seatControlRuleId: id });

          if (id === seatControlRuleId) {
            navigate(routes.workspaceSeatConrolRules({ workspaceId }));
          }

          notify('success', { title: t('sentences.record_has_been_deleted') });
        },
      },
    },
  };
};

export default useDropdown;
