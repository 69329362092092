/* eslint-disable jsx-a11y/control-has-associated-label */

import { useTranslation } from 'react-i18next';
import icon from '../../../../static/icons';
import { copyAffilateReference } from '../../../../utils/clipboard';
import FadingText from '../../../FadingText';
import PageAddonField from '../PageAddonField';
import styles from './styles.module.scss';

interface Props {
  value: string | null;
}

const PageAddonFieldAffiliateReference = (props: Props) => {
  const { value } = props;

  const { t } = useTranslation();

  const handleClick = () => {
    if (value) copyAffilateReference(value);
  };

  if (!value) return null;

  return (
    <PageAddonField label={t('common.affiliate_reference')}>
      <button type="button" onClick={handleClick} className={styles.button}>
        <FadingText tooltip={false} text={value} extra={icon('copy', 16)} />
      </button>
    </PageAddonField>
  );
};

export default PageAddonFieldAffiliateReference;
