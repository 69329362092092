import { useQuery } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { loadGlobalAccountRoomConfig } from '../../../api';
import { GlobalAccountSchema } from '../../../api/schemas/account/globalAccountSchema';
import { AccountRoomConfigSchema } from '../../../api/schemas/accountRoomConfigSchema';
import Alert from '../../../components/Alert';
import PageAddon from '../../../components/PageAddon';
import Spinner from '../../../components/Spinner';
import queryKeys from '../../../config/queryKeys';
import styles from './styles.module.scss';

interface Props {
  accountId: GlobalAccountSchema['id'];
  children: (args: { data: AccountRoomConfigSchema }) => ReactElement;
  addon?: boolean;
}

const GlobalAccountRoomConfigLoader = (props: Props) => {
  const { accountId, addon, children } = props;

  const { t } = useTranslation();

  const queryKey = [queryKeys.accountRoomConfig({ accountId })];
  const queryFn = () => loadGlobalAccountRoomConfig({ accountId });

  const { data, isPending, isError, refetch } = useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403, 404] },
  });

  if (addon) {
    if (isPending) {
      return (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      );
    }

    if (isError) return <PageAddon.Collapse.Error onClick={() => refetch()} />;

    if (!data) return null;

    if (data && !Object.keys(data).length) {
      return <Alert type="info" title={t('common.no_config')} closable={false} />;
    }

    return children({ data });
  }

  if (isPending) return <PageAddon.DetailPlaceholder />;
  if (!data) return null;

  return children({ data });
};

export default GlobalAccountRoomConfigLoader;
