import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Dropdown from '../../components/Dropdown';
import icon from '../../static/icons';
import { getBalanceParams } from '../account/AccountBalance/helpers';
import { CommonAccountTransactionBalanceSchema, OnTransactionsOpen } from '../account/types';

interface Props {
  balance: CommonAccountTransactionBalanceSchema;
  onTransactionsOpen: OnTransactionsOpen;
}

const GlobalAccountDetailBalanceDropdown = (props: Props) => {
  const { balance, onTransactionsOpen } = props;

  const { t } = useTranslation();

  return (
    <Dropdown
      title={t('common.transaction')}
      options={[
        {
          label: t('common.transactions'),
          icon: icon('clockBack', 20),
          onClick: () => onTransactionsOpen(getBalanceParams(balance)),
        },
      ]}
    >
      {({ open }) => (
        <Button focused={open} size="small" variant="transparent" icon={icon('moreVertical', 16)} />
      )}
    </Dropdown>
  );
};

export default GlobalAccountDetailBalanceDropdown;
