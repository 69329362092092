import api from '../../services/api';
import { DealSchema } from '../schemas/deal/dealSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';

type Schema = DealSchema;

interface Args {
  dealId: Schema['id'];
}

const deleteGlobalDeal = async (args: Args) => {
  const { dealId } = args;

  const response = await api.delete(`/api/deal/${dealId}`);

  return emptyResponseSchema.parse(response.data);
};

export default deleteGlobalDeal;
