import { create } from 'zustand';
import { CurrentUserSchema } from '../api/schemas/currentUserSchema';
import { PermissionSchema } from '../api/schemas/permissionSchema';
import { WorkspaceSchema, WorkspaceTypes } from '../api/schemas/workspaceSchema';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '../utils/localStorage';

const getWorkspace = () => {
  const workspace = getLocalStorageItem('workspace');

  if (workspace) {
    try {
      return JSON.parse(workspace);
    } catch (error) {
      removeLocalStorageItem('workspace');

      return null;
    }
  }

  return null;
};

type Restricted = boolean;

interface Workspace {
  types: WorkspaceTypes;
}

type Workspaces = Record<string, Workspace> | null;

interface State {
  data: CurrentUserSchema | null;
  restricted: Restricted;
  permissions: PermissionSchema[];
  workspace: WorkspaceSchema | null;
  workspaces: Workspaces;
  setUser: (user: CurrentUserSchema) => void;
  removeUser: () => void;
  setRestricted: (restricted: Restricted) => void;
  setWorkspace: (payload: WorkspaceSchema) => void;
  setWorkspaceTypes: (workspaceTypes: { id: string; types: WorkspaceTypes }) => void;
  checkOwner: (workspaceId: WorkspaceSchema['id']) => boolean;
}

const useUserStore = create<State>()((set, get) => ({
  data: null,
  restricted: false,
  permissions: [],
  workspace: getWorkspace(),
  workspaces: null,
  setUser: (payload) => {
    const workspaces: Workspaces = {};
    const permissions = new Set(payload.roles.map((item) => item.permissions).flat());

    payload.maintenances.forEach((item) => {
      workspaces[item.id] = { types: item.maintenance_types };
    });

    set({ data: payload, permissions: Array.from(permissions), workspaces });
  },
  removeUser: () => {
    set({
      data: null,
      restricted: false,
      permissions: [],
      workspaces: null,
    });
  },
  setWorkspace: (payload) => {
    setLocalStorageItem('workspace', JSON.stringify(payload));

    set({ workspace: payload });
  },
  setRestricted: (restricted) => set({ restricted }),
  setWorkspaceTypes: (workspaceTypes) =>
    set((state) => {
      const { id, types } = workspaceTypes;

      if (state.workspaces && types) {
        let workspaces = Object.entries(state.workspaces);

        workspaces = workspaces.map((item) => {
          const [worksapceId, workspace] = item;

          if (worksapceId === id) return [worksapceId, { ...workspace, types }];

          return item;
        });

        return { workspaces: Object.fromEntries(workspaces) };
      }

      return state;
    }),
  checkOwner: (workspaceId) =>
    Boolean(get().data?.maintenances.find((item) => item.id === workspaceId)?.is_owner),
}));

export { useUserStore };
