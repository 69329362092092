import { useTranslation } from 'react-i18next';
import { WorkspaceAccountSchema } from '../../api/schemas/account/workspaceAccountSchema';
import PageAddon from '../../components/PageAddon';
import { getAccountMetrics, renderVpip } from './helpers';
import WorkspaceAccountNetWin from './WorkspaceAccountNetWin';

interface Props {
  data: WorkspaceAccountSchema;
}

const WorkspaceAccountMetrics = (props: Props) => {
  const { data } = props;

  const { metrics } = data;

  const { t } = useTranslation();

  const { hands_played, win_rate_bb } = getAccountMetrics(metrics);

  return (
    <>
      <PageAddon.Collapse.Field label={t('common.hands')}>{hands_played}</PageAddon.Collapse.Field>
      <PageAddon.Collapse.Field label={t('common.net_win')}>
        <WorkspaceAccountNetWin data={data} />
      </PageAddon.Collapse.Field>
      <PageAddon.Collapse.Field label={t('common.bb/100')}>{win_rate_bb}</PageAddon.Collapse.Field>
      <PageAddon.Collapse.Field label={t('common.vpip')}>
        {renderVpip(data)}
      </PageAddon.Collapse.Field>
    </>
  );
};

export default WorkspaceAccountMetrics;
