import clsx from 'clsx';
import { WorkspaceAccountSchema } from '../../../api/schemas/account/workspaceAccountSchema';
import PrettyNumber from '../../../components/PrettyNumber';
import icon from '../../../static/icons';
import { getAccountMetrics } from '../helpers';
import styles from './styles.module.scss';

interface Props {
  data: WorkspaceAccountSchema;
}

const WorkspaceAccountNetWin = (props: Props) => {
  const { data } = props;

  const { metrics } = data;

  const { net_win_sum } = getAccountMetrics(metrics);

  const getColor = () => {
    if (net_win_sum > 0) return styles.green;
    if (net_win_sum < 0) return styles.red;

    return null;
  };

  const getIcon = () => {
    if (net_win_sum > 0) return icon('triangleUp', 16);
    if (net_win_sum < 0) return icon('triangleDown', 16);

    return null;
  };

  return (
    <div className={clsx(styles.container, getColor())}>
      {getIcon()}
      <PrettyNumber>{Math.abs(net_win_sum)}</PrettyNumber>
    </div>
  );
};

export default WorkspaceAccountNetWin;
