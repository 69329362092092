import { z } from 'zod';
import LOCALSTORAGE from '../constants/LOCALSTORAGE';

type Key = (typeof LOCALSTORAGE)[keyof typeof LOCALSTORAGE];

export const getLocalStorageItem = (key: Key) => window.localStorage.getItem(key);

export const setLocalStorageItem = (key: Key, value: string) =>
  window.localStorage.setItem(key, value);

export const removeLocalStorageItem = (key: Key) => window.localStorage.removeItem(key);

export const getParsedLocalStorageItem = (key: Key) => {
  const value = getLocalStorageItem(key);

  if (!value) return null;

  if (key === 'column_sizes') {
    return z
      .object({
        'workspace-accounts': z
          .record(z.string(), z.record(z.string(), z.record(z.string(), z.number())))
          .optional(),
        'global-deal': z.record(z.string(), z.number()).optional(),
      })
      .parse(JSON.parse(value));
  }

  return null;
};
