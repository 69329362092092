import clsx from 'clsx';
import { ElementRef, forwardRef, ReactElement, ReactNode } from 'react';
import Tooltip from '../Tooltip';
import styles from './styles.module.scss';
import TagCaption from './TagCaption';
import TagCount from './TagCount';
import TagFadingText from './TagFadingText';
import TagGroup from './TagGroup';

interface Props {
  children: ReactNode;
  icon?: ReactElement;
  highlighted?: boolean;
  secondary?: boolean;
  danger?: boolean;
  caption?: ReactNode;
  count?: number;
  countTooltip?: ReactNode;
  countDanger?: boolean;
}

const Tag = Object.assign(
  forwardRef<ElementRef<'div'>, Props>((props, ref) => {
    const {
      children,
      icon,
      highlighted,
      secondary,
      danger,
      caption,
      count,
      countTooltip,
      countDanger,
    } = props;

    const className = clsx(styles.tag, {
      [styles.highlighted]: highlighted,
      [styles.secondary]: secondary,
      [styles.danger]: danger,
      [styles.caption]: caption,
    });

    const getCount = () => {
      if (!count || count <= 1) return null;

      return (
        <Tooltip label={countTooltip}>
          <TagCount danger={countDanger}>{`+ ${count - 1}`}</TagCount>
        </Tooltip>
      );
    };

    return (
      <div className={styles.container}>
        <div ref={ref} className={className}>
          <div className={styles.content}>
            {icon}
            {children}
          </div>
          {caption}
        </div>
        {getCount()}
      </div>
    );
  }),
  {
    Caption: TagCaption,
    FadingText: TagFadingText,
    Group: TagGroup,
  }
);

export default Tag;
