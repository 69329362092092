import { useTranslation } from 'react-i18next';
import { BotSettingSchema } from '../../../api/schemas/botSettingSchema';
import Button from '../../../components/Button';
import PageAddon from '../../../components/PageAddon';
import Tooltip from '../../../components/Tooltip';
import icon from '../../../static/icons';
import { formatAbsoluteDate } from '../../../utils/date';
import { TRAINER_GAME_TYPE_LABELS } from '../../../utils/trainer';
import { can } from '../../permission/helpers';
import GlobalBotSettingDetailDropdown from '../GlobalBotSettingDetailDropdown';
import { getBotBuildStraddle, getBotBuildTight, getBotCodeTight, getWorkspace } from '../helpers';
import styles from './styles.module.scss';

interface Props {
  data: BotSettingSchema;
  onClose: () => void;
  onEdit: () => void;
  deletable?: boolean;
}

const GlobalBotSettingDetail = (props: Props) => {
  const { data, onClose, onEdit, deletable = true } = props;

  const { t } = useTranslation();

  const {
    name,
    game_type,
    bot_build,
    bot_code,
    params_size,
    params_size_hu,
    description,
    modified_on,
    created_on,
    is_deleted,
  } = data;

  const titleIcons = is_deleted && icon('trash', 12);

  return (
    <PageAddon onClose={onClose} title={name} icons={titleIcons}>
      <PageAddon.Fields>
        <PageAddon.Id id={data.id} />
        <PageAddon.Field label={t('common.game_type')}>
          {TRAINER_GAME_TYPE_LABELS[game_type]}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.code')}>{bot_code}</PageAddon.Field>
        <PageAddon.Field label={t('common.build')}>
          <span className={styles.longText}>{bot_build}</span>
        </PageAddon.Field>
        <PageAddon.Field label={t('common.straddle')}>
          <span className={styles.longText}>{getBotBuildStraddle(data)}</span>
        </PageAddon.Field>
        <PageAddon.Field label={t('common.code_tight')}>
          <span className={styles.longText}>{getBotCodeTight(data)}</span>
        </PageAddon.Field>
        <PageAddon.Field label={t('common.build_tight')}>
          <span className={styles.longText}>{getBotBuildTight(data)}</span>
        </PageAddon.Field>
        <PageAddon.Field label={t('common.workspace')}>{getWorkspace(data)}</PageAddon.Field>
        <PageAddon.Collapse label={t('common.params')}>
          <pre className={styles.pre}>{params_size || '—'}</pre>
        </PageAddon.Collapse>
        <PageAddon.Collapse label={t('common.params_hu')}>
          <pre className={styles.pre}>{params_size_hu || '—'}</pre>
        </PageAddon.Collapse>
        <PageAddon.Text label={t('common.description')}>{description}</PageAddon.Text>
        <PageAddon.Field label={t('common.modified')}>
          {formatAbsoluteDate(modified_on)}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.created')}>
          {formatAbsoluteDate(created_on)}
        </PageAddon.Field>
      </PageAddon.Fields>
      {!is_deleted && (
        <PageAddon.Controls>
          <PageAddon.Controls.Group>
            <Tooltip
              label={can.botSetting.createUpdate ? t('common.edit') : t('common.no_permission')}
              hideOnMobile={can.botSetting.createUpdate}
            >
              <Button
                variant="transparent"
                icon={icon('edit', 16)}
                onClick={onEdit}
                data-testid="edit"
                disabled={!can.botSetting.createUpdate}
              />
            </Tooltip>
            {deletable && <GlobalBotSettingDetailDropdown data={data} />}
          </PageAddon.Controls.Group>
        </PageAddon.Controls>
      )}
    </PageAddon>
  );
};

export default GlobalBotSettingDetail;
