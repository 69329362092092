import { useTranslation } from 'react-i18next';
import { GlobalAccountSchema } from '../../api/schemas/account/globalAccountSchema';
import { presets } from '../../components/Dropdown';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import icon from '../../static/icons';
import { getScheduleTooltip } from '../account/helpers';
import { can } from '../permission/helpers';
import {
  useCloseGlobalAccountTablesPokerRoomMutation,
  useResetGlobalAccountTablesCacheMutation,
} from './mutations';

interface Args {
  data: GlobalAccountSchema;
}

const useDropdown = (args: Args) => {
  const { data } = args;

  const { id, mga_id, comparative_limits, is_online } = data;

  const { t } = useTranslation();
  const { navigate } = useURL();

  const resetCache = useResetGlobalAccountTablesCacheMutation();
  const closeRoom = useCloseGlobalAccountTablesPokerRoomMutation();

  const getResetCacheTooltip = () => {
    if (!can.lobby.resetCache) return t('common.no_permission');
    if (!is_online) return t('common.account_is_offline');

    return null;
  };

  const getClosePokerRoomTooltip = () => {
    if (!can.lobby.closePokerRoom) return t('common.no_permission');
    if (!is_online) return t('common.account_is_offline');

    return null;
  };

  return {
    title: mga_id,
    options: {
      edit: {
        ...presets.edit,
        onClick: () => navigate(routes.accountEdit({ accountId: id })),
        disabled: !can.account.createUpdate,
        ...(!can.account.createUpdate && { tooltip: t('common.no_permission') }),
      },
      schedule: {
        icon: icon('calendar', 20),
        label: t('common.schedule'),
        onClick: () => navigate(routes.accountSchedule({ accountId: id })),
        disabled: !can.session.read || !comparative_limits.length,
        tooltip: getScheduleTooltip({ account: data }),
      },
      copy: presets.copyId(id),
      resetCache: {
        icon: icon('broom', 20),
        label: t('common.reset_cache'),
        disabled: !can.lobby.resetCache || !is_online,
        tooltip: getResetCacheTooltip(),
        onClick: () => resetCache.mutate({ accountId: id }),
      },

      divider: { divider: true },
      closePokerRoom: {
        danger: true,
        confirm: true,
        icon: icon('logOut', 20),
        label: t('common.close_poker_room'),
        confirmText: t('common.close'),
        onClick: () => closeRoom.mutate({ accountId: id }),
        disabled: !can.lobby.closePokerRoom || !is_online,
        tooltip: getClosePokerRoomTooltip(),
      },
    },
  };
};

export default useDropdown;
