import { PILOT_STATUS, TABLE_SIZE } from '../../api/schemas/account';
import { ACCOUNT_STATUS, AccountStatusSchema } from '../../api/schemas/accountStatusSchema';
import {
  AccountStrategyProfileSchema,
  PLAYER_TYPE,
  SEATING_PREFERENCE,
} from '../../api/schemas/accountStrategyProfileSchema';
import { MetricsSchema } from '../../api/schemas/metricsSchema';
import { TRAINER_LIMITS } from '../../api/schemas/trainerLimitSchema';
import { TRAINER_TECH } from '../../api/schemas/trainerTechSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import { Color } from '../../components/Badge';
import Tag from '../../components/Tag';
import Tooltip from '../../components/Tooltip';
import i18n from '../../i18n';
import { convertCentsToDollars } from '../../utils/data';
import {
  TRAINER_GAME_TYPE_LABELS,
  TRAINER_LIMITS_LABELS,
  TRAINER_POKER_ROOM_LABELS,
} from '../../utils/trainer';
import { can } from '../permission/helpers';
import { CommonAccountSchema } from './types';

export const TABLE_SIZE_LABELS: Record<CommonAccountSchema['table_sizes'][number], string> = {
  [TABLE_SIZE.UNKNOWN]: '—',
  [TABLE_SIZE.HEADS_UP]: i18n.t('common.heads_up'),
  [TABLE_SIZE.SHORT_HANDED]: i18n.t('common.short_handed'),
  [TABLE_SIZE.FULL_RING]: i18n.t('common.full_ring'),
};

export const PILOT_STATUS_LABELS: Record<CommonAccountSchema['pilot_status'], string> = {
  [PILOT_STATUS.UNKNOWN]: '—',
  [PILOT_STATUS.OFF]: i18n.t('common.off'),
  [PILOT_STATUS.STARTING]: i18n.t('common.starting'),
  [PILOT_STATUS.ON]: i18n.t('common.on'),
  [PILOT_STATUS.FINISHING]: i18n.t('common.finishing'),
};

export const tableSizeOptions = [
  {
    value: TABLE_SIZE.HEADS_UP,
    label: TABLE_SIZE_LABELS[TABLE_SIZE.HEADS_UP],
  },
  {
    value: TABLE_SIZE.SHORT_HANDED,
    label: TABLE_SIZE_LABELS[TABLE_SIZE.SHORT_HANDED],
  },
  {
    value: TABLE_SIZE.FULL_RING,
    label: TABLE_SIZE_LABELS[TABLE_SIZE.FULL_RING],
  },
];

export const maxTableOptions = Array.from({ length: 21 }).map((_, index) => ({
  value: index,
  label: String(index),
}));

export const getGeolocationAsString = (data: CommonAccountSchema['geo']) => {
  if (!data) return '';

  return `${data.lat}, ${data.lng}`;
};

export const getLimitBlind = (limit: CommonAccountSchema['comparative_limits'][number]) =>
  ({
    [TRAINER_LIMITS.UNKNOWN]: '—',
    [TRAINER_LIMITS.C2]: 'BB range $0 — $0.03',
    [TRAINER_LIMITS.C10]: 'BB range $0.04 — $0.16',
    [TRAINER_LIMITS.C25]: 'BB range $0.17 — $0.39',
    [TRAINER_LIMITS.C50]: 'BB range $0.4 — $0.99',
    [TRAINER_LIMITS.C100]: 'BB range $1 — $1.99',
    [TRAINER_LIMITS.C200]: 'BB range $2 — $3.99',
    [TRAINER_LIMITS.C400]: 'BB range $4 — $5.99',
    [TRAINER_LIMITS.C600]: 'BB range $6 — $9.99',
    [TRAINER_LIMITS.C1000]: 'BB range $10 — $19.99',
    [TRAINER_LIMITS.C2000]: 'BB range $20 — ∞',
  })[limit];

export const getGameTypes = (data: CommonAccountSchema) => {
  const { game_types } = data;

  if (!game_types.length) return '—';

  return game_types.map((item) => <Tag key={item}>{TRAINER_GAME_TYPE_LABELS[item]}</Tag>);
};

export const getLimits = (data: CommonAccountSchema) => {
  const { comparative_limits } = data;

  if (!comparative_limits.length) return '—';

  return comparative_limits.map((item) => (
    <Tooltip key={item} label={getLimitBlind(item)}>
      <Tag key={item}>{TRAINER_LIMITS_LABELS[item]}</Tag>
    </Tooltip>
  ));
};

export const getTableSizes = (data: CommonAccountSchema) => {
  const { table_sizes } = data;

  if (!table_sizes.length) return '—';

  return table_sizes.map((item) => <Tag key={item}>{TABLE_SIZE_LABELS[item]}</Tag>);
};

export const getScheduleTooltip = (args: {
  account: CommonAccountSchema;
  workspaceId?: WorkspaceSchema['id'];
}) => {
  const { account, workspaceId } = args;

  const { comparative_limits } = account;

  const canSessionRead = workspaceId ? true : can.session.read; // TODO: разделить

  if (!canSessionRead) return i18n.t('common.no_permission');

  if (!comparative_limits.length) return i18n.t('common.account_has_no_limits');

  return null;
};

export const openVNC = (
  account: {
    id: CommonAccountSchema['id'];
    room: CommonAccountSchema['room'];
    trainer_tech: CommonAccountSchema['maintenance']['trainer_tech'];
  },
  offset = 20
) => {
  const env = window.ENV === 'dev' ? 'stage01' : window.ENV;

  const { id, room, trainer_tech } = account;

  const open = (url: string) =>
    window.open(
      `https://${id}.${TRAINER_POKER_ROOM_LABELS[room].toLowerCase()}.${url}`,
      id,
      `width=400,height=600,top=${window.screenY + offset},left=${window.screenX + offset}`
    );

  if (
    trainer_tech.includes(TRAINER_TECH.PPPPOKER_DESKTOP) ||
    trainer_tech.includes(TRAINER_TECH.CLUBGG_DESKTOP)
  ) {
    open(`trndsk.gcp.shore.pw`);
  } else {
    open(`${env}.gcp.shore.pw/static/index.html`);
  }
};

export const PLAYER_TYPE_LABELS: Record<AccountStrategyProfileSchema['player_type'], string> = {
  [PLAYER_TYPE.DEFAULT]: 'Default',
  [PLAYER_TYPE.REG]: 'Reg',
  [PLAYER_TYPE.MIDDLE]: 'Middle',
  [PLAYER_TYPE.MIDDLE_FISH]: 'Middle fish',
  [PLAYER_TYPE.STRONG_FISH]: 'Strong fish',
  [PLAYER_TYPE.WEAK_FISH]: 'Weak fish',
};

export const playerTypesOptions = Object.values(PLAYER_TYPE).map((item) => ({
  value: item,
  label: PLAYER_TYPE_LABELS[item],
}));

export const SEATING_PREFERENCE_LABELS: Record<
  AccountStrategyProfileSchema['seating_preference'],
  string
> = {
  [SEATING_PREFERENCE.UNKNOWN]: '—',
  [SEATING_PREFERENCE.BEST_SEAT]: i18n.t('common.best_seat'),
  [SEATING_PREFERENCE.RANDOM_SEAT]: i18n.t('common.random_seat'),
};

export const seatingPreferencesOptions = Object.values(SEATING_PREFERENCE)
  .filter((item) => item !== SEATING_PREFERENCE.UNKNOWN)
  .map((item) => ({
    value: item,
    label: SEATING_PREFERENCE_LABELS[item],
  }));

export const booleanOptions = [
  {
    value: 1,
    label: i18n.t('common.true'),
  },
  {
    value: 0,
    label: i18n.t('common.false'),
  },
];

export const getBooleanOption = (value: unknown) => ({
  value: value ? 1 : 0,
  label: value ? i18n.t('common.true') : i18n.t('common.false'),
});

export const getMetrics = (data: MetricsSchema) => {
  const {
    stack_size,
    net_win_sum,
    win_rate_bb,
    ev_rate_bb,
    net_win_sum_bb,
    stack_size_bb,
    ...rest
  } = data;

  return {
    net_win_sum: convertCentsToDollars(net_win_sum),
    net_win_sum_bb: Number(net_win_sum_bb.toFixed(2)),
    stack_size: convertCentsToDollars(stack_size),
    stack_size_bb: Number(stack_size_bb.toFixed(2)),
    win_rate_bb: Number(win_rate_bb.toFixed(2)),
    ev_rate_bb: Number(ev_rate_bb.toFixed(2)),
    ...rest,
  };
};

export const ACCOUNT_STATUS_LABELS: Record<AccountStatusSchema, string> = {
  [ACCOUNT_STATUS.UNKNOWN]: '—',
  [ACCOUNT_STATUS.IN_GAME]: i18n.t('common.in_game'),
  [ACCOUNT_STATUS.SIT_OUT]: i18n.t('common.sit_out'),
  [ACCOUNT_STATUS.WATCHER]: i18n.t('common.watcher'),
  [ACCOUNT_STATUS.IN_WAITLIST]: i18n.t('common.in_waitlist'),
};

export const ACCOUNT_STATUS_COLORS: Record<AccountStatusSchema, Color> = {
  [ACCOUNT_STATUS.UNKNOWN]: 'grey',
  [ACCOUNT_STATUS.IN_GAME]: 'green',
  [ACCOUNT_STATUS.SIT_OUT]: 'orange',
  [ACCOUNT_STATUS.WATCHER]: 'purple',
  [ACCOUNT_STATUS.IN_WAITLIST]: 'blue',
};

export const limitOptions = Object.values(TRAINER_LIMITS)
  .map((item) => ({
    value: item,
    label: TRAINER_LIMITS_LABELS[item],
    caption: getLimitBlind(item),
  }))
  .filter((item) => item.value !== TRAINER_LIMITS.UNKNOWN);
