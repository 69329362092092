import pluralize from 'pluralize';
import { Fragment, ReactNode } from 'react';
import { isPlainObject } from 'remeda';
import { z } from 'zod';
import dateTimeSchema from '../../../../api/schemas/dateTimeSchema';
import { ACTION_TYPE, HistorySchema } from '../../../../api/schemas/historySchema';
import COLORS from '../../../../constants/COLORS';
import i18n from '../../../../i18n';
import { copyId } from '../../../../utils/clipboard';
import { capitalize, cutUUID, snakeToSentenceCase } from '../../../../utils/data';
import { formatAbsoluteDate } from '../../../../utils/date';
import { hexColor } from '../../../../utils/regExp';
import ColorTag from '../../../ColorTag';
import { ActionType, Enums } from '../../helpers';
import HistoryDialogCollapseField from './HistoryDialogCollapseField';
import styles from './styles.module.scss';

export type Changes = HistorySchema['changes'][number];

interface Args {
  name: Changes['name'];
  oldValue: Changes['old_value'];
  newValue: Changes['new_value'];
  actionType: ActionType;
  renderLabel: (args: { name: string }) => ReactNode;
  renderGroup: (args: Changes) => ReactNode;
  enums?: Enums;
}

const renderPrimitiveValue = (value: unknown) => {
  if (value === null || value === '') return '—';

  if (typeof value === 'boolean') return capitalize(String(value));

  if (dateTimeSchema.safeParse(value).success) {
    return formatAbsoluteDate(String(value));
  }

  if (z.string().uuid().safeParse(value).success) {
    const uuid = String(value);

    return (
      <button type="button" onClick={() => copyId(uuid)} className={styles.copyButton}>
        {cutUUID(uuid)}
      </button>
    );
  }

  if (hexColor.test(String(value))) {
    const color = String(value);

    return <ColorTag label={color} color={color} />;
  }

  return String(value);
};

const getValue = (args: { oldValue: unknown; newValue: unknown; actionType?: ActionType }) => {
  const { oldValue, newValue, actionType } = args;

  if (
    actionType === ACTION_TYPE.CREATED ||
    actionType === ACTION_TYPE.STRATEGY_PROFILE_CREATED ||
    actionType === ACTION_TYPE.NOTE_CREATED ||
    actionType === ACTION_TYPE.SESSION_CREATED
  ) {
    return renderPrimitiveValue(newValue);
  }

  if (oldValue !== newValue) {
    return (
      <>
        {renderPrimitiveValue(oldValue)}
        <span> → </span>
        {renderPrimitiveValue(newValue)}
      </>
    );
  }

  return renderPrimitiveValue(newValue);
};

export const getChange = (args: Args) => {
  let { name, oldValue, newValue } = args;
  const { actionType, enums, renderLabel, renderGroup } = args;

  if (Array.isArray(newValue) && oldValue === null) {
    oldValue = [];
  }

  if (Array.isArray(oldValue) && newValue === null) {
    newValue = [];
  }

  if (Array.isArray(newValue) && Array.isArray(oldValue)) {
    if (isPlainObject(newValue[0]) || isPlainObject(oldValue[0])) {
      name = pluralize(name, 1);

      const stringifiedNewValue = newValue.map((item) => JSON.stringify(item));
      const stringifiedOldValue = oldValue.map((item) => JSON.stringify(item));

      const filteredNewValue = newValue.filter(
        (item) => !stringifiedOldValue.includes(JSON.stringify(item))
      );

      const filteredOldValue = oldValue.filter(
        (item) => !stringifiedNewValue.includes(JSON.stringify(item))
      );

      if (
        actionType === ACTION_TYPE.DELETED ||
        actionType === ACTION_TYPE.NOTE_DELETED ||
        actionType === ACTION_TYPE.SESSION_DELETED
      ) {
        return (
          <>
            {newValue.map((item) => (
              <Fragment key={item.id}>
                {renderGroup({ name, old_value: item, new_value: item })}
              </Fragment>
            ))}
          </>
        );
      }

      return (
        <>
          {filteredNewValue.map((item) => {
            const oldItem = filteredOldValue.find((el) => el.id === item.id);

            if (oldItem) {
              return (
                <Fragment key={item.id}>
                  {renderGroup({ name, old_value: oldItem, new_value: item })}
                </Fragment>
              );
            }

            return (
              <Fragment key={item.id}>
                {renderGroup({ name, old_value: null, new_value: item })}
              </Fragment>
            );
          })}
          {filteredOldValue.map((item) => {
            const newItem = filteredNewValue.find((el) => el.id === item.id);

            if (newItem) return null;

            return (
              <Fragment key={item.id}>
                {renderGroup({ name, old_value: item, new_value: null })}
              </Fragment>
            );
          })}
        </>
      );
    }

    if (enums) {
      Object.entries(enums).forEach((item) => {
        const [key, value] = item;

        if (name === key) {
          if (newValue.length) {
            newValue = newValue.map((el: number) => value[el]);
          }

          if (oldValue.length) {
            oldValue = oldValue.map((el: number) => value[el]);
          }
        }
      });
    }

    const getArrayValue = (arrayValue: unknown[]) => {
      if (!arrayValue.length) return null;

      return arrayValue.join(', ');
    };

    return (
      <HistoryDialogCollapseField label={snakeToSentenceCase(name)}>
        {getValue({
          oldValue: getArrayValue(oldValue),
          newValue: getArrayValue(newValue),
          actionType,
        })}
      </HistoryDialogCollapseField>
    );
  }

  if (isPlainObject(newValue) || isPlainObject(oldValue)) {
    const getTag = () => {
      if (JSON.stringify(newValue) === JSON.stringify(oldValue)) return null;

      if (isPlainObject(newValue) && oldValue === null) {
        return { label: i18n.t('common.added'), color: COLORS.GREEN_100 };
      }

      if (newValue === null && isPlainObject(oldValue)) {
        return { label: i18n.t('common.removed'), color: COLORS.RED_100 };
      }

      return { label: i18n.t('common.edited'), color: COLORS.ORANGE_100 };
    };

    const tag = getTag();

    const formattedName = snakeToSentenceCase(name);

    return (
      <>
        <HistoryDialogCollapseField
          label={
            <>
              {renderLabel({ name: formattedName })}
              {tag && <span style={{ color: tag.color }}>{tag.label}</span>}
            </>
          }
        />
        {Object.entries(newValue || oldValue).map((item) => {
          const [key] = item;

          if (enums) {
            Object.entries(enums).forEach((el) => {
              const [enumKey, enumValue] = el;

              if (`${name}_${key}` === enumKey) {
                if (newValue) {
                  newValue = { ...newValue, [key]: enumValue[newValue[key]] };
                }

                if (oldValue) {
                  oldValue = { ...oldValue, [key]: enumValue[oldValue[key]] };
                }
              }
            });
          }

          const getRecordValue = (recordValue: Record<string, unknown>) => recordValue[key];

          return (
            <HistoryDialogCollapseField key={key} label={snakeToSentenceCase(key)} nested>
              {getValue({
                oldValue: getRecordValue(oldValue || newValue),
                newValue: getRecordValue(newValue || oldValue),
              })}
            </HistoryDialogCollapseField>
          );
        })}
      </>
    );
  }

  if (enums) {
    Object.entries(enums).forEach((item) => {
      const [key, value] = item;

      if (name === key) {
        if (typeof newValue === 'number') {
          newValue = value[newValue];
        }

        if (typeof oldValue === 'number') {
          oldValue = value[oldValue];
        }
      }
    });
  }

  if (newValue === null && oldValue === '') {
    oldValue = null;
  }

  if (oldValue === null && newValue === '') {
    newValue = null;
  }

  return (
    <HistoryDialogCollapseField label={snakeToSentenceCase(name)}>
      {getValue({ oldValue, newValue, actionType })}
    </HistoryDialogCollapseField>
  );
};
