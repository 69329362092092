import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { loadGlobalDeals, loadGlobalDealsFilterValues } from '../../api';
import { DealSchema } from '../../api/schemas/deal/dealSchema';
import Card from '../../components/Card';
import FadingText from '../../components/FadingText';
import Link from '../../components/Link';
import Page from '../../components/Page';
import PageControls from '../../components/PageControls';
import PageTop from '../../components/PageTop';
import Table, { getTableProps } from '../../components/Table';
import queryKeys from '../../config/queryKeys';
import routes from '../../config/routes';
import { GlobalClubProvider } from '../../features/global-club/GlobalClubContext';
import GlobalDealBalance from '../../features/global-deal/GlobalDealBalance';
import GlobalDealBalanceUSD from '../../features/global-deal/GlobalDealBalanceUSD';
import GlobalDealCreateButton from '../../features/global-deal/GlobalDealCreateButton';
import GlobalDealCredit from '../../features/global-deal/GlobalDealCredit';
import GlobalDealDetailLink from '../../features/global-deal/GlobalDealDetailLink';
import GlobalDealDropdown from '../../features/global-deal/GlobalDealDropdown';
import GlobalDealFilter from '../../features/global-deal/GlobalDealFilter';
import GlobalDealProtection from '../../features/global-deal/GlobalDealProtection';
import GlobalDealRole from '../../features/global-deal/GlobalDealRole';
import GlobalDealRooms from '../../features/global-deal/GlobalDealRooms';
import GlobalDealStatus from '../../features/global-deal/GlobalDealStatus';
import useTableColumnResize from '../../features/global-deal/useTableColumnResize';
import { can } from '../../features/permission/helpers';
import useBreakpoints from '../../hooks/useBreakpoints';
import usePaginatedDataNew from '../../hooks/usePaginatedDataNew';
import useURL from '../../hooks/useURL';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import useWebSocketPaginatedActions from '../../hooks/useWebSocketPaginatedActions';
import { addWhen } from '../../utils';
import { renderNumber } from '../../utils/data';
import { formatAbsoluteDate, formatRelativeDate } from '../../utils/date';
import { RouteParams } from '../../utils/generics';
import { createInfinitePaginatedQueryData } from '../../utils/queryClient';
import { TRAINER_POKER_ROOM_LABELS } from '../../utils/trainer';
import Error403Page from '../Error403Page';
import {
  renderClubCode,
  renderClubLeague,
  renderClubName,
  renderClubSuperLeague,
  renderPaymentSystems,
  renderWorkspaces,
} from './helpers';

type Params = RouteParams<typeof routes.deal>;

const GlobalDealsPage = () => {
  const { t } = useTranslation();
  const { urlParams } = useURL();
  const { search, poker_room_in, ...rest } = urlParams;
  const { dealId } = useParams<Params>();
  const { isDesktop, isTablet, isPhone } = useBreakpoints();

  const queryKey = [queryKeys.deals, rest, search, poker_room_in];

  const query = usePaginatedDataNew({
    queryKey,
    queryFn: (params) =>
      loadGlobalDeals({
        ...rest,
        ...params,
        ...(search && { search }),
        ...(!search && poker_room_in && { poker_room_in }),
      }),
  });

  const { data, error, checkIsInView } = query;

  const { checkIsCreated } = useWebSocketPaginatedActions<DealSchema, DealSchema>({
    queryKey,
    domain: 'GlobalDeal',
    createAction: [],
    updateAction: ['Edited', 'NoteAdded', 'NoteRemoved'],
    select: (item) => item,
  });

  useWebSocketMessage<DealSchema>({
    domain: 'GlobalDeal',
    onMessage: (message) => {
      if (message.action === 'Created') {
        if (!poker_room_in || message.payload.poker_room === Number(poker_room_in)) {
          createInfinitePaginatedQueryData(queryKey, message.payload);
        }
      }
    },
  });

  const filterQueryKey = [queryKeys.dealsFilterValues, urlParams.show_deleted];
  const filterQueryFn = () => loadGlobalDealsFilterValues({ show_deleted: urlParams.show_deleted });

  const { data: filterData, isPending: filterIsPending } = useQuery({
    queryKey: filterQueryKey,
    queryFn: filterQueryFn,
    placeholderData: keepPreviousData,
    meta: { ignoredStatuses: [403] },
  });

  const columnResize = useTableColumnResize();

  if (error?.response?.status === 403) return <Error403Page />;

  return (
    <GlobalClubProvider>
      <Page
        heading={t('common.deals')}
        tabs={[
          { to: routes.deals, label: t('common.deals'), active: true },
          ...addWhen({ to: routes.agents, label: t('common.agents') }, can.agent.read),
        ]}
        filter={<GlobalDealFilter data={filterData} isPending={filterIsPending} />}
        extra={
          <PageControls>
            <GlobalDealCreateButton />
          </PageControls>
        }
        addon={<Outlet />}
      >
        {!search && <GlobalDealRooms data={filterData} loading={filterIsPending} />}
        {isPhone && (
          <PageTop
            sorting={[
              { key: 'code', title: t('common.code'), default: true },
              { key: 'agent_name', title: t('common.agent') },
              { key: 'poker_room', title: t('common.poker_room') },
              { key: 'club_name', title: t('common.club') },
              { key: 'area_id', title: t('common.area') },
              { key: 'area2_id', title: t('common.area_2') },
              { key: 'holder_username', title: t('common.holder') },
              { key: 'owner', title: t('common.owner') },
              { key: 'legend', title: t('common.legend') },
              { key: 'role', title: t('common.role') },
              { key: 'status', title: t('common.status') },
              { key: 'credit', title: t('common.credit') },
              { key: 'protection', title: t('common.protection') },
              { key: 'currency', title: t('common.currency') },
              { key: 'chip_rate', title: t('common.chip_rate') },
              { key: 'rake_back', title: t('common.rakeback') },
              { key: 'action', title: t('common.action') },
              { key: 'rev_share', title: t('common.revshare') },
              { key: 'win_share', title: t('common.winshare') },
              { key: 'rebate', title: t('common.rebate') },
              { key: 'agent_fee', title: t('common.agent_fee') },
              { key: 'withdraw_fee', title: t('common.withdraw_fee') },
              { key: 'created_on', title: t('common.created') },
            ]}
          />
        )}
        {(isDesktop || isTablet) && (
          <Table
            data={data}
            search={search}
            {...getTableProps(query)}
            {...columnResize}
            columns={[
              {
                key: 'code',
                sorting: true,
                title: t('common.code'),
                render: ({ values }) => <GlobalDealDetailLink data={values} />,
                pin: 'left',
                resize: true,
                style: { width: 80 },
              },
              {
                key: 'agent_name',
                sorting: true,
                title: t('common.agent'),
                render: ({ values }) => (
                  <Link to={routes.dealAgent({ dealId: values.id, agentId: values.agent.id })}>
                    <FadingText text={values.agent.name} />
                  </Link>
                ),
                resize: true,
                style: { width: 80 },
              },
              {
                key: 'poker_room',
                sorting: true,
                title: t('common.poker_room'),
                render: ({ values }) => TRAINER_POKER_ROOM_LABELS[values.poker_room],
                resize: true,
                style: { width: 100 },
              },
              {
                key: 'club_name',
                sorting: true,
                title: t('common.club_name'),
                render: ({ values }) => renderClubName(values),
                resize: true,
                style: { width: 100 },
              },
              {
                key: 'club_id',
                title: t('common.club_id'),
                render: ({ values }) => renderClubCode(values),
                resize: true,
                style: { width: 80 },
              },
              {
                key: 'league',
                title: t('common.union'),
                render: ({ values }) => renderClubLeague(values),
                resize: true,
                style: { width: 80 },
              },
              {
                key: 'super_league',
                title: t('common.su'),
                render: ({ values }) => renderClubSuperLeague(values),
                resize: true,
                style: { width: 80 },
              },
              {
                key: 'area_id',
                sorting: true,
                title: t('common.area'),
                render: ({ values }) => <FadingText text={values.area_id || '—'} />,
                resize: true,
                style: { width: 80 },
              },
              {
                key: 'area2_id',
                sorting: true,
                title: t('common.area_2'),
                render: ({ values }) => <FadingText text={values.area2_id || '—'} />,
                resize: true,
                style: { width: 80 },
              },
              {
                key: 'holder_username',
                sorting: true,
                title: t('common.holder'),
                render: ({ values }) => <FadingText text={values.holder?.username || '—'} />,
                resize: true,
                style: { width: 80 },
              },
              {
                key: 'owner',
                sorting: true,
                title: t('common.owner'),
                render: ({ values }) => <FadingText text={values.owner || '—'} />,
                resize: true,
                style: { width: 130 },
              },
              {
                key: 'legend',
                sorting: true,
                title: t('common.legend'),
                render: ({ values }) => <FadingText text={values.legend || '—'} />,
                resize: true,
                style: { width: 80 },
              },
              {
                key: 'role',
                sorting: true,
                title: t('common.role'),
                render: ({ values }) => <GlobalDealRole data={values} />,
                resize: true,
                style: { width: 80 },
              },
              {
                key: 'status',
                sorting: true,
                title: t('common.status'),
                render: ({ values }) => <GlobalDealStatus data={values} />,
                resize: true,
                style: { width: 80 },
              },
              {
                key: 'credit',
                sorting: true,
                title: t('common.credit'),
                render: ({ values }) => <GlobalDealCredit data={values} />,
                resize: true,
                style: { width: 80 },
              },
              {
                key: 'protection',
                sorting: true,
                title: t('common.protection'),
                render: ({ values }) => <GlobalDealProtection data={values} />,
                resize: true,
                style: { width: 100 },
              },
              {
                key: 'currency',
                sorting: true,
                title: t('common.currency'),
                render: ({ values }) => <FadingText text={values.currency || '—'} />,
                resize: true,
                style: { width: 100 },
              },
              {
                key: 'chip_rate',
                sorting: true,
                title: t('common.chip_rate'),
                render: ({ values }) => (
                  <FadingText text={String(renderNumber(values.chip_rate))} />
                ),
                resize: true,
                style: { width: 100 },
              },
              {
                key: 'rake_back',
                sorting: true,
                title: t('common.rakeback'),
                render: ({ values }) => (
                  <FadingText text={String(renderNumber(values.rake_back))} />
                ),
                resize: true,
                style: { width: 100 },
              },
              {
                key: 'action',
                sorting: true,
                title: t('common.action'),
                render: ({ values }) => <FadingText text={String(renderNumber(values.action))} />,
                resize: true,
                style: { width: 80 },
              },
              {
                key: 'rev_share',
                sorting: true,
                title: t('common.revshare'),
                render: ({ values }) => (
                  <FadingText text={String(renderNumber(values.rev_share))} />
                ),
                resize: true,
                style: { width: 100 },
              },
              {
                key: 'win_share',
                sorting: true,
                title: t('common.winshare'),
                render: ({ values }) => (
                  <FadingText text={String(renderNumber(values.win_share))} />
                ),
                resize: true,
                style: { width: 100 },
              },
              {
                key: 'rebate',
                sorting: true,
                title: t('common.rebate'),
                render: ({ values }) => <FadingText text={String(renderNumber(values.rebate))} />,
                resize: true,
                style: { width: 80 },
              },
              {
                key: 'agent_fee',
                sorting: true,
                title: t('common.agent_fee'),
                render: ({ values }) => (
                  <FadingText text={String(renderNumber(values.agent_fee))} />
                ),
                resize: true,
                style: { width: 100 },
              },
              {
                key: 'withdraw_fee',
                sorting: true,
                title: t('common.withdraw_fee'),
                render: ({ values }) => (
                  <FadingText text={String(renderNumber(values.withdraw_fee))} />
                ),
                resize: true,
                style: { width: 120 },
              },
              {
                key: 'payment_system',
                title: t('common.payment_system'),
                render: ({ values }) => renderPaymentSystems(values),
                resize: true,
                style: { width: 140 },
              },
              {
                key: 'workspaces',
                title: t('common.workspaces'),
                render: ({ values }) => renderWorkspaces(values),
                resize: true,
                style: { width: 120 },
              },
              {
                key: 'accounts',
                title: t('common.accounts'),
                render: ({ values }) => values.accounts.length,
                resize: true,
                style: { width: 100 },
              },
              {
                key: 'balance',
                title: t('common.balance'),
                render: ({ values }) => <GlobalDealBalance data={values} />,
                resize: true,
                style: { width: 100 },
              },
              {
                key: 'balance_usd',
                title: t('common.balance_$'),
                render: ({ values }) => <GlobalDealBalanceUSD data={values} />,
                resize: true,
                style: { width: 100 },
              },
              {
                key: 'modified',
                title: t('common.modified'),
                render: ({ values }) => formatAbsoluteDate(values.modified_on),
                resize: true,
                style: { width: 100 },
              },
              {
                key: 'created_on',
                sorting: true,
                title: t('common.created'),
                render: ({ values }) => formatAbsoluteDate(values.created_on),
                resize: true,
                style: { width: 100 },
              },
              {
                render: ({ values }) => <GlobalDealDropdown data={values} />,
                align: 'right',
                controls: true,
                pin: 'right',
                style: { width: 32, marginLeft: 'auto' },
              },
            ]}
            renderRow={(row, index) => (
              <Table.Row
                highlighted={checkIsCreated(row.data.id)}
                active={row.data.id === dealId}
                danger={row.data.is_deleted}
                {...checkIsInView(index)}
                {...row}
              />
            )}
          />
        )}
        {isPhone && (
          <Card.Group>
            {data.map((item, index) => (
              <Card
                key={item.id}
                highlighted={checkIsCreated(item.id)}
                danger={item.is_deleted}
                {...checkIsInView(index)}
              >
                <Card.Top>
                  <GlobalDealDetailLink data={item} />
                  <Card.Top.Controls>
                    <GlobalDealDropdown data={item} />
                  </Card.Top.Controls>
                </Card.Top>
                <Card.Field.Group>
                  <Card.Field label={t('common.code')}>{item.code}</Card.Field>
                  <Card.Field label={t('common.agent')}>{item.agent.name}</Card.Field>
                  <Card.Field label={t('common.poker_room')}>
                    {TRAINER_POKER_ROOM_LABELS[item.poker_room]}
                  </Card.Field>
                  <Card.Field label={t('common.club_name')}>{renderClubName(item)}</Card.Field>
                  <Card.Field label={t('common.club_id')}>{renderClubCode(item)}</Card.Field>
                  <Card.Field label={t('common.union')}>{renderClubLeague(item)}</Card.Field>
                  <Card.Field label={t('common.su')}>{renderClubSuperLeague(item)}</Card.Field>
                  <Card.Field label={t('common.area')}>{item.area_id || '—'}</Card.Field>
                  <Card.Field label={t('common.area_2')}>{item.area2_id || '—'}</Card.Field>
                  <Card.Field label={t('common.holder')}>{item.holder?.username || '—'}</Card.Field>
                  <Card.Field label={t('common.owner')}>{item.owner || '—'}</Card.Field>
                  <Card.Field label={t('common.legend')}>{item.legend || '—'}</Card.Field>
                  <Card.Field label={t('common.role')}>
                    <GlobalDealRole data={item} />
                  </Card.Field>
                  <Card.Field label={t('common.status')}>
                    <GlobalDealStatus data={item} />
                  </Card.Field>
                  <Card.Field label={t('common.credit')}>
                    <GlobalDealCredit data={item} />
                  </Card.Field>
                  <Card.Field label={t('common.protection')}>
                    <GlobalDealProtection data={item} />
                  </Card.Field>
                  <Card.Field label={t('common.currency')}>{item.currency || '—'}</Card.Field>
                  <Card.Field label={t('common.chip_rate')}>{item.chip_rate || '—'}</Card.Field>
                  <Card.Field label={t('common.rakeback')}>{item.rake_back || '—'}</Card.Field>
                  <Card.Field label={t('common.action')}>{item.action || '—'}</Card.Field>
                  <Card.Field label={t('common.revshare')}>{item.rev_share || '—'}</Card.Field>
                  <Card.Field label={t('common.winshare')}>{item.win_share || '—'}</Card.Field>
                  <Card.Field label={t('common.rebate')}>{item.rebate || '—'}</Card.Field>
                  <Card.Field label={t('common.agent_fee')}>{item.agent_fee || '—'}</Card.Field>
                  <Card.Field label={t('common.withdraw_fee')}>
                    {item.withdraw_fee || '—'}
                  </Card.Field>
                  <Card.Field label={t('common.payment_system')}>
                    {renderPaymentSystems(item)}
                  </Card.Field>
                  <Card.Field label={t('common.workspaces')}>{renderWorkspaces(item)}</Card.Field>
                  <Card.Field label={t('common.accounts')}>{item.accounts.length}</Card.Field>
                  <Card.Field label={t('common.balance')}>
                    <GlobalDealBalance data={item} />
                  </Card.Field>
                  <Card.Field label={t('common.balance_$')}>
                    <GlobalDealBalanceUSD data={item} />
                  </Card.Field>
                  <Card.Field label={t('common.modified')}>
                    {formatRelativeDate(item.modified_on)}
                  </Card.Field>
                  <Card.Field label={t('common.created')}>
                    {formatRelativeDate(item.created_on)}
                  </Card.Field>
                </Card.Field.Group>
              </Card>
            ))}
          </Card.Group>
        )}
      </Page>
    </GlobalClubProvider>
  );
};

export default GlobalDealsPage;
