import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValueEditorProps } from 'react-querybuilder';
import usePrevious from '../../hooks/usePrevious';
import {
  commaSeparatedFloatNumbers,
  commaSeparatedNumbers,
  floatNumbers,
  numbers,
} from '../../utils/validations';
import Button from '../Button';
import Input from '../Input';
import Select, { Option, Options } from '../Select';
import {
  gameTypeIdOptions,
  checkIsInOperator,
  checkIsBooleanField,
  checkIsFloatField,
  selectConfig,
  statusOptions,
  blindTypeOptions,
  straddleTypeOptions,
  runItTwiceTypeOptions,
} from './helpers';
import styles from './styles.module.scss';

const QueryBuilderValueEditor = (props: ValueEditorProps) => {
  const { handleOnChange, value, operator, field } = props;

  const { t } = useTranslation();

  const isInOperator = checkIsInOperator(operator);
  const isBoolField = checkIsBooleanField(field);

  const [val, setVal] = useState(value);

  const prevField = usePrevious(field);
  const prevOperator = usePrevious(operator);

  useEffect(() => {
    if (operator !== prevOperator || field !== prevField) setVal('');
  }, [operator, prevOperator, field, prevField, setVal]);

  if (isBoolField) {
    return (
      <div className={styles.booleanField}>
        <Button
          size="small"
          onClick={() => handleOnChange(true)}
          variant={value === true ? 'primary' : 'secondary'}
          className={clsx(value === true && styles.active)}
        >
          {t('common.true')}
        </Button>
        <Button
          size="small"
          onClick={() => handleOnChange(false)}
          variant={value === false ? 'primary' : 'secondary'}
          className={clsx(value === false && styles.active)}
        >
          {t('common.false')}
        </Button>
      </div>
    );
  }

  if (
    field === 'game_type_id' ||
    field === 'Status' ||
    field === 'BlindType' ||
    field === 'StraddleType' ||
    field === 'RunItTwiceType'
  ) {
    const validate = () => {
      if (!value) return t('validations.required');

      if (String(value).includes(',') && value.length === 0) {
        return t('validations.required');
      }

      return undefined;
    };

    const getOptions = () => {
      if (field === 'game_type_id') return gameTypeIdOptions;
      if (field === 'Status') return statusOptions;
      if (field === 'BlindType') return blindTypeOptions;
      if (field === 'StraddleType') return straddleTypeOptions;
      if (field === 'RunItTwiceType') return runItTwiceTypeOptions;

      return [];
    };

    const selectProps = {
      size: 'small' as const,
      multi: isInOperator,
      error: validate(),
      options: getOptions(),
      className: styles.valueSelect,
      onChange: (event: Option | Options) => handleOnChange(event),
      config: selectConfig,
    };

    if (isInOperator) {
      return <Select.Multi value={value || []} {...selectProps} />;
    }

    return <Select clearable={false} value={value || null} {...selectProps} />;
  }

  const validate = () => {
    if (!value && value !== 0) return t('validations.required');

    if (String(value).endsWith(',')) {
      return t('validations.not_ends_with_comma');
    }

    if (!isInOperator && Number(value) > Number.MAX_SAFE_INTEGER) {
      return t('validations.big_number');
    }

    if (isInOperator) {
      const values = value.split(',');

      if (values.some((item: string) => Number(item) > Number.MAX_SAFE_INTEGER)) {
        return t('validations.big_number');
      }
    }

    return undefined;
  };

  const getAllow = () => {
    if (field === 'club_id') return undefined;

    if (checkIsFloatField(field)) {
      if (isInOperator) return commaSeparatedFloatNumbers;

      return floatNumbers(2);
    }

    if (isInOperator) return commaSeparatedNumbers;

    return numbers;
  };

  return (
    <Input
      size="small"
      name="value"
      value={val}
      error={validate()}
      allow={getAllow()}
      onChange={(event) => setVal(event.target.value)}
      onBlur={(event) => handleOnChange(event.target.value)}
      className={clsx(styles.value, isInOperator && styles.inField)}
      placeholder={isInOperator ? t('common.array') : t('common.value')}
    />
  );
};

export default QueryBuilderValueEditor;
