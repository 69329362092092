import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import WorkspaceSeatControlRuleDetail from '../../features/workspace-seat-control-rule/WorkspaceSeatControlRuleDetail';
import WorkspaceSeatControlRuleForm from '../../features/workspace-seat-control-rule/WorkspaceSeatControlRuleForm';
import WorkspaceSeatControlRuleLoader from '../../features/workspace-seat-control-rule/WorkspaceSeatControlRuleLoader';
import WorkspaceSeatControlRulesPage from '../../pages/WorkspaceSeatControlRulesPage';
import Route from './Route';

const page = (
  <Route params={['workspaceId']}>
    {({ workspaceId }) => <WorkspaceSeatControlRulesPage workspaceId={workspaceId} />}
  </Route>
);

const createForm = (
  <Route params={['workspaceId']}>
    {({ workspaceId, navigate }) => (
      <WorkspaceSeatControlRuleForm
        workspaceId={workspaceId}
        onClose={() => navigate(routes.workspaceSeatConrolRules({ workspaceId }))}
        onCreate={({ seatControlRuleId }) =>
          navigate(routes.workspaceSeatConrolRule({ workspaceId, seatControlRuleId }))
        }
      />
    )}
  </Route>
);

const detail = (
  <Route params={['workspaceId', 'seatControlRuleId']}>
    {({ workspaceId, seatControlRuleId, navigate }) => (
      <WorkspaceSeatControlRuleLoader
        workspaceId={workspaceId}
        seatControlRuleId={seatControlRuleId}
      >
        {({ data }) => (
          <WorkspaceSeatControlRuleDetail
            data={data}
            workspaceId={workspaceId}
            onClose={() => navigate(routes.workspaceSeatConrolRules({ workspaceId }))}
            onEdit={() =>
              navigate(routes.workspaceSeatConrolRuleEdit({ workspaceId, seatControlRuleId }))
            }
          />
        )}
      </WorkspaceSeatControlRuleLoader>
    )}
  </Route>
);

const editForm = (
  <Route params={['workspaceId', 'seatControlRuleId']}>
    {({ workspaceId, seatControlRuleId, navigate }) => (
      <WorkspaceSeatControlRuleLoader
        workspaceId={workspaceId}
        seatControlRuleId={seatControlRuleId}
      >
        {({ data }) => (
          <WorkspaceSeatControlRuleForm
            data={data}
            workspaceId={workspaceId}
            onClose={() =>
              navigate(routes.workspaceSeatConrolRule({ workspaceId, seatControlRuleId }))
            }
            onEdit={() =>
              navigate(routes.workspaceSeatConrolRule({ workspaceId, seatControlRuleId }))
            }
          />
        )}
      </WorkspaceSeatControlRuleLoader>
    )}
  </Route>
);

const workspaceSeatConrolRulesPage: RouteObject = {
  path: routes.workspaceSeatConrolRules({ workspaceId: ':workspaceId' }),
  element: page,
  children: [
    {
      path: routes.workspaceSeatConrolRulesCreate({ workspaceId: ':workspaceId' }),
      element: createForm,
    },
    {
      path: routes.workspaceSeatConrolRule({
        workspaceId: ':workspaceId',
        seatControlRuleId: ':seatControlRuleId',
      }),
      element: detail,
    },
    {
      path: routes.workspaceSeatConrolRuleEdit({
        workspaceId: ':workspaceId',
        seatControlRuleId: ':seatControlRuleId',
      }),
      element: editForm,
    },
  ],
};

export default workspaceSeatConrolRulesPage;
