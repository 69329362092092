import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { loadGlobalBotSettings, updateGlobalAccount } from '../../../api';
import { GlobalAccountSchema } from '../../../api/schemas/account/globalAccountSchema';
import Form from '../../../components/Form';
import Input from '../../../components/Input';
import PageAddon from '../../../components/PageAddon';
import Select from '../../../components/Select';
import SubmitButton from '../../../components/SubmitButton';
import notify from '../../../utils/notify';
import { trainerGameTypeOptions } from '../../../utils/trainer';
import AccountFormLimitIds from '../../account/AccountFormLimitIds';
import AccountGeolocationDialog from '../../account/AccountGeolocationDialog';
import { maxTableOptions, tableSizeOptions } from '../../account/helpers';
import { GlobalAccountFields as Fields } from '../../account/types';
import { getBotSettingCaption } from '../../global-bot-setting-rule/helpers';
import { can } from '../../permission/helpers';
import { getDefaultValues } from './helpers';
import styles from './styles.module.scss';

interface Props {
  data: GlobalAccountSchema;
  onClose: () => void;
}

const GlobalAccountForm = (props: Props) => {
  const { data, onClose } = props;

  const { t } = useTranslation();

  const form = useForm<Fields>({
    defaultValues: getDefaultValues({ data }),
  });

  const {
    register,
    control,
    formState: { errors },
  } = form;

  const onSubmit = async (values: Fields) => {
    const [lat, lng] = values.geo.split(',');

    const payload = {
      game_types: values.game_types.map((item) => item.value),
      comparative_limits: values.comparative_limits.map((item) => item.value),
      table_sizes: values.table_sizes.map((item) => item.value),
      ...(values.max_tables && { max_tables: values.max_tables.value }),
      geo: values.geo ? { lat: Number(lat), lng: Number(lng) } : null,
      bot_settings_ids: values.bot_settings_ids.map((item) => item.value),
    };

    await updateGlobalAccount({ accountId: data.id, payload });

    onClose();
    notify('success', { title: t('sentences.changes_have_been_saved') });
  };

  return (
    <PageAddon onClose={onClose} title={`${t('common.edit')} ${data.mga_id}`}>
      <Form form={form} onSubmit={onSubmit}>
        <Controller
          name="game_types"
          control={control}
          render={({ field }) => (
            <Select.Multi
              renderValueAsTag
              label={t('common.game_types')}
              value={field.value}
              onChange={field.onChange}
              error={errors.game_types?.message}
              options={trainerGameTypeOptions}
            />
          )}
        />
        <AccountFormLimitIds />
        <Controller
          name="table_sizes"
          control={control}
          render={({ field }) => (
            <Select.Multi
              label={t('common.table_size')}
              options={tableSizeOptions}
              value={field.value}
              onChange={field.onChange}
              error={errors.table_sizes?.message}
            />
          )}
        />
        <Controller
          name="max_tables"
          control={control}
          render={({ field }) => (
            <Select
              label={t('common.max_tables')}
              options={maxTableOptions}
              value={field.value}
              onChange={field.onChange}
              error={errors.max_tables?.message}
            />
          )}
        />
        <Input
          {...register('geo')}
          label={t('common.geolocation')}
          extra={<AccountGeolocationDialog />}
          className={styles.input}
        />
        {can.botSetting.read && (
          <Controller
            name="bot_settings_ids"
            control={control}
            render={({ field }) => (
              <Select.Async
                onLoad={async (args) => {
                  const response = await loadGlobalBotSettings({
                    ...args,
                    maintenance_in: data?.maintenance.id || '',
                  });

                  const options = response.items.map((item) => ({
                    value: item.id,
                    label: item.name,
                    caption: getBotSettingCaption(item),
                  }));

                  return options;
                }}
              >
                {({ options, ...rest }) => (
                  <Select.Multi
                    label={t('common.bot_settings')}
                    options={options}
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.bot_settings_ids?.message}
                    {...rest}
                  />
                )}
              </Select.Async>
            )}
          />
        )}
        <PageAddon.Controls>
          <SubmitButton>{t('common.save')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default GlobalAccountForm;
