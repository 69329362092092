import { activateWorkspaceSeatControlRule, deactivateWorkspaceSeatControlRule } from '../../api';
import { SeatControlRuleSchema } from '../../api/schemas/seatControlRuleSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Checkbox from '../../components/Checkbox';
import { handleError } from '../../utils/form';

interface Props {
  data: SeatControlRuleSchema;
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceSeatControlRuleIsActive = (props: Props) => {
  const { data, workspaceId } = props;

  const { id, is_active, is_deleted } = data;

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (event.target.checked) {
        await activateWorkspaceSeatControlRule({ workspaceId, seatControlRuleId: id });
      } else {
        await deactivateWorkspaceSeatControlRule({ workspaceId, seatControlRuleId: id });
      }
    } catch (error) {
      handleError({ error });
    }
  };

  return (
    <Checkbox variant="switch" checked={is_active} onChange={handleChange} disabled={is_deleted} />
  );
};

export default WorkspaceSeatControlRuleIsActive;
