import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  bulkUpdateWorkspaceAccounts,
  bulkUpdateFilteredWorkspaceAccounts,
  bulkSwitchOnWorkspaceAccountsPilot,
  bulkSwitchOffWorkspaceAccountsPilot,
  loadWorkspaceAccountsVNC,
  loadFilteredWorkspaceAccountsVNC,
} from '../../../api';
import { PILOT_STATUS } from '../../../api/schemas/account';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import bulkSwitchOffFilteredWorkspaceAccountsPilot from '../../../api/workspace-account/bulkSwitchOffFilteredWorkspaceAccountsPilot';
import bulkSwitchOnFilteredWorkspaceAccountsPilot from '../../../api/workspace-account/bulkSwitchOnFilteredWorkspaceAccountsPilot';
import Button from '../../../components/Button';
import Dropdown from '../../../components/Dropdown';
import Tooltip from '../../../components/Tooltip';
import routes from '../../../config/routes';
import useURL from '../../../hooks/useURL';
import icon from '../../../static/icons';
import { openVNC } from '../../account/helpers';
import useBulkActions from '../useBulkActions';
import useDropdownStages from '../useDropdownStages';
import WorkspaceAccountDuplicateSessionsDialog from '../WorkspaceAccountDuplicateSessionsDialog';
import WorkspaceAccountExportToCSVDialog from '../WorkspaceAccountExportToCSVDialog';
import WorkspaceAccountRemoveSessionsDialog from '../WorkspaceAccountRemoveSessionsDialog';
import { useWorkspaceAccountsContext } from '../WorkspaceAccountsContext';
import styles from './styles.module.scss';

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceAccountTableSelectionActions = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();
  const { navigate } = useURL();
  const { displayAll } = useWorkspaceAccountsContext();

  const [duplicateSessions, setDuplicateSessions] = useState(false);
  const [removeSessions, setRemoveSessions] = useState(false);

  const { ids, params, selectAllMode, disabled, handle } = useBulkActions();

  const { stages, activeStage } = useDropdownStages({
    workspaceId,
    displayAll,
    onClick: (stage) =>
      handle(() => {
        const fn = selectAllMode
          ? bulkUpdateFilteredWorkspaceAccounts
          : bulkUpdateWorkspaceAccounts;

        return fn({
          workspaceId,
          payload: { account_ids: ids, to_edit: { stage_id: stage.id } },
          params,
        });
      }),
  });

  return (
    <>
      {duplicateSessions && (
        <WorkspaceAccountDuplicateSessionsDialog
          workspaceId={workspaceId}
          onClose={() => setDuplicateSessions(false)}
        />
      )}
      {removeSessions && (
        <WorkspaceAccountRemoveSessionsDialog
          workspaceId={workspaceId}
          onClose={() => setRemoveSessions(false)}
        />
      )}
      <Dropdown options={stages} className={styles.dropdown}>
        {({ open, referenceProps }) => (
          <Tooltip label={t('common.move_to')}>
            <Button
              danger
              focused={open}
              variant="transparent"
              icon={icon('cornerDownRight', 16)}
              data-testid="bulk-move-to"
              disabled={disabled}
              {...referenceProps}
            />
          </Tooltip>
        )}
      </Dropdown>
      <WorkspaceAccountExportToCSVDialog workspaceId={workspaceId} />
      <Dropdown
        options={[
          {
            icon: icon('copy', 20),
            label: t('common.duplicate_schedule'),
            onClick: () => setDuplicateSessions(true),
          },
          {
            icon: icon('broom', 20),
            label: t('sentences.remove_planned_sessions'),
            onClick: () => setRemoveSessions(true),
          },
        ]}
        className={styles.dropdown}
      >
        {({ open, referenceProps }) => (
          <Tooltip label={t('common.schedule_actions')}>
            <Button
              focused={open}
              variant="transparent"
              icon={icon('calendar', 16)}
              data-testid="bulk-schedule-actions"
              disabled={disabled}
              {...referenceProps}
            />
          </Tooltip>
        )}
      </Dropdown>
      <Dropdown
        options={[
          {
            icon: icon('play', 20),
            label: t('common.run_pilot'),
            confirm: true,
            confirmText: t('common.run'),
            disabled: activeStage && !activeStage.is_playable,
            onClick: () =>
              handle(() => {
                const fn = selectAllMode
                  ? bulkSwitchOnFilteredWorkspaceAccountsPilot
                  : bulkSwitchOnWorkspaceAccountsPilot;

                return fn({
                  workspaceId,
                  payload: ids,
                  params,
                });
              }),
          },
          {
            icon: icon('pause', 20),
            label: t('common.stop_pilot'),
            confirm: true,
            confirmText: t('common.stop'),
            disabled: activeStage && !activeStage.is_playable,
            onClick: () =>
              handle(() => {
                const fn = selectAllMode
                  ? bulkSwitchOffFilteredWorkspaceAccountsPilot
                  : bulkSwitchOffWorkspaceAccountsPilot;

                return fn({
                  workspaceId,
                  payload: ids,
                  params,
                });
              }),
          },
          {
            icon: icon('monitor', 20),
            label: t('common.open_vnc'),
            confirm: true,
            confirmDanger: false,
            confirmText: t('common.open'),
            onClick: () =>
              handle(async () => {
                let count = 1;

                const fn = selectAllMode
                  ? loadFilteredWorkspaceAccountsVNC
                  : loadWorkspaceAccountsVNC;

                const { accounts } = await fn({
                  workspaceId,
                  payload: ids,
                  params,
                });

                accounts.forEach((item) => {
                  if (item.pilot_status === PILOT_STATUS.ON) {
                    openVNC(item, count * 20);

                    count += 1;
                  }
                });
              }),
          },
        ]}
        className={styles.dropdown}
      >
        {({ open, referenceProps }) => (
          <Tooltip label={t('common.pilot_actions')}>
            <Button
              focused={open}
              variant="transparent"
              icon={icon('monitor', 16)}
              data-testid="bulk-pilot-actions"
              disabled={disabled}
              {...referenceProps}
            />
          </Tooltip>
        )}
      </Dropdown>
      <Dropdown
        options={[
          {
            label: t('common.accounts'),
            onClick: () => navigate(routes.workspaceAccountsBulkEdit({ workspaceId })),
          },
          {
            label: t('common.strategy_profile'),
            onClick: () =>
              navigate(routes.workspaceAccountsBulkStrategyProfileEdit({ workspaceId })),
          },
          {
            label: t('common.poker_room_config'),
            onClick: () => navigate(routes.workspaceAccountsBulkRoomConfig({ workspaceId })),
          },
        ]}
        className={styles.dropdown}
      >
        {({ open, referenceProps }) => (
          <Tooltip label={t('common.edit')}>
            <Button
              focused={open}
              variant="transparent"
              icon={icon('edit', 16)}
              data-testid="bulk-edit"
              disabled={disabled}
              {...referenceProps}
            />
          </Tooltip>
        )}
      </Dropdown>
    </>
  );
};

export default WorkspaceAccountTableSelectionActions;
