import clsx from 'clsx';
import { useState, ReactNode } from 'react';
import staticIcon from '../../../static/icons';
import PageAddonCollapseError from './PageAddonCollapseError';
import PageAddonCollapseField from './PageAddonCollapseField';
import styles from './styles.module.scss';

interface Props {
  icon?: ReactNode;
  label: string;
  children?: ReactNode;
  defaultOpen?: boolean;
}

const PageAddonCollapse = (props: Props) => {
  const { icon, label, children, defaultOpen = false } = props;

  const [open, setOpen] = useState(defaultOpen);

  const handleClick = () => setOpen((prev) => !prev);

  return (
    <div className={styles.container}>
      <button
        type="button"
        className={clsx(styles.toggler, open && styles.open)}
        onClick={handleClick}
      >
        <div className={styles.label}>
          {icon}
          {label}
        </div>
        {staticIcon('chevronDown', 16)}
      </button>
      {open && <div className={styles.content}>{children}</div>}
    </div>
  );
};

PageAddonCollapse.Error = PageAddonCollapseError;
PageAddonCollapse.Field = PageAddonCollapseField;

export default PageAddonCollapse;
