import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { GlobalAccountSchema } from '../../api/schemas/account/globalAccountSchema';
import { GlobalUserSchema } from '../../api/schemas/globalUserSchema';
import Button from '../../components/Button';
import PageAddon from '../../components/PageAddon';
import Tooltip from '../../components/Tooltip';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import icon from '../../static/icons';
import { addWhen } from '../../utils';
import AccountPilotButton from '../account/AccountPilotButton';
import AccountPilotView from '../account/AccountPilotView';
import { getScheduleTooltip } from '../account/helpers';
import { OnTransactionsOpen } from '../account/types';
import { can } from '../permission/helpers';
import GlobalAccountDetailBalanceTab from './GlobalAccountDetailBalanceTab';
import GlobalAccountDetailDetailsTab from './GlobalAccountDetailDetailsTab';
import GlobalAccountDetailDropdown from './GlobalAccountDetailDropdown';
import GlobalAccountDetailTablesTab from './GlobalAccountDetailTablesTab';
import { useGlobalAccountTablesQuery } from './queries';

interface Props {
  data: GlobalAccountSchema;
  onClose: () => void;
  onEdit: () => void;
  onRoomConfig: () => void;
  onTransactionsOpen?: OnTransactionsOpen;
  getUserRoute?: (args: { userId: GlobalUserSchema['id'] }) => string;
}

const GlobalAccountDetail = (props: Props) => {
  const { data, onClose, onEdit, onRoomConfig, onTransactionsOpen, getUserRoute } = props;

  const { t } = useTranslation();
  const { navigate } = useURL();

  /* const { fingerprintAccountId } = urlParams; */

  const { id, mga_id, comparative_limits } = data;

  const { data: tables } = useGlobalAccountTablesQuery(data.id, can.lobby.read && data.is_online);

  return (
    <PageAddon.Group>
      <Outlet />
      {/*
        раньше тут была завязка на пропс nested. Удалил её т.к. возможно из-за
        рефакторинга роутера она не нужна больше
      */}
      {/* {fingerprintAccountId && (
        <GlobalAccountLoader accountId={fingerprintAccountId}>
          {(args) => (
            <GlobalAccountDetail
              data={args.data}
              onClose={() => setURLParams({ fingerprintAccountId: null })}
            />
          )}
        </GlobalAccountLoader>
      )} */}
      <PageAddon
        title={mga_id}
        onClose={onClose}
        tabs={[
          {
            label: t('common.details'),
            content: (
              <GlobalAccountDetailDetailsTab
                data={data}
                onRoomConfig={onRoomConfig}
                getUserRoute={getUserRoute}
              />
            ),
          },
          ...addWhen(
            {
              label: t('common.balance'),
              content: (
                <GlobalAccountDetailBalanceTab
                  accountData={data}
                  onTransactionsOpen={onTransactionsOpen}
                />
              ),
            },
            can.account.balanceRead
          ),
          ...addWhen(
            {
              label: t('common.tables'),
              content: <GlobalAccountDetailTablesTab data={data} />,
              count: tables?.tables.length,
            },
            can.lobby.read
          ),
          /* {
            label: t('common.diagnostic'),
            content: <GlobalAccountDetailDiagnosticTab data={data} />,
          }, */
          /* ...addWhen(
            {
              label: t('common.fingerprint'),
              content: <GlobalAccountDetailFingerprintTab accountData={data} />,
            },
            can.account.fingerprintRead
          ), */
        ]}
      >
        <PageAddon.Controls>
          <PageAddon.Controls.Group>
            <AccountPilotView data={data} isDetail />
            <AccountPilotButton data={data} isDetail />
            <Tooltip
              label={getScheduleTooltip({ account: data }) || t('common.schedule')}
              hideOnMobile={can.session.read && Boolean(comparative_limits.length)}
            >
              <div>
                <Button
                  icon={icon('calendar', 16)}
                  variant="transparent"
                  onClick={() => navigate(routes.accountSchedule({ accountId: id }))}
                  data-testid="schedule"
                  disabled={!can.session.read || !comparative_limits.length}
                />
              </div>
            </Tooltip>
            <Tooltip
              label={can.account.createUpdate ? t('common.edit') : t('common.no_permission')}
              hideOnMobile={can.account.createUpdate}
            >
              <div>
                <Button
                  icon={icon('edit', 16)}
                  variant="transparent"
                  onClick={onEdit}
                  data-testid="edit"
                  disabled={!can.account.createUpdate}
                />
              </div>
            </Tooltip>
            <GlobalAccountDetailDropdown data={data} />
          </PageAddon.Controls.Group>
        </PageAddon.Controls>
      </PageAddon>
    </PageAddon.Group>
  );
};

export default GlobalAccountDetail;
