import clsx from 'clsx';
import { forwardRef, ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
  variant?: 'default' | 'bordered';
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
}

const InputButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { children, variant = 'default', disabled, className, ...rest } = props;

  return (
    <button
      ref={ref}
      type="button"
      disabled={disabled}
      className={clsx(styles.button, styles[variant], className)}
      data-input-button
      {...rest}
    >
      {children}
    </button>
  );
});

export default InputButton;
