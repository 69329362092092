import { STATUS } from '../../api/schemas/deal';
import { DealSchema } from '../../api/schemas/deal/dealSchema';
import Badge, { Color } from '../../components/Badge';
import { STATUS_LABELS } from './helpers';

const COLORS: Record<DealSchema['status'], Color> = {
  [STATUS.UNKNOWN]: 'grey',
  [STATUS.REVIEW]: 'grey',
  [STATUS.AVAILABLE]: 'blue',
  [STATUS.ACTIVE]: 'green',
  [STATUS.PAUSED]: 'orange',
  [STATUS.DISCUSSION]: 'grey',
  [STATUS.STOP]: 'red',
  [STATUS.NOT_RELEVANT]: 'orange',
};

interface Props {
  data: DealSchema;
}

const GlobalDealStatus = (props: Props) => {
  const { data } = props;

  const { status } = data;

  return <Badge color={COLORS[status]}>{STATUS_LABELS[status]}</Badge>;
};

export default GlobalDealStatus;
