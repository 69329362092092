import { useTranslation } from 'react-i18next';
import { WorkspaceAccountSchema } from '../../../api/schemas/account/workspaceAccountSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Button from '../../../components/Button';
import PageAddon from '../../../components/PageAddon';
import icon from '../../../static/icons';
import AccountStrategyProfile from '../../account/AccountStrategyProfile';
import { useWorkspaceAccountStrategyProfileQuery } from './queries';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  account: WorkspaceAccountSchema;
  onEdit: () => void;
}

const WorkspaceAccountStrategyProfile = (props: Props) => {
  const { workspaceId, account, onEdit } = props;

  const { id } = account;

  const { t } = useTranslation();

  const { data, isPending, isError, refetch } = useWorkspaceAccountStrategyProfileQuery({
    workspaceId,
    accountId: id,
  });

  if (isError) return <PageAddon.Collapse.Error onClick={() => refetch()} />;

  return (
    <>
      <AccountStrategyProfile data={data} loading={isPending} />
      <Button
        size="small"
        variant="secondary"
        icon={icon('edit', 16)}
        disabled={isPending}
        onClick={onEdit}
      >
        {t('common.edit')}
      </Button>
    </>
  );
};

export default WorkspaceAccountStrategyProfile;
