import { useMutation } from '@tanstack/react-query';
import {
  closeGlobalAccountTablesPokerRoom,
  closeGlobalAccountTable,
  resetGlobalAccountTablesCache,
} from '../../api';
import i18n from '../../i18n';
import notify from '../../utils/notify';

export const useCloseGlobalAccountTablesPokerRoomMutation = () =>
  useMutation({
    mutationFn: closeGlobalAccountTablesPokerRoom,
    onSuccess: () =>
      notify('success', {
        title: String(i18n.t('sentences.command_sended')),
      }),
  });

export const useResetGlobalAccountTablesCacheMutation = () =>
  useMutation({
    mutationFn: resetGlobalAccountTablesCache,
    onSuccess: () => notify('success', { title: String(i18n.t('sentences.waiting_list_cleared')) }),
  });

export const useCloseGlobalAccountTableMutation = () =>
  useMutation({
    mutationFn: closeGlobalAccountTable,
    onSuccess: () => notify('success', { title: String(i18n.t('sentences.command_sended')) }),
  });
